export const LightningStrike = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ? props.width : 13}
    height={props.height ? props.height : 22}
    viewBox='0 0 13 22'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    stroke={props.stroke}
    color={props.color}>
    <path d='M7 0L0 13H6V22L13 9H7V0Z' fill='currentColor' />
  </svg>
);
