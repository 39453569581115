//2021-Day

export const ChanceOfThunderstormDay = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10.84'>
    <g fill='currentColor'>
      <path
        className='st0'
        d='M8.36,4.53c-0.05,0-0.11,0-0.16,0.01c-0.23-0.9-1.03-1.52-1.96-1.52c-1.1,0-2,0.88-2.03,1.98
                C3.55,5.05,3.03,5.59,3.03,6.26c0,0.69,0.56,1.26,1.26,1.26h4.08c0.82,0,1.49-0.67,1.49-1.49C9.85,5.2,9.19,4.53,8.36,4.53z
                M4.59,5.05c0-0.91,0.74-1.65,1.65-1.65c0.82,0,1.5,0.58,1.62,1.39c0.01,0.05,0.04,0.1,0.08,0.13s0.1,0.04,0.15,0.03
                c0.1-0.02,0.19-0.03,0.27-0.03c0.61,0,1.11,0.5,1.11,1.11s-0.5,1.11-1.11,1.11H8.34c-0.02,0-0.04,0-0.06,0H4.29
                c-0.48,0-0.87-0.39-0.87-0.88c0-0.48,0.39-0.87,0.87-0.87c0.03,0,0.07,0,0.1,0.01c0.05,0,0.11-0.02,0.15-0.05
                C4.58,5.3,4.6,5.24,4.6,5.18C4.59,5.14,4.59,5.1,4.59,5.05z'
      />
      <path
        className='st0'
        d='M3.83,1.53c0.11,0,0.19-0.09,0.19-0.19V0.41c0-0.11-0.09-0.19-0.19-0.19c-0.1,0-0.19,0.09-0.19,0.19v0.93
                C3.64,1.44,3.72,1.53,3.83,1.53z'
      />
      <path
        className='st0'
        d='M2.33,1.82c0.04,0.06,0.1,0.09,0.16,0.09c0.03,0,0.07-0.01,0.1-0.03c0.04-0.03,0.07-0.07,0.09-0.12
                c0.01-0.05,0-0.1-0.02-0.15L2.17,0.82C2.11,0.74,2,0.71,1.91,0.76C1.82,0.82,1.79,0.94,1.84,1.03L2.33,1.82z'
      />
      <path
        className='st0'
        d='M1.51,2.84C1.54,2.86,1.57,2.87,1.6,2.87c0.07,0,0.13-0.04,0.17-0.1c0.02-0.05,0.03-0.1,0.01-0.15
                S1.73,2.53,1.69,2.51L0.87,2.07c-0.04-0.02-0.1-0.03-0.15-0.01C0.67,2.07,0.63,2.1,0.61,2.15C0.59,2.19,0.58,2.25,0.59,2.3
                C0.6,2.35,0.64,2.39,0.68,2.41L1.51,2.84z'
      />
      <path
        className='st0'
        d='M1.49,3.86c0-0.11-0.09-0.19-0.19-0.19H0.37c-0.1,0-0.19,0.09-0.19,0.19c0,0.11,0.09,0.19,0.19,0.19H1.3
                C1.4,4.05,1.49,3.97,1.49,3.86z'
      />
      <path
        className='st0'
        d='M1.66,4.9c-0.05-0.01-0.1-0.01-0.14,0.02L0.7,5.36C0.61,5.41,0.57,5.52,0.62,5.62c0.03,0.06,0.1,0.1,0.17,0.1
                c0.03,0,0.07-0.01,0.09-0.02L1.7,5.25C1.79,5.2,1.82,5.08,1.78,4.99C1.75,4.94,1.71,4.91,1.66,4.9z'
      />
      <path
        className='st0'
        d='M6.05,2.84c0.03,0,0.07-0.01,0.09-0.02l0.82-0.45l0,0C7.05,2.32,7.08,2.2,7.04,2.11
                C7.01,2.07,6.97,2.04,6.92,2.02C6.87,2,6.82,2.01,6.77,2.04L5.96,2.48C5.91,2.51,5.88,2.55,5.87,2.6S5.86,2.7,5.89,2.75
                C5.92,2.81,5.98,2.84,6.05,2.84z'
      />
      <path
        className='st0'
        d='M2.44,5.83C2.39,5.84,2.35,5.87,2.32,5.91L1.83,6.7C1.78,6.79,1.8,6.91,1.9,6.96C1.93,6.98,1.97,6.99,2,6.99
                c0.07,0,0.13-0.03,0.16-0.09l0.49-0.79c0.05-0.08,0.03-0.2-0.06-0.26C2.54,5.82,2.49,5.81,2.44,5.83z'
      />
      <path
        className='st0'
        d='M5.17,1.91c0.07,0,0.13-0.03,0.16-0.09l0.49-0.79c0.03-0.04,0.04-0.09,0.02-0.14c0-0.05-0.03-0.09-0.08-0.12
                C5.67,0.71,5.56,0.74,5.5,0.83L5,1.62c-0.05,0.09-0.02,0.2,0.07,0.26C5.1,1.9,5.14,1.91,5.17,1.91z'
      />
      <g fill='currentColor'>
        <path
          className='st0'
          d='M4.01,8.04L3.59,8.87C3.55,8.97,3.59,9.08,3.68,9.13c0.03,0.01,0.06,0.02,0.08,0.02
                    c0.07,0,0.14-0.04,0.17-0.1l0.42-0.83C4.4,8.12,4.36,8.01,4.27,7.96C4.18,7.91,4.06,7.95,4.01,8.04z'
        />
        <path
          className='st0'
          d='M8.45,8.04L8.03,8.87C7.99,8.97,8.02,9.08,8.12,9.13C8.15,9.14,8.18,9.15,8.2,9.15c0.07,0,0.14-0.04,0.17-0.1
                    l0.42-0.83C8.84,8.12,8.8,8.01,8.71,7.96C8.61,7.91,8.5,7.95,8.45,8.04z'
        />
        <path
          className='st0'
          d='M5.49,8.05L5.07,8.87c-0.02,0.05-0.03,0.1-0.01,0.15c0.02,0.05,0.05,0.09,0.1,0.11
                    c0.03,0.01,0.06,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.11l0.41-0.83c0.02-0.05,0.03-0.1,0.01-0.15
                    c-0.02-0.05-0.05-0.09-0.1-0.11C5.65,7.91,5.54,7.95,5.49,8.05z'
        />
        <path
          className='st0'
          d='M7.63,8.75C7.59,8.66,7.5,8.6,7.39,8.6H7.28l0.18-0.47c0.05-0.12,0-0.26-0.11-0.32
                    C7.24,7.74,7.09,7.77,7.01,7.86L5.98,9.04C5.92,9.12,5.9,9.23,5.94,9.32c0.04,0.09,0.14,0.15,0.24,0.15h0.13l-0.29,0.67
                    c-0.05,0.12,0,0.26,0.11,0.33c0.04,0.03,0.09,0.04,0.14,0.04c0.08,0,0.16-0.04,0.21-0.1L7.6,9.03C7.67,8.95,7.68,8.84,7.63,8.75z
                    M6.27,10.26l0.45-1.04H6.18l1.03-1.18L6.89,8.87h0.5L6.27,10.26z'
        />
      </g>
    </g>
    <path
      fill='currentColor'
      d='M2.89,5.57c0.05-0.1,0.09-0.2,0.16-0.29C3.03,5.26,3.02,5.24,3,5.22C2.55,4.93,2.28,4.44,2.28,3.9
            c0-0.87,0.7-1.57,1.57-1.57c0.54,0,0.98,0.23,1.27,0.66c0.11-0.06,0.22-0.11,0.34-0.15c-0.01-0.02,0-0.04-0.01-0.05
            c-0.36-0.54-0.92-0.84-1.6-0.84C2.78,1.95,1.9,2.83,1.9,3.9l0,0l0,0c0,0.67,0.34,1.28,0.9,1.64C2.82,5.56,2.85,5.57,2.89,5.57z'
    />
  </svg>
);
