import { useAuth } from "oidc-react";
import { PropsWithChildren, ReactElement } from "react";
import { CustomerEntitlements, UserRoles } from "../../Constants/Constants";

type PermissionGateProps = {
  allowedRoles?: UserRoles[]; // Array of roles that are allowed to view the children
  allowedEntitlements?: CustomerEntitlements[]; // A user must have one of these entitlements to view the children
  fallback?: React.ReactNode; // What gets shown if a user doesn't have permission. Defaults to nothing
}

const PermissionGate = ({
  children,
  allowedRoles,
  allowedEntitlements,
  fallback,
}: PropsWithChildren<PermissionGateProps>): ReactElement => {
  const { userData: user } = useAuth();
  const hasPermission =
    (allowedRoles?.includes(user?.profile?.Role) ?? true) &&
    (allowedEntitlements?.some(entitlement => user?.profile?.permissions?.includes(entitlement)) ?? true);
  
  if (hasPermission) {
    return (children ?? <></>) as ReactElement;
  }
  return (fallback ?? <></>) as ReactElement;
}

export default PermissionGate;