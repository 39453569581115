import { memo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  styled,
  Typography,
  Grid,
  Portal,
  Chip,
} from '@mui/material';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import { Button, Card, Checkbox } from '../Base';
import { useSitesQuery, useRolesQuery, User } from '../../Services/API';

interface FilterSelection {
  [index: string]: boolean | string[];
  inactiveUsers: boolean;
  activeUsers: boolean;
  userGroups: string[];
  userRoles: string[];
}

interface UserTableFilterAccordionProps {
  onFilterSelectionChange: (selection: FilterSelection, isFiltering: boolean) => void;
  currentUser?: User;
  chipContainer?: React.MutableRefObject<null>;
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    '&.Mui-expanded': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const UserTableFilterAccordion = memo(function UserTableFilterAccordion(props: UserTableFilterAccordionProps) {
  const { onFilterSelectionChange, currentUser, chipContainer } = props;
  const [selection, setSelection] = useState<FilterSelection>({
    inactiveUsers: false,
    activeUsers: false,
    userGroups: currentUser && currentUser.roleHierarchy === 3 ? [currentUser.siteName] : [],
    userRoles: [],
  });
  const { data: sites } = useSitesQuery();
  const { data: roles } = useRolesQuery();

  const handleSelectionChange = (s: FilterSelection) => {
    onFilterSelectionChange(
      s,
      (s.activeUsers ? !s.inactiveUsers : s.inactiveUsers) ||
        (s.userGroups.length !== 0 && s.userGroups.length !== sites?.length) ||
        (s.userRoles.length !== 0 && s.userRoles.length !== (roles?.length || 0) + 1)
    );
  };

  const ChipGridContainer = styled(props => <Grid item {...props} />)(({ theme }) => ({
    maxWidth: '30%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  }));

  const filterGroups: { [index: string]: string[] } = {
    Status: [],
    Groups: selection.userGroups,
    Roles: selection.userRoles,
  };

  const generateChips = () => {
    if (selection.inactiveUsers) filterGroups.Status.push('Inactive');
    if (selection.activeUsers) filterGroups.Status.push('Active');

    const keys = Object.keys(filterGroups);
    return keys.map((key, i) => {
      let selectionKey: string = '';
      if (key === 'Groups') selectionKey = 'userGroups';
      else if (key === 'Roles') selectionKey = 'userRoles';

      const values = filterGroups[key];
      if (values.length == 0) return <></>;

      return <ChipGrid title={key} values={values} selectionKey={selectionKey} />;
    });
  };

  interface ChipGridProps {
    title: string;
    values: string[];
    selectionKey: string;
  }
  const ChipGrid = (props: ChipGridProps) => {
    let { title, values, selectionKey } = props;
    return (
      <ChipGridContainer key={title}>
        <Typography mb={1} color='textPrimary'>
          {title}
        </Typography>
        <Grid container spacing={1} display={'flex'} justifyContent='center'>
          {values.map(value => {
            let nextStateValue = () => {
              return { ...selection, [selectionKey]: filterGroups[title].filter(x => x !== value) };
            };
            if (value === 'Inactive') {
              selectionKey = 'inactiveUsers';
              nextStateValue = () => {
                return { ...selection, [selectionKey]: false };
              };
            }
            if (value === 'Active') {
              selectionKey = 'activeUsers';
              nextStateValue = () => {
                return { ...selection, [selectionKey]: false };
              };
            }

            return (
              <Grid item key={value}>
                <Chip
                  variant='outlined'
                  label={value}
                  color='primary'
                  size='small'
                  onDelete={() => {
                    handleSelectionChange(nextStateValue());
                    setSelection(nextStateValue());
                  }}
                  onClick={() => {
                    handleSelectionChange(nextStateValue());
                    setSelection(nextStateValue());
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </ChipGridContainer>
    );
  };

  return (
    <>
      <Accordion>
        <AccordionSummary>
          <Typography>User Status</Typography>
          <Typography
            display='flex'
            flexDirection='column'
            justifyContent='center'
            marginLeft='8px'
            variant='caption'
            color='textSecondary'>
            {!selection.activeUsers && !selection.inactiveUsers
              ? 'Showing 2 of 2'
              : `Showing ${(selection.activeUsers ? 1 : 0) + (selection.inactiveUsers ? 1 : 0)} of 2`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item>
              <Checkbox
                checked={selection.inactiveUsers}
                onChange={e => {
                  handleSelectionChange({ ...selection, inactiveUsers: e.target.checked });
                  setSelection({ ...selection, inactiveUsers: e.target.checked });
                }}
              />
            </Grid>
            <Grid item display='flex' flexDirection='column' justifyContent='center'>
              <Typography>Inactive Users</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionDetails>
          <Grid container>
            <Grid item>
              <Checkbox
                checked={selection.activeUsers}
                onChange={e => {
                  handleSelectionChange({ ...selection, activeUsers: e.target.checked });
                  setSelection({ ...selection, activeUsers: e.target.checked });
                }}
              />
            </Grid>
            <Grid item display='flex' flexDirection='column' justifyContent='center'>
              <Typography>Active Users</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography>User Groups</Typography>
          <Typography
            display='flex'
            flexDirection='column'
            justifyContent='center'
            marginLeft='8px'
            variant='caption'
            color='textSecondary'>
            {`Showing ${selection.userGroups.length < 1 ? sites?.length : selection.userGroups.length} of ${
              sites?.length
            }`}
          </Typography>
        </AccordionSummary>
        {sites &&
          sites.map(s => (
            <AccordionDetails key={s.id}>
              <Grid container>
                <Grid item>
                  <Checkbox
                    checked={selection.userGroups.includes(s.name)}
                    onChange={e => {
                      if (e.target.checked) {
                        handleSelectionChange({ ...selection, userGroups: [...selection.userGroups, s.name] });
                        setSelection({ ...selection, userGroups: [...selection.userGroups, s.name] });
                      } else {
                        handleSelectionChange({
                          ...selection,
                          userGroups: selection.userGroups.filter(x => x !== s.name),
                        });
                        setSelection({ ...selection, userGroups: selection.userGroups.filter(x => x !== s.name) });
                      }
                    }}
                  />
                </Grid>
                <Grid item display='flex' flexDirection='column' justifyContent='center'>
                  <Typography>{s.name}</Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          ))}
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography>User Permission Levels</Typography>
          <Typography
            display='flex'
            flexDirection='column'
            justifyContent='center'
            marginLeft='8px'
            variant='caption'
            color='textSecondary'>
            {`Showing ${selection.userRoles.length < 1 ? 1 + (roles?.length || 0) : selection.userRoles.length} of ${
              1 + (roles?.length || 0)
            }`}
          </Typography>
        </AccordionSummary>
        {roles &&
          roles.map(r => (
            <AccordionDetails key={r.id}>
              <Grid container>
                <Grid item>
                  <Checkbox
                    checked={selection.userRoles.includes(r.name)}
                    onChange={e => {
                      if (e.target.checked) {
                        handleSelectionChange({ ...selection, userRoles: [...selection.userRoles, r.name] });
                        setSelection({ ...selection, userRoles: [...selection.userRoles, r.name] });
                      } else {
                        handleSelectionChange({
                          ...selection,
                          userRoles: selection.userRoles.filter(x => x !== r.name),
                        });
                        setSelection({ ...selection, userRoles: selection.userRoles.filter(x => x !== r.name) });
                      }
                    }}
                  />
                </Grid>
                <Grid item display='flex' flexDirection='column' justifyContent='center'>
                  <Typography>{r.displayName || r.name}</Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          ))}
        <AccordionDetails>
          <Grid container>
            <Grid item>
              <Checkbox
                checked={selection.userRoles.includes('Notification Only')}
                onChange={e => {
                  if (e.target.checked) {
                    handleSelectionChange({ ...selection, userRoles: [...selection.userRoles, 'Notification Only'] });
                    setSelection({ ...selection, userRoles: [...selection.userRoles, 'Notification Only'] });
                  } else {
                    handleSelectionChange({
                      ...selection,
                      userRoles: selection.userRoles.filter(x => x !== 'Notification Only'),
                    });
                    setSelection({
                      ...selection,
                      userRoles: selection.userRoles.filter(x => x !== 'Notification Only'),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item display='flex' flexDirection='column' justifyContent='center'>
              <Typography>Notification Only</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Portal container={chipContainer?.current}>
        {(selection.activeUsers ||
          selection.inactiveUsers ||
          selection.userGroups.length > 0 ||
          selection.userRoles.length > 0) && (
          <Card>
            <Grid container display={'flex'} justifyContent='flex-start'>
              <Typography mb={1} mt={1} color='textPrimary' variant='h6'>
                Active Filters
              </Typography>
              <Button
                type='clearBlue'
                style={{ border: 0 }}
                onClick={() => {
                  handleSelectionChange({
                    inactiveUsers: false,
                    activeUsers: false,
                    userGroups: currentUser && currentUser.roleHierarchy === 3 ? [currentUser.siteName] : [],
                    userRoles: [],
                  });
                  setSelection({
                    inactiveUsers: false,
                    activeUsers: false,
                    userGroups: currentUser && currentUser.roleHierarchy === 3 ? [currentUser.siteName] : [],
                    userRoles: [],
                  });
                }}>
                Clear Filters
              </Button>
            </Grid>
            <Grid container spacing={4} display='flex' justifyContent={'flex-start'}>
              {generateChips()}
            </Grid>
          </Card>
        )}
      </Portal>
    </>
  );
});
