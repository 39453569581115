import { BBox } from '../../Components/Dashboard/Map/Util';
import { Coordinates } from '../../features/map/MapHelpers';
import { api } from './api';

export interface Strike {
    age: number;
    amp: number;
    latitude: number;
    longitude: number;
    strikeId: string;
    timeStamp: number;
    type: number;
    // timeStamp: 1632246523
}

export interface RadarParams {
    type: string;
    isFuture: boolean;
    frame: number;
    product?: string;
}

export interface NWSMapAlert {
    text: string;
    code: string;
    type: string;
}

export interface TropicalForecast {
    meta: {
        from: string;
        page: number;
        pages: number;
    };
    data: {
        advisoryNumber: string;
        name: string;
        source: string;
        pressure: {
            units: string;
            value: number;
        };
        conePolygon: number[][];
        points: Point[];
        movement: {
            direction: {
                units: string;
                value: number;
            };
            speed: {
                units: string;
                value: number;
            };
        };
        issueTime: string;
        type: string;
    }[];
}

export interface Point {
    coordinates: number[];
    time: string;
    coneRadius: {
        units: string;
        endpoints: number[][];
        value: number;
    };
    wind: {
        units: string;
        gust: number;
        radii: {
            quadrants: {
                units: string;
                sw: number;
                nw: number;
                ne: number;
                se: number;
            };
        };
        maxSustained: number;
    };
}

const baseUrl: string = process.env.REACT_APP_MAP_API_BASE_URL as string;

const mapApi = api.injectEndpoints({
    endpoints: builder => ({
        lightningMap: builder.query<string, BBox>({
            query: (box: BBox) => ({
                headers: { Accept: 'application/geo+json' },
                url: `${baseUrl}/ClusterStrikes?top=${box.top}&right=${box.right}&left=${box.left}&bottom=${box.bottom}&zoom=${box.zoom}&nonClusterLevel=8`
            }),
            transformResponse: (response: { data: string }) => response.data
        }),
        lightningMapGeoJson: builder.query<string, null>({
            query: () => ({ headers: { Accept: 'application/geo+json' }, url: `${baseUrl}/Strikes` }),
            // query: (box) => ({headers: {'Accept': 'application/geo+json'}, url:`${baseUrl}/ClusterStrikes?top=${box.top}&right=${box.right}&left=${box.left}&bottom=${box.bottom}&zoom=${box.zoom}`}),
            transformResponse: (response: { data: string }) => response.data
        }),
        stormVectorsGeoJson: builder.query<string, null>({
            query: () => ({ headers: { Accept: 'application/geo+json' }, url: `${baseUrl}/StormVectors/All` }),
            transformResponse: (response: { data: string }) => response.data
        }),
        radar: builder.query<string, RadarParams>({
            query: params => ({
                url:
                    params.product === ''
                        ? `${baseUrl}/${params.type}?frame=${params.frame}&future=${params.isFuture ? 'true' : 'false'}`
                        : `${baseUrl}/${params.type}?frame=${params.frame}&product=${params.product}`
            }),

            transformResponse: (response: { data: string }) => response.data
        }),
        currentRoadConditions: builder.query<string, null>({
            query: () => ({ url: `${baseUrl}/url?product=road-condition-current-hires&mask=Standard-Mercator` }),
            transformResponse: (response: { data: string }) => response.data
        }),
        forecastedRoadConditions: builder.query<string, null>({
            query: () => ({ url: `${baseUrl}/url?product=road-condition&mask=Standard-Mercator` }),
            transformResponse: (response: { data: string }) => response.data
        }),
        surfaceAnalysis: builder.query<string, null>({
            query: () => ({ url: `${baseUrl}/url?product=surface-analysis&mask=Standard-Mercator` }),
            transformResponse: (response: { data: string }) => response.data
        }),
        tropicalModels: builder.query<string, null>({
            query: () => ({ url: `${baseUrl}/url?product=tropical-spaghetti-plot-us&mask=Standard-Mercator` }),
            transformResponse: (response: { data: string }) => response.data
        }),
        tropicalForecast: builder.query<TropicalForecast, null>({
            query: () => ({ url: `${baseUrl}/TropicalForecast` }),
            transformResponse: (response: { data: TropicalForecast }) => response.data
        }),
        alertsByPoint: builder.mutation<NWSMapAlert[], Coordinates>({
            query: params => ({ url: `${baseUrl}/Alerts/ByPoint?latitude=${params.latitude}&longitude=${params.longitude}` }),
            transformResponse: (response: { data: NWSMapAlert[] }) => response.data
        }),
        radarAnimList: builder.query<string[], RadarParams>({
            query: params => ({
                url:
                    params.product === ''
                        ? `${baseUrl}/${params.type}/WMS?future=${params.isFuture}`
                        : `${baseUrl}/${params.type}/WMS?product=${params.product}`
            }),
            transformResponse: (response: { data: string[] }) => response.data
        }),
        radarTmsList: builder.query<string[], RadarParams>({
            query: params => ({
                url:
                    params.product === ''
                        ? `${baseUrl}/${params.type}/TMS?future=${params.isFuture ? 'true' : 'false'}`
                        : `${baseUrl}/${params.type}/TMS?product=${params.product}`
            }),
            transformResponse: (response: { data: string[] }) => response.data
        })
    })
});

export const {
    useLightningMapGeoJsonQuery,
    useStormVectorsGeoJsonQuery,
    useLightningMapQuery,
    useRadarQuery,
    useRadarAnimListQuery,
    useSurfaceAnalysisQuery,
    useCurrentRoadConditionsQuery,
    useForecastedRoadConditionsQuery,
    useTropicalModelsQuery,
    useTropicalForecastQuery,
    useAlertsByPointMutation,
    useRadarTmsListQuery
} = mapApi;
