//0002-Day

export const HazeDay = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st4'
        d='M6.86,6.56c0.12,0,0.22-0.1,0.22-0.22c0-0.12-0.1-0.22-0.22-0.22l-6.29,0c-0.06,0-0.12,0.02-0.16,0.07
            c-0.04,0.04-0.06,0.1-0.06,0.15c0,0.12,0.1,0.22,0.22,0.22L6.86,6.56z'
      />
      <path
        className='st4'
        d='M7.63,6.56l1.41,0c0.12,0,0.22-0.1,0.22-0.22c0-0.12-0.1-0.22-0.22-0.22l-1.41,0c-0.06,0-0.11,0.02-0.16,0.07
            c-0.04,0.04-0.06,0.1-0.06,0.15C7.41,6.46,7.51,6.56,7.63,6.56z'
      />
      <path
        className='st4'
        d='M4.6,8.08c0-0.12-0.1-0.22-0.22-0.22l-3.72,0c-0.06,0-0.11,0.02-0.16,0.07c-0.04,0.04-0.06,0.1-0.06,0.15
            c0,0.12,0.1,0.22,0.22,0.22l3.72,0C4.5,8.3,4.6,8.2,4.6,8.08z'
      />
      <path
        className='st4'
        d='M5.25,8.3l1.15,0c0.12,0,0.22-0.1,0.22-0.22c0-0.12-0.1-0.22-0.22-0.22l-1.15,0c-0.06,0-0.11,0.02-0.16,0.07
            c-0.04,0.04-0.06,0.1-0.06,0.15C5.03,8.2,5.12,8.3,5.25,8.3z'
      />
      <path
        className='st4'
        d='M8.33,7.86l-1.15,0c-0.06,0-0.12,0.02-0.16,0.07c-0.04,0.04-0.06,0.1-0.06,0.15c0,0.12,0.1,0.22,0.22,0.22
            l1.15,0c0.12,0,0.22-0.1,0.22-0.22S8.45,7.86,8.33,7.86z'
      />
      <path
        className='st4'
        d='M5.18,7.21c0-0.12-0.1-0.22-0.22-0.22l-3.72,0c-0.06,0-0.11,0.02-0.16,0.07C1.04,7.1,1.02,7.15,1.02,7.21
            c0,0.12,0.1,0.22,0.22,0.22l3.72,0C5.08,7.43,5.18,7.33,5.18,7.21z'
      />
      <path
        className='st4'
        d='M9.43,6.99l-3.72,0c-0.06,0-0.11,0.02-0.16,0.07C5.51,7.1,5.49,7.15,5.49,7.21c0,0.12,0.1,0.22,0.22,0.22
            l3.72,0c0.12,0,0.22-0.1,0.22-0.22C9.65,7.09,9.55,6.99,9.43,6.99z'
      />
      <path
        className='st4'
        d='M7.43,8.73l-3.72,0c-0.06,0-0.11,0.02-0.16,0.07c-0.04,0.04-0.06,0.1-0.06,0.15c0,0.12,0.1,0.22,0.22,0.22
            l3.72,0c0.12,0,0.22-0.1,0.22-0.22C7.65,8.83,7.55,8.73,7.43,8.73z'
      />
      <path
        className='st4'
        d='M6.65,2.89c0.08,0,0.15-0.04,0.19-0.1l0.61-0.98c0.06-0.1,0.03-0.24-0.07-0.3C7.33,1.47,7.27,1.47,7.21,1.48
            C7.15,1.49,7.1,1.53,7.07,1.58L6.47,2.56C6.44,2.61,6.43,2.67,6.44,2.72c0.01,0.06,0.05,0.1,0.1,0.13
            C6.57,2.88,6.61,2.89,6.65,2.89z'
      />
      <path
        className='st4'
        d='M7.92,5.34L7.92,5.34c0,0.12,0.1,0.22,0.22,0.22l1.15,0c0.12,0,0.22-0.1,0.22-0.22S9.42,5.11,9.3,5.11l-1.15,0
            C8.02,5.12,7.92,5.21,7.92,5.34z'
      />
      <path
        className='st4'
        d='M6.92,5.8h0.45l0.01-0.06c0.02-0.14,0.03-0.26,0.03-0.38c0-1.33-1.08-2.4-2.4-2.4c-1.33,0-2.41,1.08-2.41,2.4
            c0,0.12,0.01,0.24,0.03,0.38L2.64,5.8h0.45L3.07,5.71C3.05,5.58,3.03,5.47,3.03,5.36c0-1.08,0.88-1.97,1.97-1.97
            c1.08,0,1.97,0.88,1.97,1.97c0,0.11-0.01,0.23-0.03,0.35L6.92,5.8z'
      />
      <path
        className='st4'
        d='M7.77,4.08c0.04,0,0.07-0.01,0.1-0.03l1.02-0.54C8.94,3.48,8.98,3.44,9,3.38c0.02-0.06,0.01-0.12-0.02-0.17
            c-0.06-0.11-0.19-0.15-0.3-0.09L7.67,3.67C7.61,3.7,7.58,3.74,7.56,3.8C7.54,3.85,7.55,3.91,7.58,3.96
            C7.61,4.03,7.69,4.08,7.77,4.08z'
      />
      <path
        className='st4'
        d='M0.7,5.58L0.7,5.58l1.15,0c0.12,0,0.22-0.1,0.22-0.22s-0.1-0.22-0.22-0.22l-1.15,0
            c-0.06,0-0.11,0.02-0.16,0.07C0.51,5.24,0.48,5.3,0.49,5.36C0.49,5.48,0.58,5.58,0.7,5.58z'
      />
      <path
        className='st4'
        d='M5,2.42c0.12,0,0.22-0.1,0.22-0.22V1.05c0-0.12-0.1-0.22-0.22-0.22s-0.22,0.1-0.22,0.22V2.2
            C4.78,2.32,4.88,2.42,5,2.42z'
      />
      <path
        className='st4'
        d='M2.14,4.02c0.03,0.02,0.07,0.03,0.1,0.03c0.08,0,0.15-0.04,0.19-0.11c0.06-0.11,0.02-0.24-0.09-0.3L1.34,3.09
            C1.23,3.03,1.1,3.07,1.04,3.17C1.01,3.23,1,3.28,1.02,3.34c0.02,0.06,0.05,0.1,0.1,0.13L2.14,4.02z'
      />
      <path
        className='st4'
        d='M3.15,2.79c0.04,0.06,0.11,0.1,0.19,0.1c0.04,0,0.08-0.01,0.12-0.03c0.1-0.06,0.13-0.2,0.07-0.3L2.91,1.58
            C2.88,1.53,2.83,1.5,2.77,1.48c-0.06-0.01-0.12,0-0.17,0.03c-0.1,0.06-0.13,0.2-0.07,0.3L3.15,2.79z'
      />
    </g>
  </svg>
);
