import { BaseModal, ModalTooltip } from '../Base/BaseModal';
import Button from '../Base/Button';
import { Grid, Box, useTheme, Theme, Typography, Autocomplete, TextField, createFilterOptions } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { WarningAmberOutlined } from '@mui/icons-material';
import React from 'react';
import { useLocation } from 'wouter';
import { useCustomersQuery } from '../../Services/API/customerApi';
// import { TextField } from '../Base';

interface WarningDialogProps {
  button: JSX.Element;
  disabled?: boolean;
  onConfirm?: React.MouseEventHandler;
  children?: JSX.Element;
  tooltip?: ModalTooltip;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '&.Mui-focused': {
        backgroundColor: theme.palette.primary.main + ' !important',
        borderColor: 'transparent',
        color: theme.palette.text.primary,
      },
    },
  })
);

export function SuperAdminWidgetModal(props: WarningDialogProps) {
  const theme = useTheme();
  const { button, disabled, onConfirm, children, tooltip } = props;
  const { data: customers } = useCustomersQuery();
  const [selectedCustomer, setSelectedCustomer] = React.useState<{ label: string; id: number } | null>(null);
  const classes = useStyles(theme);
  const [location, setLocation] = useLocation();

  let autoCompleteOptions: { label: string; id: number }[] = [];
  if (customers != undefined) {
    autoCompleteOptions = customers.map(customer => {
      return {
        label: customer.name,
        id: customer.id,
      };
    });
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: { label: string; id: number }) => option.label,
    ignoreCase: true,
    limit: 3,
  });

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onConfirm) onConfirm(e);
    if (selectedCustomer) setLocation(`/widgets/${selectedCustomer.id}/builder`);

    handleClose();
  };

  const SuperAdminModal = (props: { children: React.ReactNode }) => {
    return (
      <BaseModal
        tooltip={tooltip}
        disabled={disabled}
        modalState={{ open: dialogOpen, handleOpen: handleOpen, handleClose: handleClose }}
        modalButton={button}>
        {props.children}
      </BaseModal>
    );
  };

  return (
    <SuperAdminModal>
      <Grid container spacing={2}>
        <Grid container item style={{ marginBottom: 8 }}>
          <Grid item md={10} xs={12}>
            <Typography variant='h5'>Super Admin Create Widget</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid xs={12} item>
            <Typography variant='body1'>Search for a customer by name</Typography>
          </Grid>
          <Grid xs={12} item>
            {autoCompleteOptions.length > 0 && (
              <Autocomplete
                classes={{ option: classes.option }}
                options={autoCompleteOptions}
                renderInput={params => <TextField {...params} />}
                filterOptions={filterOptions}
                value={selectedCustomer}
                onChange={(e, value) => setSelectedCustomer(value)}
              />
            )}
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item md={6} xs={false} />
          <Grid item container spacing={1} md={6} xs={12}>
            <Grid item xs={6}>
              <Button type='clear' onClick={handleClose}>
                <Typography variant='body1'>Cancel</Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button disabled={selectedCustomer == null} type='primary' onClick={handleConfirm}>
                <Typography variant='body1'>Create Widget</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SuperAdminModal>
  );
}
