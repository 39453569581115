export const Thermometer = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ? props.width : '10'}
    height={props.height ? props.height : '19'}
    viewBox='0 0 10 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4 0C2.90694 0 2 0.906937 2 2V10.1855C0.843001 11.0972 0 12.4123 0 14C0 16.7495 2.25048 19 5 19C7.74952 19 10 16.7495 10 14C10 12.4123 9.157 11.0972 8 10.1855V10H10V8H8V6H10V4H8V2C8 0.906937 7.09306 0 6 0H4ZM4 2H6V4H4V2ZM4 6H6V11.1309L6.49805 11.4199C7.39835 11.9438 8 12.8882 8 14C8 15.6685 6.66848 17 5 17C3.33152 17 2 15.6685 2 14C2 12.8882 2.60165 11.9438 3.50195 11.4199L4 11.1309V6Z'
      fill={props.color}
    />
  </svg>
);
