/* eslint-disable react-hooks/exhaustive-deps */
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import InputMap from '../../Base/Input/InputMap';
import { InputRow, Button } from '../../Base';
import { CustomTextField } from '../../Base/Input/TextField';
import { Box, Autocomplete, InputAdornment, MenuItem, Typography, useTheme, Dialog, DialogTitle, DialogContent, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Location, useLazyGetLocationWhitelistQuery} from '../../../Services/API';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { editedLocation, getEditedLocation } from '../../../features/dash/dashSlice';

export interface SearchableInputMapData {
  address: string;
  geometry: { lat: number; lng: number };
}

interface SearchableInputMapProps {
  value?: string;
  hideSearch?: boolean;
  geometry?: { lat: number; lng: number };
  radii?: { radius1: number; radius2: number; radius3: number, radius4: number };
  disableMapInput?: boolean;
  isError?: boolean;
  helperText?: string;
  placeholder?: string;
}

interface GooglePlacesAutocompleteProps {
  searchValue?: string;
  isError?: boolean;
  helperText?: string;
  placeholder?: string;
  onSelect?: (value: string) => void;
}

function GooglePlacesSearch(props: GooglePlacesAutocompleteProps) {
  const theme = useTheme();
  const { searchValue, onSelect, isError = false, helperText, placeholder } = props;
  const [options, setOptions] = useState<string[]>([]);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete();

  useEffect(() => {
    if (searchValue) setValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (status === 'OK') setOptions(data.map(sugg => sugg.description));
  }, [status, data]);

  const handleInput = (v: string): void => {
    setValue(v);
  };

  const handleSelect = (val: string | null): void => {
    setValue(val as string, false);
    if (onSelect !== undefined) onSelect(val as string);
  };

  return (
    <Autocomplete
      onInputChange={(e, v, r) => handleInput(v)}
      onChange={(e, v, r) => (r === 'selectOption' ? handleSelect(v) : false)}
      value={value}
      disabled={!ready}
      options={options}
      style={{ width: '100%' }}
      renderOption={(props, option, state) => (
        <MenuItem
          {...props}
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.action.hoverSecondary + ' !important',
            },
            '&.Mui-focused': {
              backgroundColor: theme.palette.action.hoverSecondary + ' !important',
            },
          }}>
          <Typography>{option}</Typography>
        </MenuItem>
      )}
      renderInput={params => (
        <CustomTextField
          sx={{
            '& .MuiOutlinedInput-root': { paddingTop: 0, paddingBottom: 0 },
          }}
          placeholder={placeholder}
          helperText={helperText}
          error={isError}
          variant='outlined'
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

export default function SearchableInputMap(props: SearchableInputMapProps) {
  const dispatch = useAppDispatch();
  const { hideSearch = false, disableMapInput = false, isError = false, helperText, placeholder } = props;
  const editLocation: Location = useAppSelector(getEditedLocation);
  const [warningMessage, setWarningMessage] = useState(false);
  const [getWhitelist, whitelistResult] = useLazyGetLocationWhitelistQuery();
  const theme = useTheme();

  useEffect(() => {
    if (whitelistResult.data?.display && !whitelistResult.isFetching && !whitelistResult.isUninitialized && !whitelistResult.isError) {
      setWarningMessage(true);
    }
  }, [whitelistResult])

  const handleSelect = (search: string) => {
    getGeocode({ address: search })
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        getWhitelist({Latitude: latLng.lat, Longitude: latLng.lng})
        dispatch(
          editedLocation({ ...editLocation, locationName: search, latitude: latLng.lat, longitude: latLng.lng })
        );
      })
      .catch(error => {
        getGeocode({ location: { lat: editLocation.latitude, lng: editLocation.longitude } })
          .then(results => {
            dispatch(editedLocation({ ...editLocation, locationName: results[0].formatted_address }));
          })
          .catch(error => {
            // console.debug("Error: ", error);
          });
        // console.debug("Error: ", error);
      });
  };

  const handleMapSelect = (geometry: { lat: number; lng: number }) => {
    getGeocode({ location: geometry })
      .then(results => {
        getWhitelist({Latitude: geometry.lat, Longitude: geometry.lng})
        dispatch(
          editedLocation({
            ...editLocation,
            latitude: geometry.lat,
            longitude: geometry.lng,
            locationName: results[0].formatted_address,
          })
        );
      })
      .catch(error => {
        // console.debug("Error: ", error);
      });
  };

  return (
    <Box width='100%'>
      {!hideSearch && (
        <InputRow label='Address'>
          <GooglePlacesSearch
            placeholder={placeholder}
            helperText={helperText}
            isError={isError}
            searchValue={editLocation.locationName}
            onSelect={handleSelect}
          />
        </InputRow>
      )}
      <Box height='200px' width='100%'>
        <InputMap disableInput={disableMapInput} onClick={handleMapSelect} />
      </Box>
      <Dialog
        onClose={() => setWarningMessage(false)}
        aria-labelledby="AddLocationWarning"
        open={warningMessage}
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title" sx={{ backgroundColor: theme.palette.background.focus}}> {whitelistResult.data?.title}: </DialogTitle>
        <DialogContent dividers style={{backgroundColor:theme.palette.background.focus}}>
          <Typography variant='body1' style={{ color: theme.palette.text.primary }}>
            {whitelistResult.data?.message}
          </Typography>
        </DialogContent>
        <Grid spacing={1} container display='flex' justifyContent='center' style={{backgroundColor: theme.palette.background.focus, paddingBottom: 10}}>
          <Grid item> <Button style={{backgroundColor: theme.palette.background.focus}} onClick={() => setWarningMessage(false)}> I Understand </Button> </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
}
