//2014-Night

export const ChanceOfRainNight = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M2.55,5.96c-0.54-0.07-1.03-0.33-1.4-0.73c0.04,0,0.07,0,0.11,0C2.77,5.23,3.99,4,3.99,2.5
                c0-0.34-0.06-0.68-0.19-0.99c0.5,0.21,0.9,0.59,1.15,1.07c0.02,0.04,0.06,0.05,0.1,0.03c0.06-0.03,0.14-0.06,0.22-0.09
                C5.29,2.52,5.31,2.5,5.31,2.48c0.01-0.02,0.01-0.04,0-0.06C4.95,1.7,4.29,1.18,3.51,0.99C3.38,0.96,3.27,0.94,3.16,0.93
                c-0.03,0-0.06,0.01-0.07,0.04C3.07,0.99,3.07,1.03,3.09,1.05c0.06,0.08,0.13,0.17,0.18,0.27C3.49,1.68,3.6,2.08,3.6,2.5
                c0,1.29-1.05,2.34-2.34,2.34c-0.15,0-0.3-0.01-0.45-0.04C0.71,4.77,0.6,4.74,0.5,4.7c-0.03-0.01-0.06,0-0.08,0.02
                C0.4,4.75,0.39,4.78,0.4,4.81c0.05,0.11,0.11,0.21,0.17,0.31c0.45,0.7,1.19,1.15,2.02,1.24c0,0,0.01,0,0.01,0
                c0.02,0,0.04-0.01,0.06-0.03c0.02-0.02,0.02-0.05,0.01-0.07C2.65,6.19,2.63,6.1,2.62,6.02C2.61,5.99,2.59,5.96,2.55,5.96z'
      />
      <path
        className='st1'
        d='M7.95,4.1C7.72,3.2,6.92,2.58,5.99,2.58c-1.1,0-2,0.89-2.03,1.98C3.3,4.6,2.78,5.15,2.78,5.82
                c0,0.69,0.56,1.26,1.26,1.26h4.08c0.82,0,1.49-0.67,1.49-1.49C9.6,4.71,8.84,4,7.95,4.1z M4.34,4.61c0-0.91,0.74-1.65,1.65-1.65
                c0.81,0,1.5,0.58,1.62,1.39c0.01,0.05,0.04,0.1,0.08,0.13c0.04,0.03,0.1,0.04,0.15,0.03c0.72-0.18,1.38,0.38,1.38,1.08
                c0,0.61-0.5,1.11-1.11,1.11c0,0-0.01,0-0.01,0c-0.02,0-0.04,0-0.07,0h-4c-0.48,0-0.87-0.39-0.87-0.88s0.39-0.87,0.87-0.87
                c0.03,0,0.07,0,0.1,0c0.05,0.01,0.11-0.01,0.15-0.05c0.04-0.04,0.06-0.1,0.06-0.15C4.34,4.7,4.34,4.65,4.34,4.61z'
      />
    </g>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M4.45,7.61L4.03,8.44C3.98,8.53,4.02,8.65,4.12,8.69C4.15,8.71,4.18,8.71,4.2,8.71c0.07,0,0.14-0.04,0.17-0.1
                l0.42-0.83C4.84,7.69,4.8,7.57,4.7,7.53C4.61,7.48,4.5,7.52,4.45,7.61z'
      />
      <path
        className='st1'
        d='M5.93,7.61L5.51,8.44C5.49,8.48,5.48,8.53,5.5,8.58c0.02,0.05,0.05,0.09,0.1,0.11
                c0.03,0.01,0.06,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.11l0.41-0.83c0.02-0.05,0.03-0.1,0.01-0.15c-0.02-0.05-0.05-0.09-0.1-0.11
                C6.09,7.48,5.98,7.52,5.93,7.61z'
      />
      <path
        className='st1'
        d='M7.66,7.53C7.62,7.5,7.57,7.5,7.52,7.51c-0.05,0.02-0.09,0.05-0.11,0.1L6.99,8.44
                c-0.02,0.05-0.03,0.1-0.01,0.15s0.05,0.09,0.1,0.11C7.1,8.7,7.13,8.71,7.16,8.71c0.07,0,0.14-0.04,0.17-0.11l0.41-0.83
                c0.02-0.05,0.03-0.1,0.01-0.15C7.74,7.59,7.71,7.55,7.66,7.53z'
      />
    </g>
  </svg>
);
