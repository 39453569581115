const WBGTIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        viewBox='0 0 20 20'
        width={props.width ?? 20}
        height={props.height ?? 20}
        xmlns='http://www.w3.org/2000/svg'>
        <path
            fill={props.color}
            d='M10 0C4.489 0 0 4.489 0 10C0 15.511 4.489 20 10 20C15.511 20 20 15.511 20 10C20 4.489 15.511 0 10 0ZM10 2C10.2483 2 10.5204 2.10164 10.8672 2.43164C11.214 2.76164 11.5905 3.31488 11.9141 4.04297C12.1604 4.59722 12.3619 5.27368 12.5352 6H7.46484C7.63808 5.27368 7.8396 4.59722 8.08594 4.04297C8.40953 3.31488 8.78605 2.76164 9.13281 2.43164C9.47958 2.10164 9.75171 2 10 2ZM6.45117 2.83398C6.38604 2.96489 6.31884 3.09315 6.25781 3.23047C5.8961 4.04433 5.61534 4.98621 5.4043 6H3.07227C3.85871 4.63718 5.03488 3.53436 6.45117 2.83398ZM13.5488 2.83398C14.9651 3.53436 16.1413 4.63718 16.9277 6H14.5957C14.3847 4.98621 14.1039 4.04433 13.7422 3.23047C13.6812 3.09315 13.614 2.96489 13.5488 2.83398ZM2.26172 8H5.09766C5.03627 8.64688 5 9.31363 5 10C5 10.6864 5.03627 11.3531 5.09766 12H2.26172C2.09755 11.3598 2 10.6926 2 10C2 9.30738 2.09755 8.64021 2.26172 8ZM7.11719 8H12.8828C12.9524 8.64259 13 9.30662 13 10C13 10.6934 12.9524 11.3574 12.8828 12H7.11719C7.04758 11.3574 7 10.6934 7 10C7 9.30662 7.04758 8.64259 7.11719 8ZM14.9023 8H17.7383C17.9024 8.64021 18 9.30738 18 10C18 10.6926 17.9024 11.3598 17.7383 12H14.9023C14.9637 11.3531 15 10.6864 15 10C15 9.31363 14.9637 8.64688 14.9023 8ZM3.07227 14H5.4043C5.61534 15.0138 5.8961 15.9557 6.25781 16.7695C6.31884 16.9068 6.38604 17.0351 6.45117 17.166C5.03488 16.4656 3.85871 15.3628 3.07227 14ZM7.46484 14H12.5352C12.3619 14.7263 12.1604 15.4028 11.9141 15.957C11.5905 16.6851 11.2139 17.2384 10.8672 17.5684C10.5204 17.8984 10.2483 18 10 18C9.75171 18 9.47958 17.8984 9.13281 17.5684C8.78605 17.2384 8.40953 16.6851 8.08594 15.957C7.8396 15.4028 7.63808 14.7263 7.46484 14ZM14.5957 14H16.9277C16.1413 15.3628 14.9651 16.4656 13.5488 17.166C13.614 17.0351 13.6812 16.9068 13.7422 16.7695C14.1039 15.9557 14.3847 15.0138 14.5957 14Z'
        />
    </svg>
);

export default WBGTIcon;