import { Select as MUISelect, SelectChangeEvent, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useEffect } from 'react';
import { useState } from 'react';
import { SelectOption } from './Select';
import { useTheme } from '@mui/styles';
import { PWMenuItem } from '../../Base';

interface SelectProps {
  value: string[] | number[];
  disabled?: boolean;
  options: SelectOption[];
  placeholder?: string;
  onChange?: (values: string[]) => void;
  onBlur?: (values: string[]) => void;
  renderValue?: React.ReactNode;
  isError?: boolean;
  helperText?: string;
  renderMenuItem?: (option: SelectOption, selectedValues: string[] | number[]) => JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      width: '100%',
      height: 48,
      '& .MuiOutlinedInput-root': {
        height: 'inherit',
        '& .MuiInputAdornment-root .MuiIconButton-root': {
          color: theme.palette.action.inactive,
        },
      },
      '& .MuiOutlinedInput-adornedEnd ': {
        padding: 0,
      },
      '& .MuiOutlinedInput-root.Mui-error': {
        '& .MuiInputAdornment-root': {
          color: theme.palette.error.light,
        },
      },
      '& .MuiSelect-select:focus': {
        borderRadius: 100,
        background: 'none',
        border: '1px solid',
        borderColor: theme.palette.action.selected,
        boxShadow: '0px 0px 0px 2px #0F151E, 0px 0px 0px 4px rgba(96, 177, 215, 0.3)',
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px 32px 12px 14px',
        zIndex: 1,
      },
      '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor: theme.palette.action.disabled,
        borderRadius: 100,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor: theme.palette.border.input,
        borderRadius: 100,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderRadius: 100,
      },
      '& .Mui-error .MuiOutlinedInput-notchedOutline': {
        color: theme.palette.error.light,
        borderColor: theme.palette.error.light,
      },
    },
  })
);

export function MultiSelect(props: SelectProps) {
  const theme = useTheme();
  const { value, options, placeholder, renderValue, renderMenuItem, disabled, onChange, onBlur, isError, helperText } =
    props;
  const classes = useStyles(theme);
  const [selectValues, setSelection] = useState<string[]>([]);

  const MenuProps = {
    PaperProps: {
      style: {
        borderRadius: 8,
        background: theme.palette.background.default,
        border: '1px solid',
        borderColor: theme.palette.border.main,
        '&.MuiMenuItemRoot:hover': {
          backgroundColor: theme.palette.action.hoverSecondary,
        },
      },
    },
  };

  const handleSelect = (event: SelectChangeEvent<typeof selectValues>) => {
    const {
      target: { value },
    } = event;
    setSelection(value as string[]);
    if (onChange !== undefined) onChange(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    if (value) {
      setSelection(value as string[]);
    }
  }, [value]);

  return (
    <>
      <MUISelect
        disabled={disabled || false}
        multiple
        error={isError}
        displayEmpty
        value={selectValues}
        placeholder={placeholder}
        style={{ overflow: 'elipses' }}
        variant='outlined'
        onChange={handleSelect}
        onBlur={e => {
          if (onBlur) onBlur(selectValues || []);
        }}
        className={classes.select}
        MenuProps={MenuProps}
        renderValue={
          selectValues.length > 0
            ? renderValue
              ? () => renderValue
              : undefined
            : () => (
                <Typography textAlign='left' color={theme.palette.action.inactive}>
                  {placeholder}
                </Typography>
              )
        }>
        {options.map(
          renderMenuItem
            ? option => {
                return renderMenuItem(option, value);
              }
            : option => {
                return (
                  <PWMenuItem
                    key={option.value}
                    value={option.value}
                    style={{
                      fontStyle: option.italic ? 'italic' : 'normal',
                      fontWeight: option.bold ? 'bold' : 'normal',
                    }}
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.action.hoverSecondary,
                      },
                    }}>
                    {option.text || option.value}
                  </PWMenuItem>
                );
              }
        )}
      </MUISelect>
      {helperText && (
        <Typography variant='caption' color={theme.palette.error.main}>
          {helperText}
        </Typography>
      )}
    </>
  );
}
