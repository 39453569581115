//9000-Night

export const ClearNight = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <path
      fill='currentColor'
      d='M5.74,1.44C5.57,1.4,5.42,1.37,5.26,1.35c-0.03,0-0.06,0.01-0.07,0.04C5.18,1.41,5.18,1.45,5.2,1.47
        c0.09,0.12,0.17,0.24,0.25,0.37c0.28,0.48,0.42,1.03,0.42,1.58c0,1.75-1.43,3.18-3.18,3.18c-0.22,0-0.44-0.02-0.66-0.07
        C1.9,6.51,1.76,6.47,1.6,6.41c-0.03-0.01-0.06,0-0.08,0.02C1.5,6.45,1.5,6.49,1.51,6.51C1.58,6.66,1.65,6.8,1.74,6.93
        c0.68,1.07,1.84,1.71,3.1,1.71c2.02,0,3.66-1.64,3.66-3.66C8.5,3.31,7.36,1.85,5.74,1.44z M6.35,3.42c0-0.47-0.09-0.92-0.26-1.36
        c1.16,0.5,1.93,1.65,1.93,2.92c0,1.75-1.43,3.18-3.18,3.18c-0.93,0-1.79-0.4-2.4-1.09c0.09,0.01,0.17,0.01,0.25,0.01
        C4.7,7.08,6.35,5.44,6.35,3.42z'
    />
  </svg>
);
