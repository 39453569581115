/* eslint-disable react-hooks/exhaustive-deps */
import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, Grid, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  editedLocation,
  selectLocation,
  getEditedLocation,
  getSelectedLocation,
} from '../../../features/dash/dashSlice';
import {
  Location,
  LocationErrorModel,
  ModelStateErrorResponse,
  Policy,
  PWError,
  useUserQuery,
  CustomerEntitlements,
  useCustomerQuery,
  useAddLocationMutation,
  useDeleteLocationMutation,
  useLocationsQuery,
  useOrganizationLocationsQuery,
  useSitePoliciesQuery,
  useUpdateLocationMutation,
} from '../../../Services/API';
import { PWColors } from '../../../Theme/PWColors';
import {
  BaseModal,
  Button,
  Select,
  SelectOption,
  SubFormContainer,
  TextField,
  InputRow,
  Banner,
  Switch,
  Label,
} from '../../Base';
import SearchableStaticMap from './SearchableInputMap';
import AllClearSlider from './AllClearSlider';
import { Radii, RadiiSliders } from './RadiiSliders';
import { DeletionDialog } from '../../Base/DeletionDialog';
import { openToast } from '../../../features/toast/toastSlice';
import { Add, Edit, InfoOutlined, WarningAmberOutlined } from '@mui/icons-material';

type FormType = 'new' | 'edit';

interface LocationModalProps {
  formType?: FormType;
  children?: React.ReactNode;
}

const initLocation: Location = {
  id: undefined,
  label: '',
  locationName: '',
  latitude: 32.7767,
  longitude: -96.797,
  radius1: 10,
  radius2: 0,
  radius3: 0,
  radius4: 0,
  allClearMinutes: 30,
  flags: 0,
  forecastLocation: false,
};

export function LocationModal(props: LocationModalProps) {
  const { formType = 'new' } = props;

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [selectOption, setSelectOption] = useState('');
  const [formState, setFormState] = useState(formType);
  const [policiesApplied, setPoliciesApplied] = useState(false);
  const [settingsOverwritten, setSettingsOverwritten] = useState(false);
  const [fourthLZSupport, setFourthLZSupport] = useState(false);

  const [locationErrors, setLocationErrors] = useState<{ Label?: string[]; LocationName?: string[] }>();

  const { data: user } = useUserQuery();
  const {
    data: customer, 
    isSuccess: customerFetchSuccess
  } = useCustomerQuery(user ? user.customerId : 0, {skip: !user});

  const [
    updateLocation,
    {
      data: updateResponse,
      isSuccess: updateSuccess,
      isError: updateError,
      error: updateErrorResponse,
      isLoading: updateLocationLoading,
    },
  ] = useUpdateLocationMutation();
  const [
    addLocation,
    {
      data: addResponse,
      isSuccess: addSuccess,
      isError: addError,
      error: addErrorResponse,
      isLoading: addLocationLoading,
    },
  ] = useAddLocationMutation();
  const [
    deleteLocation,
    {
      data: deleteResponse,
      isSuccess: deleteSuccess,
      isError: deleteError,
      error: deleteErrorResponse,
      isLoading: deleteLocationLoading,
    },
  ] = useDeleteLocationMutation();
  const { data: locations, refetch: refetchLocations } = useLocationsQuery();
  const { data: orgLocations } = useOrganizationLocationsQuery();
  const { data: userPolicies } = useSitePoliciesQuery();

  const modalRef = useRef<HTMLDivElement>(null);

  const editLocation: Location = useAppSelector(getEditedLocation);
  const selectedLocation = useAppSelector(getSelectedLocation);

  const handleClose = () => {
    setOpen(false);
    setFormState(formType);
    setSettingsOverwritten(false);
    setPoliciesApplied(false);
    setLocationErrors(undefined);
    dispatch(
      editedLocation({
        id: undefined,
        label: '',
        locationName: '',
        latitude: 0,
        longitude: 0,
        radius1: 10,
        radius2: 0,
        radius3: 0,
        radius4: 0,
        allClearMinutes: 30,
        flags: 0,
        forecastLocation: false,
      })
    );
    setSelectOption('');
  };

  const handleOpen = () => {
    setOpen(true);
    if (selectedLocation && formType === 'edit') {
      applyUserPolicies(selectedLocation);
      setSelectOption(selectedLocation.organizationLocationId || selectedLocation.id || '');
      setFormState('edit');
    }
  };

  const handleRadiiChange = (radii: Radii) => {
    applyUserPolicies({ ...editLocation, radius1: radii.radius1, radius2: radii.radius2, radius3: radii.radius3, radius4: radii.radius4 });
  };

  const handleOnLocationSelect = (value: string) => {
    //TODO: Get locations and set to selected location.
    if (orgLocations) {
      let orgLoc = orgLocations.find(loc => loc.id === value);
      if (orgLoc) {
        applyUserPolicies({
          ...editLocation,
          label: orgLoc.name,
          locationName: orgLoc.address,
          latitude: orgLoc.latitude,
          longitude: orgLoc.longitude,
          organizationLocationId: value,
          flags: 0,
        });
        setFormState('edit');
      } else if (locations && locations.some(l => l.id === value)) {
        let loc = locations.find(l => l.id === value);
        if (loc) applyUserPolicies(loc);
      } else {
        applyUserPolicies(initLocation);
        setFormState('new');
      }
    }

    setSelectOption(value);
  };

  const applyUserPolicies = (loc: Location) => {
    let radii = { radius1: loc.radius1, radius2: loc.radius2, radius3: loc.radius3, radius4: loc.radius4 };
    let polAllClearMinutes = loc.allClearMinutes;

    if (userPolicies) {
      let lrPolicies: Policy[] = userPolicies.filter(x => x.policyTypeShortName?.substr(0, 2) === 'LR');

      if (lrPolicies && lrPolicies.length > 0) {
        setPoliciesApplied(true);

        if (lrPolicies.some(x => x.policyTypeShortName === 'LR1')) {
          let polRad1 = lrPolicies.find(x => x.policyTypeShortName === 'LR1');

          if (loc.allClearMinutes !== polRad1!.allClearMinutes && formState === 'edit') {
            setSettingsOverwritten(true);
          }
          if (loc.radius1 !== polRad1!.threshold && formState === 'edit') setSettingsOverwritten(true);

          polAllClearMinutes = polRad1?.allClearMinutes || loc.allClearMinutes;
          radii.radius1 = polRad1?.threshold || loc.radius1;
        }
        if (lrPolicies.some(x => x.policyTypeShortName === 'LR2')) {
          let polRad2 = lrPolicies.find(x => x.policyTypeShortName === 'LR2')!.threshold;

          if (loc.radius2 !== polRad2 && formState === 'edit') setSettingsOverwritten(true);

          radii.radius2 = polRad2 || loc.radius2;
        }
        if (lrPolicies.some(x => x.policyTypeShortName === 'LR3')) {
          let polRad3 = lrPolicies.find(x => x.policyTypeShortName === 'LR3')!.threshold;

          if (loc.radius3 !== polRad3 && formState === 'edit') setSettingsOverwritten(true);

          radii.radius3 = polRad3 || loc.radius3;
        }
        if (lrPolicies.some(x => x.policyTypeShortName === 'LR4')) {
          let polRad4 = lrPolicies.find(x => x.policyTypeShortName === 'LR4')!.threshold;

          if (loc.radius4 !== polRad4 && formState === 'edit') setSettingsOverwritten(true);

          radii.radius4 = polRad4 || loc.radius4;
        }
      }
    }
    dispatch(
      editedLocation({
        ...loc,
        radius1: radii.radius1,
        radius2: radii.radius2,
        radius3: radii.radius3,
        radius4: radii.radius4,
        allClearMinutes: polAllClearMinutes,
      })
    );
  };

  const handleUpdateLocation = () => {
    updateLocation(editLocation);
  };

  const handleAddLocation = () => {
    addLocation(editLocation);
  };

  const handleDeleteLocation = () => {
    if (editLocation.id) {
      deleteLocation(editLocation.id);
    }
  };

  //LZ4 policy support based on entitlement
  useEffect(() => { 
    if(customerFetchSuccess){
      if(customer?.entitlements?.includes(CustomerEntitlements.FOURTH_LIGHTNING_ZONE_SUPPORT)){
        setFourthLZSupport(true);
      }
      else{
        setFourthLZSupport(false);
      }
    }
  }, [customerFetchSuccess]);

  useEffect(() => {
    if (updateSuccess && updateResponse) {
      dispatch(selectLocation(editLocation));
      refetchLocations();
      dispatch(openToast({ variant: 'success', header: updateResponse }));
      handleClose();
    } else if (updateError && updateErrorResponse) {
      modalRef.current?.scrollTo({ top: 0, behavior: 'smooth' });

      let updateErrors = (updateErrorResponse as ModelStateErrorResponse)?.data?.responseException
        ?.errors as LocationErrorModel;

      if (updateErrors) {
        if (updateErrors.ServerErrors) dispatch(openToast({ variant: 'error', header: updateErrors.ServerErrors[0] }));
        else dispatch(openToast({ variant: 'error', header: 'Error updating location' }));

        setLocationErrors({ ...updateErrors });
      }
    }
  }, [updateSuccess, updateError, updateErrorResponse]);

  useEffect(() => {
    if (addSuccess && addResponse) {
      dispatch(openToast({ variant: 'success', header: addResponse.message }));
      refetchLocations();
      dispatch(selectLocation(addResponse.location));
      handleClose();
    } else if (addError && addErrorResponse) {
      modalRef.current?.scroll({ top: 0, behavior: 'smooth' });
      let addErrors = (addErrorResponse as ModelStateErrorResponse)?.data?.responseException
        ?.errors as LocationErrorModel;

      if (addErrors) {
        if (addErrors.ServerErrors) dispatch(openToast({ variant: 'error', header: addErrors.ServerErrors[0] }));
        else dispatch(openToast({ variant: 'error', header: 'Error adding location' }));

        setLocationErrors({ ...addErrors });
      }
    }
  }, [addSuccess, addError, addErrorResponse]);

  useEffect(() => {
    if (deleteSuccess) {
      refetchLocations();
      dispatch(openToast({ variant: 'success', header: deleteResponse || '' }));
      handleClose();
      dispatch(selectLocation(undefined));
    } else if (deleteError && deleteErrorResponse) {
      dispatch(
        openToast({ variant: 'error', header: (deleteErrorResponse as PWError)?.data.responseException.message })
      );
    }
  }, [deleteSuccess, deleteError, deleteErrorResponse]);

  // useEffect(() => {
  //     if(!isFetchingLocations)
  //         handleClose();
  // },[isFetchingLocations])

  const options = (): SelectOption[] => {
    let sharedLocations: SelectOption[] = [];
    // if(formType ===  'new')
    //     sharedLocations.push({value: 'new', text:'Create Personal Location'});

    // if(formType === 'edit' && selectedLocation && selectedLocation.id)
    //     sharedLocations.push({value: selectedLocation.id, text: selectedLocation.label})

    if (locations && orgLocations)
      orgLocations.forEach(orgLoc => {
        if (
          !locations.some(loc => orgLoc.id === loc.organizationLocationId) ||
          orgLoc.id === editLocation.organizationLocationId
        )
          sharedLocations.push({ value: orgLoc.id, text: orgLoc.name });
      });

    return sharedLocations;
  };

  const locationForm = (
    <Grid container>
      <Grid item sm={12} style={{ marginBottom: 24 }}>
        {formType === 'edit' ? (
          <Typography variant='h5'>{'Editing "' + editLocation.label + '"'}</Typography>
        ) : (
          <Typography variant='h5'>{`Add${selectOption === 'new' ? ' Custom ' : ' '}Location`}</Typography>
        )}
      </Grid>
      {editLocation.organizationLocationId !== null && selectOption !== 'new' && (
        <Grid item xs={12}>
          <SubFormContainer>
            <InputRow label='Organization Location'>
              <Select
                disabled={formState === 'edit' && editLocation.id !== undefined}
                placeholder='Select an organization location'
                value={selectOption}
                options={options()}
                onChange={handleOnLocationSelect}
              />
            </InputRow>
          </SubFormContainer>
        </Grid>
      )}
      {formState === 'new' && selectOption !== 'new' && (
        <>
          <Grid item xs={12}>
            <Divider flexItem>OR</Divider>
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='center' padding='16px 0'>
            <Button type='clear' onClick={e => handleOnLocationSelect('new')}>
              Add Custom Location
            </Button>
          </Grid>
        </>
      )}
      {(formState === 'edit' || selectOption === 'new') && (
        <SubFormContainer>
          {!editLocation.organizationLocationId && (
            <InputRow label='Location Name*'>
              <TextField
                disabled={editLocation.type === 1}
                placeholder='Name your location'
                helperText={
                  locationErrors?.Label && locationErrors?.Label.length > 0 ? locationErrors.Label[0] : undefined
                }
                isError={locationErrors?.Label !== undefined}
                value={editLocation.label}
                onChange={e => dispatch(editedLocation({ ...editLocation, label: e.target.value }))}
              />
            </InputRow>
          )}
          <InputRow label='Enable Alerts?'>
            <div style={{ display: 'flex' }}>
              <Switch
                style={{ marginRight: '10px' }}
                checked={0 === (editLocation.flags & 30)}
                onChange={() => dispatch(editedLocation({ ...editLocation, flags: editLocation.flags === 0 ? 30 : 0 }))}
              />
              <Label text={(editLocation.flags & 30) !== 0 ? 'No, thanks' : 'Yes, please'} labelColor='textSecondary' />
            </div>
          </InputRow>
          <Wrapper apiKey={'AIzaSyAHiPGWDqX3OsIst1MonWDxrR4H2vYm0fs'} libraries={['places']}>
            <SearchableStaticMap
              placeholder='Search address or click map'
              helperText={
                locationErrors?.LocationName && locationErrors?.LocationName.length > 0
                  ? locationErrors.LocationName[0]
                  : undefined
              }
              isError={locationErrors?.LocationName !== undefined}
              disableMapInput={formState === 'edit'}
              hideSearch={formState === 'edit'}
            />
          </Wrapper>
          {formState === 'edit' && (
            <>
              <Typography padding='8px 0' variant='body1' color={PWColors.dark.base['100']}>
                {editLocation.locationName}
              </Typography>
              {/* <Typography variant='body1' color={PWColors.dark.base['100']}>{`Latitude: ${editLocation.latitude}`}</Typography>
                            <Typography variant='body1' color={PWColors.dark.base['100']}>{`Longitude: ${editLocation.longitude}`}</Typography> */}
            </>
          )}
          <SubFormContainer subheader='Radii Settings'>
            <Grid item xs={12} style={{ margin: '12px 0 ' }}>
              {policiesApplied && !settingsOverwritten && (
                <Banner
                  icon={<InfoOutlined />}
                  variant='info'
                  message="Some settings are locked by your organization's policies"
                />
              )}
              {settingsOverwritten && (
                <Banner
                  icon={<WarningAmberOutlined />}
                  variant='warn'
                  message="Your settings have been overwritten by your organization's policy. Please save to apply these changes."
                />
              )}
            </Grid>
            <RadiiSliders
              fourthLZSupport={fourthLZSupport}
              radii={{ radius1: editLocation.radius1, radius2: editLocation.radius2, radius3: editLocation.radius3, radius4: editLocation.radius4? editLocation.radius4 : 0}}
              onChange={handleRadiiChange}
            />
            <AllClearSlider
              value={editLocation.allClearMinutes}
              onChange={value => dispatch(editedLocation({ ...editLocation, allClearMinutes: value }))}
            />
          </SubFormContainer>
          {/* <SubFormContainer subheader='Alerts' containerDesc='You will receive the following alerts for this location. They are set by your organization’s administrators and cannot be changed.'>
                        <EnabledUserAlertsPolicy/>
                    </SubFormContainer> */}
        </SubFormContainer>
      )}
      <Grid spacing={1} container display='flex' justifyContent='flex-end' style={{ marginTop: 16 }}>
        <Grid item justifySelf='flex-start'>
          {formType === 'edit' && (
            <DeletionDialog
              disabled={editLocation.type === 1}
              onRemove={handleDeleteLocation}
              item='Location'
              identifier={editLocation.label}
              button={
                <Button type='delete' disabled={editLocation.type === 1 || deleteLocationLoading}>
                  <Typography variant='body1'>Delete Location</Typography>
                </Button>
              }
            />
          )}
        </Grid>
        <Grid item>
          <Button type='clear' onClick={handleClose}>
            <Typography variant='body1'> Cancel </Typography>
          </Button>
        </Grid>
        {selectOption.length > 0 && (
          <Grid item>
            <Button
              onClick={formType === 'edit' ? handleUpdateLocation : handleAddLocation}
              disabled={updateLocationLoading || addLocationLoading}>
              <Typography variant='body1'> {'Save' + (formType === 'edit' ? ' Changes' : '')} </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return formType === 'edit' ? (
    <BaseModal
      disableHover
      ref={modalRef}
      modalState={{ open: open, handleOpen: handleOpen, handleClose: handleClose }}
      modalIcon={
        <Box color={theme.palette.action.selected}>
          <Edit style={{ height: 18, width: 18 }} />
        </Box>
      }>
      {locationForm}
    </BaseModal>
  ) : (
    <BaseModal
      ref={modalRef}
      modalState={{ open: open, handleOpen: handleOpen, handleClose: handleClose }}
      modalButton={
        <Button type='primary'>
          <Add />
          <Typography variant='body1'>Add Location</Typography>
        </Button>
      }>
      {locationForm}
    </BaseModal>
  );
}
