/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme, Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { LightningStrike2 } from '../../Assets/LightningStrike2';
import TimeParser from '../../features/Time/TimeHelpers';
import { Checkmark } from '../../Assets/Checkmark';

interface LightningTimerProps {
  time: string;
  allClearMinutes: number;
  radiusDisplay: JSX.Element;
  hasLR4?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  circleContainer: {
    width: '100%',
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  clearCircleContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  clearCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width: 30,
    borderRadius: 40,
    border: '2px solid',
  },
  top: {
    position: 'absolute',
  },
  bottom: {
    position: 'absolute',
    color: theme.palette.action.disabled,
  },
  delay: {
    color: theme.palette.error.light,
  },
  clear: {
    color: theme.palette.success.main,
  },
}));

export function LightningTimerComponent(props: LightningTimerProps) {
  const { time, allClearMinutes, radiusDisplay, hasLR4 } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  var timeTuple = TimeParser(time);

  const [parsedTime, setTime] = useState({
    minutes: 0,
    seconds: 0,
  });

  var currentTimeInSecs = parsedTime.minutes * 60 + parsedTime.seconds;
  var totalTimeInSecs = allClearMinutes * 60;
  var currentProgress = (-100 * currentTimeInSecs) / totalTimeInSecs;

  if (parsedTime.minutes === 0 && parsedTime.seconds === 0) {
    currentProgress = -100;
  }

  const tick = () => {
    // Time up
    if (parsedTime.minutes === 0 && parsedTime.seconds === 0) {
    } else if (parsedTime.seconds === 0) {
      // decrement minute
      setTime({
        minutes: parsedTime.minutes - 1,
        seconds: 59,
      });
    } else {
      // decrement seconds
      setTime({
        minutes: parsedTime.minutes,
        seconds: parsedTime.seconds - 1,
      });
    }
  };

  useEffect(() => {
    setTime({
      minutes: timeTuple[0],
      seconds: timeTuple[1],
    });
  }, [time]);

  useEffect(() => {
    const timer = setInterval(() => {
      tick();
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  return (
    <div>
      {currentProgress !== -100 ? (
        <div>
          <div className={classes.circleContainer}>
            <CircularProgress variant='determinate' className={classes.bottom} size={64} thickness={1} value={100} />
            <CircularProgress
              className={[classes.top, classes.delay].join(' ')}
              size={64}
              variant='determinate'
              value={currentProgress}
              thickness={1}
            />
            <LightningStrike2 color={theme.palette.error.main}></LightningStrike2>
          </div>
          <Typography variant='subtitle1' style={{ color: theme.palette.error.main, fontSize: 22 }}>
            {`${parsedTime.minutes.toString().padStart(2, '0')}:${parsedTime.seconds.toString().padStart(2, '0')}`}
          </Typography>
        </div>
      ) : (
        <div className={classes.clearCircleContainer}>
          <div className={classes.clearCircle} style={{ borderColor: theme.palette.success.main }}>
            <Checkmark height={16} width={16} color={theme.palette.success.main} />
          </div>
          <Typography color={theme.palette.success.main} variant={hasLR4 ? 'h5' : 'h4'}>
            All Clear
          </Typography>
        </div>
      )}
      {radiusDisplay}
    </div>
  );
}

export default LightningTimerComponent;
