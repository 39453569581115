import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { store, RootState } from '../../app/store';
import { setNeedsHardLogin } from '../Auth/authSlice';

export type PWError = {
  status: number;
  data: {
    isError: boolean;
    responseException: { message: string; errors: string };
  };
};

export const baseQuery = (baseUrl: string) => fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth = (baseUrl: string): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(baseUrl)(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    store.dispatch(setNeedsHardLogin(true));
  }
  return result;
};