/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme, Typography, Zoom } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { PolicyTypeName } from '../../../../Constants/Constants';
import {
  addSevereLocation,
  getSelectedLocation,
  removeSevereLocation,
  selectLocation,
} from '../../../../features/dash/dashSlice';
import { useLightningStatusDelay } from '../../../../hooks/useLightningStatusDelay';
import {
  useObservationsForLocationQuery,
  Location,
  useCachedPolicyAlertsForLocationQuery,
  useNwsAlertsQuery,
} from '../../../../Services/API';
import { WxImage } from '../../../Base';
import React from 'react';

interface LocationCardProps {
  location?: Location;
  onSelect?: (e: Location) => void;
  itemId: string;
  style?: React.CSSProperties;
  show?: boolean;
}

export function LocationCard(props: LocationCardProps) {
  const { location, onSelect, style, show = true } = props;
  const theme = useTheme();

  const { data: nwsAlerts } = useNwsAlertsQuery(location ? location.id || '' : '', {
    skip: location === undefined,
  });
  const { data: observation } = useObservationsForLocationQuery(location ? location.id || '' : '', {
    skip: location === undefined,
  });
  const { data: policyViolations } = useCachedPolicyAlertsForLocationQuery(location?.id || '', {
    skip: location?.id === undefined,
  });

  var selected: boolean = false;
  const dispatch = useAppDispatch();
  useLightningStatusDelay(location?.id, 10000);

  useEffect(() => {
    if (location && location.id && nwsAlerts) {
      // NWS Bulletin
      if (nwsAlerts.length > 0)
        dispatch(
          addSevereLocation({
            locationId: location.id,
            threatType: { shortName: 'NWS', longName: [...nwsAlerts.map(x => x.Event)] },
          })
        );
      else dispatch(removeSevereLocation({ locationId: location.id, threatType: { shortName: 'NWS', longName: [] } }));
    }
  }, [nwsAlerts]);

  useEffect(() => {
    if (policyViolations && location && location.id !== undefined) {
      const alertSuffix = ' Policy Warning';
      const policyTypes: PolicyTypeName[] = ['WBGT', 'WIND', 'GUST', 'AQI', 'CHILL', 'HEAT', 'PRECIP', 'AQI'];

      policyTypes.forEach(policyType => {
        let exists = policyViolations.find(x => x.policyShortName === policyType);
        if (exists)
          dispatch(
            addSevereLocation({
              locationId: location.id!,
              threatType: { shortName: policyType, longName: [exists.policyDisplayName + alertSuffix] },
            })
          );
        else
          dispatch(
            removeSevereLocation({ locationId: location.id!, threatType: { shortName: policyType, longName: [] } })
          );
      });
    }
  }, [policyViolations]);

  //Not efficient at all. should prob call once from parent
  const selectedLocation = useAppSelector(getSelectedLocation);

  if (selectedLocation && location && selectedLocation.id === location.id) selected = true;

  var weatherStationInfo = observation?.weatherStation;

  const LocationCardComponent = () => {
    if (location) {
      return (
        <>
          <WxImage
            locationId={location.id}
            style={{ width: 240, maxHeight: 240, minHeight: 225, cursor: 'pointer' }}
            weatherStationId={weatherStationInfo && weatherStationInfo != null ? weatherStationInfo.id : undefined}
            selectable={true}
            showStatus={true}
          />
          <Typography
            textAlign='center'
            variant='body1'
            color={theme.palette.text.secondary}
            style={{ overflow: 'hidden' }}
            textOverflow={'ellipsis'}
            width={240}
            whiteSpace={'nowrap'}>
            {location.label}
          </Typography>
        </>
      );
    }
  };

  if (location !== undefined)
    return (
      <Zoom appear={false} in={show} style={{ ...style, outline: 0 }}>
        <div
          onClick={e => {
            if (onSelect) onSelect(location);
          }}
          style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10 }}>
          {LocationCardComponent()}
        </div>
      </Zoom>
    );
  else return <></>;
}
