//9004-Day

export const MostlyCloudyDay = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M1.58,7.68c-0.49-0.1-0.85-0.52-0.85-1.03c0-0.59,0.48-1.06,1.06-1.06c0.08,0,0.17,0.01,0.26,0.03
            c0.05,0.01,0.11,0,0.15-0.03c0.04-0.03,0.08-0.08,0.08-0.13C2.4,4.7,3.06,4.14,3.84,4.14c0.19,0,0.37,0.04,0.55,0.11
            c0.19-0.06,0.39-0.09,0.59-0.1C4.97,4.14,4.97,4.12,4.96,4.12C4.63,3.89,4.24,3.76,3.84,3.76c-0.9,0-1.67,0.6-1.89,1.46
            C1.09,5.13,0.36,5.81,0.36,6.65c0,0.73,0.55,1.34,1.29,1.42C1.61,7.95,1.58,7.82,1.58,7.68z'
      />
      <path
        className='st1'
        d='M7.08,5.97c-0.05,0-0.1,0-0.16,0.01C6.7,5.12,5.93,4.52,5.03,4.52c-1.06,0-1.93,0.85-1.95,1.91
            C2.45,6.46,1.95,6.99,1.95,7.63c0,0.67,0.54,1.21,1.21,1.21h3.92c0.79,0,1.44-0.64,1.44-1.44C8.51,6.61,7.87,5.97,7.08,5.97z
                M3.16,8.47c-0.46,0-0.84-0.38-0.84-0.84c0-0.46,0.38-0.84,0.84-0.84c0.03,0,0.06,0,0.09,0.01c0.06,0,0.11-0.01,0.15-0.05
            c0.04-0.04,0.06-0.1,0.06-0.15c0-0.04-0.01-0.08-0.01-0.13c0-0.87,0.71-1.58,1.58-1.58c0.78,0,1.44,0.56,1.56,1.33
            c0.01,0.05,0.04,0.1,0.08,0.13c0.04,0.03,0.1,0.04,0.15,0.03C6.91,6.35,7,6.34,7.08,6.34c0.59,0,1.06,0.48,1.06,1.06
            c0,0.59-0.48,1.06-1.06,1.06c0,0-0.01,0-0.01,0c-0.02,0-0.04,0-0.06,0H3.16z'
      />
      <path
        className='st1'
        d='M6.28,2.49c-0.65,0-1.2,0.29-1.55,0.81C4.7,3.34,4.69,3.39,4.7,3.44c0,0,0,0,0.01,0.01
            C4.8,3.48,4.88,3.52,4.96,3.56c0.03-0.01,0.07-0.03,0.09-0.06c0.28-0.42,0.7-0.64,1.24-0.64c0.84,0,1.52,0.68,1.52,1.52
            c0,0.47-0.22,0.89-0.58,1.18c0,0.02,0,0.03,0.01,0.05c0.01,0,0.02,0,0.02,0c0.15,0,0.29,0.03,0.42,0.06C8,5.33,8.18,4.88,8.18,4.39
            C8.18,3.34,7.33,2.49,6.28,2.49z'
      />
      <path
        className='st1'
        d='M6.3,2.08C6.2,2.08,6.12,2,6.12,1.89v-0.9c0-0.1,0.08-0.19,0.19-0.19c0.1,0,0.19,0.08,0.19,0.19v0.9
            C6.49,2,6.41,2.08,6.3,2.08z'
      />
      <path
        className='st1'
        d='M7.76,2.36C7.72,2.42,7.66,2.45,7.6,2.45c-0.03,0-0.07-0.01-0.1-0.03l0,0C7.46,2.4,7.43,2.35,7.42,2.3
            c-0.01-0.05,0-0.1,0.02-0.14L7.91,1.4c0.03-0.04,0.07-0.07,0.12-0.08c0.05-0.01,0.1,0,0.14,0.02C8.26,1.39,8.29,1.5,8.23,1.59
            L7.76,2.36z'
      />
      <path
        className='st1'
        d='M8.56,3.36C8.53,3.37,8.5,3.38,8.47,3.38c-0.07,0-0.13-0.04-0.16-0.1c-0.05-0.09-0.02-0.2,0.08-0.25l0.8-0.43
            c0.09-0.05,0.21-0.01,0.26,0.08c0.05,0.09,0.01,0.21-0.08,0.25L8.56,3.36z'
      />
      <path
        className='st1'
        d='M8.76,4.54l0.9,0c0.1,0,0.19-0.08,0.19-0.19c0-0.1-0.08-0.19-0.19-0.19l-0.9,0c-0.1,0-0.19,0.08-0.19,0.19
            C8.58,4.45,8.66,4.54,8.76,4.54z M9.67,4.23L9.67,4.23L9.67,4.23L9.67,4.23z'
      />
      <path
        className='st1'
        d='M8.41,5.35c0.05-0.02,0.1-0.01,0.14,0.02L9.35,5.8c0.04,0.02,0.08,0.06,0.09,0.11
            c0.01,0.05,0.01,0.1-0.02,0.14c-0.03,0.06-0.1,0.1-0.16,0.1c-0.03,0-0.07-0.01-0.09-0.02L8.37,5.7C8.33,5.67,8.3,5.63,8.28,5.59
            C8.27,5.54,8.28,5.49,8.3,5.44C8.32,5.4,8.36,5.37,8.41,5.35z'
      />
      <path
        className='st1'
        d='M4.14,3.36c-0.03,0-0.06-0.01-0.09-0.02L3.26,2.9v0C3.22,2.88,3.18,2.84,3.17,2.79
            c-0.01-0.05-0.01-0.1,0.02-0.14c0.05-0.09,0.16-0.12,0.25-0.07l0.79,0.43c0.04,0.02,0.08,0.06,0.09,0.11
            c0.01,0.05,0.01,0.1-0.02,0.14C4.28,3.32,4.21,3.36,4.14,3.36z'
      />
      <path
        className='st1'
        d='M5,2.45c-0.06,0-0.13-0.03-0.16-0.09L4.36,1.6C4.33,1.56,4.32,1.51,4.34,1.46c0.01-0.05,0.04-0.09,0.08-0.12
            C4.46,1.31,4.51,1.3,4.56,1.31C4.61,1.33,4.65,1.36,4.68,1.4l0.48,0.77c0.05,0.09,0.03,0.2-0.06,0.26C5.07,2.44,5.03,2.45,5,2.45z'
      />
    </g>
  </svg>
);
