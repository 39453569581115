import { Radio as MuiRadio, styled, useTheme } from '@mui/material';

const CustomRadio = styled(MuiRadio)``;

interface RadioProps {
  size?: 'small' | 'medium';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked?: boolean;
  value: string;
}

export function Radio(props: RadioProps) {
  const { onChange, size, checked, value } = props;
  const theme = useTheme();

  return (
    <CustomRadio
      onChange={onChange}
      checked={checked}
      value={value}
      size={size}
      sx={{
        color: theme.palette.border.input,
        '&:hover': {
          color: theme.palette.action.hover,
        },
      }}></CustomRadio>
  );
}

export default Radio;
