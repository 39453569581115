import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth, PWError } from '../sharedApiUtils';

export const INTERNAL_SINGLEWIRE_API_URL = process.env.REACT_APP_INTERNAL_SINGLEWIRE_API_URL!;
export const SINGLEWIRE_API_URL = process.env.REACT_APP_SINGLEWIRE_API_URL!;

export const singlewireApi = createApi({
  reducerPath: 'singlewireApi',
  baseQuery: baseQueryWithReauth(SINGLEWIRE_API_URL) as BaseQueryFn<string | FetchArgs, unknown, PWError, {}>,
  tagTypes: [
    'Integrations.Singlewire'
  ],
  refetchOnMountOrArgChange: 60,
  endpoints: () => ({}),
});
