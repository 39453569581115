//0014

export const Rain = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M7.82,3.39c-0.05,0-0.11,0-0.16,0.01C7.43,2.5,6.62,1.87,5.68,1.87c-1.1,0-2.01,0.88-2.04,2
                C2.98,3.91,2.46,4.46,2.46,5.13c0,0.7,0.57,1.27,1.26,1.27h4.1c0.83,0,1.5-0.67,1.5-1.5C9.32,4.07,8.65,3.39,7.82,3.39z M4.02,3.91
                c0-0.91,0.74-1.66,1.66-1.66c0.82,0,1.51,0.59,1.64,1.4c0.01,0.05,0.04,0.1,0.08,0.13c0.04,0.03,0.1,0.04,0.15,0.03
                C8.3,3.64,8.94,4.19,8.94,4.89c0,0.62-0.5,1.12-1.12,1.12l-0.02,0c-0.02,0-0.04,0-0.06,0H3.72c-0.49,0-0.88-0.39-0.88-0.88
                s0.4-0.88,0.88-0.88c0.03,0,0.06,0,0.1,0.01c0.06,0,0.11-0.01,0.15-0.05c0.04-0.04,0.06-0.1,0.06-0.17
                C4.03,3.99,4.02,3.95,4.02,3.91z'
      />
      <path
        className='st1'
        d='M2.08,5.19c-0.52-0.1-0.9-0.54-0.9-1.08c0-0.7,0.65-1.26,1.39-1.08c0.05,0.01,0.11,0,0.15-0.03
                c0.04-0.03,0.07-0.08,0.08-0.13c0.13-0.81,0.82-1.4,1.64-1.4c0.2,0,0.4,0.05,0.59,0.12C5.2,1.53,5.4,1.49,5.62,1.49
                C5.61,1.48,5.61,1.46,5.6,1.45C5.26,1.21,4.85,1.08,4.43,1.08c-0.94,0-1.75,0.63-1.98,1.53C2.4,2.61,2.34,2.61,2.29,2.61
                c-0.83,0-1.5,0.67-1.5,1.5c0,0.76,0.56,1.39,1.32,1.48C2.08,5.46,2.07,5.32,2.08,5.19z'
      />
      <path
        className='st1'
        d='M4.08,6.87L3.31,8.4C3.26,8.49,3.3,8.6,3.4,8.65c0.03,0.01,0.05,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
                l0.77-1.53c0.02-0.05,0.03-0.1,0.01-0.15C4.41,6.84,4.37,6.8,4.33,6.78C4.24,6.74,4.12,6.78,4.08,6.87z'
      />
      <path
        className='st1'
        d='M5.6,6.78C5.51,6.74,5.4,6.78,5.35,6.87L4.59,8.4C4.54,8.49,4.58,8.6,4.67,8.65C4.7,8.66,4.73,8.67,4.76,8.67
                c0.07,0,0.14-0.04,0.17-0.1l0.77-1.53C5.74,6.94,5.7,6.83,5.6,6.78L5.6,6.78z'
      />
      <path
        className='st1'
        d='M6.63,6.87L5.86,8.4c-0.02,0.04-0.03,0.1-0.01,0.14C5.87,8.59,5.9,8.63,5.95,8.65C5.97,8.66,6,8.67,6.03,8.67
                c0.07,0,0.14-0.04,0.17-0.1l0.77-1.53c0.02-0.04,0.03-0.1,0.01-0.14c-0.02-0.05-0.05-0.09-0.1-0.11C6.79,6.74,6.68,6.77,6.63,6.87z
                '
      />
    </g>
  </svg>
);
