import * as React from 'react';
import { useAppDispatch } from '../app/hooks';
import { addSevereLocation, removeSevereLocation } from '../features/dash/dashSlice';
import TimeParser from '../features/Time/TimeHelpers';
import { useLightningStatusQuery } from '../Services/API';

export const useLightningStatusDelay = (locationId: string = '', interval: number = 30000) => {
    const {
        data: delay,
        isFetching,
        isLoading,
        isError
    } = useLightningStatusQuery(locationId, { skip: locationId === '', pollingInterval: interval });
    const [isInDelay, setIsInDelay] = React.useState(false);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (delay) {
            let delay1 = TimeParser(delay.item1);
            let delay2 = TimeParser(delay.item2);
            let delay3 = TimeParser(delay.item3);
            let delay4 = TimeParser(delay.item4);

            var shouldAdd = false;

            if (delay1[0] > 0 || delay1[1] > 0) {
                shouldAdd = true;
            } else if (delay2[0] > 0 || delay2[1] > 0) {
                shouldAdd = true;
            } else if (delay3[0] > 0 || delay3[1] > 0) {
                shouldAdd = true;
            } else if (delay4[0] > 0 || delay4[1] > 0) {
                shouldAdd = true;
            } else {
                shouldAdd = false;
            }

            setIsInDelay(shouldAdd);

            if (locationId) {
                if (shouldAdd) {
                    dispatch(
                        addSevereLocation({
                            locationId: locationId,
                            threatType: { shortName: 'LR1', longName: ['Lightning Policy Alert'] }
                        })
                    );
                } else {
                    dispatch(
                        removeSevereLocation({
                            locationId: locationId,
                            threatType: { shortName: 'LR1', longName: ['Lightning Policy Alert'] }
                        })
                    );
                }
            }
        }
    }, [delay]);

    return { delay, isInDelay, isFetching, isLoading, isError };
};
