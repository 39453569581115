import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { Button, Card, Page, Pagination, SubCardContainer, TextArea, Tooltip } from '../Components/Base';
import { RecipientAutoComplete, RecipientSelectOption } from '../Components/MassNotify/RecipientAutoComplete';
import { Redirect } from 'wouter';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'oidc-react';
import { useDispatch } from 'react-redux';
import { openToast } from '../features/toast/toastSlice';
import {
  useNotiUsersQuery,
  useSitesQuery,
  useUsersQuery,
  useSendMassMessageMutation,
  Communication,
  ModelStateErrorResponse,
  CommunicationErrorModel,
  useMassMessageHistoryQuery,
  MassMessageHistory,
} from '../Services/API';
import { ArrowRight, Chat } from '@mui/icons-material';
import { ConvertToUtc } from '../features/Time/TimeHelpers';

export function MassNotify() {
  const theme = useTheme();
  const { userData } = useAuth();
  const dispatch = useDispatch();
  const AMT_PER_PAGE = 4;

  const [errorState, setErrorState] = useState<CommunicationErrorModel>({});
  const [disableSend, setDisableSend] = useState<boolean>(false);
  const [recipOptions, setRecipOptions] = useState<RecipientSelectOption[]>([]);
  const [overrideValues, setOverrideValues] = useState<RecipientSelectOption[]>([]);
  const [communication, setCommunication] = useState<Communication>({ recipients: [], message: '', channel: 3 });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data: notiUsers } = useNotiUsersQuery();
  const { data: users } = useUsersQuery();
  const { data: sites } = useSitesQuery();
  const { data: messageHistory } = useMassMessageHistoryQuery();
  const [
    sendMassMessage,
    { isSuccess: isSendMassMessageSuccess, isError: isSendMassMessageError, error: sendMassMessageError },
  ] = useSendMassMessageMutation();

  const currentUser = users?.find(u => u.id === userData?.profile?.sub);

  var hasMassNotify =
    userData?.profile?.permissions?.includes('massnotify.access') &&
    (userData?.profile?.Role === 'Admin' ||
      userData?.profile?.Role === 'Super Admin' ||
      userData?.profile?.Role === 'Assistant Admin');

  useMemo(() => {
    if (userData && userData.profile && users && sites && notiUsers) {
      let options: RecipientSelectOption[] = [];
      if (userData.profile.Role === 'Assistant Admin') {
        let currentUser = users.find(x => x.id === userData.profile.sub);
        if (currentUser) {
          options = sites
            .filter(x => x.id === currentUser!.siteId)
            .map(x => ({ value: x.id.toString(), text: x.name, type: 'site', siteId: x.id, bold: true, italic: true }));
          options = options.concat(
            users
              .filter(x => x.id !== userData?.profile?.sub && x.siteId === currentUser!.siteId)
              .map(x => ({ value: x.id, text: x.fullName, type: 'user', siteId: x.siteId }))
          );
        }
      } else {
        options = sites
          .filter(x => users.some(u => u.siteId === x.id) || notiUsers.some(u => u.siteId === x.id))
          .map(x => ({ value: x.id.toString(), text: x.name, type: 'site', siteId: x.id, bold: true, italic: true }));
        options = options.concat(
          users
            .filter(x => x.id !== userData.profile.sub && x.isActive)
            .map(x => ({ value: x.id, text: x.fullName, type: 'user', siteId: x.siteId }))
        );
      }
      options = options.concat(
        notiUsers.filter(x => x.isActive).map(x => ({ value: x.id, text: x.fullName, type: 'user', siteId: x.siteId }))
      );

      setRecipOptions(options);
    }
  }, [users, sites, notiUsers, userData]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    sendMassMessage(communication);
    setDisableSend(true);
  };

  const handleCopyMessage = (mh: MassMessageHistory) => {
    let selectedRecipIds = mh.deliveredRecipients.map(x => x.recipientId);
    let selectedRecips = recipOptions.filter(x => selectedRecipIds.some(y => y === x.value));

    setOverrideValues(selectedRecips);
    setCommunication({ ...communication, message: mh.message, channel: mh.channel });
  };

  useEffect(() => {
    if (isSendMassMessageSuccess) {
      dispatch(openToast({ variant: 'success', header: 'Message sent' }));
      setCommunication({ recipients: [], message: '', channel: 3 });
      setOverrideValues([]);
      setDisableSend(false);
    } else if (isSendMassMessageError) {
      setDisableSend(false);
      let massMessageError = (sendMassMessageError as ModelStateErrorResponse).data.responseException!
        .errors as CommunicationErrorModel;
      if (sendMassMessageError && massMessageError) {
        setErrorState({ ...massMessageError });

        if (massMessageError.ServerError && massMessageError.ServerError.length > 0)
          dispatch(openToast({ variant: 'error', header: massMessageError.ServerError[0] }));
        else dispatch(openToast({ variant: 'error', header: 'Error sending message' }));
      } else dispatch(openToast({ variant: 'error', header: 'Error sending message' }));
    }
  }, [isSendMassMessageSuccess, isSendMassMessageError, sendMassMessageError]);

  const messageHistoryList = () => {
    let displayedMessageHistory = messageHistory?.slice(
      AMT_PER_PAGE * (currentPage - 1),
      AMT_PER_PAGE * (currentPage - 1) + AMT_PER_PAGE
    ); //0,2 | 3,5 | 6,8 | 9,10

    return displayedMessageHistory ? (
      displayedMessageHistory.map(mh => (
        <Grid item xs={12} key={mh.id}>
          <Divider />
          <Grid container padding='12px'>
            <Grid item xs={12} display='flex' justifyContent='flex-start'>
              <div>
                <Typography textAlign='left' variant='overline' color='textSecondary'>
                  {`${ConvertToUtc(mh.created).toLocaleString()} to `}{' '}
                </Typography>
                <Tooltip
                  disableHoverListener={mh.deliveredRecipients.length < 1}
                  disableTouchListener={mh.deliveredRecipients.length < 1}
                  title={
                    <Box style={{ maxHeight: '400px', overflow: 'auto', padding: '0px 16px' }}>
                      <ul style={{ listStyle: 'initial' }}>
                        {mh.deliveredRecipients.map(dr => (
                          <li key={dr.recipientId}>
                            <Typography variant='caption'>{dr.recipientName}</Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  }>
                  <Typography
                    textAlign='left'
                    variant='caption'
                    color='textSecondary'
                    sx={{
                      textDecoration: 'underline',
                      textDecorationStyle: 'dashed',
                      '&:hover': { color: theme.palette.text.primary },
                    }}>
                    {`
                  ${
                    mh.undeliveredRecipients.length === 0
                      ? mh.deliveredRecipients.length.toString()
                      : mh.deliveredRecipients.length.toString() +
                        '/' +
                        (mh.deliveredRecipients.length + mh.undeliveredRecipients.length).toString()
                  } Users`}
                  </Typography>
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={12} maxHeight='72px'>
              <Typography
                textAlign='left'
                variant='body1'
                maxHeight='inherit'
                overflow='hidden'
                style={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                {mh.message}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography
                  onClick={() => handleCopyMessage(mh)}
                  component='p'
                  variant='caption'
                  sx={{
                    color: theme.palette.primary.main,
                    '&:hover': { color: theme.palette.primary.light, cursor: 'pointer' },
                  }}>
                  <ArrowRight sx={{ verticalAlign: 'middle', color: 'inherit' }} />
                  New Message to Recipients
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))
    ) : (
      <></>
    );
  };

  if (!hasMassNotify) {
    return <Redirect to='/Unauthorized' />;
  }

  return (
    <Page
      docTitle='Notify Users'
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          Notify Users
        </Typography>
      }
      subheader={
        <Typography variant='body1' color='textSecondary'>
          Send a one-way text message and email to all or some of the users in your organization.
        </Typography>
      }>
      <Grid container spacing={4}>
        <Grid item md={8} sm={12}>
          <Card>
            <SubCardContainer
              title='Recipients'
              desc={
                <Typography textAlign='left' variant='body2' color='textSecondary'>
                  Choose users to receive this message.
                </Typography>
              }>
              <RecipientAutoComplete
                value={overrideValues}
                helperText={errorState.Recipients ? errorState.Recipients[0] : ''}
                error={errorState.Recipients !== undefined}
                placeholder='Add more recipients...'
                options={recipOptions}
                limitTags={recipOptions.filter(x => x.type === 'site').length}
                onChange={e => {
                  setCommunication({ ...communication, recipients: e });
                  setErrorState(es => ({ ...es, Recipients: undefined }));
                }}
                multiple
                disableCloseOnSelect
              />
            </SubCardContainer>
            <Divider style={{ margin: '24px 0' }} />
            <SubCardContainer
              title='Message'
              desc={
                <Typography textAlign='left' variant='body2' color='textSecondary'>
                  Type a message for your users to receive.
                </Typography>
              }
              justifyTitle='flex-start'>
              <Box style={{ height: '160px' }}>
                <TextArea
                  value={communication.message}
                  isError={errorState.Message !== undefined}
                  onChange={e => {
                    setCommunication({ ...communication, message: e.target.value });
                    setErrorState(es => ({ ...es, Message: undefined }));
                  }}
                  helperText={
                    <Typography
                      variant={errorState.Message ? 'caption' : 'overline'}
                      color={
                        errorState.Message
                          ? theme.palette.error.main
                          : 100 > 500 - communication.message.length
                          ? 20 > 500 - communication.message.length
                            ? theme.palette.error.main
                            : theme.palette.warning.main
                          : undefined
                      }>
                      {errorState.Message
                        ? errorState.Message[0]
                        : `${500 - communication.message.length} characters left`}
                    </Typography>
                  }
                />
              </Box>
            </SubCardContainer>
            <Divider style={{ margin: '24px 0' }} />
            <SubCardContainer
              title='Preview'
              justifyTitle='flex-start'
              desc={
                <Typography textAlign='left' variant='body2' color='textSecondary'>
                  This will be sent via text message and email, depending on each user's settings.
                </Typography>
              }>
              <Box style={{ height: '160px' }}>
                <TextArea
                  disabled
                  sx={{
                    '&.MuiInputBase-root, textarea': {
                      WebkitTextFillColor: `${theme.palette.text.primary} !important`,
                      color: theme.palette.text.primary,
                    },
                  }}
                  value={`${communication.message}\n\nSent by ${currentUser?.fullName || ''} at ${
                    currentUser?.customerName
                  } using Perry Weather`}
                />
              </Box>
            </SubCardContainer>
            <Box marginTop='48px' display='flex' justifyContent='flex-end'>
              <Button onClick={handleSubmit} disabled={disableSend}>
                <Typography variant='body1'>Send Message</Typography>
              </Button>
            </Box>
          </Card>
        </Grid>

        <Grid container item md={4} sm={12} style={{ overflow: 'hidden' }}>
          <Card>
            <Typography textAlign='left' variant='subtitle1' color='textPrimary'>
              History
            </Typography>
            <Typography textAlign='left' variant='body2' color='textSecondary'>
              Your recently sent messages
            </Typography>
            {messageHistory && messageHistory.length > 0 ? (
              <>
                <Grid container height='100%' justifyContent='center' display='flex'>
                  <Grid container>{messageHistoryList()}</Grid>
                </Grid>
                <Box bottom='8px'>
                  <Pagination
                    count={Math.ceil(messageHistory.length / AMT_PER_PAGE)}
                    page={currentPage}
                    onChange={(event, page) => {
                      setCurrentPage(page);
                    }}
                  />
                </Box>
              </>
            ) : (
              <Box height='100%' display='flex' flexDirection='column' justifyContent='center'>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Chat style={{ width: 85, height: 85 }} htmlColor={theme.palette.primary.main} />
                </div>
                <Typography variant='body1' color='textSecondary'>
                  You haven't sent any messages.
                </Typography>
                <Typography variant='body1' color='textSecondary'>
                  Try sending one!
                </Typography>
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}
export default MassNotify;
