import { Box, useTheme, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { styledLinkClass } from './Link';

// import { Variant } from '@mui/material/styles';

type LabelColor = 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';

interface LabelProps {
  text: string;
  href?: string;
  labelVariant?: Variant;
  labelColor?: LabelColor;
}

export function Label(props: LabelProps) {
  const theme = useTheme();
  const classes = styledLinkClass(theme);
  const { text, href, labelVariant, labelColor } = props;

  const displayText = (
    <Typography color={labelColor || 'inherit'} variant={labelVariant || 'body1'}>
      {text}
    </Typography>
  );
  return (
    <Box display='flex' flexDirection='column' justifyContent='center'>
      {href !== undefined ? (
        <a className={classes.link} href={href}>
          {displayText}
        </a>
      ) : (
        displayText
      )}
    </Box>
  );
}
