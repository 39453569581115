import { Map, RasterSource } from "maplibre-gl";
import { useEffect, useState } from "react";
import { Layer, LayerProps, Source } from "react-map-gl";
import { Location, useRadarTmsListQuery } from "../../../../../Services/API";
import { radarTypes } from "../../../../../features/map/mapSlice";
import { BaronUtil } from "../Util/BaronUtil";
import { RadarUtil } from "../Util/RadarUtil";

export interface StaticTmsSourceProps {
  beforeId: string;
  mapRef: Map;
  opacity: number;
  location: Location | undefined;
  radarType: radarTypes;
  progress: number;
  handleLabelChange: (value: string) => void;
  handleLoadingState: (value: boolean) => void;
}

export default function StaticTmsSource(props: StaticTmsSourceProps) {
  const { beforeId, mapRef, opacity, location, radarType, progress, handleLabelChange, handleLoadingState } = props;
  const [radarParams, setRadarParams] = useState(BaronUtil.getRadarParams(radarType, location));

  const { data: radarAnimList, isSuccess, isLoading } = useRadarTmsListQuery(radarParams, { pollingInterval: 180000 });
  const [currentUrl, setCurrentUrl] = useState<string>(RadarUtil.transparentImageUrl);

  useEffect(() => {
    const radarParams = BaronUtil.getRadarParams(radarType, location)
    setRadarParams(radarParams);
  }, [radarType, location]);

  useEffect(() => {
    handleLoadingState(isLoading);
  }, [isLoading])

  //Updating raster sources is a bit goofy, update the sources tiles, clear the sources cache, upddate the source cache, then trigger a repaint. Whew.
  useEffect(() => {
    if (isSuccess && radarAnimList) {
      const currentIndex = BaronUtil.getCurrentFrame(radarAnimList, progress);
      const currentFrame = radarAnimList[currentIndex];
      handleLabelChange(BaronUtil.parseDateFromTmsUrl(currentFrame, radarType));
      const source = (mapRef.getSource('static-tms-radar') as RasterSource)
      if (source) {
        source.tiles = [currentFrame];
      }

      var mapAny = mapRef as any;
      var sourceCache = mapAny.style.sourceCaches['static-tms-radar'];
      if (sourceCache) {
        sourceCache.clearTiles();
        sourceCache.update(mapAny.transform);
      }
      else {
        setCurrentUrl(currentFrame);
      }
      mapAny.triggerRepaint();
    }
  }, [radarAnimList, isSuccess, mapRef, progress])

  return (
    <>
      {currentUrl != RadarUtil.transparentImageUrl && (
        <Source id='static-tms-radar' type='raster' tileSize={256} tiles={[currentUrl]} scheme='tms'>
          <Layer beforeId={beforeId} {...{ ...layerProps, paint: { 'raster-fade-duration': 0, 'raster-opacity': opacity } }} />
        </Source>
      )}
    </>
  )
}

const layerProps: LayerProps = {
  id: 'static-tms-radar',
  type: 'raster',
  minzoom: 0,
  maxzoom: 14,
  paint: {
    'raster-fade-duration': 0,
  },
};
