//9003-Day

export const PartlyCloudyDay = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st4'
        d='M8.25,5.65c-0.06,0-0.11,0-0.16,0.01c-0.22-0.87-1-1.48-1.91-1.48c-1.07,0-1.95,0.86-1.97,1.93
                C3.58,6.15,3.07,6.68,3.07,7.33c0,0.67,0.55,1.22,1.22,1.22h3.96c0.8,0,1.45-0.65,1.45-1.45C9.71,6.3,9.05,5.65,8.25,5.65z
                M4.59,6.15c0-0.88,0.72-1.6,1.6-1.6c0.79,0,1.45,0.57,1.58,1.35C7.77,5.95,7.8,6,7.84,6.03c0.04,0.03,0.1,0.04,0.15,0.03
                c0.09-0.02,0.18-0.03,0.26-0.03c0.59,0,1.07,0.48,1.07,1.08S8.85,8.17,8.25,8.17l-0.02,0c-0.02,0-0.04,0-0.05,0H4.29
                c-0.47,0-0.85-0.38-0.85-0.85c0-0.47,0.38-0.85,0.85-0.85c0.03,0,0.06,0,0.1,0.01c0.06,0,0.11-0.02,0.15-0.05
                C4.58,6.39,4.6,6.34,4.59,6.28C4.59,6.24,4.59,6.2,4.59,6.15z'
      />
      <path
        className='st4'
        d='M3.13,6.44C3.12,6.39,3.09,6.34,3.04,6.32c-0.44-0.28-0.7-0.76-0.7-1.28c0-0.84,0.68-1.52,1.52-1.52
                c0.53,0,0.96,0.22,1.24,0.64C5.13,4.2,5.18,4.23,5.23,4.24c0.05,0.01,0.1,0,0.14-0.03c0.04-0.03,0.07-0.07,0.08-0.12
                c0.01-0.05,0-0.1-0.03-0.14c-0.35-0.52-0.9-0.81-1.55-0.81c-1.05,0-1.9,0.85-1.9,1.9c0,0.65,0.33,1.25,0.87,1.6
                c0.03,0.02,0.06,0.03,0.1,0.03c0.07,0,0.13-0.03,0.16-0.08C3.13,6.54,3.14,6.49,3.13,6.44z'
      />
      <path
        className='st4'
        d='M3.85,2.73c0.1,0,0.19-0.08,0.19-0.19v-0.9c0-0.1-0.08-0.19-0.19-0.19c-0.1,0-0.19,0.08-0.19,0.19v0.9
                C3.66,2.64,3.74,2.73,3.85,2.73z'
      />
      <path
        className='st4'
        d='M2.39,3.01C2.43,3.06,2.49,3.1,2.55,3.1c0.03,0,0.07-0.01,0.1-0.03l0,0C2.69,3.04,2.72,3,2.73,2.95
                c0.01-0.05,0-0.1-0.02-0.14L2.24,2.04C2.21,2,2.17,1.97,2.12,1.96c-0.05-0.01-0.1,0-0.14,0.02C1.89,2.04,1.86,2.15,1.92,2.24
                L2.39,3.01z'
      />
      <path
        className='st4'
        d='M1.59,4.01c0.03,0.01,0.06,0.02,0.09,0.02c0.07,0,0.13-0.04,0.16-0.1c0.05-0.09,0.02-0.2-0.08-0.25l-0.8-0.43
                C0.88,3.2,0.76,3.24,0.71,3.33C0.67,3.42,0.7,3.53,0.79,3.58L1.59,4.01z'
      />
      <path
        className='st4'
        d='M1.57,5c0-0.1-0.08-0.19-0.19-0.19l-0.9,0c-0.1,0-0.19,0.08-0.19,0.19c0,0.1,0.08,0.19,0.19,0.19l0.9,0
                C1.49,5.18,1.57,5.1,1.57,5z M0.48,4.88L0.48,4.88L0.48,4.88L0.48,4.88z'
      />
      <path
        className='st4'
        d='M1.74,6C1.69,5.98,1.64,5.99,1.6,6.02L0.8,6.45C0.76,6.47,0.73,6.51,0.71,6.56C0.7,6.61,0.7,6.66,0.73,6.7
                c0.03,0.06,0.1,0.1,0.16,0.1c0.03,0,0.07-0.01,0.09-0.02l0.79-0.43c0.04-0.02,0.08-0.06,0.09-0.11c0.01-0.05,0.01-0.1-0.02-0.14
                C1.83,6.05,1.79,6.01,1.74,6z'
      />
      <path
        className='st4'
        d='M6.01,4.01C6.04,4.01,6.06,4,6.1,3.98l0.79-0.43v0c0.04-0.02,0.08-0.06,0.09-0.11
                c0.01-0.05,0.01-0.1-0.02-0.14C6.91,3.2,6.8,3.17,6.71,3.22L5.92,3.65C5.87,3.68,5.84,3.72,5.83,3.77
                c-0.01,0.05-0.01,0.1,0.02,0.14C5.87,3.97,5.94,4.01,6.01,4.01z'
      />
      <path
        className='st4'
        d='M2.38,6.99L1.9,7.75C1.88,7.79,1.87,7.84,1.88,7.89c0.01,0.05,0.04,0.09,0.08,0.12
                c0.03,0.02,0.06,0.03,0.1,0.03c0.06,0,0.13-0.03,0.16-0.09L2.7,7.19c0.03-0.04,0.04-0.09,0.02-0.14C2.71,7,2.68,6.95,2.64,6.93
                C2.55,6.87,2.44,6.9,2.38,6.99z'
      />
      <path
        className='st4'
        d='M5.15,3.1c0.06,0,0.13-0.03,0.16-0.09l0.48-0.76C5.81,2.2,5.82,2.15,5.81,2.11C5.8,2.06,5.77,2.01,5.73,1.99
                C5.69,1.96,5.64,1.95,5.59,1.96C5.54,1.97,5.5,2,5.47,2.05L4.99,2.81C4.94,2.9,4.96,3.02,5.05,3.07C5.08,3.09,5.12,3.1,5.15,3.1z'
      />
    </g>
  </svg>
);
