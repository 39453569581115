//2013-Night

export const ChanceOfRainShowersNight = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M2.55,5.96c-0.54-0.07-1.03-0.33-1.4-0.73c0.04,0,0.07,0,0.11,0C2.77,5.23,3.99,4,3.99,2.5
            c0-0.34-0.06-0.68-0.19-0.99c0.5,0.21,0.9,0.59,1.15,1.07c0.02,0.04,0.06,0.05,0.1,0.03c0.06-0.03,0.14-0.06,0.22-0.09
            C5.29,2.52,5.31,2.5,5.31,2.48c0.01-0.02,0.01-0.04,0-0.06C4.95,1.7,4.29,1.18,3.51,0.99C3.38,0.96,3.27,0.94,3.16,0.93
            c-0.03,0-0.06,0.01-0.07,0.04C3.07,0.99,3.07,1.03,3.09,1.05c0.06,0.08,0.13,0.17,0.18,0.27C3.49,1.68,3.6,2.08,3.6,2.5
            c0,1.29-1.05,2.34-2.34,2.34c-0.15,0-0.3-0.01-0.45-0.04C0.71,4.77,0.6,4.74,0.5,4.7c-0.03-0.01-0.06,0-0.08,0.02
            C0.4,4.75,0.39,4.78,0.4,4.81c0.05,0.11,0.11,0.21,0.17,0.31c0.45,0.7,1.19,1.15,2.02,1.24c0,0,0.01,0,0.01,0
            c0.02,0,0.04-0.01,0.06-0.03c0.02-0.02,0.02-0.05,0.01-0.07C2.65,6.19,2.63,6.1,2.62,6.02C2.61,5.99,2.59,5.96,2.55,5.96z'
      />
      <path
        className='st1'
        d='M7.95,4.1C7.72,3.2,6.92,2.58,5.99,2.58c-1.1,0-2,0.89-2.03,1.98C3.3,4.6,2.78,5.15,2.78,5.82
            c0,0.69,0.56,1.26,1.26,1.26h4.08c0.82,0,1.49-0.67,1.49-1.49C9.6,4.71,8.84,4,7.95,4.1z M4.34,4.61c0-0.91,0.74-1.65,1.65-1.65
            c0.81,0,1.5,0.58,1.62,1.39c0.01,0.05,0.04,0.1,0.08,0.13c0.04,0.03,0.1,0.04,0.15,0.03c0.72-0.18,1.38,0.38,1.38,1.08
            c0,0.61-0.5,1.11-1.11,1.11c0,0-0.01,0-0.01,0c-0.02,0-0.04,0-0.07,0h-4c-0.48,0-0.87-0.39-0.87-0.88s0.39-0.87,0.87-0.87
            c0.03,0,0.07,0,0.1,0c0.05,0.01,0.11-0.01,0.15-0.05c0.04-0.04,0.06-0.1,0.06-0.15C4.34,4.7,4.34,4.65,4.34,4.61z'
      />
    </g>
    <path
      fill='currentColor'
      d='M2.97,7.61L2.55,8.44C2.51,8.53,2.54,8.65,2.64,8.69C2.67,8.71,2.7,8.71,2.72,8.71c0.07,0,0.14-0.04,0.17-0.1
        l0.42-0.83c0.05-0.09,0.01-0.21-0.08-0.26C3.13,7.48,3.02,7.52,2.97,7.61z'
    />
    <path
      fill='currentColor'
      d='M7.41,7.61L6.99,8.44C6.94,8.53,6.98,8.65,7.08,8.69c0.03,0.01,0.06,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
        l0.42-0.83c0.05-0.09,0.01-0.21-0.08-0.26C7.57,7.48,7.45,7.52,7.41,7.61z'
    />
    <path
      fill='currentColor'
      d='M8.89,7.61L8.47,8.44C8.42,8.53,8.46,8.65,8.56,8.69c0.03,0.01,0.06,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
        l0.42-0.83c0.05-0.09,0.01-0.21-0.08-0.26C9.05,7.48,8.93,7.52,8.89,7.61z'
    />
    <path
      fill='currentColor'
      d='M4.45,7.61L4.03,8.44C4.01,8.48,4,8.53,4.02,8.58c0.02,0.05,0.05,0.09,0.1,0.11C4.15,8.71,4.17,8.71,4.2,8.71
        c0.07,0,0.14-0.04,0.17-0.11l0.41-0.83c0.02-0.05,0.03-0.1,0.01-0.15C4.78,7.59,4.75,7.55,4.7,7.53C4.61,7.48,4.5,7.52,4.45,7.61z'
    />
    <path
      fill='currentColor'
      d='M6.04,7.51c-0.05,0.02-0.09,0.05-0.11,0.1L5.51,8.44C5.49,8.48,5.48,8.53,5.5,8.58s0.05,0.09,0.1,0.11
        C5.62,8.7,5.65,8.71,5.68,8.71c0.07,0,0.14-0.04,0.17-0.11l0.41-0.83c0.02-0.05,0.03-0.1,0.01-0.15c-0.02-0.05-0.05-0.09-0.1-0.11
        C6.14,7.5,6.09,7.5,6.04,7.51z'
    />
  </svg>
);
