import * as React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Layer, ResponsiveLine, Serie, Point, AccessorFunc } from '@nivo/line';
import { TIME_PRECISION } from '@nivo/scales';
import { DateFormatter, DateTimeFormatter } from '../../features/Time/TimeHelpers';
import { CartesianMarkerProps, Margin } from '@nivo/core';

interface APLProps {
  points: Point[];
}

const ActivePointLayer = (props: APLProps) => {
  const { points } = props;
  if (points.length < 1) return null;

  return (
    <g pointerEvents={'none'}>
      {points.map(point => {
        return (
          <g pointerEvents={'none'} transform={`translate(${point.x},${point.y})`}>
            <circle r={10} fill={point.color} />
          </g>
        );
      })}
    </g>
  );
};

export interface TimeChartProps {
  style?: React.CSSProperties;
  curve?:
    | 'basis'
    | 'cardinal'
    | 'catmullRom'
    | 'linear'
    | 'monotoneX'
    | 'monotoneY'
    | 'natural'
    | 'step'
    | 'stepAfter'
    | 'stepBefore';
  chartMargin?: Partial<Margin>;
  title?: JSX.Element;
  data: Serie[];
  colors?: string[];
  lineWidth?: number;
  bottomTickValue?: number;
  leftTickValue?: number;
  min?: number;
  max?: number;
  enablePoints?: boolean;
  pointSize?: number;
  enableAxes?: boolean;
  enableGridX?: boolean;
  enableActivePoint?: boolean;
  formatType?: TIME_PRECISION;
  customPointLabel?: string | AccessorFunc;
  markers?: CartesianMarkerProps[];
  labelSuffix?: string;
}

export default function BaseTimeChart(props: TimeChartProps) {
  const {
    style,
    title,
    data,
    lineWidth,
    colors,
    max,
    min,
    chartMargin,
    markers,
    customPointLabel,
    bottomTickValue = 7,
    leftTickValue,
    curve = 'linear',
    enableActivePoint = true,
    enableAxes = true,
    enableGridX = true,
    formatType = 'day',
    enablePoints = false,
    pointSize = 16,
    labelSuffix = '',
  } = props;
  const theme = useTheme();

  var timeFormat = formatType === 'day' ? '%Y-%m-%d' : '%Y-%m-%d %H:%M:%S';
  var axisTimeFormat = formatType === 'day' ? '%b %d' : '%_I%p';

  const [current, setCurrent] = React.useState<Point[]>([]);

  var layers: Layer[] = [];

  layers.push('grid');
  layers.push('markers');
  layers.push('areas');
  layers.push('crosshair');
  layers.push('points');
  layers.push('slices');
  layers.push('mesh');
  layers.push('legends');
  layers.push('lines');

  if (enableAxes) {
    layers.push('axes');
  }
  if (enableActivePoint) {
    layers.push(() => <ActivePointLayer points={current} />);
  }

  return (
    <div style={style}>
      {title || <></>}
      <ResponsiveLine
        data={data}
        curve={curve}
        lineWidth={lineWidth}
        xScale={{
          type: 'time',
          precision: formatType,
          format: `${timeFormat}`,
          useUTC: false,
        }}
        xFormat={`time:${timeFormat}`}
        yScale={{ type: 'linear', min: min, max: max }}
        margin={chartMargin}
        axisLeft={{ tickValues: leftTickValue, tickSize: 5, tickPadding: 10, tickRotation: 0 }}
        axisBottom={{
          format: axisTimeFormat,
          tickValues: bottomTickValue,
          tickSize: 5,
          tickPadding: 5,
        }}
        layers={layers}
        sliceTooltip={({ slice }) => {
          setCurrent(slice.points);
          return (
            <div
              style={{
                background: theme.palette.background.paper,
                width: 185,
                borderRadius: 8,
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
                border: '1px solid',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderColor: theme.palette.border.main,
              }}>
              <Typography variant='subtitle1'>
                {formatType === 'day'
                  ? DateFormatter(slice.points[0].data.xFormatted.toString())
                  : DateTimeFormatter(slice.points[0].data.x.toString())}
              </Typography>
              {slice.points.map(point => (
                <div
                  key={point.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 8,
                    marginTop: 4,
                    marginBottom: 4,
                    height: 40,
                    background: theme.palette.background.default,
                    borderRadius: 6,
                  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ height: 16, width: 16, backgroundColor: point.color, borderRadius: '50%' }}></div>
                    <Typography sx={{ marginLeft: '8px' }}>{point.serieId}</Typography>
                  </div>
                  <Typography variant='subtitle1'>{point.data.yFormatted} {labelSuffix}</Typography>
                </div>
              ))}
            </div>
          );
        }}
        areaOpacity={0.3}
        areaBaselineValue={min}
        enablePoints={enablePoints}
        enablePointLabel={true}
        pointLabel={customPointLabel}
        pointSize={pointSize}
        enableGridX={enableGridX}
        enableGridY={false}
        enableCrosshair={false}
        enableArea={true}
        useMesh={true}
        isInteractive={true}
        colors={colors}
        enableSlices={'x'}
        markers={markers}
        defs={[
          {
            id: 'gradientA',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: 'inherit' },
              { offset: 90, color: 'inherit', opacity: 0 },
            ],
          },
        ]}
        fill={[{ match: '*', id: 'gradientA' }]}
        animate={true}
        theme={{
          fontFamily: 'proxima-nova',
          fontSize: 12,
          textColor: theme.palette.text.primary,
          axis: {
            domain: {
              line: {
                stroke: 'none',
              },
            },
            ticks: {
              line: {
                stroke: 'none',
              },
            },
          },
          grid: {
            line: {
              stroke: theme.palette.divider,
            },
          },
        }}
      />
    </div>
  );
}
