import { Create, InfoOutlined, WarningAmber } from '@mui/icons-material';
import { Box, CircularProgress, Grid, IconButton, Link, Typography, useTheme } from '@mui/material';
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import { useAuth } from 'oidc-react';
import React, { useEffect } from 'react';
import { ALERT_GROUP_ID, useEmailSubscriptionsQuery, User } from '../../Services/API';
import Table, { RowMenuProps } from '../Base/BaseTable';
import {  styledLinkClass, Tooltip } from '../Base';
import { UserDeleteModal } from './UserDeleteModal';
import { useSendActivationEmailMutation } from '../../Services/API';
import { useDispatch } from 'react-redux';
import { openToast } from '../../features/toast/toastSlice';

interface TeamTableProps {
  rows: User[];
  onSelectionModelChange?: any;
  currentUser: User;
  hideId?: boolean;
  hideUsername?: boolean;
  hideEmail?: boolean;
  hideRole?: boolean;
  hideCustomerName?: boolean;
  hideFullName?: boolean;
  hideModalOptions?: boolean;
  loading?: boolean;
  onEditRowClick?: (userId: string) => void;
  onDeleteRowClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function getDisplayedRows(rows: User[]) {
  let i = 0;
  let rowDis: User[] = [];
  rowDis = rows.map(row => {
    let newRow: User = { ...row, id: (++i).toString() };
    return newRow;
  });
  // rows.forEach(row => rowDis.push({...row, id: (++i).toString(), fullName: `${row.firstName} ${row.lastName}`}))
  // return rows;
  return rowDis;
}

export function UsersTable(props: TeamTableProps) {
  const {
    rows,
    hideEmail,
    hideRole,
    hideCustomerName,
    currentUser,
    hideFullName,
    onSelectionModelChange,
    hideModalOptions,
    onEditRowClick,
    loading,
  } = props;

  const theme = useTheme();
  const classes = styledLinkClass(theme);
  const { userData: user } = useAuth();

  // console.debug('user table rendered');

  function RenderModals(props: RowMenuProps) {
    const { id } = props;

    const row: User = rows[(id as number) - 1];

    const handleRowEdit = () => {
      if (onEditRowClick) onEditRowClick(row.id);
    };

    if (
      currentUser === undefined ||
      row.roleHierarchy <= currentUser.roleHierarchy ||
      currentUser.roleHierarchy > 3 ||
      (currentUser.roleHierarchy > 2 && currentUser.siteId !== row.siteId)
    )
      return <> </>;
    else
      return (
        <Box component='div' display='flex' overflow='hidden' width='100%'>
          <Grid container>
            <Grid item md={4}>
              <Box>
                <IconButton onClick={handleRowEdit}>
                  <Create htmlColor={theme.palette.primary.main} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box>
                <UserDeleteModal user={row} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      );
  }

  function RenderActive(props: { children: React.ReactNode; id: GridRowId }) {
    const active: boolean =
      rows[(props.id as number) - 1].isActive ||
      rows[(props.id as number) - 1].smsNotifications ||
      rows[(props.id as number) - 1].emailNotifications ||
      false;

    return (
      <Grid container spacing={1} color={!active ? theme.palette.grey[600] : undefined}>
        <Grid item>
          <Typography overflow='hidden' textOverflow='ellipsis'>
            {props.children}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function RenderFullName(props: GridRenderCellParams) {
    const [sendActivationEmail, sendActivationEmailResult] = useSendActivationEmailMutation();
    const [sendText, setSendText] = React.useState('resend?');
    const row = rows[(props.id as number) - 1];
    const active: boolean = row.isActive || row.smsNotifications || row.emailNotifications || false;
    const userId: string = row.id;
    const roleId: string = row.roleId;

    const dispatch = useDispatch();

    const handleSendActiviation = () => {
      sendActivationEmail(userId);
    };

    useEffect(() => {
      
      if (sendActivationEmailResult.isSuccess && sendActivationEmailResult.data && sendActivationEmailResult.data.isSuccess) {
        dispatch(openToast({ variant: 'success', header: 'Invite email sent!' }));
        setSendText('Sent! Send again?');
      }
      if (sendActivationEmailResult.isError || (sendActivationEmailResult.data && !sendActivationEmailResult.data.isSuccess)){
        setSendText('Failed to send. Try again?')
        dispatch(openToast({ variant: 'error', header: 'Unable to send invite email' }));
      }
    }, [sendActivationEmailResult, dispatch]);

    let sendTextColor = theme.palette.primary.main;
    if(sendActivationEmailResult.isSuccess)
      sendTextColor = theme.palette.success.main;
    if(sendActivationEmailResult.isError)
      sendTextColor = theme.palette.error.main;

    return (
      <RenderActive id={props.id}>
        {props.value}
        {props.colDef.field === 'fullName' && !active && (
          <Typography component='span' variant='body2'>
            {' (inactive) '}
            {(user?.profile.Role === 'Super Admin' ||
              user?.profile.Role === 'Admin' ||
              (user?.profile.Role === 'Assistant Admin' && row.siteId === currentUser?.siteId)) &&
              roleId !== '-1' && (
                <Tooltip title={<Typography>Resend user invitation email?</Typography>} placement='top'>
                  <Typography
                    onClick={() => handleSendActiviation()}
                    variant='caption'
                    component='span'
                    style={{
                      background: 'none',
                      color: sendTextColor,
                      textDecoration: 'none',
                      padding: '2px',
                      cursor: 'pointer',
                    }}
                    className={classes.link}>
                    {sendActivationEmailResult.isLoading 
                      && <CircularProgress size={15}/>
                    }
                    {!sendActivationEmailResult.isLoading &&
                      sendText
                    }
                  </Typography>
                </Tooltip>
              )}
          </Typography>
        )}
      </RenderActive>
    );
  }

  function RenderEmail(props: GridRenderCellParams) {
    const row = rows[(props.id as number) - 1];
    const active: boolean = row.isActive || row.smsNotifications || row.emailNotifications || false;
    const { data: emailSubscriptionResult, refetch: refetchEmailSubscriptions } = useEmailSubscriptionsQuery(row.id, { skip: row.roleName !== 'Notification Only'});

    const emailSubscriptionMessage = 
    <>
      <Typography>This user unsubscribed from email alerts.</Typography>
      <Typography>
        Contact 
        <Link href="mailto:support@perryweather.com"> support@perryweather.com </Link> 
        for help.
      </Typography>
    </> 
    
    const showSubscriptionWarning = row.emailNotifications  && emailSubscriptionResult && emailSubscriptionResult.some(x => x.id === ALERT_GROUP_ID && x.suppressed === true);
    return (
      <RenderActive id={props.id}>
        <Grid container justifyContent={'center'}>
          {row.email}
          {showSubscriptionWarning && (
            <Tooltip title={emailSubscriptionMessage}>
              <WarningAmber htmlColor={theme.palette.error.main} />
            </Tooltip>
          )}
        </Grid>
      </RenderActive>
    );
  }

  function RenderRole(props: GridRenderCellParams) {
    let tooltip: string = '';
    let roleDisplayName = rows.find(x => x.roleName === props.value)?.roleDisplayName;

    switch (props.value) {
      case 'Admin':
        tooltip = 'Full permissions';
        break;
      case 'Assistant Admin':
        tooltip = 'Full permissions, limited to specific user group(s) only';
        break;
      case 'Customer':
        tooltip =
          'Basic permissions. Cannot edit policies, users, and hardware settings. Cannot use Talk to a Meteorologist';
        break;
      case 'Notification Only':
        tooltip = 'Receives notifications only. Cannot sign in to Perry Weather on web or mobile.';
        break;
    }

    return (
      <RenderActive id={props.id}>
        <Tooltip title={tooltip}>
          <Typography component='span'>{roleDisplayName || props.value}</Typography>
        </Tooltip>
      </RenderActive>
    );
  }

  function RenderSite(props: GridRenderCellParams) {
    let roleHierarchy = rows[(props.id as number) - 1].roleHierarchy;

    return (
      <RenderActive id={props.id}>
        <Typography component='span'>{roleHierarchy < 3 ? '' : props.value}</Typography>
      </RenderActive>
    );
  }

  function RenderPermissionHeader(props: GridColumnHeaderParams) {
    const permissionsHelperText = (
      <Box padding='0 16px'>
        <ul style={{ listStyle: 'initial' }}>
          <li>
            <b>Admin:</b> Full permissions
          </li>
          <li>
            <b>Limited Admin:</b> Full permissions for assigned user group only
          </li>
          <li>
            <b>Basic:</b> Basic permissions. Cannot edit policies, users, or hardware settings. Cannot use "Talk to a
            Meteorologist."
          </li>
          <li>
            <b>Notification Only:</b> Receives notifications only. Cannot sign into Perry Weather app on web or mobile
          </li>
        </ul>
      </Box>
    );

    return (
      <>
        <Tooltip title={permissionsHelperText}>
          <InfoOutlined htmlColor={theme.palette.primary.main} />
        </Tooltip>
        <Typography marginLeft='4px'>{props.colDef.headerName || props.field}</Typography>
      </>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: true,
      flex: 0.1,
      hide: true,
      renderCell: e => RenderActive({ children: e.value, id: e.id }),
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      sortable: true,
      flex: 0.2,
      hide: hideFullName,
      renderCell: RenderFullName,
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      flex: 0.2,
      hide: hideEmail,
      renderCell: RenderEmail,
    },
    {
      field: 'roleName',
      headerName: 'Permission Level',
      sortable: true,
      flex: 0.2,
      hide: hideRole,
      renderCell: RenderRole,
      renderHeader: RenderPermissionHeader,
    },
    {
      field: 'siteName',
      headerName: 'User Group',
      sortable: true,
      flex: 0.2,
      hide: hideCustomerName,
      renderCell: RenderSite,
    },
  ];

  if (
    user &&
    user.profile &&
    (user.profile.Role === 'Super Admin' || user.profile.Role === 'Admin' || user.profile.Role === 'Assistant Admin')
  )
    columns.push({
      field: 'modalOptions',
      headerName: ' ',
      renderCell: RenderModals,
      sortable: false,
      flex: 0.1,
      hide: hideModalOptions,
    });

  return (
    <Table
      loading={loading}
      cols={columns}
      rows={getDisplayedRows(rows)}
      rowHeight={54}
      rowSpace={8}
      rowsPerPageOptions={[10, 25, 50]}
      onSelectionModelChange={onSelectionModelChange}
    />
  );
}

export const MemoizedUsersTable = React.memo(UsersTable);
