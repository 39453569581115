//0012

export const Drizzle = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M7.82,3.39c-0.05,0-0.11,0-0.16,0.01C7.43,2.5,6.62,1.87,5.68,1.87c-1.1,0-2.01,0.88-2.04,2
                C2.98,3.91,2.46,4.46,2.46,5.13c0,0.7,0.57,1.27,1.26,1.27h4.1c0.83,0,1.5-0.67,1.5-1.5C9.32,4.07,8.65,3.39,7.82,3.39z M4.02,3.91
                c0-0.91,0.74-1.66,1.66-1.66c0.82,0,1.51,0.59,1.64,1.4c0.01,0.05,0.04,0.1,0.08,0.13c0.04,0.03,0.1,0.04,0.15,0.03
                C8.3,3.64,8.94,4.19,8.94,4.89c0,0.62-0.5,1.12-1.12,1.12l-0.02,0c-0.02,0-0.04,0-0.06,0H3.72c-0.49,0-0.88-0.39-0.88-0.88
                s0.4-0.88,0.88-0.88c0.03,0,0.06,0,0.1,0.01c0.06,0,0.11-0.01,0.15-0.05c0.04-0.04,0.06-0.1,0.06-0.17
                C4.03,3.99,4.02,3.95,4.02,3.91z'
      />
      <path
        className='st1'
        d='M2.08,5.19c-0.52-0.1-0.9-0.54-0.9-1.08c0-0.7,0.65-1.26,1.39-1.08c0.05,0.01,0.11,0,0.15-0.03
                c0.04-0.03,0.07-0.08,0.08-0.13c0.13-0.81,0.82-1.4,1.64-1.4c0.2,0,0.4,0.05,0.59,0.12C5.2,1.53,5.4,1.49,5.62,1.49
                C5.61,1.48,5.61,1.46,5.6,1.45C5.26,1.21,4.85,1.08,4.43,1.08c-0.94,0-1.75,0.63-1.98,1.53C2.4,2.61,2.34,2.61,2.29,2.61
                c-0.83,0-1.5,0.67-1.5,1.5c0,0.76,0.56,1.39,1.32,1.48C2.08,5.46,2.07,5.32,2.08,5.19z'
      />
      <path
        className='st1'
        d='M3.97,6.87L3.59,7.58C3.55,7.67,3.59,7.79,3.68,7.83c0.03,0.01,0.05,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
                L4.3,7.04c0.02-0.05,0.03-0.1,0.01-0.15C4.3,6.84,4.26,6.8,4.22,6.78C4.13,6.74,4.02,6.78,3.97,6.87z'
      />
      <path
        className='st1'
        d='M5.91,6.87L5.53,7.58C5.49,7.67,5.53,7.79,5.62,7.83C5.65,7.85,5.67,7.85,5.7,7.85c0.07,0,0.14-0.04,0.17-0.1
                l0.37-0.71c0.02-0.05,0.03-0.1,0.01-0.15C6.24,6.84,6.2,6.8,6.16,6.78C6.07,6.74,5.96,6.78,5.91,6.87z'
      />
      <path
        className='st1'
        d='M4.56,8.04L4.19,8.75C4.14,8.84,4.18,8.96,4.28,9C4.3,9.02,4.33,9.02,4.36,9.02c0.07,0,0.14-0.04,0.17-0.1
                L4.9,8.21c0.02-0.05,0.03-0.1,0.01-0.15C4.89,8.01,4.86,7.97,4.81,7.95C4.72,7.91,4.61,7.95,4.56,8.04z'
      />
      <path
        className='st1'
        d='M6.5,8.04L6.13,8.75C6.08,8.84,6.12,8.96,6.22,9C6.24,9.02,6.27,9.02,6.3,9.02c0.07,0,0.14-0.04,0.17-0.1
                l0.37-0.71c0.02-0.05,0.03-0.1,0.01-0.15C6.83,8.01,6.8,7.97,6.75,7.95C6.66,7.91,6.55,7.95,6.5,8.04z'
      />
      <path
        className='st1'
        d='M7.85,6.87L7.47,7.58C7.43,7.67,7.47,7.79,7.56,7.83c0.03,0.01,0.05,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
                l0.37-0.71c0.02-0.05,0.03-0.1,0.01-0.15C8.18,6.84,8.14,6.8,8.1,6.78C8.01,6.74,7.9,6.78,7.85,6.87z'
      />
    </g>
  </svg>
);
