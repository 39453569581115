import {
  ChanceOfDrizzleDay,
  ChanceOfDrizzleNight,
  ChanceOfFreezingRainDay,
  ChanceOfFreezingRainNight,
  ChanceOfRainDay,
  ChanceOfRainNight,
  ChanceOfRainShowersDay,
  ChanceOfRainShowersNight,
  ChanceOfSnowDay,
  ChanceOfSnowNight,
  ChanceOfThunderstormDay,
  ChanceOfThunderstormNight,
  ClearDay,
  ClearNight,
  Cloudy,
  Drizzle,
  HazeDay,
  HazeNight,
  MostlyCloudyDay,
  MostlyCloudyNight,
  NA,
  PartlyCloudyDay,
  PartlyCloudyNight,
  Rain,
  Sleet,
  Snow,
  Thunderstorm,
  ThunderstormsAndFrozenPrecipitation,
  WindyDay,
  WindyNight,
} from '../../../Assets/WeatherCodeIcons';

interface WeatherIconProps {
  code: string;
  isDaylight: boolean;
}

export function WeatherIcon(props: WeatherIconProps) {
  const { code, isDaylight } = props;

  if (code === '0002' || code === '0003' || code === '0004' || code === '0005') {
    if (isDaylight) {
      return <HazeDay />;
    } else {
      return <HazeNight />;
    }
  } else if (code === '0012') {
    return <Drizzle />;
  } else if (code === '0013' || code === '0014') {
    return <Rain />;
  } else if (code === '0016' || code === '0017' || code === '0011') {
    return <Snow />;
  } else if (
    code === '0018' ||
    code === '0019' ||
    code === '0020' ||
    code === '0024' ||
    code === '0025' ||
    code === '0026' ||
    code === '0027' ||
    code === '0028' ||
    code === '0029' ||
    code === '0030' ||
    code === '9200'
  ) {
    return <Sleet />;
  } else if (code === '0021' || code === '0022' || code === '0023') {
    return <Thunderstorm />;
  } else if (code === '2012') {
    if (isDaylight) {
      return <ChanceOfDrizzleDay />;
    } else {
      return <ChanceOfDrizzleNight />;
    }
  } else if (code === '2013') {
    if (isDaylight) {
      return <ChanceOfRainShowersDay />;
    } else {
      return <ChanceOfRainShowersNight />;
    }
  } else if (code === '2014') {
    if (isDaylight) {
      return <ChanceOfRainDay />;
    } else {
      return <ChanceOfRainNight />;
    }
  } else if (code === '2016' || code === '2017' || code === '2018') {
    if (isDaylight) {
      return <ChanceOfSnowDay />;
    } else {
      return <ChanceOfSnowNight />;
    }
  } else if (
    code === '2019' ||
    code === '2020' ||
    code === '2024' ||
    code === '2025' ||
    code === '2026' ||
    code === '2027' ||
    code === '2028' ||
    code === '2029' ||
    code === '2030'
  ) {
    if (isDaylight) {
      return <ChanceOfFreezingRainDay />;
    } else {
      return <ChanceOfFreezingRainNight />;
    }
  } else if (code === '2021' || code === '2022' || code === '2023') {
    if (isDaylight) {
      return <ChanceOfThunderstormDay />;
    } else {
      return <ChanceOfThunderstormNight />;
    }
  } else if (code === '9000' || code === '9001' || code === '9002') {
    if (isDaylight) {
      return <ClearDay />;
    } else {
      return <ClearNight />;
    }
  } else if (code === '9003') {
    if (isDaylight) {
      return <PartlyCloudyDay />;
    } else {
      return <PartlyCloudyNight />;
    }
  } else if (code === '9004') {
    if (isDaylight) {
      return <MostlyCloudyDay />;
    } else {
      return <MostlyCloudyNight />;
    }
  } else if (code === '9005') {
    return <Cloudy />;
  } else if (code === '9100') {
    if (isDaylight) {
      return <WindyDay />;
    } else {
      return <WindyNight />;
    }
  } else if (code === '9201') {
    return <ThunderstormsAndFrozenPrecipitation />;
  } else {
    return <NA />;
  }
}

export default WeatherIcon;
