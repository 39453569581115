// ** Config ** //
import { AuthProvider } from 'oidc-react';

// ** Styles ** //
import './App.css';
import { Theme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// ** Redux ** //
import { store } from './app/store';
import { Provider } from 'react-redux';

import { PWUserManager } from './config/oidcconfig';

import getTheme from './Theme/PWTheme';

// ** Main Component ** //
import Content from './Components/Shared/Content';
import DateAdapter from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import { useState, useEffect } from 'react';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

declare global {
    // Allows you to use hasOwnProperty as a type-guard to narrow down on type unions.
    // Specifically useful for awaiting rtk query mutation hooks, which return a union
    // of the data and error types.
    interface Object {
        hasOwnProperty<K extends PropertyKey>(key: K): this is Record<K, unknown>;
    }
    interface Window {
        gtag: (...args: any[]) => void;
    }
}

export enum ThemeType {
    DARK = 'dark',
    LIGHT = 'light'
}

function App() {
    const [theme, setTheme] = useState<ThemeType>(ThemeType.DARK);

    useEffect(() => {
        var storedTheme = window.localStorage.getItem('theme');

        if (storedTheme == null) {
            setTheme(ThemeType.DARK);
            window.localStorage.setItem('theme', ThemeType.DARK);
        } else {
            setTheme(storedTheme === ThemeType.DARK ? ThemeType.DARK : ThemeType.LIGHT);
        }
        var script = document.createElement('script');
        script.src = 'https://status.perryweather.com/en/8d8a82d3/widget/script.js';
        document.body.appendChild(script);
    }, []);

    const toggleTheme = (themeType: ThemeType) => {
        setTheme(themeType);
        window.localStorage.setItem('theme', themeType);
    };

    return (
        <Provider store={store}>
            <AuthProvider autoSignIn={false} userManager={PWUserManager}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme === ThemeType.DARK ? getTheme('DarkTheme') : getTheme('LightTheme')}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                            <div className="App">
                                <CssBaseline />
                                <Content toggleTheme={toggleTheme} />
                            </div>
                        </LocalizationProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </AuthProvider>
        </Provider>
    );
}

export default App;
