//0018

export const Sleet = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M3.03,7L2.25,8.56C2.23,8.6,2.23,8.65,2.24,8.7c0.02,0.05,0.05,0.09,0.1,0.11C2.37,8.83,2.4,8.83,2.42,8.83
		c0.07,0,0.14-0.04,0.17-0.11l0.78-1.55C3.4,7.13,3.4,7.08,3.38,7.03s-0.05-0.09-0.1-0.11C3.19,6.87,3.08,6.91,3.03,7z'
      />
      <path
        className='st1'
        d='M5.74,6.91C5.69,6.92,5.65,6.96,5.63,7L4.85,8.56C4.8,8.65,4.84,8.77,4.94,8.81c0.03,0.01,0.05,0.02,0.08,0.02
		c0.07,0,0.14-0.04,0.17-0.11l0.78-1.55c0.02-0.04,0.03-0.1,0.01-0.15c-0.02-0.05-0.05-0.09-0.1-0.11C5.84,6.89,5.78,6.89,5.74,6.91
		z'
      />
      <path
        className='st1'
        d='M8.22,7L7.45,8.56C7.42,8.6,7.42,8.65,7.43,8.7c0.02,0.05,0.05,0.09,0.1,0.11c0.03,0.01,0.05,0.02,0.08,0.02
		c0.07,0,0.14-0.04,0.17-0.11l0.78-1.56c0.02-0.05,0.03-0.1,0.01-0.15c-0.02-0.05-0.05-0.09-0.1-0.11C8.38,6.87,8.27,6.91,8.22,7z'
      />
      <g fill='currentColor'>
        <path
          className='st1'
          d='M4.63,7.4L4.55,7.33l0.08-0.08c0.08-0.08,0.08-0.2,0-0.28c-0.08-0.08-0.2-0.08-0.27,0L4.27,7.05L4.2,6.97
			C4.12,6.9,4,6.9,3.92,6.97s-0.08,0.2,0,0.27L4,7.32L3.92,7.4c-0.08,0.08-0.08,0.2,0,0.27c0.04,0.04,0.09,0.06,0.14,0.06
			c0.05,0,0.1-0.02,0.14-0.06L4.27,7.6l0.08,0.08c0.04,0.04,0.08,0.06,0.14,0.06c0.05,0,0.1-0.02,0.14-0.06
			C4.7,7.6,4.7,7.48,4.63,7.4z'
        />
        <path
          className='st1'
          d='M7.35,7.4L7.27,7.32l0.08-0.08c0.08-0.08,0.08-0.2,0-0.27c-0.07-0.07-0.2-0.07-0.27,0L7,7.05L6.92,6.97
			c-0.08-0.08-0.2-0.08-0.27,0s-0.08,0.2,0,0.27l0.08,0.08L6.65,7.4c-0.08,0.08-0.08,0.2,0,0.27c0.04,0.04,0.09,0.06,0.13,0.06
			c0.05,0,0.1-0.02,0.14-0.06L7,7.6l0.08,0.08c0.04,0.04,0.09,0.06,0.14,0.06c0.05,0,0.1-0.02,0.14-0.06
			C7.42,7.6,7.42,7.48,7.35,7.4z'
        />
      </g>
      <g fill='currentColor'>
        <path
          className='st1'
          d='M3.99,9.02L3.91,8.95l0.08-0.08c0.08-0.08,0.08-0.2,0-0.28c-0.08-0.08-0.2-0.08-0.27,0L3.64,8.67L3.56,8.6
			c-0.08-0.08-0.2-0.08-0.27,0c-0.08,0.08-0.08,0.2,0,0.27l0.08,0.08L3.29,9.02c-0.08,0.08-0.08,0.2,0,0.27
			c0.04,0.04,0.09,0.06,0.14,0.06c0.05,0,0.1-0.02,0.14-0.06l0.08-0.08L3.72,9.3C3.75,9.34,3.8,9.36,3.85,9.36
			c0.05,0,0.1-0.02,0.14-0.06C4.07,9.22,4.07,9.1,3.99,9.02z'
        />
        <path
          className='st1'
          d='M6.71,9.03L6.64,8.95l0.08-0.08c0.08-0.08,0.08-0.2,0-0.27c-0.07-0.07-0.2-0.07-0.27,0L6.36,8.67L6.28,8.6
			c-0.08-0.08-0.2-0.08-0.27,0c-0.08,0.08-0.08,0.2,0,0.27l0.08,0.08L6.01,9.02c-0.08,0.08-0.08,0.2,0,0.27
			C6.05,9.34,6.1,9.36,6.14,9.36c0.05,0,0.1-0.02,0.14-0.06l0.08-0.08L6.44,9.3c0.04,0.04,0.09,0.06,0.14,0.06
			c0.05,0,0.1-0.02,0.14-0.06C6.79,9.22,6.79,9.1,6.71,9.03z'
        />
      </g>
      <path
        className='st1'
        d='M7.79,3.45c-0.05,0-0.11,0-0.16,0.01C7.4,2.56,6.6,1.94,5.67,1.94c-1.1,0-1.99,0.88-2.02,1.97
		C3.01,3.96,2.49,4.5,2.49,5.16c0,0.69,0.56,1.25,1.25,1.25h4.05c0.82,0,1.48-0.67,1.48-1.48C9.27,4.11,8.61,3.45,7.79,3.45z
		 M4.04,4.07c0-0.03,0-0.07,0-0.11c0-0.9,0.73-1.64,1.64-1.64c0.81,0,1.49,0.58,1.61,1.38c0.01,0.05,0.04,0.1,0.08,0.13
		c0.05,0.03,0.1,0.04,0.15,0.03C7.62,3.84,7.7,3.83,7.79,3.83c0.61,0,1.1,0.49,1.1,1.1s-0.49,1.1-1.1,1.1l-0.02,0
		c-0.02,0-0.04,0-0.06,0H3.74c-0.48,0-0.87-0.39-0.87-0.87c0-0.48,0.39-0.87,0.87-0.87c0.03,0,0.06,0,0.1,0.01
		c0.06,0,0.11-0.01,0.15-0.05c0.04-0.04,0.06-0.1,0.06-0.15L4.04,4.07z'
      />
      <path
        className='st1'
        d='M2.2,5.24C1.64,5.18,1.22,4.71,1.22,4.15c0-0.61,0.49-1.1,1.1-1.1c0.09,0,0.17,0.01,0.27,0.03
		c0.05,0.02,0.11,0,0.15-0.03c0.05-0.03,0.08-0.08,0.08-0.13c0.13-0.8,0.81-1.38,1.61-1.38c0.24,0,0.46,0.07,0.67,0.16
		c0.17-0.04,0.34-0.06,0.53-0.06c0.01,0,0.02,0,0.03,0C5.66,1.6,5.64,1.56,5.59,1.53C5.25,1.29,4.85,1.17,4.44,1.17
		c-0.93,0-1.72,0.62-1.95,1.51C2.43,2.67,2.38,2.67,2.32,2.67c-0.82,0-1.48,0.67-1.48,1.48c0,0.76,0.57,1.4,1.33,1.47l0.02,0
		c0.02,0,0.03-0.01,0.04-0.02C2.21,5.49,2.2,5.37,2.2,5.24z'
      />
    </g>
  </svg>
);
