export enum Month {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  June = 6,
  July = 7,
  Aug = 8,
  Sept = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12,
}

export enum DaysOfWeek {
  'Sun' = 0,
  'Mon' = 1,
  'Tue' = 2,
  'Wed' = 3,
  'Thu' = 4,
  'Fri' = 5,
  'Sat' = 6,
}
