import { singlewireApi } from '.';

export interface IntegrationConfig {
  id?: string;
  customerId: number;
  webhookId: string;
  vaultKey?: string;
  token?: string;
}

export interface SinglewireSite {
  id: string;
  name: string;
}

export interface SinglewireScenario {
  id: string;
  name: string;
}

export interface SinglewireMapping {
  id?: string;
  integrationConfigId: string;
  organizationLocationId: string;
  policyGroupId: number;
  singlewireSiteId: string;
  singlewireScenarioId: string;
}

const integrationsApi = singlewireApi.injectEndpoints({
  endpoints: builder => ({
    verifySinglewireConnection: builder.mutation<boolean, string>({
      query: token => ({
        url: 'connection/verify',
        method: 'POST',
        body: { token },
      }),
    }),
    singlewireConfig: builder.query<IntegrationConfig, number>({
      query: customerId => `connection/customer/${customerId}`,
      transformResponse: (response: IntegrationConfig) => response,
    }),
    addSinglewireConfig: builder.mutation<IntegrationConfig, IntegrationConfig>({
      query: body => ({
        url: 'connection',
        method: 'POST',
        body,
      }),
      transformResponse: (response: IntegrationConfig) => response,
    }),
    singlewireSites: builder.query<SinglewireSite[], number>({
      query: (customerId) => `singlewire/sites/customer/${customerId}`,
    }),
    singlewireScenarios: builder.query<SinglewireScenario[], number>({
      query: (customerId) => `singlewire/scenarios/customer/${customerId}`,
    }),
    singlewireMappings: builder.query<SinglewireMapping[], number>({
      query: (customerId) => `scenario-mappings/customer/${customerId}`,
      providesTags: ['Integrations.Singlewire'],
    }),
    addSinglewireMapping: builder.mutation<SinglewireMapping[], SinglewireMapping>({
      query: body => ({
        url: 'scenario-mappings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Integrations.Singlewire'],
    }),
    deleteSinglewireMapping: builder.mutation<SinglewireMapping[], string>({
      query: id => ({
        url: `scenario-mappings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Integrations.Singlewire'],
    }),
  }),
});

export const {
  useVerifySinglewireConnectionMutation,
  useSinglewireConfigQuery,
  useAddSinglewireConfigMutation,
  useSinglewireSitesQuery,
  useSinglewireScenariosQuery,
  useSinglewireMappingsQuery,
  useAddSinglewireMappingMutation,
  useDeleteSinglewireMappingMutation
} = integrationsApi;
