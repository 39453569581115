import { Box, Fade, Typography } from '@mui/material';
import { Popup } from 'react-map-gl';

const GetStormVectorType = (typeInfo: any, flagsInfo: any) => {
  if (typeInfo === 'null') {
    if (flagsInfo?.Tvs) {
      return 'TVS';
    } else if (flagsInfo?.Meso) {
      return 'Meso';
    } else if (flagsInfo?.SevereHail) {
      return 'Severe Hail';
    } else if (flagsInfo?.Hail) {
      return 'Hail';
    } else {
      return 'Storm';
    }
  } else {
    return typeInfo;
  }
};

const GetStormVectorColor = (typeInfo: any, flagsInfo: any) => {
  if (typeInfo === 'null') {
    if (flagsInfo?.Tvs) {
      return '#FF0018';
    } else if (flagsInfo?.Meso) {
      return '#8E00FF';
    } else if (flagsInfo?.SevereHail) {
      return '#0C0875';
    } else if (flagsInfo?.Hail) {
      return '#2DA4FE';
    } else {
      return '#878787';
    }
  } else {
    if (typeInfo === 'Tornado – High Probability') {
      return '#FD0001';
    } else if (typeInfo === 'Tornado – Low Probability') {
      return '#7F0001';
    } else if (typeInfo === 'High Winds') {
      return '#FE9900';
    } else if (typeInfo === 'Extreme Hail') {
      return '#00007E';
    } else if (typeInfo === 'Hail') {
      return '#0000FE';
    } else {
      return '#878787';
    }
  }
};

interface StormVectorViewProps {
  clickedInfo: any;
}

export function StormVectorView(props: StormVectorViewProps) {
  const { clickedInfo } = props;

  if (!clickedInfo?.feature?.properties) {
    return <Box style={{ position: 'absolute' }}></Box>;
  }

  let id = clickedInfo.feature?.properties?.Id;
  let issueTime = new Date(clickedInfo.feature.properties.IssueTime).toLocaleString();

  let typeInfo = clickedInfo.feature.properties.Type;
  let flagsInfo = JSON.parse(clickedInfo.feature.properties.Flags);

  let type = GetStormVectorType(typeInfo, flagsInfo);
  let typeColor = GetStormVectorColor(typeInfo, flagsInfo);

  let movementInfo = JSON.parse(clickedInfo?.feature?.properties?.Movement);
  let heightInfo = JSON.parse(clickedInfo?.feature?.properties?.Height);
  let maxReflectivityInfo = JSON.parse(clickedInfo?.feature?.properties?.MaxReflectivity);
  let chanceOfHailInfo = JSON.parse(clickedInfo?.feature?.properties?.Hail);
  let topInfo = JSON.parse(clickedInfo?.feature?.properties?.Top);
  let vilInfo = JSON.parse(clickedInfo?.feature?.properties?.Vil);

  let movementSpeedStr = '--';
  if(movementInfo?.Speed != null){
    var milesPerMeterSecond = 2.236936;
    movementSpeedStr = (movementInfo?.Speed?.Value * milesPerMeterSecond).toFixed(2) + ' mph';
  }
  
  let heightStr = heightInfo?.Value ? heightInfo?.Value + ' ' + heightInfo?.Units : '--';
  let maxReflectStr = maxReflectivityInfo?.Value ? maxReflectivityInfo?.Value + ' ' + maxReflectivityInfo?.Units : '--';
  let chanceOfHailStr = chanceOfHailInfo?.Chance
    ? chanceOfHailInfo?.Chance?.Value + ' ' + chanceOfHailInfo?.Chance?.Units
    : '--';
  let chanceOfSevereHailStr = chanceOfHailInfo?.ChanceOfSevere
    ? chanceOfHailInfo?.ChanceOfSevere?.Value + ' ' + chanceOfHailInfo?.ChanceOfSevere?.Units
    : '--';
  let topStr = topInfo?.Value ? topInfo?.Value + ' ' + topInfo?.Units : '--';
  let vilStr = vilInfo?.Value ? vilInfo?.Value + ' ' + vilInfo?.Units : '--';

  return (
    <Popup longitude={clickedInfo.lngLat.lng} latitude={clickedInfo.lngLat.lat} anchor='bottom'>
      <Box
        style={{
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          margin: 8,
          padding: 8,
          minWidth: 160,
          fontSize: 10,
          zIndex: 9,
          pointerEvents: 'none',
        }}>
        <Typography variant='caption'>ID: {id}</Typography>
        <Typography variant='caption'>Issued: {issueTime}</Typography>
        <hr style={{ backgroundColor: '#fff', width: '100%' }} />
        <Typography variant='caption'>
          Type: {type}{' '}
          <Typography
            fontSize={32}
            lineHeight='16px'
            component='span'
            style={{ color: typeColor, verticalAlign: 'middle' }}>
            {'\u25A0'}
          </Typography>
        </Typography>
        <Typography variant='caption'>Movement: {movementSpeedStr}</Typography>
        <Typography variant='caption'>Height: {heightStr}</Typography>
        <Typography variant='caption'>Max Reflectivity: {maxReflectStr}</Typography>
        <Typography variant='caption'>Hail: {chanceOfHailStr}</Typography>
        <Typography variant='caption'>Severe Hail: {chanceOfSevereHailStr}</Typography>
        <Typography variant='caption'>Top: {topStr}</Typography>
        <Typography variant='caption'>VIL: {vilStr}</Typography>
      </Box>
    </Popup>
  );
}

export default StormVectorView;
