import { api } from '.';

export interface Webhook {
  id?: string;
  customerId: number;
  name: string;
  description: string;
  url: string;
  readonly: boolean;
  versionNumber: string;
}

interface WebhookOrgLocSite {
  id?: string;
  orgLocSiteId: string;
  webhookId: string;
}

const integrationsApi = api.injectEndpoints({
  endpoints: builder => ({
    webhooks: builder.query<Webhook[], void>({
      query: () => 'Webhook',
      transformResponse: (response: { data: Webhook[] }) => response.data,
      providesTags: ['Webhooks'],
    }),
    addWebhook: builder.mutation<Webhook, Webhook>({
      query: webhook => ({
        url: 'Webhook',
        method: 'POST',
        body: webhook,
      }),
      transformResponse: (response: { data: Webhook }) => response.data,
      invalidatesTags: ['Webhooks'],
    }),
    addWebhookOrgLocSite: builder.mutation<WebhookOrgLocSite, WebhookOrgLocSite>({
      query: webhook => ({
        url: 'Webhook/OrgLocSite',
        method: 'POST',
        body: webhook,
      }),
      transformResponse: (response: { data: WebhookOrgLocSite }) => response.data,
    }),
  }),
});

export const {
  useAddWebhookMutation,
  useWebhooksQuery,
  useAddWebhookOrgLocSiteMutation
} = integrationsApi;
