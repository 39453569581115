import Page from '../Components/Base/Page';
import { Grid, Typography } from '@mui/material';
import { SinglewireIntegrationCard } from '../Components/Integrations/Singlewire/IntegrationCard';

export function Integrations() {
  return (
    <Page
      docTitle='Integrations'
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          Integrations
        </Typography>
      }
      subheader={
        <Typography variant='body1' color='textSecondary'>
          Review and manage your enabled integrations
        </Typography>
      }>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          {/* All future integrations can be listed here and handle their own configuration / management */}
          <SinglewireIntegrationCard />
        </Grid>
      </Grid>
    </Page>
  );
}
export default Integrations;
