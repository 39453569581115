import { CSSProperties } from 'react';
import { _useMapControl as useMapControl } from 'react-map-gl';
import { Box, Grow, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PWColors } from '../../../../Theme/PWColors';
import BaseControl from './BaseControl';
import { LegendControlProps } from './LegendControl';
import { DirectionsCar } from '@mui/icons-material';

const LegendRow = ({ color, text }: { color: string; text: string }) => (
  <Box display='flex' flexDirection='row' alignItems='center'>
    <Typography
      fontSize={32}
      lineHeight='16px'
      component='span'
      style={{ color, margin: '-2px 4px 0 0' }}>
      {'\u25A0'}
    </Typography>
    <Typography color={PWColors.light.base[900]}>{text}</Typography>
  </Box>
);

const AllItems = () => (
  <>
    <LegendRow color="#008b00ff" text="Flooded" />
    <LegendRow color="#00ff00ff" text="Slippery" />
    <LegendRow color="#ff00ffff" text="Patchy Ice" />
    <LegendRow color="#ff0000ff" text="Ice" />
    <LegendRow color="#1e90ffff" text="Snow" />
    <LegendRow color="#00008bff" text="Heavy Snow" />
  </>
);

export const RoadLegendControl = ({ onClick, show }: LegendControlProps) => {
  const stopPropagation = (evt: any) => evt.stopPropagation();

  const { containerRef } = useMapControl({
    onDragStart: stopPropagation,
    onDoubleClick: stopPropagation,
    onClick: stopPropagation,
  });

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

    // essentially need position absolute if being used standalone, i.e, not inside the group.
  const baseStyle: CSSProperties = {
    position: 'absolute',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
    zIndex: 2,
  };
  const desktopStyle: CSSProperties = {
    ...baseStyle,
    right: 10,
    top: 60,
    borderRadius: 100,
  };
  const mobileStyle: CSSProperties = {
    ...baseStyle,
    borderRadius: 8,
    top: 10,
    right: 50,
  };

  const controlClass = `mapbroxgl-ctrl mapboxgl-ctrl-group`;

  return !isMd ? (
    <div style={desktopStyle} className={controlClass} ref={containerRef}>
      <Box
        alignItems='center'
        justifyContent='space-around'
        display='flex'
        flexDirection='row'
        paddingLeft={2}
        paddingRight={2}
        width={500}
        height={40}>
        <AllItems />
      </Box>
    </div>
  ) : (
    <div style={{ marginBottom: '10px' }}>
      <Grow in={show}>
        <div
          style={mobileStyle}
          className={controlClass}
          ref={containerRef}
          onClick={onClick}>
          <Box
            justifyContent='space-around'
            display='flex'
            flexDirection='column'
            padding="6px 6px"
            width={140}
            height={180}>
            <AllItems />
          </Box>
        </div>
      </Grow>
      <BaseControl label='Road Conditions' onClick={onClick}>
        <DirectionsCar />
      </BaseControl>
    </div>
  );
}

export default RoadLegendControl;
