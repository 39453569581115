import React from "react";
import { useNotificationsQuery } from "../../../Services/API";
import { clientUsTimezone, CorrectUTC } from "../../../utils/utils";
import { Divider, Typography } from "@mui/material";
import { PWMenuItem } from "../../Base";
import UserNotiPreviewItem from "./UserNotiPreviewItem";

function UserAlerts() {
    // const theme = useTheme()
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);
    const { data } = useNotificationsQuery({
        count: 3,
        startDate: startDate.toUTCString(),
        endDate: endDate.toUTCString(),
    });

    return (
        <>
            {data && data.length > 0 ? (
                data.map(userNoti => {
                    const sentDate = CorrectUTC(userNoti.sentUtc);

                    const timezone = clientUsTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
                    const yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);
                    let display: string;
                    if (sentDate.toDateString() === endDate.toDateString()) {
                        display = `Today at ${sentDate.toLocaleTimeString()} ${timezone}`;
                    } else if (sentDate.toDateString() === yesterday.toDateString()) {
                        display = `Yesterday at ${sentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })} ${timezone}`;
                    } else {
                        display = sentDate.toLocaleDateString('en-US', {
                            weekday: 'short',
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        });
                    }
                    return (
                        <React.Fragment key={userNoti.id}>
                            <PWMenuItem disabled disableRipple>
                                <UserNotiPreviewItem notification={userNoti} time={display} />
                            </PWMenuItem>
                            <Divider />
                        </React.Fragment>
                    );
                })
            ) : (
                <>
                    <PWMenuItem disabled disableRipple style={{ marginBottom: 20 }}>
                        <Typography variant='caption' color='textSecondary'>
                            No recent notifications.
                        </Typography>
                    </PWMenuItem>
                </>
            )}
        </>
    );
}

export default UserAlerts;