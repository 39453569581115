export const RainDropOutline = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ? props.width : '15'}
    height={props.height ? props.height : '20'}
    viewBox='0 0 15 20'
    fill={props.color}
    xmlns='http://www.w3.org/2000/svg'
    color={props.color}>
    <path
      d='M7.58828 1C7.58828 1 1 7.98852 1 12.4117C1 16.0822 3.91778 19 7.58828 19C11.2588 19 14.1766 16.0822 14.1766 12.4117C14.1766 7.98852 7.58828 1 7.58828 1ZM7.58828 17.3529C4.81708 17.3529 2.64707 15.1829 2.64707 12.4117C2.64707 10.1017 5.32603 6.16768 7.5891 3.47966C9.85218 6.16521 12.5295 10.0959 12.5295 12.4117C12.5295 15.1829 10.3595 17.3529 7.58828 17.3529Z'
      fill={props.color}
      stroke={props.color}
    />
  </svg>
);
