import { useMemo } from 'react';
import { _useMapControl as useMapControl } from 'react-map-gl';

interface RightLayerGroupProps {
    className?: any;
    style?: any;
    children: React.ReactNode;
}

export function RightLayerGroup(props: RightLayerGroupProps) {
    const { children, className } = props;
    const style = useMemo(
        () => ({
            position: 'absolute',
            right: '10px',
            top: '10px',
            // width: '50%',
            ...props.style,
        }),
        [props.style]
    );

    const { containerRef } = useMapControl({
        onDragStart: (evt: any) => {
            evt.stopPropagation();
        },
        onDoubleClick: (evt: any) => {
            evt.stopPropagation();
        },
        onClick: (evt: any) => {
            evt.stopPropagation();
        },
    });

    return (
        <div style={style} className={className}>
            {/* <div  ref={containerRef}> */}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} ref={containerRef}>
                {children}
            </div>
        </div>
    );
}

export default RightLayerGroup;
