import { useAuth } from 'oidc-react';

export function SilentRenew() {
  const { userManager } = useAuth();
  // dispatch(setCredentials({user: userData!, token: userData!.access_token}))
  // console.debug('silent renew');
  userManager.signinSilent();

  // userManager.signinSilent();

  return <></>;
  //This stuff has some work to do
  // let redirect: string = sessionStorage.getItem ("auth:redirect") ? sessionStorage.getItem("auth:redirect") as string : "/";
}

export default SilentRenew;
