//2020-Day

export const ChanceOfFreezingRainDay = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st0'
        d='M8.36,4.8c-0.05,0-0.11,0-0.16,0.01c-0.23-0.9-1.03-1.52-1.96-1.52c-1.1,0-2,0.88-2.03,1.98
            C3.55,5.32,3.03,5.86,3.03,6.53c0,0.69,0.56,1.26,1.26,1.26h4.08c0.82,0,1.49-0.67,1.49-1.49C9.85,5.47,9.19,4.8,8.36,4.8z
                M4.59,5.32c0-0.91,0.74-1.65,1.65-1.65c0.82,0,1.5,0.58,1.62,1.39c0.01,0.05,0.04,0.1,0.08,0.13s0.1,0.04,0.15,0.03
            c0.1-0.02,0.19-0.03,0.27-0.03c0.61,0,1.11,0.5,1.11,1.11s-0.5,1.11-1.11,1.11H8.34c-0.02,0-0.04,0-0.06,0H4.29
            c-0.48,0-0.87-0.39-0.87-0.88c0-0.48,0.39-0.87,0.87-0.87c0.03,0,0.07,0,0.1,0.01c0.05,0,0.11-0.02,0.15-0.05
            C4.58,5.57,4.6,5.51,4.6,5.45C4.59,5.41,4.59,5.37,4.59,5.32z'
      />
      <path
        className='st0'
        d='M3.83,1.8c0.11,0,0.19-0.09,0.19-0.19V0.68c0-0.11-0.09-0.19-0.19-0.19c-0.1,0-0.19,0.09-0.19,0.19v0.93
            C3.64,1.71,3.72,1.8,3.83,1.8z'
      />
      <path
        className='st0'
        d='M2.33,2.09c0.04,0.06,0.1,0.09,0.16,0.09c0.03,0,0.07-0.01,0.1-0.03c0.04-0.03,0.07-0.07,0.09-0.12
            c0.01-0.05,0-0.1-0.02-0.15L2.17,1.09C2.11,1.01,2,0.98,1.91,1.03C1.82,1.09,1.79,1.21,1.84,1.3L2.33,2.09z'
      />
      <path
        className='st0'
        d='M1.51,3.11C1.54,3.13,1.57,3.14,1.6,3.14c0.07,0,0.13-0.04,0.17-0.1c0.02-0.05,0.03-0.1,0.01-0.15
            S1.73,2.8,1.69,2.78L0.87,2.34c-0.04-0.02-0.1-0.03-0.15-0.01C0.67,2.34,0.63,2.37,0.61,2.42c-0.02,0.04-0.03,0.1-0.02,0.15
            C0.6,2.62,0.64,2.66,0.68,2.68L1.51,3.11z'
      />
      <path
        className='st0'
        d='M1.49,4.13c0-0.11-0.09-0.19-0.19-0.19H0.37c-0.1,0-0.19,0.09-0.19,0.19c0,0.11,0.09,0.19,0.19,0.19H1.3
            C1.4,4.32,1.49,4.24,1.49,4.13z'
      />
      <path
        className='st0'
        d='M1.66,5.17c-0.05-0.01-0.1-0.01-0.14,0.02L0.7,5.63C0.61,5.68,0.57,5.79,0.62,5.89c0.03,0.06,0.1,0.1,0.17,0.1
            c0.03,0,0.07-0.01,0.09-0.02L1.7,5.52c0.09-0.05,0.12-0.17,0.08-0.26C1.75,5.21,1.71,5.18,1.66,5.17z'
      />
      <path
        className='st0'
        d='M6.05,3.11c0.03,0,0.07-0.01,0.09-0.02l0.82-0.45l0,0c0.09-0.05,0.12-0.17,0.08-0.26
            C7.01,2.34,6.97,2.31,6.92,2.29c-0.05-0.02-0.1-0.01-0.15,0.02L5.96,2.75C5.91,2.78,5.88,2.82,5.87,2.87s-0.01,0.1,0.02,0.15
            C5.92,3.08,5.98,3.11,6.05,3.11z'
      />
      <path
        className='st0'
        d='M2.44,6.1C2.39,6.11,2.35,6.14,2.32,6.18L1.83,6.97C1.78,7.06,1.8,7.18,1.9,7.23C1.93,7.25,1.97,7.26,2,7.26
            c0.07,0,0.13-0.03,0.16-0.09l0.49-0.79C2.7,6.3,2.68,6.18,2.59,6.12C2.54,6.09,2.49,6.08,2.44,6.1z'
      />
      <path
        className='st0'
        d='M5.17,2.18c0.07,0,0.13-0.03,0.16-0.09L5.82,1.3c0.03-0.04,0.04-0.09,0.02-0.14c0-0.05-0.03-0.09-0.08-0.12
            C5.67,0.98,5.56,1.01,5.5,1.1L5,1.89c-0.05,0.09-0.02,0.2,0.07,0.26C5.1,2.17,5.14,2.18,5.17,2.18z'
      />
      <path
        className='st0'
        d='M3.22,8.3L2.8,9.13c-0.04,0.09,0,0.21,0.09,0.26C2.92,9.4,2.95,9.4,2.98,9.4c0.07,0,0.14-0.04,0.17-0.1
            l0.42-0.83c0.05-0.09,0.01-0.21-0.08-0.26C3.39,8.17,3.27,8.21,3.22,8.3z'
      />
      <path
        className='st0'
        d='M9.14,8.3L8.72,9.13C8.68,9.22,8.71,9.34,8.81,9.39C8.84,9.4,8.87,9.4,8.89,9.4c0.07,0,0.14-0.04,0.17-0.1
            l0.42-0.83C9.53,8.38,9.49,8.26,9.4,8.21C9.3,8.17,9.19,8.21,9.14,8.3z'
      />
      <path
        className='st0'
        d='M6.29,8.21C6.24,8.22,6.2,8.26,6.18,8.3L5.77,9.13c-0.02,0.05-0.03,0.1-0.01,0.15s0.05,0.09,0.1,0.11
            c0.01,0,0.04,0.01,0.07,0.01C6,9.4,6.07,9.36,6.1,9.29l0.41-0.83c0.02-0.05,0.03-0.1,0.01-0.15C6.5,8.26,6.47,8.22,6.42,8.2
            C6.39,8.19,6.34,8.19,6.29,8.21z'
      />
    </g>
    <path
      fill='currentColor'
      d='M2.89,5.84c0.05-0.1,0.09-0.2,0.16-0.29C3.03,5.53,3.02,5.51,3,5.49C2.55,5.2,2.28,4.71,2.28,4.17
        c0-0.87,0.7-1.57,1.57-1.57c0.54,0,0.98,0.23,1.27,0.66C5.23,3.2,5.34,3.15,5.46,3.11c-0.01-0.02,0-0.04-0.01-0.05
        c-0.36-0.54-0.92-0.84-1.6-0.84C2.78,2.22,1.9,3.1,1.9,4.17l0,0l0,0c0,0.67,0.34,1.28,0.9,1.64C2.82,5.83,2.85,5.84,2.89,5.84z'
    />
    <g fill='currentColor'>
      <path
        className='st0'
        d='M4.98,8.98L4.91,8.9l0.08-0.08c0.08-0.08,0.08-0.2,0-0.28c-0.08-0.08-0.2-0.08-0.27,0L4.63,8.63L4.55,8.55
            c-0.08-0.08-0.2-0.08-0.27,0c-0.08,0.08-0.08,0.2,0,0.27L4.36,8.9L4.28,8.98c-0.08,0.08-0.08,0.2,0,0.27
            c0.04,0.04,0.09,0.06,0.14,0.06c0.05,0,0.1-0.02,0.14-0.06l0.08-0.08l0.08,0.08c0.04,0.04,0.08,0.06,0.14,0.06
            c0.05,0,0.1-0.02,0.14-0.06C5.06,9.18,5.06,9.06,4.98,8.98z'
      />
      <path
        className='st0'
        d='M8,8.98L7.93,8.9L8,8.83c0.08-0.08,0.08-0.2,0-0.27c-0.07-0.07-0.2-0.07-0.27,0L7.65,8.63L7.57,8.55
            c-0.08-0.08-0.2-0.08-0.27,0c-0.08,0.08-0.08,0.2,0,0.27L7.38,8.9L7.3,8.98c-0.08,0.08-0.08,0.2,0,0.27
            c0.04,0.04,0.09,0.06,0.13,0.06c0.05,0,0.1-0.02,0.14-0.06l0.08-0.08l0.08,0.08c0.04,0.04,0.09,0.06,0.14,0.06
            c0.05,0,0.1-0.02,0.14-0.06C8.08,9.18,8.08,9.06,8,8.98z'
      />
    </g>
  </svg>
);
