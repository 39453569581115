//9999

export const NA = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 73.5 73.5'>
    <g fill='currentColor'>
      <path
        d='M27.1,48.2L12.2,28v19.5c0,0.6-0.5,1.1-1.1,1.1h-0.3c-0.6,0-1.1-0.5-1.1-1.1V24.1c0-0.6,0.5-1.1,1.1-1.1h0.3
            c0.4,0,0.7,0.2,0.9,0.5l15,20.2V24.1c0-0.6,0.5-1.1,1.1-1.1h0.3c0.6,0,1.1,0.5,1.1,1.1v23.4c0,0.6-0.5,1.1-1.1,1.1H28
            C27.7,48.6,27.3,48.4,27.1,48.2z'
      />
      <path
        d='M34.3,50L34.3,50c-0.6-0.2-0.9-0.9-0.6-1.4l9.9-26.8c0.2-0.6,0.9-0.9,1.5-0.7l0,0c0.6,0.2,0.9,0.9,0.6,1.4l-9.9,26.8
            C35.6,49.9,34.9,50.2,34.3,50z'
      />
      <path
        d='M55.8,23h0.9c0.5,0,0.9,0.3,1.1,0.8l8.2,23.4c0.3,0.7-0.3,1.5-1.1,1.5h-0.7c-0.5,0-0.9-0.3-1.1-0.8l-1.4-4.3
            c-0.2-0.5-0.6-0.8-1.1-0.8H51c-0.5,0-0.9,0.3-1,0.7l-1.7,4.4c-0.2,0.4-0.6,0.7-1,0.7h-0.4c-0.8,0-1.3-0.8-1-1.5l9-23.4
            C55,23.2,55.4,23,55.8,23z M51.1,40.3h9.6l-4.5-13.5h0L51.1,40.3z'
      />
    </g>
  </svg>
);
