import { api } from '.';

export interface Site {
  id: number;
  customerId: number;
  name: string;
  users: number;
  notificationUsers: number;
  policies: number;
  alertTimes: SiteAlertTime[];
}

export interface SiteAlertTime {
  id: string | null;
  day: number;
  startTime: string;
  endTime: string;
  enabled: boolean;
  allDay: boolean;
}

export interface SiteErrorModel {
  Name?: string[];
}

const siteApi = api.injectEndpoints({
  endpoints: builder => ({
    userSites: builder.query<Site[], number>({
      query: customerId => `site/${customerId}`,
      transformResponse: (response: { data: Site[] }) => response.data,
      providesTags: ['Sites'],
    }),
    userSite: builder.query<Site, void>({
      query: customerId => `site/user`,
      transformResponse: (response: { data: Site }) => response.data,
      providesTags: ['Sites'],
    }),
    sites: builder.query<Site[], void>({
      query: () => `site`,
      transformResponse: (response: { data: Site[] }) => response.data,
      providesTags: ['Sites'],
    }),
    addSite: builder.mutation<string, Site>({
      query: newSite => ({
        url: 'site/add',
        method: 'POST',
        body: newSite,
      }),
      transformResponse: (response: { data: string }) => response.data,
      invalidatesTags: ['Policies', 'Sites'],
    }),
    updateSite: builder.mutation<string, Site>({
      query: updatedSite => ({
        url: 'site/update',
        method: 'POST',
        body: updatedSite,
      }),
      transformResponse: (response: { data: string }) => response.data,
      invalidatesTags: ['Policies', 'Sites', 'Users', 'NotiUsers'],
    }),
    deleteSite: builder.mutation<string, number>({
      query: siteId => ({
        url: `site/delete/${siteId}`,
        method: 'POST',
      }),
      transformResponse: (response: { data: string }) => response.data,
      invalidatesTags: ['Policies', 'Sites', 'Users', 'NotiUsers'],
    }),
  }),
});

export const {
  useSitesQuery,
  useUserSiteQuery,
  useAddSiteMutation,
  useDeleteSiteMutation,
  useUpdateSiteMutation,
  useUserSitesQuery,
} = siteApi;
