import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { Button } from '../Components/Base';
import { useAuth } from 'oidc-react';

export function Unauthorized() {
  const theme = useTheme();
  let auth = useAuth();

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Grid
        container
        margin={1}
        alignContent='center'
        alignItems='center'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        spacing={1}>
        <Grid item>
          <LockIcon style={{ width: 85, height: 85 }} htmlColor={theme.palette.primary.main} />
        </Grid>

        <Grid item>
          <Typography variant='h4' noWrap>
            Unauthorized
          </Typography>
        </Grid>

        <Grid item width='90%'>
          <Typography variant='body1'>
            If you have been granted access recently, you may have to sign out and sign back in.
          </Typography>
        </Grid>

        <Grid item>
          <Button onClick={() => auth.userManager.signoutRedirect()}>Sign Out</Button>
        </Grid>
      </Grid>
    </div>
  );
}
// function mapStateToProps(state?: any) {
// 	if (state.oidc) {
// 		return {
// 			user: state.oidc.user
// 		};
// 	}
// 	else return {
// 		user: null
// 	};
//   }

//   function mapDispatchToProps(dispatch: any) {
// 	return {
// 	  dispatch
// 	};
//   }

// export default Dashboard;
// export default connect(mapStateToProps, mapDispatchToProps) (Dashboard);
export default Unauthorized;
