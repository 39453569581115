/* eslint-disable no-mixed-spaces-and-tabs */
import Oidc from 'oidc-client';
import { UserManager } from 'oidc-react';

export const PWUserManager = new UserManager({
    automaticSilentRenew: true, // needs to be true and handle automatic silent renew as well.
    authority: process.env.REACT_APP_LOGIN_URL,
    scope: 'openid profile api1 email phone offline_access app_access widget-api WIDGET_ADMIN singlewire.api',
    client_id: 'pw3-a',
    response_type: 'code',
    loadUserInfo: true,
    filterProtocolClaims: false,
    silent_redirect_uri: window.location.origin + '/silentrenew',
    post_logout_redirect_uri: window.location.origin + '/',
    redirect_uri: window.location.origin + '/callback'
});
