/* eslint-disable react-hooks/exhaustive-deps */
import { Button as MUIButton } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

interface ButtonProps {
  children?: React.ReactNode;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  finishedLoading: Function;
  duration?: number; // in seconds
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: 48,
      width: '100%',
      borderRadius: 100,
      border: '1px solid',
      borderColor: theme.palette.border.input,
      padding: '0 24px',
      background: theme.palette.mode === 'dark' ? '#1E3341' : '#fff',
      textTransform: 'none',
      textAlign: 'center',
      overflow: 'hidden',
      '&:focus': {
        boxShadow: '0px 0px 0px 2px #0F151E, 0px 0px 0px 4px rgba(96, 177, 215, 0.5)',
      },
      '&:focus-visible': {
        boxShadow: '0px 0px 0px 2px #0F151E, 0px 0px 0px 4px rgba(96, 177, 215, 0.5)',
      },
      '&.Mui-disabled': {
        '& .MuiButton-label': {
          color: theme.palette.text.disabled,
        },
      },
    },
    progressBar: {
      position: 'absolute',
      background: 'linear-gradient(270deg, #D3DB8F 0%, #49C2C1 90.3%)',
      opacity: 0.2,
      left: 0,
      width: '100%',
      height: '100%',
    },
  })
);

export function LoadingButton(props: ButtonProps) {
  const {
    children,
    style,
    onClick,
    disabled = false,
    isLoading = false,
    loadingText = '',
    finishedLoading,
    duration = 5,
  } = props;
  const theme = useTheme();

  const classes = useStyles(theme);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick && !isLoading) {
      onClick(event);
    }
  };

  const [animatedStyle, animate] = useSpring(() => ({
    from: {
      transform: 'translate(-100%)',
    },
  }));

  useEffect(() => {
    if (isLoading) {
      animate({
        transform: 'translate(0%)',
        onRest: () => {
          finishedLoading();
        },
        config: { duration: duration * 1000 },
      });
    } else {
      animate({ transform: 'translate(-100%)', config: { duration: 0 } });
    }
  }, [isLoading]);

  return (
    <MUIButton
      style={{ justifyContent: isLoading ? 'center' : 'space-between', ...style }}
      onClick={handleClick}
      className={classes.button}
      disabled={disabled}>
      {isLoading ? loadingText : children}
      <animated.div className={classes.progressBar} style={animatedStyle}></animated.div>
    </MUIButton>
  );
}

export default LoadingButton;
