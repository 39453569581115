import { api } from '.';
import { WidgetParams } from '../../Pages/WidgetBuilder';

export interface Forecast {
  observationTime: string;
  ambientTemperature: Value;
  precipitationChance: Value;
  wgbt: Value;
  windSpeed: Value;
  windDirection: string;
  weather_code: { value: string; text: string };
  daylight: boolean;
}

export class Widget {
  id?: string;
  theme: PaletteType;
  type: WidgetType;
  orgLocId: string | undefined;
  widgetConditions: Condition[];
  primaryCondition: cType;
  widgetForecasts: {
    forecastType: ForecastType;
    conditionType: cType;
  }[];
  weatherStationId: string;
  primaryThreshold?: number;
  customerId: number;
  customerName?: string;
  name: string;
  policyGroupId?: number;
  lightningRadius?: number;
  headerMessage?: string;
  headerMessageExpiration?: Date;
  lightningAllClearMinutes?: number;
  creatorId?: string;

  constructor(
    theme: PaletteType,
    type: WidgetType,
    orgLocId: string | undefined,
    widgetConditions: Condition[],
    primaryCondition: cType,
    widgetForecasts: { forecastType: ForecastType; conditionType: cType }[],
    weatherStationId: string,
    primaryThreshold: number | undefined,
    customerId: number,
    name: string,
    policyGroupId: number | undefined,
    lightningRadius: number | undefined,
    headerMessage: string | undefined,
    headerMessageExpiration: Date | undefined,
    lightningAllClearMinutes: number | undefined,
    creatorId?: string | undefined
  ) {
    this.theme = theme;
    this.type = type;
    this.orgLocId = orgLocId;
    this.widgetConditions = widgetConditions;
    this.primaryCondition = primaryCondition;
    this.widgetForecasts = widgetForecasts;
    this.weatherStationId = weatherStationId;
    this.primaryThreshold = primaryThreshold;
    this.customerId = customerId;
    this.name = name;
    this.policyGroupId = policyGroupId;
    this.lightningRadius = lightningRadius;
    this.headerMessage = headerMessage;
    this.headerMessageExpiration = headerMessageExpiration;
    this.lightningAllClearMinutes = lightningAllClearMinutes;
    this.creatorId = creatorId;
  }

  static fromWidgetParams(params: WidgetParams): Widget {
    const hourlyForecast =
      params.hourlyWidgetForecast != -1
        ? { forecastType: ForecastType.HOURLY, conditionType: params.hourlyWidgetForecast }
        : undefined;
    const dailyForecast =
      params.dailyWidgetForecast != -1
        ? { forecastType: ForecastType.DAILY, conditionType: params.dailyWidgetForecast }
        : undefined;

    const forecasts = [];
    if (hourlyForecast != undefined) forecasts.push(hourlyForecast);
    if (dailyForecast != undefined) forecasts.push(dailyForecast);

    //This should eventually be moved to backend.
    let widgetConditions = params.widgetConditions.map(c => {
      let threshold = undefined;
      if (params.policies) {
        if (c == cType.WBGT) threshold = params.policies.find(p => p.policyTypeShortName == 'WBGT')?.threshold;
        else if (c == cType.PRECIP) threshold = params.policies.find(p => p.policyTypeShortName == 'PRECIP')?.threshold;
        else if (c == cType.WIND) threshold = params.policies.find(p => p.policyTypeShortName == 'WIND')?.threshold;
      }

      return { conditionType: c, threshold: threshold } as Condition;
    });

    let primaryThreshold = undefined;
    if (params.policies) {
      if (params.primaryCondition == cType.WBGT)
        primaryThreshold = params.policies.find(p => p.policyTypeShortName == 'WBGT')?.threshold;
      else if (params.primaryCondition == cType.PRECIP)
        primaryThreshold = params.policies.find(p => p.policyTypeShortName == 'PRECIP')?.threshold;
      else if (params.primaryCondition == cType.WIND)
        primaryThreshold = params.policies.find(p => p.policyTypeShortName == 'WIND')?.threshold;
    }

    let lightningRadius = undefined;
    let lightningAllClearMinutes = undefined;
    if (params.policies) {
      var LR1Policy = params.policies.find(p => p.policyTypeShortName == 'LR1');
      if (LR1Policy != undefined) {
        lightningRadius = LR1Policy.threshold;
        lightningAllClearMinutes = LR1Policy.allClearMinutes;
      }
    }

    const widget: Widget = {
      id: params.id,
      theme: params.theme,
      type: params.type,
      orgLocId: params.orgLocId,
      widgetConditions: widgetConditions,
      widgetForecasts: forecasts,
      weatherStationId: params.weatherStationId,
      primaryCondition: params.primaryCondition,
      customerId: params.customerId,
      name: params.name,
      policyGroupId: params.policyGroupId == -1 ? undefined : params.policyGroupId,
      primaryThreshold: primaryThreshold,
      lightningRadius: lightningRadius,
      headerMessage: params.headerMessage,
      headerMessageExpiration: params.headerMessageExpiration,
      lightningAllClearMinutes: lightningAllClearMinutes,
      creatorId: params.creatorId,
    };
    return widget;
  }
}

export interface Condition {
  conditionType: cType;
  threshold?: number;
}

export interface Observation {
  observationTime: string;
  daylight: boolean;
  ambientTemperature: Value;
  precipitation: Value;
  windSpeed: Value;
  windDirection: string;
  wgbt: Value;
  weatherStation: WeatherStation;
}

export interface WeatherStation {
  lastUpdated: string;
  id: string;
  name: string;
  type: number;
}

export interface OrganizationLocation {
  id: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  zoneId: string;
  countyId: string;
  customerName: string;
  customerId: number;
  numberOfHardwareUnits: number;
  notificationOnlyUsers: number;
  numberOfConnectedLocations: number;
}

interface Value {
  value: number;
  unit: string;
}

export enum WidgetSize {
  xs,
  sm,
  md,
  lg,
  xl,
}

export enum ForecastType {
  HOURLY,
  DAILY,
}
export enum cType {
  TEMP,
  WBGT,
  WIND,
  PRECIP,
  GUST,
  FEEL,
}
export enum WidgetType {
  DEF,
  IMG,
  RADAR,
}
export enum PaletteType {
  DARK,
  LIGHT,
}

const widgetUrl = process.env.REACT_APP_WIDGET_API_URL;

const widgetApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    customerWidgets: builder.query<Widget[], number>({
      query: (customerId: number) => `${widgetUrl}/Widget/Customer/${customerId}`,
      transformResponse: (data: Widget[]) => data,
      providesTags: ['Widget'],
    }),
    widget: builder.query<Widget, string>({
      query: (widgetId: string) => `${widgetUrl}/Widget/${widgetId}`,
      transformResponse: (data: Widget) => data,
      providesTags: ['Widget'],
    }),
    superAdminWidgets: builder.query<Widget[], void>({
      query: () => `${widgetUrl}/Widget/All`,
      transformResponse: (data: Widget[]) => data,
      providesTags: ['Widget'],
    }),
    updateWidget: builder.mutation<Widget, Widget>({
      query: (widget: Widget) => ({
        url: `${widgetUrl}/Widget/${widget.id}`,
        method: 'PUT',
        body: widget,
      }),
      transformResponse: (data: Widget) => data,
      invalidatesTags: ['Widget'],
    }),
    createWidget: builder.mutation<Widget, Widget>({
      query: (widget: Widget) => ({
        url: `${widgetUrl}/Widget`,
        method: 'POST',
        body: widget,
      }),
      transformResponse: (data: Widget) => data,
      invalidatesTags: ['Widget'],
    }),
    deleteWidget: builder.mutation<void, string>({
      query: (widgetId: string) => ({
        url: `${widgetUrl}/Widget/${widgetId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Widget'],
    }),
  }),
});

export const {
  useCustomerWidgetsQuery,
  useWidgetQuery,
  useSuperAdminWidgetsQuery,
  useUpdateWidgetMutation,
  useCreateWidgetMutation,
  useDeleteWidgetMutation,
} = widgetApi;
