

import { api } from ".";

export interface LiveCamera {
  id: string,
  ipAddress: string,
  port: number,
  name: string,
}
export interface LiveStreamLog {
  id: string,
  userName: string,
  cameraFeedName: string,
  startTime: Date,
  duration: number
}

export interface LiveSteamLogParams {
  customerId: number,
  monthOffset: number
}

const liveStreamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    allCameraFeeds: builder.query<LiveCamera[], void>({
        query: () => 'LiveStream/CameraFeed/All',
        transformResponse: (response: {data: LiveCamera[]}) => response.data
    }),
    cameraFeedsForCustomer: builder.query<LiveCamera[], number>({
        query: (customerId: number) => `LiveStream/CameraFeed/Customer/${customerId}`,
        transformResponse: (response: {data: LiveCamera[]}) => response.data
    }),
    consumedMinutesForCustomer: builder.query<number, number>({
        query: (customerId: number) => `LiveStream/ConsumedMinutes/Customer/${customerId}`,
        transformResponse: (response: {data: number}) => response.data
    }),
    liveStreamLogsForCustomer: builder.query<LiveStreamLog[], LiveSteamLogParams>({
        query: ({customerId, monthOffset}) => `LiveStream/Logs/Customer/${customerId}?monthOffset=${monthOffset}`,
        transformResponse: (response: {data: LiveStreamLog[]}) => response.data
    }),
  })
})

export const {
    useLazyAllCameraFeedsQuery, 
    useLazyCameraFeedsForCustomerQuery,
    useLazyConsumedMinutesForCustomerQuery,
    useLiveStreamLogsForCustomerQuery,
    useLazyLiveStreamLogsForCustomerQuery,
} = liveStreamApi;

