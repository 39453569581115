const WindChillIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        fill={props.color}
        width={props.width ?? 24}
        height={props.height ?? 24}
        viewBox='0 0 724.3 750.6'>
        <g>
            <path
                d='M166,20c-40.9,0-74.8,33.9-74.8,74.8v306.1C47.9,435,16.4,484.2,16.4,543.6c0,102.8,84.2,187,187,187s187-84.2,187-187
            c0-59.4-31.5-108.5-74.8-142.6V394h74.8v-74.8h-74.8v-74.8h74.8v-74.8h-74.8V94.8c0-40.9-33.9-74.8-74.8-74.8H166z M166,94.8h74.8
            v224.4H166V94.8z M166,394h74.8v42.3l18.6,10.8c33.7,19.6,56.2,54.9,56.2,96.5c0,62.4-49.8,112.2-112.2,112.2
            S91.2,605.9,91.2,543.6c0-41.6,22.5-76.9,56.2-96.5l18.6-10.8V394z'
            />
            <path
                d='M693.1,565.6h-54.2l12.4-21.5l-12.4-21.5h54.2c8.2,0,14.9-6.6,14.9-14.9c0-8.2-6.6-14.9-14.9-14.9h-43l21.5-37.2
            c4.1-7.1,1.7-16.2-5.4-20.3c-7.1-4.1-16.2-1.7-20.3,5.4l-27.1,46.9l-12.4-21.5h-24.8l27.1-46.9c4.1-7.1,1.7-16.2-5.4-20.3
            c-7.1-4.1-16.2-1.7-20.3,5.4l-21.5,37.2L540,404.6c-4.1-7.1-13.2-9.5-20.3-5.4c-7.1,4.1-9.5,13.2-5.4,20.3l27.1,46.9h-24.8
            l-12.4,21.5l-27.1-46.9c-4.1-7.1-13.2-9.5-20.3-5.4c-7.1,4.1-9.5,13.2-5.4,20.3l21.5,37.2h-43c-8.2,0-14.9,6.6-14.9,14.9
            c0,8.2,6.6,14.9,14.9,14.9h54.2l-12.4,21.5l12.4,21.5h-54.2c-8.2,0-14.9,6.6-14.9,14.9s6.6,14.9,14.9,14.9h43l-21.5,37.2
            c-4.1,7.1-1.7,16.2,5.4,20.3c2.3,1.4,4.9,2,7.4,2c5.1,0,10.1-2.7,12.9-7.4l27.1-46.9l12.4,21.5h24.8l-27.1,46.9
            c-4.1,7.1-1.7,16.2,5.4,20.3c7.1,4.1,16.2,1.7,20.3-5.4l21.5-37.2l21.5,37.2c2.8,4.8,7.7,7.4,12.9,7.4c2.5,0,5.1-0.6,7.4-2
            c7.1-4.1,9.5-13.2,5.4-20.3l-27.1-46.9h24.8l12.4-21.5l27.1,46.9c2.8,4.8,7.7,7.4,12.9,7.4c2.5,0,5.1-0.6,7.4-2
            c7.1-4.1,9.5-13.2,5.4-20.3l-21.5-37.2h43c8.2,0,14.9-6.6,14.9-14.9S701.3,565.6,693.1,565.6z M589.2,592.2h-55.5L506,544.1
            l27.8-48.1h55.5l27.8,48.1L589.2,592.2z'
            />
        </g>
    </svg>
);

export default WindChillIcon;