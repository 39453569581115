import { Box, Typography, useTheme } from '@mui/material';
import { AirQuality, RainDropOutline, Thermometer } from '../../Assets';
import { Policy } from '../../Services/API';
import ProgressHalfCircleIcon from '../Animated/ProgressHalfCircleComponent';
import VectorIcon from '../Animated/VectorComponent';
import { styledLinkClass } from '../Base';
import Tooltip from '../Base/Tooltip';

interface ComponentProps {
    type: 'Temp' | 'Precip' | 'Feels' | 'WBGT' | 'Wind' | 'AQI';
    value: number;
    direction?: number;
    isApprox?: boolean;
    policy?: Policy;
    stDev?: number;
    isZeroMin?: boolean;
    windDirectionText?: string;
}

const approxTextDesc =
    'Estimated WBGT, using the nearest government weather station. Often differs from actual WBGT, which can only be measured with an ';

export function CurrentCondtionComponent(props: ComponentProps) {
    const { type, value, direction, policy, stDev = 0, isZeroMin = false, isApprox = false, windDirectionText } = props;
    const theme = useTheme();
    const classes = styledLinkClass(theme);

    let color: string = theme.palette.text.primary;
    if (policy) {
        if (!policy.isDesc) {
            if (value > policy.threshold) color = theme.palette.error.main;
            else if (((isZeroMin && value > 0) || !isZeroMin) && value > policy.threshold - stDev) {
                color = theme.palette.warning.main;
            } else if (value > policy.limit) {
                color = theme.palette.success.main;
            } else {
                color = theme.palette.action.inactive;
            }
        } else {
            if (value < policy.threshold) color = theme.palette.primary.main;
            else if (((isZeroMin && value < 0) || !isZeroMin) && value < policy.threshold + stDev) {
                color = theme.palette.warning.main;
            } else if (value < policy.limit) {
                color = theme.palette.success.main;
            } else {
                color = theme.palette.action.inactive;
            }
        }
    }

    const GetIcon = () => {
        switch (type) {
            case 'Temp':
                return <Thermometer color={color} />;
            case 'WBGT':
                return <ProgressHalfCircleIcon color={color} value={value} max={98} min={70} />;
            case 'Wind':
                return <VectorIcon color={color} value={value} direction={direction} max={100} min={0} />;
            case 'Feels':
                return <Thermometer color={color} />;
            case 'Precip':
                return <RainDropOutline color={color} />;
            case 'AQI':
                return <AirQuality height={20} width={20} color={color} />;
        }
    };

    const GetValueText = () => {
        switch (type) {
            case 'Temp':
                return <Typography variant="h3">{value + '°'}</Typography>;
            case 'WBGT':
                return (
                    <Typography color={color} variant="h3">
                        {value}
                    </Typography>
                );
            case 'Wind':
                return (
                    <Typography component={'span'} color={color} variant="h3">
                        {value}
                        {windDirectionText && (
                            <Typography component={'span'} variant="h6">
                                {windDirectionText}
                            </Typography>
                        )}
                    </Typography>
                );
            case 'Feels':
                return (
                    <Typography color={color} variant="h3">
                        {value + '°'}
                    </Typography>
                );
            case 'Precip':
                return (
                    <Typography color={color} variant="h3">
                        {value + '"'}
                    </Typography>
                );
            case 'AQI':
                return (
                    <Typography color={color} variant="h3">
                        {value}
                    </Typography>
                );
        }
    };

    return (
        <Box>
            <div style={{ height: 20, justifyContent: 'center', display: 'flex' }}>
                <div style={{ position: 'absolute' }}>{GetIcon()}</div>
            </div>
            {GetValueText()}
            <Typography>
                {type}
                {isApprox && (
                    <Tooltip
                        title={
                            <Typography component="span">
                                {approxTextDesc}
                                <a
                                    className={classes.link}
                                    rel="noreferrer"
                                    href={'https://perryweather.com/2021/08/24/should-i-use-wet-bulb-or-wet-bulb-globe-temperature-wbgt/'}
                                    target="_blank">
                                    on site sensor
                                </a>
                            </Typography>
                        }
                        arrow
                        placement="right"
                        enterDelay={300}
                        leaveDelay={200}>
                        <Typography variant="caption" color={theme.palette.primary.main} style={{ backgroundColor: 'transparent' }}>
                            {' Approx.'}
                        </Typography>
                    </Tooltip>
                )}
            </Typography>
        </Box>
    );
}
