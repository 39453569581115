const HeatIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        fill={props.color}
        width={props.width ?? 24}
        height={props.height ?? 24}
        viewBox='0 0 724.3 843.7'>
        <g>
            <g>
                <path
                    d='M552.5,97.6c-41,0-74.3,33.3-74.3,74.3s33.3,74.3,74.3,74.3s74.3-33.3,74.3-74.3S593.5,97.6,552.5,97.6z M552.5,218.1
                c-25.5,0-46.3-20.8-46.3-46.3c0-25.5,20.8-46.3,46.3-46.3s46.3,20.8,46.3,46.3C598.8,197.4,578,218.1,552.5,218.1z'
                />
                <path
                    d='M552.5,79.3c8.6,0,15.5-6.9,15.5-15.5V26.2c0-8.6-6.9-15.5-15.5-15.5S537,17.6,537,26.2v37.6
                C537,72.4,543.9,79.3,552.5,79.3z'
                />
                <path
                    d='M485,86c2.9,5,8.1,7.8,13.4,7.8c2.6,0,5.3-0.7,7.7-2.1c7.4-4.3,10-13.8,5.7-21.2L493.1,38c-4.3-7.4-13.8-10-21.2-5.7
                s-10,13.8-5.7,21.2L485,86z'
                />
                <path
                    d='M418.6,112.5l32.5,18.8c2.4,1.4,5.1,2.1,7.7,2.1c5.4,0,10.6-2.8,13.4-7.8c4.3-7.4,1.7-16.9-5.7-21.2l-32.5-18.8
                c-7.4-4.3-16.9-1.7-21.2,5.7C408.7,98.7,411.2,108.2,418.6,112.5z'
                />
                <path
                    d='M459.9,171.9c0-8.6-6.9-15.5-15.5-15.5h-37.6c-8.6,0-15.5,6.9-15.5,15.5s6.9,15.5,15.5,15.5h37.6
                C453,187.4,459.9,180.4,459.9,171.9z'
                />
                <path
                    d='M472.3,218.1c-4.3-7.4-13.8-10-21.2-5.7l-32.5,18.8c-7.4,4.3-10,13.8-5.7,21.2c2.9,5,8.1,7.8,13.4,7.8
                c2.6,0,5.3-0.7,7.7-2.1l32.5-18.8C474.1,235,476.6,225.5,472.3,218.1z'
                />
                <path
                    d='M506.2,252c-7.4-4.3-16.9-1.7-21.2,5.7l-18.8,32.5c-4.3,7.4-1.7,16.9,5.7,21.2c2.4,1.4,5.1,2.1,7.7,2.1
                c5.4,0,10.6-2.8,13.4-7.8l18.8-32.5C516.2,265.8,513.6,256.3,506.2,252z'
                />
                <path
                    d='M552.5,264.4c-8.6,0-15.5,6.9-15.5,15.5v37.6c0,8.6,6.9,15.5,15.5,15.5s15.5-6.9,15.5-15.5v-37.6
                C568,271.4,561.1,264.4,552.5,264.4z'
                />
                <path
                    d='M620,257.7c-4.3-7.4-13.8-10-21.2-5.7c-7.4,4.3-10,13.8-5.7,21.2l18.8,32.5c2.9,5,8.1,7.8,13.4,7.8c2.6,0,5.3-0.7,7.7-2.1
                c7.4-4.3,10-13.8,5.7-21.2L620,257.7z'
                />
                <path
                    d='M686.4,231.3l-32.5-18.8c-7.4-4.3-16.9-1.7-21.2,5.7c-4.3,7.4-1.7,16.9,5.7,21.2l32.5,18.8c2.4,1.4,5.1,2.1,7.7,2.1
                c5.4,0,10.6-2.8,13.4-7.8C696.3,245,693.8,235.5,686.4,231.3z'
                />
                <path
                    d='M698.1,156.4h-37.6c-8.6,0-15.5,6.9-15.5,15.5s6.9,15.5,15.5,15.5h37.6c8.6,0,15.5-6.9,15.5-15.5S706.7,156.4,698.1,156.4
                z'
                />
                <path
                    d='M632.7,125.6c2.9,5,8.1,7.8,13.4,7.8c2.6,0,5.3-0.7,7.7-2.1l32.5-18.8c7.4-4.3,10-13.8,5.7-21.2
                c-4.3-7.4-13.8-10-21.2-5.7l-32.5,18.8C630.9,108.7,628.4,118.2,632.7,125.6z'
                />
                <path
                    d='M598.8,91.7c2.4,1.4,5.1,2.1,7.7,2.1c5.4,0,10.6-2.8,13.4-7.8l18.8-32.5c4.3-7.4,1.7-16.9-5.7-21.2
                c-7.4-4.3-16.9-1.7-21.2,5.7l-18.8,32.5C588.8,77.9,591.4,87.4,598.8,91.7z'
                />
            </g>
            <path
                d='M160.3,112.5c-40.9,0-74.8,33.9-74.8,74.8v306.1c-43.3,34.1-74.8,83.3-74.8,142.6c0,102.8,84.2,187,187,187
            s187-84.2,187-187c0-59.4-31.5-108.5-74.8-142.6v-6.9h74.8v-74.8h-74.8v-74.8h74.8v-74.8h-74.8v-74.8c0-40.9-33.9-74.8-74.8-74.8
            H160.3z M160.3,187.3h74.8v74.8h-74.8V187.3z M160.3,336.9h74.8v191.9l18.6,10.8c33.7,19.6,56.2,54.9,56.2,96.5
            c0,62.4-49.8,112.2-112.2,112.2S85.5,698.5,85.5,636.1c0-41.6,22.5-76.9,56.2-96.5l18.6-10.8V336.9z'
            />
        </g>
    </svg>
);

export default HeatIcon;