import { Box, Grid, Skeleton, Typography, Card as MuiCard, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Margin } from '@nivo/core';
import { Datum, Point } from '@nivo/line';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Card } from '../Base';
import BaseTimeChart from '../Base/BaseTimeChart';
import moment from 'moment';
import {
  EvapotranspirationReading,
  useHistoricalEvapotranspirationCustomQuery,
  useHistoricalEvapotranspirationQuery,
} from '../../Services/API';

interface EvapotranspirationTimelineCardProps {
  stationId: string;
  days?: number;
  bottomTickValues?: number;
  style?: CSSProperties;
  chartMargin?: Partial<Margin>;
  children?: React.ReactNode;
  range: { start: Date; end: Date };
  showCustomTimeRange: boolean;
  rangeError?: boolean;
}

const setUpChartData = (evapotranspirationData: EvapotranspirationReading[], custom: boolean = false) => {
  var histDatum: Datum[] = [];

  if (evapotranspirationData.length < 2) return [];

  evapotranspirationData.forEach(point => {
    const currentDate = new Date(point.date.toString());

    histDatum.push({ x: currentDate, y: point.value });
  });

  return [{ id: 'ET (in.)', data: histDatum }];
};

const generateCustomPointLabel = (datum: Point['data'], precision: number = 2) => {
  return Number(datum.yFormatted).toFixed(precision) + '"';
};

function EvapotranspirationTimeline({
  stationId,
  style,
  chartMargin,
  showCustomTimeRange,
  range,
  rangeError,
  days = 7,
}: EvapotranspirationTimelineCardProps) {
  const [selectedData, setSelectedData] = useState<EvapotranspirationReading[]>([]);
  const [timezone, setTimezone] = useState<string>('');

  const {
    data: data3Day,
    isLoading: isLoading3Day,
    isFetching: isFetching3Day,
  } = useHistoricalEvapotranspirationQuery({ id: stationId, days: 3 }, { pollingInterval: 300000 });

  const {
    data: data7Day,
    isLoading: isLoading7Day,
    isFetching: isFetching7Day,
  } = useHistoricalEvapotranspirationQuery({ id: stationId, days: 7 }, { pollingInterval: 300000 });

  const {
    data: customData,
    isLoading: customIsLoading,
    isFetching: customIsFetching,
  } = useHistoricalEvapotranspirationCustomQuery(
    {
      id: stationId,
      startDate: moment(range.start).format('YYYY-MM-DD'),
      endDate: moment(range.end).format('YYYY-MM-DD'),
    },
    { pollingInterval: 300000, skip: rangeError }
  );

  useEffect(() => {
    let data: EvapotranspirationReading[] = [];
    if (showCustomTimeRange && customData) data = customData;
    else if (days === 3 && data3Day) data = data3Day;
    else if (days === 7 && data7Day) data = data7Day;
    else data = [];

    setTimezone(data3Day?.[0]?.timeZone || data7Day?.[0]?.timeZone || customData?.[0]?.timeZone || '');

    setSelectedData(
      data.map(x => {
        return {
          value: x.value && !isNaN(parseFloat(x.value.toFixed(3))) ? parseFloat(x.value.toFixed(3)) : null,
          date: new Date(x.date),
        };
      })
    );
  }, [days, showCustomTimeRange, range, customData, data3Day, data7Day]);

  const ranges = {
    total: selectedData?.reduce((acc, cur) => acc + (cur.value as number), 0),
    average: selectedData?.reduce((acc, cur) => acc + (cur.value as number), 0) / selectedData.length,
  };

  const insufficentData =
    (!showCustomTimeRange && days === 3 && selectedData.length < 2) ||
    (!showCustomTimeRange && days === 7 && selectedData.length < 5) ||
    (showCustomTimeRange && selectedData.length < moment(range.end).diff(moment(range.start), 'days') - 2);

  const noData = selectedData.length === 0;

  const isFetching =
    (isFetching3Day && days === 3) || (isFetching7Day && days === 7) || (customIsFetching && showCustomTimeRange);

  const isLoading =
    (isLoading3Day && days === 3) || (isLoading7Day && days === 7) || (customIsLoading && showCustomTimeRange);

  const isValid =
    !(rangeError && showCustomTimeRange) &&
    !noData &&
    !insufficentData &&
    !isFetching &&
    !isLoading &&
    ((!!data3Day && days === 3) || (!!data7Day && days === 7) || (!!customData && showCustomTimeRange));

  return (
    <Card fullContent>
      <Grid container>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          {(isLoading || isFetching) && <Skeleton style={{ height: 300, width: '100%' }} />}

          {insufficentData && !isLoading && !isFetching && !rangeError && (
            <Grid height='100%' display='flex' alignItems='center' justifyContent='center'>
              {noData ? (
                <Typography variant='subtitle1' textAlign='center'>
                  Error getting ET data. Unable to generate graph.
                </Typography>
              ) : (
                <Typography variant='subtitle1' textAlign='center'>
                  Insufficient data. Unable to generate graph.
                </Typography>
              )}
            </Grid>
          )}

          {isValid && (
            <BaseTimeChart
              style={style}
              curve='natural'
              leftTickValue={5}
              bottomTickValue={selectedData.length < 10 ? selectedData.length : 10}
              chartMargin={chartMargin}
              data={setUpChartData(selectedData, showCustomTimeRange)}
              max={Math.max(...selectedData.map(x => x.value as number)) + 0.1}
              min={Math.min(...selectedData.map(x => x.value as number)) - 0.1}
              customPointLabel={d => generateCustomPointLabel(d)}
              enablePoints={true}
              pointSize={8}
              colors={['lightgreen']}
              formatType='day'
              labelSuffix='"'
            />
          )}
        </Grid>

        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Box padding={2} gap={2} display='flex' flexDirection='column'>
            <Card>
              Totals:
              <MuiCard>
                <Box padding={1}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='subtitle1' textAlign='left'>
                      24 Hour:
                    </Typography>
                    <Typography variant='subtitle1' textAlign='right'>
                      {isFetching3Day ? <Skeleton width={40} /> : `${data3Day?.[2].value?.toFixed(2)}"`}
                    </Typography>
                  </Box>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='subtitle1' textAlign='left'>
                      3 Day:
                    </Typography>
                    <Typography variant='subtitle1' textAlign='right'>
                      {isFetching3Day ? (
                        <Skeleton width={40} />
                      ) : (
                        `${data3Day?.reduce((a, b) => a + (b.value as number), 0)?.toFixed(2)}"`
                      )}
                    </Typography>
                  </Box>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='subtitle1' textAlign='left'>
                      7 Day:
                    </Typography>
                    <Typography variant='subtitle1' textAlign='right'>
                      {isFetching7Day ? (
                        <Skeleton width={40} />
                      ) : (
                        `${data7Day?.reduce((a, b) => a + (b.value as number), 0)?.toFixed(2)}"`
                      )}
                    </Typography>
                  </Box>
                </Box>
              </MuiCard>
            </Card>

            <Card>
              Selected Range:
              <MuiCard>
                <Box padding={1}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='subtitle1' textAlign='left'>
                      Total:
                    </Typography>
                    <Typography variant='subtitle1' textAlign='right'>
                      {isValid ? `${isNaN(ranges.total) ? '-' : ranges.total?.toFixed(2)}"` : <Skeleton width={40} />}
                    </Typography>
                  </Box>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='subtitle1' textAlign='left'>
                      Average:
                    </Typography>
                    <Typography variant='subtitle1' textAlign='right'>
                      {isValid ? (
                        `${isNaN(ranges.average) ? '-' : ranges.average?.toFixed(2)}"`
                      ) : (
                        <Skeleton width={40} />
                      )}
                    </Typography>
                  </Box>
                </Box>
              </MuiCard>
            </Card>
          </Box>
        </Grid>
      </Grid>
      {timezone && (
        <>
          <Typography variant='subtitle2' textAlign='left' padding={1} paddingTop={0}>
            <Typography variant='subtitle2' color='textSecondary' component='span'>
              {timezone}
            </Typography>
            &nbsp; time &nbsp;
            <Tooltip
              title={
                <Box>
                  <Typography component='span'>All dates and times for evapotranspiration are in the timezone associated with this weather station, not your current location.</Typography>
                </Box>
              }
              arrow
              placement='top'
              enterDelay={300}
              leaveDelay={200}>
              <HelpOutlineIcon color='info' sx={{ fontSize: 14 }} />
            </Tooltip>
          </Typography>
        </>
      )}
    </Card>
  );
}

export default EvapotranspirationTimeline;
