import { api, CalibratedConditions } from '.';
import { AirQuality } from './airQualityApi';

export interface WxHardare {
  weatherStationId: string;
}

export interface OWSTest {
  id: string;
  locationName: string;
  ipAddress: string;
  paId?: string;
}

export interface Hardware {
  id: string;
  name: string;
  ipAddress: string;
  latitude: number;
  longitude: number;
  voltage: number;
  connectedAt: string;
  customerId: number;
  customerName: string;
  modelId: string;
  timeZoneId: string;
  enabled: boolean;
}

export interface OWSAlertTime {
  id: string;
  day: number;
  startTime: string;
  endTime: string;
  enabled: boolean;
  allDay: boolean;
}

export interface OWS {
  id: string;
  locationName: string;
  ipAddress: string;
  customerName: string;
  latitude: number;
  longitude: number;
  afterSirenPaId?: string;
  postWarnMessage?: string;
  postAllClearMessage?: string;
  radius: number;
  duration: number;
  frequency: number;
  allClearMinutes: number;
  allClearDuration: number;
  allClearFrequency: number;
  alertTimes: OWSAlertTime[];
  enabled: boolean;
}

export interface OWSSettings {
  sirenId: string;
  alertTimes: OWSAlertTime[];
  duration: number;
  frequency: number;
  allClearDuration: number;
  allClearFrequency: number;
  postWarnMessage?: string;
  postAllClearMessage?: string;
}

export interface WX {
  id: string;
  lastUpdated?: string;
}

export interface WXData {
  id: string;
  locationName: string;
  lastUpdated?: Date;
  hardwareId?: number;
  latitude?: number;
  longitude?: number;
  ambientTemperature?: number;
  windSpeed?: number;
  windDirection?: number;
  wetBulbGlobalTemp?: number;
  windChill?: number;
  heatIndex?: number;
  rainToday?: number;
  calibratedData?: CalibratedConditions
}

export interface TestStatus {
  id: string,
  status: number
}


const hardwareApi = api.injectEndpoints({
  endpoints: builder => ({
    hardware: builder.query<Hardware[], void>({
      query: () => 'Hardware',
      transformResponse: (response: { data: Hardware[] }) => response.data,
      providesTags: ['Hardware'],
    }),
    weatherStation: builder.query<WX, string>({
      query: (hardwareId: string) => `Hardware/WeatherStation/${hardwareId}`,
      transformResponse: (response: { data: WX }) => response.data,
      providesTags: ['Hardware'],
    }),
    wxData: builder.query<WXData, string>({
      query: (weatherStationId: string) => `Hardware/WeatherStation/Data/${weatherStationId}`,
      transformResponse: (response: { data: WXData }) => response.data,
    }),
    ows: builder.query<OWS, string>({
      query: (hardwareId: string) => `Hardware/OWS/${hardwareId}`,
      transformResponse: (response: { data: OWS }) => response.data,
      providesTags: ['Hardware'],
    }),
    stationAirQuality: builder.query<AirQuality, string>({
      query: (stationId: string) => `AirQuality/station/${stationId}`,
      transformResponse: (response: { data: AirQuality }) => response.data,
    }),
    owsTestDelay: builder.mutation<TestStatus, string[]>({
      query: (owsIds: string[]) => ({
        url: 'Siren/Test/Delay',
        method: 'POST',
        body: owsIds,
      }),
      transformResponse: (response: { data: TestStatus }) => response.data,
    }),
    owsTestAllClear: builder.mutation<TestStatus, string[]>({
      query: (owsIds: string[]) => ({
        url: 'Siren/Test/AllClear',
        method: 'POST',
        body: owsIds,
      }),
      transformResponse: (response: { data: TestStatus }) => response.data,
    }),
    owsTestLight: builder.mutation<TestStatus, string[]>({
      query: (owsIds: string[]) => ({
        url: 'Siren/Test/Light',
        method: 'POST',
        body: owsIds,
      }),
      transformResponse: (response: { data: TestStatus }) => response.data,
    }),
    owsTurnOff: builder.mutation<TestStatus, string[]>({
      query: (owsIds: string[]) => ({
        url: 'Siren/TurnOff',
        method: 'POST',
        body: owsIds,
      }),
      transformResponse: (response: { data: TestStatus }) => response.data,
    }),
    updateOWSSettings: builder.mutation<OWS, OWSSettings>({
      query: (settings: OWSSettings) => ({
        url: 'Hardware/OWS/Settings',
        method: 'PATCH',
        body: settings,
      }),
      transformResponse: (response: { data: OWS }) => response.data,
    }),
    WXImageLastModified: builder.query<string | undefined, string>({
      query: (weatherStationId: string) => `Hardware/WeatherStation/Image/LastModified/${weatherStationId}`,
      transformResponse: (response: { data: string }) => response.data,
    }),
    siren: builder.query<OWS[], void>({
      query: () => 'Siren',
      transformResponse: (response: { data: OWS[] }) => response.data,
    }),
   
  })
})

export const {
  useHardwareQuery,
  useOwsQuery,
  useStationAirQualityQuery,
  useOwsTestDelayMutation,
  useOwsTestAllClearMutation,
  useOwsTestLightMutation,
  useOwsTurnOffMutation,
  useUpdateOWSSettingsMutation,
  useWeatherStationQuery,
  useWxDataQuery,
  useWXImageLastModifiedQuery,
  useSirenQuery,
} = hardwareApi;
