
interface RadarOptionsPortalProps {
    container: React.RefObject<HTMLDivElement>;
}

//Find the impelementation of this component in Radar.tsx
export default function RadarOptionsPortal(props: RadarOptionsPortalProps){
    const {container} = props;
    return (
        <div 
            ref={container}
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1
            }}
        >
            
        </div>
    )
}