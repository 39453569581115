import { api } from '.';

type Feature = {
  id: string;
  name: string;
  displayName: string;
  value: string;
};

interface WeatherStation {
  id: string;
  features: Feature[];
}

const weatherStationApi = api.injectEndpoints({
  endpoints: builder => ({
    wx: builder.query<WeatherStation, string>({
      query: (weatherStationId: string) => `WeatherStation/${weatherStationId}`,
      transformResponse: (response: { data: WeatherStation }) => response.data,
      providesTags: ['WeatherStation'],
    }),
  }),
});

export const { useWxQuery } = weatherStationApi;
