import { Fragment, useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import MasterMapLocation from '../../../../types/MasterMapLocation';
import { ReactComponent as LocationMarker } from '../../../../Assets/masterMapMarker.svg';

interface MasterMapLocationLayerProps {
    locations: MasterMapLocation[];
    onClick?: (location: MasterMapLocation) => void;
}

export function MasterMapLocationLayer(props: MasterMapLocationLayerProps) {
    const { locations, onClick } = props;
    const [hoveredLocation, setHoveredLocation] = useState<MasterMapLocation | null>(null);

    const handleLocationClick = (location: MasterMapLocation) => {
        if (onClick){
            onClick(location);
        }
    }

    return (
        <>
            {locations.map((location, index) => (
                <Fragment key={index}>
                    <div onMouseOver={() => setHoveredLocation(location)} onMouseLeave={() => setHoveredLocation(null)}>
                        <Marker
                            offsetLeft={-20}
                            offsetTop={-30}
                            latitude={location.latitude}
                            longitude={location.longitude}
                            onClick={() => handleLocationClick(location)}
                        >
                            <LocationMarker height={40} width={40} fill='#d3d3d3' stroke='black' />
                        </Marker>
                    </div>
                    {location === hoveredLocation &&
                        <div style={{ position: 'absolute', zIndex: 100 }}>
                            <Popup longitude={location.longitude} latitude={location.latitude} closeButton={false} anchor='top'>
                                <div className='popup' dangerouslySetInnerHTML={{ __html: location.label }} style={{ fontSize: '.8rem' }} />
                            </Popup>
                        </div>
                    }
                </Fragment>
            ))}
        </>
    );
}

export default MasterMapLocationLayer;
