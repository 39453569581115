import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { Grow, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import BaseControl from '../Controls/BaseControl';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getSpeedText, setOpacity, setSpeed, speedTypes } from '../../../../features/map/mapSlice';
import { PWColors } from '../../../../Theme/PWColors';
import { RadarSpeed } from './Radar';

const StyledToggleButton = styled(ToggleButton)(() => ({
  border: '1px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#E9E9E9',
  },
}));

interface OptionsControlProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  show: boolean;
  handleOpacityChange: (newOpacity: number) => void;
  handleSpeedChange: (newSpeed: RadarSpeed) => void;
  speed: RadarSpeed;
}

export function OpacityAndSpeedOptions(props: OptionsControlProps) {

  const { onClick, show, handleOpacityChange, handleSpeedChange, speed } = props;


  const [op, setOp] = React.useState<string>('high');
  const speedText = speed === RadarSpeed.SLOW ? 'slow' : speed === RadarSpeed.NORMAL ? 'normal' : 'fast';

  const changeSpeed = (event: React.MouseEvent<HTMLElement>, newSpeed: string) => {
    if (newSpeed !== null) {
        if(newSpeed === 'slow')
          handleSpeedChange(RadarSpeed.SLOW);
        else if(newSpeed === 'normal')
          handleSpeedChange(RadarSpeed.NORMAL);
        else if(newSpeed === 'fast')
          handleSpeedChange(RadarSpeed.FAST);
    }
  };

  const handleOpacity = (event: React.MouseEvent<HTMLElement>, newOpacity: string) => {
    if (newOpacity !== null) {
      setOp(newOpacity);
      var newOp = 0.6;
      switch (newOpacity) {
        case 'low':
          newOp = 0.4;
          break;
        case 'medium':
          newOp = 0.6;
          break;
        case 'high':
          newOp = 1.0;
          break;
      }
      handleOpacityChange(newOp);
    }
  };

  return (
    <div>
      <Grow in={show}>
        <div className='overlayMenu'>
          <ul>
            <li>
              <Typography textAlign='left' variant='subtitle1' color={PWColors.dark.base['900']}>
                Transparency:
              </Typography>
            </li>
            <li>
              <ToggleButtonGroup size='small' exclusive color='secondary' onChange={handleOpacity} value={op}>
                <StyledToggleButton value='low'>
                  <Typography color={PWColors.dark.base['900']}>Low</Typography>
                </StyledToggleButton>
                <StyledToggleButton value='medium'>
                  <Typography color={PWColors.dark.base['900']}>Medium</Typography>
                </StyledToggleButton>
                <StyledToggleButton value='high'>
                  <Typography color={PWColors.dark.base['900']}>High</Typography>
                </StyledToggleButton>
              </ToggleButtonGroup>
            </li>
            <li>
              <Typography textAlign='left' variant='subtitle1' color={PWColors.dark.base['900']}>
                Speed:
              </Typography>
            </li>
            <li>
              <ToggleButtonGroup
                size='small'
                exclusive
                color='secondary'
                onChange={changeSpeed}
                value={speedText}>
                <StyledToggleButton value='slow'>
                  <Typography color={PWColors.dark.base['900']}>Slow</Typography>
                </StyledToggleButton>
                <StyledToggleButton value='normal'>
                  <Typography color={PWColors.dark.base['900']}>Normal</Typography>
                </StyledToggleButton>
                <StyledToggleButton value='fast'>
                  <Typography color={PWColors.dark.base['900']}>Fast</Typography>
                </StyledToggleButton>
              </ToggleButtonGroup>
            </li>
          </ul>
        </div>
      </Grow>
      <BaseControl onClick={onClick} label='Options'>
        <MoreHorizIcon />
      </BaseControl>
    </div>
  );
}

export default OpacityAndSpeedOptions;
