import { LngLatBounds, Map } from 'maplibre-gl';
import { Viewport } from './';

export interface BBox {
  top: number;
  bottom: number;
  left: number;
  right: number;
  zoom: number;
}

export function getBBox(viewport: Viewport, mapRef: Map) {
  const bounds: LngLatBounds = mapRef.getBounds();
  const precision: number = 10000;
  const bbox: BBox = {
    bottom: Math.round(bounds.getSouth() * precision) / precision,
    top: Math.round(bounds.getNorth() * precision) / precision,
    left: Math.round(bounds.getWest() * precision) / precision,
    right: Math.round(bounds.getEast() * precision) / precision,
    zoom: Math.round(viewport.zoom),
  };

  return bbox;
}

export function getLargeBBox(bbox: BBox) {
  const vertDiff = Math.abs(bbox.bottom - bbox.top);
  const horiDiff = Math.abs(bbox.left - bbox.right);
  const scale = 1.2;
  const largeBbox = {
    bottom: bbox.bottom - vertDiff * scale,
    top: bbox.top + vertDiff * scale,
    left: bbox.left - horiDiff * scale,
    right: bbox.right + horiDiff * scale,
    zoom: bbox.zoom,
  };

  return largeBbox;
}

export function convertBBoxToAspectRatio(bbox: number[]) {
  const width = bbox[2] - bbox[0];
  const height = bbox[3] - bbox[1];
  return width / height;
}
