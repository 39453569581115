import { api, WeatherCode, WeatherModel } from '.';
import { Coordinates } from '../../features/map/MapHelpers';

export interface Forecast {
  weatherCode?: WeatherCode;
  wbgt?: WeatherModel;
  feelsLike?: WeatherModel;
  forecastStartTime: Date;
  observationTime: Date;
  ambientTemperature?: WeatherModel;
  temperatureHigh?: WeatherModel;
  temperatureLow?: WeatherModel;
  dayLight: boolean;
  feelLike?: WeatherModel;
  precipitation?: WeatherModel;
  precipitationChance?: WeatherModel;
  snowAccumulation?: WeatherModel;
  wgbt?: WeatherModel;
  windSpeed?: WeatherModel;
  windDirection: string;
  weather_code?: WeatherCode;
}

const forecastApi = api.injectEndpoints({
  endpoints: builder => ({
    forecastForLocation: builder.query<Forecast, string>({
      query: id => `forecast/Today/ForLocation/${id}`,
      transformResponse: (response: { data: Forecast }) => response.data,
    }),
    forecastHourlyForLocation: builder.query<Forecast[], string>({
      query: id => `forecast/Hourly/ForLocation/${id}`,
      transformResponse: (response: { data: Forecast[] }) => response.data,
    }),
    forecastTodayHourly: builder.query<Forecast[], Coordinates>({
      query: ({ latitude, longitude }) => `forecast/Today/Hourly?latitude=${latitude}&longitude=${longitude}`,
      transformResponse: (response: { data: Forecast[] }) => response.data,
    }),
    forecastTodayHourlyForLocation: builder.query<Forecast[], string>({
      query: id => `forecast/Today/Hourly/ForLocation/${id}`,
      transformResponse: (response: { data: Forecast[] }) => response.data,
    }),
    forecastDailyForLocation: builder.query<Forecast[], string>({
      query: id => {
        return { url: `forecast/Daily/ForLocation/${id}`, headers: { version: '2.0' } };
      },
      transformResponse: (response: { data: Forecast[] }) => response.data,
    }),
  }),
});

export const {
  useForecastForLocationQuery,
  useForecastHourlyForLocationQuery,
  useForecastTodayHourlyQuery,
  useForecastTodayHourlyForLocationQuery,
  useForecastDailyForLocationQuery,
} = forecastApi;
