import { useState } from 'react';
import { StaticMap as ReactStaticMap, Marker } from 'react-map-gl';
import LocationMarkerIcon from '../../Assets/locationMarker.svg';
import './staticMap.css';

interface MapProps {
  latitude: number;
  longitude: number;
}

const mapTilerKey = process.env.REACT_APP_MAP_TILER_KEY;

export default function StaticMap(props: MapProps) {
  const [light] = useState(true);

  return (
    <ReactStaticMap
      className='static-map'
      latitude={props.latitude}
      longitude={props.longitude}
      attributionControl={false}
      zoom={10}
      width='100%'
      height='100%' // <--- Has problems in fullscreen, using 100vh causes a much too large map.
      mapStyle={
        light
          ? `https://api.maptiler.com/maps/basic/style.json?key=${mapTilerKey}`
          : `https://api.maptiler.com/maps/7a330e3e-5974-4112-9b7f-15f3a2416735/style.json?key=${mapTilerKey}`
      }>
      <Marker offsetLeft={-8} offsetTop={-33} latitude={props.latitude} longitude={props.longitude}>
        <img height={40} width={40} src={LocationMarkerIcon} alt='Location Marker' />
      </Marker>
    </ReactStaticMap>
  );
}
