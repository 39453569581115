import { Box, Fade, Typography } from '@mui/material';
import { NWSMapAlert } from '../../../../Services/API';

interface NWSAlertProps {
  clickedInfo: any;
  alerts: NWSMapAlert[];
}

export function NWSAlertView(props: NWSAlertProps) {
  const { clickedInfo, alerts } = props;

  return (
    <Fade in={alerts.length !== 0}>
      <Box
        style={{
          borderRadius: 8,
          position: 'absolute',
          padding: 4,
          background: 'rgba(0,0,0,0.8)',
          color: '#fff',
          minWidth: 140,
          fontSize: 10,
          zIndex: 9,
          pointerEvents: 'none',
          left: clickedInfo.x - 70,
          top: clickedInfo.y - (alerts.length * 24 + 24),
        }}>
        {alerts.map(alert => (
          <Typography key={alert.type}>{alert.type}</Typography>
        ))}
      </Box>
    </Fade>
  );
}

export default NWSAlertView;
