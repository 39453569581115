//9000-Day

export const ClearDay = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st4'
        d='M5,2.59c-1.34,0-2.43,1.09-2.43,2.43c0,1.34,1.09,2.43,2.43,2.43c1.34,0,2.43-1.09,2.43-2.43
                C7.43,3.68,6.34,2.59,5,2.59z M6.99,5.02c0,1.1-0.89,1.99-1.99,1.99c-1.1,0-1.99-0.89-1.99-1.99c0-1.1,0.89-1.99,1.99-1.99
                C6.1,3.03,6.99,3.92,6.99,5.02z'
      />
      <path
        className='st4'
        d='M5,2.04c0.12,0,0.22-0.1,0.22-0.22V0.66c0-0.12-0.1-0.22-0.22-0.22c-0.12,0-0.22,0.1-0.22,0.22v1.17
                C4.78,1.95,4.88,2.04,5,2.04z'
      />
      <path
        className='st4'
        d='M5,7.96c-0.12,0-0.22,0.1-0.22,0.22v1.17c0,0.12,0.1,0.22,0.22,0.22c0.12,0,0.22-0.1,0.22-0.22V8.18
                C5.22,8.06,5.12,7.96,5,7.96z'
      />
      <path
        className='st4'
        d='M6.67,2.52c0.08,0,0.15-0.04,0.19-0.1l0.61-0.99C7.5,1.38,7.51,1.32,7.5,1.26C7.49,1.2,7.45,1.15,7.4,1.12
                C7.35,1.09,7.29,1.08,7.24,1.09C7.18,1.11,7.13,1.14,7.1,1.19L6.49,2.18C6.45,2.23,6.44,2.29,6.46,2.35
                c0.01,0.06,0.05,0.11,0.1,0.14C6.59,2.51,6.63,2.52,6.67,2.52z'
      />
      <path
        className='st4'
        d='M3.28,7.49C3.22,7.5,3.17,7.53,3.14,7.59L2.53,8.58C2.46,8.68,2.5,8.82,2.6,8.88C2.63,8.9,2.67,8.91,2.71,8.91
                c0.08,0,0.15-0.04,0.19-0.1l0.61-0.99c0.03-0.05,0.04-0.11,0.03-0.17s-0.05-0.11-0.1-0.14C3.39,7.48,3.33,7.47,3.28,7.49z'
      />
      <path
        className='st4'
        d='M7.8,3.73c0.04,0,0.07-0.01,0.1-0.03l1.03-0.55c0.05-0.03,0.09-0.07,0.11-0.13C9.06,2.96,9.05,2.9,9.02,2.85
                C8.97,2.74,8.83,2.7,8.73,2.76L7.7,3.31C7.65,3.34,7.61,3.38,7.59,3.44C7.57,3.5,7.58,3.56,7.61,3.61C7.64,3.68,7.72,3.73,7.8,3.73
                z'
      />
      <path
        className='st4'
        d='M2.09,6.31L1.06,6.86c-0.11,0.06-0.15,0.19-0.09,0.3c0.04,0.07,0.11,0.12,0.19,0.12c0.04,0,0.08-0.01,0.1-0.03
                L2.3,6.7c0.05-0.03,0.09-0.07,0.11-0.13C2.43,6.51,2.42,6.45,2.39,6.4C2.33,6.29,2.2,6.25,2.09,6.31z'
      />
      <path
        className='st4'
        d='M9.34,4.77l-1.17,0c-0.12,0-0.22,0.1-0.22,0.22c0,0.12,0.1,0.22,0.22,0.22l1.17,0c0.12,0,0.22-0.1,0.22-0.22
                C9.57,4.87,9.47,4.77,9.34,4.77z'
      />
      <path
        className='st4'
        d='M2.04,5.01c0-0.12-0.1-0.22-0.22-0.22l-1.17,0c-0.06,0-0.12,0.02-0.16,0.07C0.46,4.9,0.43,4.96,0.43,5.02
                c0,0.12,0.1,0.22,0.22,0.22l1.17,0C1.94,5.23,2.04,5.14,2.04,5.01z'
      />
      <path
        className='st4'
        d='M7.9,6.33C7.79,6.27,7.66,6.31,7.6,6.42c-0.06,0.11-0.02,0.24,0.09,0.3l1.02,0.56C8.74,7.29,8.78,7.3,8.82,7.3
                c0.08,0,0.16-0.04,0.19-0.11c0.03-0.05,0.04-0.11,0.02-0.17s-0.05-0.1-0.11-0.13L7.9,6.33z'
      />
      <path
        className='st4'
        d='M1.29,2.72C1.19,2.66,1.05,2.7,0.99,2.81c-0.06,0.11-0.02,0.24,0.09,0.3L2.1,3.67
                c0.03,0.02,0.07,0.03,0.11,0.03c0.08,0,0.15-0.04,0.2-0.12c0.06-0.11,0.02-0.24-0.09-0.3L1.29,2.72z'
      />
      <path
        className='st4'
        d='M6.87,7.58c-0.06-0.1-0.2-0.13-0.3-0.07c-0.1,0.07-0.13,0.2-0.07,0.3L7.12,8.8c0.04,0.07,0.11,0.1,0.19,0.1
                c0.04,0,0.08-0.01,0.12-0.03c0.05-0.03,0.09-0.08,0.1-0.14s0-0.12-0.03-0.17L6.87,7.58z'
      />
      <path
        className='st4'
        d='M3.13,2.42c0.04,0.07,0.11,0.1,0.19,0.1c0.04,0,0.08-0.01,0.12-0.03c0.1-0.06,0.13-0.2,0.07-0.3L2.88,1.2
                C2.85,1.15,2.8,1.11,2.75,1.1c-0.06-0.01-0.12,0-0.17,0.03c-0.1,0.06-0.13,0.2-0.07,0.3L3.13,2.42z'
      />
    </g>
  </svg>
);
