import { Map } from "maplibre-gl"

export interface RadarImageBundle {
  base64: string;
  url: string;
}

export class RadarUtil {

  static transparentImageUrl = `${process.env.PUBLIC_URL}/transparent.png`;

  static async preloadImages(urls: string[]): Promise<RadarImageBundle[]> {
    const base64Urls = urls.map(url => RadarUtil.preloadImage(url));
    return Promise.all(base64Urls);
  }

  private static async preloadImage(url: string): Promise<RadarImageBundle> {
    var result = await fetch(url)
    var blob = await result.blob()
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      }

      reader.onloadend = () => {
        const base64data = reader.result;
        resolve({
          base64: base64data as string,
          url: url
        });
      }
      reader.readAsDataURL(blob);
    });
  }

  // private static convertImageToBase64(image: HTMLImageElement) : string {
  //   let dataUrl = "";
  //   const canvas = document.createElement('canvas');
  //   const ctx = canvas.getContext('2d');
  //   canvas.height = image.naturalHeight;
  //   canvas.width = image.naturalWidth;
  //   image.crossOrigin = "Anonymous";
  //   if(ctx != null){
  //     ctx.drawImage(image, 0, 0);
  //   }
  //   dataUrl = canvas.toDataURL();
  //   return dataUrl;
  // }

  static getCoordinates(mapRef: Map): number[][] {
    if (mapRef) {
      let bounds = mapRef.getBounds();
      return [
        [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
        [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
        [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
      ];
    } else
      return [
        [0.0, 0.0],
        [0.0, 0.0],
        [0.0, 0.0],
        [0.0, 0.0],
      ];
  }
}
