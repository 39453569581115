import { useEffect, useState } from 'react';
import { Timeline } from './Timeline';
import { PlayButton } from './PlayButton';
import { FullscreenControl, LinearInterpolator } from 'react-map-gl';
import { Map } from 'maplibre-gl';
import { Location, useLocationsQuery } from '../../../../Services/API';
import { CircularProgress, Grid, Portal, Typography } from '@mui/material';
import { Viewport } from '../Util';
import OpacityAndSpeedOptions from './OpacityAndSpeedOptions';
import { baseLayerType, getBaseLayer, getIsLayerRadar, getRadarStyle, getShowAllLocations, radarTypes, selectBaseLayer, setRadarStyle, setShowAllLocations } from '../../../../features/map/mapSlice';
import RadarTypeSelect from './RadarTypeSelect';
import PlayImageSource from './Sources/PlayImageSource';
import ScrubImageSource from './Sources/ScrubImageSource';
import StaticTmsSource from './Sources/StaticTmsSource';
import { Button, Checkbox, Select } from '../../../Base';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { _useMapControl as useMapControl } from 'react-map-gl';
import { getSelectedLocation, selectLocation } from '../../../../features/dash/dashSlice';
import MapState from 'react-map-gl/src/utils/map-state';
import SpaceBar from '@mui/icons-material/SpaceBar';


export enum RadarState {
    STATIC,
    SCRUB,
    PLAY
}

export interface RadarProps {
    container: React.RefObject<HTMLDivElement>;
    hasLocation?: boolean;
    viewport: Viewport;
    mapRef: Map;
    location: Location | undefined;
    broadcastView?: boolean;
}

export enum RadarSpeed {
    SLOW = 1000,
    NORMAL = 750,
    FAST = 500
}

export function Radar(props: RadarProps) {
    const { container, mapRef, location, hasLocation, viewport, broadcastView = false } = props;
    const [radarState, setRadarState] = useState<RadarState>(RadarState.STATIC);
    const [progress, setProgress] = useState(0);
    const [labelText, setLabelText] = useState<string>('');
    const [activeElement, setActiveElement] = useState<string>('');
    const [opacity, setOpacity] = useState<number>(1);
    const [speed, setSpeed] = useState<RadarSpeed>(RadarSpeed.NORMAL);
    const [radarIsLoading, setRadarIsLoading] = useState<boolean>(true);
    const { data: locations } = useLocationsQuery();
    const selectedLocation = useAppSelector(getSelectedLocation);
    const radarType = useAppSelector(getRadarStyle);
    const isStyleRadar = useAppSelector(getIsLayerRadar);
    const dispatch = useAppDispatch();

    const handleRadarStateChange = (value: RadarState) => {
        setRadarState(value);
    };

    const handleSetProgress = (value: number) => {
        setProgress(value);
    };

    const onChildClick = (element: string) => {
        if (element === activeElement) setActiveElement('none');
        else setActiveElement(element);
    };

    const handleOpacityChange = (value: number) => {
        setOpacity(value);
    };

    const handleSpeedChange = (value: RadarSpeed) => {
        setSpeed(value);
    };

    const handleRadarTypeChange = (value: radarTypes) => {
        dispatch(setRadarStyle(value));
    };

    const handleLabelChange = (value: string) => {
        setLabelText(value);
    };

    const handleLoadingState = (isLoading: boolean) => {
        setRadarIsLoading(isLoading);
    };

    const handleSelectedLocationChange = (value: string) => {
        const newLocation = locations?.find(location => location.id === value);
        dispatch(selectLocation(newLocation));
    };

    useEffect(() => {
        if (radarType === 'past') {
            setProgress(100);
        } else {
            setProgress(0);
        }
        setRadarState(RadarState.STATIC);
    }, [radarType]);

    useEffect(() => {
        if (radarState === RadarState.PLAY || radarState === RadarState.SCRUB) {
            setRadarState(RadarState.STATIC);
        }
    }, [viewport]);

    function updateViewport(context: any, props: any, opts: any) {
        const { viewport } = context;
        const mapState = new MapState(Object.assign({}, viewport, opts));
        const viewState = Object.assign({}, mapState.getViewportProps(), new LinearInterpolator());
        viewState.transitionDuration = 200;
        const noop = () => {};
        const onViewportChange = props.onViewportChange || context.onViewportChange || noop;
        const onViewStateChange = props.onViewStateChange || context.onViewStateChange || noop;

        // Call new style callback
        onViewStateChange({ viewState });

        // Call old style callback
        onViewportChange(viewState);
    }

    const { context } = useMapControl({});

    const onZoomIn = () => {
        updateViewport(context, {}, { zoom: context.viewport!.zoom + 1 });
    };

    const onZoomOut = () => {
        updateViewport(context, {}, { zoom: context.viewport!.zoom - 1 });
    };

    let beforeId =
        radarType == 'current-road-conditions' || radarType == 'future-road-conditions' ? 'road_minor' : hasLocation ? 'radii-1' : 'admin_sub';

    return (
        <>
            {radarState === RadarState.STATIC && (
                <StaticTmsSource
                    beforeId={beforeId}
                    mapRef={mapRef}
                    opacity={opacity}
                    location={location}
                    radarType={radarType}
                    progress={progress}
                    handleLabelChange={handleLabelChange}
                    handleLoadingState={handleLoadingState}
                />
            )}
            {radarState === RadarState.SCRUB && (
                <ScrubImageSource
                    beforeId={beforeId}
                    viewport={viewport}
                    mapRef={mapRef}
                    opacity={opacity}
                    location={location}
                    radarType={radarType}
                    progress={progress}
                    handleLabelChange={handleLabelChange}
                    handleLoadingState={handleLoadingState}
                />
            )}
            {radarState === RadarState.PLAY && (
                <PlayImageSource
                    beforeId={beforeId}
                    viewport={viewport}
                    mapRef={mapRef}
                    opacity={opacity}
                    location={location}
                    radarType={radarType}
                    progress={progress}
                    handleSetProgress={handleSetProgress}
                    speed={speed}
                    handleLabelChange={handleLabelChange}
                    handleLoadingState={handleLoadingState}
                />
            )}
            {radarIsLoading && (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pointerEvents: 'none'
                    }}>
                    <CircularProgress size={80} />
                </div>
            )}
            {/* This Portal has to render as a child of BottomLayerGroup. You can find the portal "exit" in Map.tsx, and component in RadarOptionsPortal.tsx  */}
            <Portal container={container.current}>
                {!broadcastView && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            flexGrow: 0,
                            marginLeft: '10px',
                            marginRight: '10px',
                            gap: 10
                        }}>
                        <OpacityAndSpeedOptions
                            onClick={e => onChildClick('options')}
                            show={activeElement === 'options'}
                            handleOpacityChange={handleOpacityChange}
                            handleSpeedChange={handleSpeedChange}
                            speed={speed}
                        />
                        <div>
                            <RadarTypeSelect
                                onClick={e => onChildClick('radar')}
                                show={activeElement === 'radar'}
                                handleRadarTypeChange={handleRadarTypeChange}
                                location={location}
                                radarType={radarType}
                                variant={isStyleRadar ? 'radar' : 'road-conditions'}
                            />
                        </div>
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        flexGrow: 1,
                        gap: 20,
                        marginRight: '20px'
                    }}>
                    <PlayButton handleRadarStateChange={handleRadarStateChange} radarState={radarState} />
                    <Timeline
                        handleRadarStateChange={handleRadarStateChange}
                        handleSetProgress={handleSetProgress}
                        labelText={labelText}
                        radarState={radarState}
                        progress={progress}
                    />
                </div>
                {broadcastView && (
                    <BroadcastViewRadarControls
                        onZoomIn={onZoomIn}
                        onZoomOut={onZoomOut}
                        locations={locations}
                        selectedLocation={selectedLocation}
                        handleSelectedLocationChange={handleSelectedLocationChange}
                        radarType={radarType}
                        handleRadarTypeChange={handleRadarTypeChange}
                        speed={speed}
                        handleSpeedChange={handleSpeedChange}
                        radarState={radarState}
                        handleRadarStateChange={handleRadarStateChange}
                        progress={progress}
                        handleSetProgress={handleSetProgress}
                    />
                )}
            </Portal>
        </>
    );
}

interface BroadcastViewRadarControlsProps {
    onZoomIn: () => void;
    onZoomOut: () => void;
    locations: Location[] | undefined;
    selectedLocation: Location | undefined;
    handleSelectedLocationChange: (value: string) => void;
    radarType: radarTypes;
    handleRadarTypeChange: (value: radarTypes) => void;
    speed: RadarSpeed;
    handleSpeedChange: (value: RadarSpeed) => void;
    radarState: RadarState;
    handleRadarStateChange: (value: RadarState) => void;
    progress: number;
    handleSetProgress: (value: number) => void;
}

function BroadcastViewRadarControls({
    onZoomIn,
    onZoomOut,
    locations,
    selectedLocation,
    handleSelectedLocationChange,
    radarType,
    handleRadarTypeChange,
    speed,
    handleSpeedChange,
    radarState,
    handleRadarStateChange,
    progress,
    handleSetProgress
}: BroadcastViewRadarControlsProps) {

    const dispatch = useAppDispatch();
    const baseLayer = useAppSelector(getBaseLayer);
    const showAllLocations = useAppSelector(getShowAllLocations);

    const handleBaseLayerSelect = (value: string) => {
        dispatch(selectBaseLayer(value as baseLayerType));
    }

    const handleAllLocationsChange = (value: boolean) => {
        dispatch(setShowAllLocations(value));
    }

    useEffect(() => {
        dispatch(selectBaseLayer('satellite'));

    }, []);

    useEffect(() => {

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === ' ') {
                if(radarState === RadarState.PLAY) {
                    handleRadarStateChange(RadarState.STATIC); 
                }
                else {
                    handleRadarStateChange(RadarState.PLAY);
                }
            }

            if(event.key === 'ArrowRight') {
                if(radarState != RadarState.SCRUB) {
                    handleRadarStateChange(RadarState.SCRUB);
                }
                handleSetProgress(Math.min(100,progress + 5));
            }
            if(event.key === 'ArrowLeft') {
                if(radarState != RadarState.SCRUB) {
                    handleRadarStateChange(RadarState.SCRUB);
                }
                handleSetProgress(Math.max(0, progress - 5));
            }
          };
      
          window.addEventListener('keydown', handleKeyDown);

          return () => {
            window.removeEventListener('keydown', handleKeyDown);
          }
      
    },[radarState, dispatch, handleRadarStateChange, progress, handleSetProgress]);

    return (
        <Grid item xs={12} marginTop={1} container display={'flex'} flexDirection={'column'} spacing={4} alignItems={'flex-start'}>
            <Grid item container display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Grid xs={6} item>
                    <Typography textAlign={'start'} variant={'h6'}>
                        Full Screen
                    </Typography>
                </Grid>
                <Grid xs={6} justifyContent={'flex-end'} container item>
                    <FullscreenControl style={{position: 'relative'}} />   
                </Grid>
            </Grid>
            <Grid xs={12} item container display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-around'} spacing={4}>
                <Grid xs={6} item>
                    <Button onClick={onZoomIn} type="clear">
                        Zoom In
                    </Button>
                </Grid>
                <Grid xs={6} item>
                    <Button onClick={onZoomOut} type="clear">
                        Zoom Out
                    </Button>
                </Grid>
            </Grid>
            <Grid item container display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Grid xs={12} item container display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography textAlign={'start'} variant={'h6'}>
                        Location
                    </Typography>
                    <Grid item display={'flex'} alignItems={'center'}>
                        <Typography>
                            All Locations
                        </Typography>
                        <Checkbox onChange={() => handleAllLocationsChange(!showAllLocations)} checked={showAllLocations} />
                    </Grid>
                </Grid>
                <Grid xs={12} item>
                    <Select
                        value={selectedLocation?.id}
                        options={locations?.map(location => ({ value: location.id!, text: location.label })) || []}
                        onChange={value => handleSelectedLocationChange(value)}
                    />
                </Grid>
            </Grid>
            <Grid item container display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Grid xs={12} item>
                    <Typography textAlign={'start'} variant={'h6'}>
                        Radar Type
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <Select
                        value={radarType}
                        options={[
                            { value: 'past', text: 'Past' },
                            { value: 'future', text: 'Future' }
                        ]}
                        onChange={value => handleRadarTypeChange(value as radarTypes)}
                    />
                </Grid>
            </Grid>
            <Grid item container display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Grid xs={12} item>
                    <Typography textAlign={'start'} variant={'h6'}>
                        Playback Speed
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <Select
                        value={speed}
                        options={[
                            { value: RadarSpeed.SLOW, text: 'Slow' },
                            { value: RadarSpeed.NORMAL, text: 'Normal' },
                            { value: RadarSpeed.FAST, text: 'Fast' }
                        ]}
                        onChange={value => handleSpeedChange(parseInt(value))}
                    />
                </Grid>
            </Grid>

            <Grid item container display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Grid xs={12} item>
                    <Typography textAlign={'start'} variant={'h6'}>
                        Map Style
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <Select
                        value={baseLayer}
                        options={[
                            { value: "satellite", text: 'Satellite' },
                            { value: "dark", text: 'Dark' },
                            { value: "light", text: 'Light' }
                        ]}
                        onChange={value => handleBaseLayerSelect(value)}
                    />
                </Grid>
            </Grid>

            <Grid item container display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Grid xs={12} item>
                    <Typography textAlign={'start'} variant={'h6'}>
                        Keyboard Controls
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <Typography textAlign={'start'} variant={'body1'}>
                        Spacebar - Play/Pause
                    </Typography>
                    <Typography textAlign={'start'} variant={'body1'}>
                        Arrow Right - Step Radar Forward
                    </Typography>
                    <Typography textAlign={'start'} variant={'body1'}>
                        Arrow Left - Step Radar Backward
                    </Typography>
                </Grid>
            </Grid>

            
        </Grid>
    );
}
