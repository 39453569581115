import { Box, Grid, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import Card from '../Base/Card';
import { AQIIndex, PollutantData, useGetAirQualityForLocationQuery } from '../../Services/API';
import { useAppSelector } from '../../app/hooks';
import { getSelectedLocation } from '../../features/dash/dashSlice';
import { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ProgressCircle from '../Animated/ProgressCircle';
import { AqiType } from '../../Constants/AqiConstants';

const AQI_RANGE_MAX = 500;
const AQI_RANGE_MIN = 0;

const getOzoneAQI = (o3: PollutantData) => {
  if (o3?.aqiData.oneHourIndex?.value && o3?.aqiData.eightHourIndex?.value) {
    if (o3?.aqiData.oneHourIndex.value >= o3?.aqiData.eightHourIndex.value) {
      return o3?.aqiData.oneHourIndex;
    } else {
      return o3?.aqiData.eightHourIndex;
    }
  } else if (o3?.aqiData.oneHourIndex?.value) {
    return o3?.aqiData.oneHourIndex;
  } else {
    return o3?.aqiData.eightHourIndex;
  }
};

export function AirQualityCard() {
  const selectedLocation = useAppSelector(getSelectedLocation);
  const {
    data: airQualityData,
    isFetching: isAirQualityFetching,
    isLoading: isAirQualityLoading,
  } = useGetAirQualityForLocationQuery(selectedLocation?.id ?? skipToken);

  const [isSevere, setIsSevere] = useState(false); //Will need to hook this up once we have policies in place

  const pm25Data = airQualityData?.find(x => x.pollutantName === AqiType.PM25);
  const pm10Data = airQualityData?.find(x => x.pollutantName === AqiType.PM10);
  const o3Data = airQualityData?.find(x => x.pollutantName === AqiType.O3);

  return (
    <Card header='Air Quality' scary={isSevere}>
      {isAirQualityFetching || isAirQualityLoading ? (
        <Box display='flex' justifyContent='space-evenly'>
          <Skeleton animation={false} variant='rectangular' width='100%' height={110} />
          <Skeleton animation={false} variant='rectangular' width='100%' height={110} />
          <Skeleton animation={false} variant='rectangular' width='100%' height={110} />
        </Box>
      ) : airQualityData && airQualityData.length > 0 ? (
        <>
          <Grid
            spacing={2}
            container
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            justifyContent={'space-around'}>
            {pm25Data && <AirQualityGauge pollutantData={pm25Data} />}
            {pm10Data && <AirQualityGauge pollutantData={pm10Data} />}
            {o3Data && <AirQualityGauge pollutantData={o3Data} />}
          </Grid>
        </>
      ) : (
        <Typography>No Data</Typography>
      )}
    </Card>
  );
}
export default AirQualityCard;

interface AirQualityGaugeProps {
  pollutantData: PollutantData;
}
function AirQualityGauge({ pollutantData }: AirQualityGaugeProps) {
  let aqiIndex: AQIIndex | undefined;

  if (pollutantData.pollutantName === 'Ozone') {
    aqiIndex = pollutantData.aqiData.nowCastIndex ? pollutantData.aqiData.nowCastIndex : getOzoneAQI(pollutantData);
  } else {
    aqiIndex = pollutantData.aqiData.nowCastIndex
      ? pollutantData.aqiData.nowCastIndex
      : pollutantData.aqiData.twentyFourHourIndex;
  }

  return (
    <Grid item>
      <Typography variant='h6'>{pollutantData.pollutantName}</Typography>
      <ProgressCircle
        width={100}
        value={aqiIndex?.value}
        max={AQI_RANGE_MAX}
        min={AQI_RANGE_MIN}
        color={aqiIndex?.color}
      />
      <Typography marginTop={-3} variant='h5'>
        {aqiIndex?.value}
      </Typography>
      <Typography variant='h6'>{aqiIndex?.shortDescription ?? ''}</Typography>
    </Grid>
  );
}
