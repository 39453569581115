import { memo } from 'react';
import { PolicyType, usePolicyTypesQuery } from '../../Services/API';
import { Select, SelectOption } from '../Base';

interface PolicyTypeSelectProps {
  value?: number;
  disabled?: boolean;
  placeholder?: string;
  onSelect: (policyType: PolicyType) => void;
}

export const PolicyTypeSelect = memo(function PolicyTypeSelect(props: PolicyTypeSelectProps) {
  const { value, onSelect, disabled, placeholder } = props;
  const { data: policyTypes } = usePolicyTypesQuery();

  return (
    <Select
      placeholder={placeholder}
      disabled={disabled}
      onChange={(value, name) => {
        if (policyTypes) onSelect(policyTypes.find(x => x.id === parseInt(value)) || policyTypes[0]);
      }}
      value={value}
      options={
        policyTypes?.map(policyType => {
          let option: SelectOption = { value: policyType.id, text: policyType.displayName };
          return option;
        }) || []
      }
    />
  );
});
