import { Map } from "maplibre-gl";
import { useEffect, useState } from "react";
import { Layer, LayerProps, Source } from "react-map-gl";
import { Location, useRadarAnimListQuery } from "../../../../../Services/API";
import { radarTypes } from "../../../../../features/map/mapSlice";
import { Viewport } from "../../Util";
import { BaronUtil } from "../Util/BaronUtil";
import { RadarImageBundle, RadarUtil } from "../Util/RadarUtil";

export interface ScrubImageSourceProps {
  beforeId: string;
  mapRef: Map;
  opacity: number;
  location: Location | undefined;
  radarType: radarTypes;
  viewport: Viewport;
  progress: number;
  handleLabelChange: (value: string) => void;
  handleLoadingState: (value: boolean) => void;
}

export default function ScrubImageSource(props: ScrubImageSourceProps) {
  const { beforeId, mapRef, opacity, location, radarType, viewport, progress, handleLabelChange, handleLoadingState } = props;
  const [coordinates, setCoordinates] = useState<number[][]>(RadarUtil.getCoordinates(mapRef));
  const [radarParams, setRadarParams] = useState(BaronUtil.getRadarParams(radarType, location));

  const { data: radarAnimList, isSuccess, isLoading } = useRadarAnimListQuery(radarParams, { pollingInterval: 180000 });
  const [currentUrl, setCurrentUrl] = useState<string>(RadarUtil.transparentImageUrl);
  const [imageBundles, setImageBundles] = useState<RadarImageBundle[]>([]);
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);

  //Update fullUrls and preload images when radarAnimList changes.
  useEffect(() => {
    if (radarAnimList) {
      setImagesLoaded(false);
      const urls = BaronUtil.getPaths(viewport, mapRef, radarAnimList)
      RadarUtil.preloadImages(urls).then((imageBundles) => {
        setImageBundles(imageBundles);
        setImagesLoaded(true);
      });
    }
  }, [radarAnimList])

  useEffect(() => {
    if (isLoading || !imagesLoaded) {
      handleLoadingState(true);
    }
    if (imagesLoaded) {
      handleLoadingState(false);
    }
  }, [isLoading, imagesLoaded])

  //Update radarParams when radarType or location change.
  useEffect(() => {
    const radarParams = BaronUtil.getRadarParams(radarType, location)
    setRadarParams(radarParams);
  }, [radarType, location]);

  //Update currentUrl when progress, viewport, or radarAnimList change.
  useEffect(() => {
    if (isSuccess && radarAnimList && imagesLoaded) {
      const currentBundle = imageBundles[BaronUtil.getCurrentBundle(imageBundles, progress)];
      setCurrentUrl(currentBundle.base64);
      handleLabelChange(BaronUtil.parseDateFromUrl(currentBundle.url, radarType));
    }
    setCoordinates(RadarUtil.getCoordinates(mapRef))
  }, [radarAnimList, isSuccess, viewport, mapRef, progress, imagesLoaded])

  return (
    <>
      {currentUrl != RadarUtil.transparentImageUrl && //Issue chaning currentUrl from transparency on first render. Only render component when currentUrl is not transparency.
        <Source coordinates={coordinates} id='scrub-image-radar' url={currentUrl} type='image'>
          <Layer beforeId={beforeId} {...{ ...animStyle, paint: { 'raster-fade-duration': 0, 'raster-opacity': opacity } }} />
        </Source>
      }
    </>
  )
}

const animStyle: LayerProps = {
  id: 'scrub-image-radar',
  type: 'raster',
  minzoom: 0,
  maxzoom: 14,
  paint: {
    'raster-fade-duration': 0,
  }
};
