/* eslint-disable react-hooks/exhaustive-deps */
import { Banner, BaseModal, Button } from '../Base';
import {
  OWS,
  useOwsTestAllClearMutation,
  useOwsTestDelayMutation,
  useOwsTestLightMutation,
  useOwsTurnOffMutation,
} from '../../Services/API';
import { SirenTests } from '../../Constants/Constants';
import LoadingButton from '../Base/LoadingButton';
import { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { openToast } from '../../features/toast/toastSlice';
import { useAppDispatch } from '../../app/hooks';

interface ModalProps {
  open: boolean;
  resetTesting: () => void;
  batchSirens: OWS[];
  testType?: SirenTests;
}

export function BatchTestModal(props: ModalProps) {
  const { open, resetTesting, batchSirens, testType } = props;

  const [
    testDelay,
    {
      data: delayResponse,
      isLoading: delayIsLoading,
      isError: delayIsError,
      isSuccess: delayIsSuccess,
      error: delayError,
    },
  ] = useOwsTestDelayMutation();
  const [
    testAllClear,
    {
      data: allClearResponse,
      isLoading: allClearIsLoading,
      isError: allClearIsError,
      isSuccess: allClearIsSuccess,
      error: allClearError,
    },
  ] = useOwsTestAllClearMutation();
  const [
    testLight,
    {
      data: lightResponse,
      isLoading: lightIsLoading,
      isError: lightIsError,
      isSuccess: lightIsSuccess,
      error: lightError,
    },
  ] = useOwsTestLightMutation();
  const [
    turnOff,
    { data: offResponse, isLoading: offIsLoading, isError: offIsError, isSuccess: offIsSuccess, error: offError },
  ] = useOwsTurnOffMutation();

  const dispatch = useAppDispatch();

  const [showWarning, setShowWarning] = useState(false);
  //add toast for success and error!
  const GetTestDuration = () => {
    let duration = 10;
    if (batchSirens && testType != null) {
      switch (testType) {
        case SirenTests.Delay:
          return (
            duration +
            batchSirens.reduce((a, b) => {
              return Math.max(a, b.frequency * b.duration);
            }, 0)
          );
        case SirenTests.AllClear:
          return (
            duration +
            batchSirens.reduce((a, b) => {
              return Math.max(a, b.allClearFrequency * b.allClearDuration);
            }, 0)
          );
        case SirenTests.Light:
          return 3;
      }
    }
    return undefined;
  };

  const handleClose = () => {
    resetTesting();
    setShowWarning(false);
  };

  const handleFinishLoading = () => {
    if (testType === SirenTests.AllClear) {
      handleStopTest();
    }
  };

  const handleStopTest = () => {
    let sirensToTurnOff = batchSirens.map(siren => {
      return siren.id;
    });
    turnOff(sirensToTurnOff);
    handleClose();
  };

  useEffect(() => {
    if (testType) {
      let sirensToTest = batchSirens.map(siren => {
        return siren.id;
      });
      switch (testType) {
        case SirenTests.Delay:
          setShowWarning(true);
          testDelay(sirensToTest);
          break;
        case SirenTests.AllClear:
          testAllClear(sirensToTest);
          break;
        case SirenTests.Light:
          setShowWarning(true);
          testLight(sirensToTest);
          break;
      }
    }
  }, [testType]);

  useEffect(() => {
    if (lightIsError && !lightIsLoading) {
      dispatch(openToast({ variant: 'error', header: 'Unable to test light for selected units.' }));
    }
    if (lightIsSuccess && !lightIsLoading) {
      dispatch(openToast({ variant: 'success', header: 'Successfully tested light for selected units.' }));
    }
  }, [lightIsError, lightIsSuccess]);

  useEffect(() => {
    if (delayIsError && !delayIsLoading) {
      dispatch(openToast({ variant: 'error', header: 'Unable to test Delay for selected units.' }));
    }
    if (delayIsSuccess && !delayIsLoading) {
      dispatch(openToast({ variant: 'success', header: 'Successfully tested Delay for selected units.' }));
    }
  }, [delayIsError, delayIsSuccess]);

  useEffect(() => {
    if (allClearIsError && !allClearIsLoading) {
      dispatch(openToast({ variant: 'error', header: 'Unable to test All Clear for selected units.' }));
    }
    if (allClearIsSuccess && !allClearIsLoading) {
      dispatch(openToast({ variant: 'success', header: 'Successfully tested All Clear for selected units.' }));
    }
  }, [allClearIsError, allClearIsSuccess]);

  useEffect(() => {
    if (offIsError && !offIsLoading) {
      dispatch(openToast({ variant: 'error', header: 'Unable to turn off tests for selected units.' }));
    }
    if (offIsSuccess && !offIsLoading) {
      dispatch(openToast({ variant: 'success', header: 'Successfully turned off tests for selected units.' }));
    }
  }, [offIsError, offIsSuccess]);

  const owsNames = () => {
    return batchSirens.map(siren => {
      return (
        <Typography textAlign='center' variant='h5'>
          {siren.locationName}
        </Typography>
      );
    });
  };

  return (
    <BaseModal modalState={{ open: open, handleOpen: () => {}, handleClose: () => handleClose() }}>
      <Typography textAlign='center' variant='h4'>
        OWS Test
      </Typography>
      <Divider />
      <Box marginTop={2}>
        {showWarning && <Banner variant='warn' message='Make sure to stop testing to turn off all OWS Systems.' />}
      </Box>
      <Grid container spacing={1} marginTop={1}>
        <Grid item xs={12}>
          {owsNames()}
        </Grid>
        <Grid item container flexDirection='column' xs={12} spacing={2}>
          <Grid item>
            <LoadingButton
              onClick={() => {}}
              isLoading={testType !== undefined}
              duration={GetTestDuration()}
              loadingText={`Running Test of ${testType}...`}
              finishedLoading={handleFinishLoading}></LoadingButton>
          </Grid>
          {testType && (
            <Grid item>
              <Button
                style={{ width: '100%' }}
                onClick={handleStopTest}
                type='clearBlue'
                disabled={testType === undefined}>
                <Typography>Stop Test</Typography>
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button style={{ width: '100%' }} onClick={handleClose} type='clear' disabled={testType === undefined}>
              <Typography>Cancel</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  );
}
