import { api, User } from '.';

export interface EmailSubscription {
  description: string;
  id: number;
  name: string;
  isDefault: boolean;
  suppressed: boolean;
}

export const ALERT_GROUP_ID = 22584;

const notificationUserApi = api.injectEndpoints({
  endpoints: builder => ({
    notiUsers: builder.query<User[], void>({
      query: () => 'notificationuser',
      transformResponse: (response: { data: User[] }) => response.data,
      providesTags: ['NotiUsers'],
    }),
    updateNotiUser: builder.mutation<string, User>({
      query: updatedUser => ({
        url: 'notificationuser/update',
        method: 'POST',
        body: updatedUser,
      }),
      transformResponse: (response: { data: string }) => response.data,
      invalidatesTags: ['NotiUsers'],
    }),
    createNotiUser: builder.mutation<{ message: string; user: User }, User>({
      query: newUser => ({
        headers: { ReturnUser: '' },
        url: 'notificationuser',
        method: 'POST',
        body: newUser,
      }),
      transformResponse: (response: { data: { message: string; user: User } }) => response.data,
      invalidatesTags: ['NotiUsers'],
    }),
    deleteNotiUser: builder.mutation<string, string>({
      query: userId => ({
        url: `notificationuser/${userId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: { data: string }) => response.data,
      invalidatesTags: ['NotiUsers'],
    }),
    emailSubscriptions: builder.query< EmailSubscription[], string>({
      query: userId => `notificationuser/${userId}/emailsubscriptions`,
      transformResponse: (response: { data: EmailSubscription[] }) => response.data,
    })
  }),
});

export const { useNotiUsersQuery, useUpdateNotiUserMutation, useCreateNotiUserMutation, useDeleteNotiUserMutation, useEmailSubscriptionsQuery } =
  notificationUserApi;
