import React, { useEffect, useState } from 'react';
import { Forecast } from '../../../Services/API';
import { Datum, Serie } from '@nivo/line';
import { max } from 'moment';
import BaseTimeChart from '../../Base/BaseTimeChart';
import moment from 'moment';

interface ForecastLineChartProps {
  forecastType: string;
  seriesType: string;
  hourlyForecastData: Forecast[] | undefined;
  dailyForecastData: Forecast[] | undefined;
  forecastColors: { [index: string]: string };
}

const ForecastLineChart = ({
  forecastType,
  seriesType,
  hourlyForecastData,
  dailyForecastData,
  forecastColors,
}: ForecastLineChartProps) => {
  const [serie, setHourlySerie] = useState<Serie[]>();
  const [dailySerie, setDailySerie] = useState<Serie[]>();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);

  useEffect(() => {
    if (hourlyForecastData) {
      let datumArr: Datum[] = [];

      datumArr = getForecastDatum(hourlyForecastData, seriesType, 'hourly');
      const temps = datumArr.map(datum => datum.y) as number[];

      if (forecastType == 'hourly') {
        if (seriesType === 'precipitation' || seriesType === 'snowAccumulation' || seriesType === 'windSpeed') {
          setMin(-1);
          setMax(Math.max.apply(Math, temps) + 5);
        } else {
          setMin(Math.min.apply(Math, temps) - 30);
          setMax(Math.max.apply(Math, temps) + 10);
        }
      }

      setHourlySerie([{ id: 'temp', data: datumArr }]);
    }
    if (dailyForecastData) {
      let datumArr: Datum[] = [];

      datumArr = getForecastDatum(dailyForecastData, seriesType, 'daily');
      const temps = datumArr.map(datum => datum.y) as number[];

      if (forecastType == 'daily') {
        if (seriesType === 'precipitation' || seriesType === 'snowAccumulation' || seriesType === 'windSpeed') {
          setMin(-1);
          setMax(Math.max.apply(Math, temps) + 5);
        } else {
          setMin(Math.min.apply(Math, temps) - 30);
          setMax(Math.max.apply(Math, temps) + 10);
        }
      }

      setDailySerie([{ id: 'temp', data: datumArr }]);
    }
  }, [forecastType, seriesType, hourlyForecastData, dailyForecastData]);

  return (
    <>
      {serie && dailySerie && (
        <BaseTimeChart
          style={{
            margin: 0,
            height: 225,
            width: forecastType === 'hourly' ? '2160px' : '420px',
            position: 'absolute',
            bottom: 0,
          }}
          chartMargin={{ top: 50, bottom: 15 }}
          curve={'natural'}
          min={min}
          max={max}
          lineWidth={3}
          data={forecastType === 'hourly' ? serie : dailySerie}
          enableAxes={false}
          enableGridX={false}
          enableActivePoint={false}
          colors={[forecastColors[seriesType]]}
          formatType={forecastType === 'hourly' ? 'hour' : 'day'}
        />
      )}
    </>
  );
};

export default ForecastLineChart;

const getForecastDatum = (forecastData: Forecast[], seriesType: string, forecastType: string) => {
  let datumArr: Datum[] = [];
  if (forecastType === 'hourly') {
    datumArr = forecastData.map(forecast => {
      let dateStr = '';
      var date = new Date(forecast.observationTime ? forecast.observationTime?.toString() : forecast.forecastStartTime?.toString());
      dateStr = moment(date).format('YYYY-MM-DD HH:mm:ss');
      var value = null;

      let feelsLike = forecast.feelsLike ? Math.round(forecast.feelsLike?.value) : '--';
      let windSpeed = forecast.windSpeed ? Math.round(forecast.windSpeed?.value) : '--';
      let precipitation = forecast.precipitation ? Math.round(forecast.precipitation?.value) : '--';
      let snowAccum = forecast.snowAccumulation ? Math.round(forecast.snowAccumulation?.value) : '--';
      let wbgt = forecast.wbgt ? Math.round(forecast.wbgt?.value) : '--';
      let ambientTemperature = forecast.ambientTemperature ? Math.round(forecast.ambientTemperature?.value) : '--';

      switch (seriesType) {
        case 'feelLike':
          value = feelsLike;
          break;
        case 'windSpeed':
          value = windSpeed;
          break;
        case 'precipitation':
          value = precipitation;
          break;
        case 'snowAccumulation':
          value = snowAccum;
          break;
        case 'wbgt':
          value = wbgt;
          break;
        case 'ambientTemperature':
          value = ambientTemperature;
          break;
      }

      return { x: dateStr, y: value };
    });
  }
  if (forecastType === 'daily') {
    datumArr = forecastData.map(forecast => {
      let dateStr = '';
      var d = new Date(forecast.observationTime.toString());
      dateStr =
        d.getFullYear() +
        '-' +
        (d.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        d.getDate().toString().padStart(2, '0');
      var value = null;

      let feelLike = forecast.feelLike ? Math.round(forecast.feelLike?.value) : '--';
      let windSpeed = forecast.windSpeed ? Math.round(forecast.windSpeed?.value) : '--';
      let precipitation = forecast.precipitation ? Math.round(forecast.precipitation?.value) : '--';
      let snowAccum = forecast.snowAccumulation ? Math.round(forecast.snowAccumulation?.value) : '--';
      let wbgt = forecast.wgbt ? Math.round(forecast.wgbt?.value) : '--';
      let tempHigh = forecast.temperatureHigh ? Math.round(forecast.temperatureHigh?.value) : '--';
      let tempLow = forecast.temperatureLow ? Math.round(forecast.temperatureLow?.value) : '--';

      switch (seriesType) {
        case 'feelLike':
          value = feelLike;
          break;
        case 'windSpeed':
          value = windSpeed;
          break;
        case 'precipitation':
          value = precipitation;
          break;
        case 'snowAccumulation':
          value = snowAccum;
          break;
        case 'wbgt':
          value = wbgt;
          break;
        case 'temperatureHigh':
          value = tempHigh
          break;
        case 'temperatureLow':
          value = tempLow;
          break;
      }

      return { x: dateStr, y: value };
    });
  }

  return datumArr;
};
