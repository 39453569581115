//2021-Night

export const ChanceOfThunderstormNight = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M2.55,5.5c-0.54-0.07-1.03-0.33-1.4-0.73c0.04,0,0.07,0,0.11,0c1.51,0,2.73-1.23,2.73-2.73
            c0-0.34-0.06-0.68-0.19-0.99c0.5,0.21,0.9,0.59,1.15,1.07c0.02,0.04,0.06,0.05,0.1,0.03c0.06-0.03,0.14-0.06,0.22-0.09
            c0.02-0.01,0.04-0.02,0.04-0.04c0.01-0.02,0.01-0.04,0-0.06c-0.36-0.72-1.02-1.24-1.8-1.43C3.38,0.5,3.27,0.48,3.16,0.47
            c-0.03,0-0.06,0.01-0.07,0.04C3.07,0.53,3.07,0.57,3.09,0.59c0.06,0.08,0.13,0.17,0.18,0.27C3.49,1.22,3.6,1.63,3.6,2.04
            c0,1.29-1.05,2.34-2.34,2.34c-0.15,0-0.3-0.01-0.45-0.04C0.71,4.31,0.6,4.28,0.5,4.24c-0.03-0.01-0.06,0-0.08,0.02
            C0.4,4.29,0.39,4.32,0.4,4.35c0.05,0.11,0.11,0.21,0.17,0.31c0.45,0.7,1.19,1.15,2.02,1.24c0,0,0.01,0,0.01,0
            c0.02,0,0.04-0.01,0.06-0.03C2.68,5.85,2.68,5.82,2.67,5.8C2.65,5.73,2.63,5.65,2.62,5.56C2.61,5.53,2.59,5.5,2.55,5.5z'
      />
      <path
        className='st1'
        d='M7.95,3.64c-0.23-0.9-1.03-1.52-1.96-1.52c-1.1,0-2,0.89-2.03,1.98C3.3,4.14,2.78,4.69,2.78,5.36
            c0,0.69,0.56,1.26,1.26,1.26h4.08c0.82,0,1.49-0.67,1.49-1.49C9.6,4.25,8.84,3.54,7.95,3.64z M4.34,4.15
            c0-0.91,0.74-1.65,1.65-1.65c0.81,0,1.5,0.58,1.62,1.39c0.01,0.05,0.04,0.1,0.08,0.13c0.04,0.03,0.1,0.04,0.15,0.03
            c0.72-0.18,1.38,0.38,1.38,1.08c0,0.61-0.5,1.11-1.11,1.11c0,0-0.01,0-0.01,0c-0.02,0-0.04,0-0.07,0h-4
            c-0.48,0-0.87-0.39-0.87-0.88s0.39-0.87,0.87-0.87c0.03,0,0.07,0,0.1,0c0.05,0.01,0.11-0.01,0.15-0.05
            c0.04-0.04,0.06-0.1,0.06-0.15C4.34,4.24,4.34,4.19,4.34,4.15z'
      />
    </g>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M4.01,7.16L3.59,7.99C3.55,8.08,3.59,8.2,3.68,8.25c0.03,0.01,0.06,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
            l0.42-0.83C4.4,7.24,4.36,7.13,4.27,7.08C4.18,7.03,4.06,7.07,4.01,7.16z'
      />
      <path
        className='st1'
        d='M8.45,7.16L8.03,7.99C7.99,8.08,8.02,8.2,8.12,8.25C8.15,8.26,8.18,8.27,8.2,8.27c0.07,0,0.14-0.04,0.17-0.1
            l0.42-0.83C8.84,7.24,8.8,7.13,8.71,7.08C8.61,7.03,8.5,7.07,8.45,7.16z'
      />
      <path
        className='st1'
        d='M5.49,7.16L5.07,7.99c-0.02,0.05-0.03,0.1-0.01,0.15c0.02,0.05,0.05,0.09,0.1,0.11
            c0.03,0.01,0.06,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.11l0.41-0.83c0.02-0.05,0.03-0.1,0.01-0.15C5.83,7.14,5.79,7.1,5.75,7.08
            C5.65,7.03,5.54,7.07,5.49,7.16z'
      />
      <path
        className='st1'
        d='M7.63,7.87C7.59,7.78,7.5,7.72,7.39,7.72H7.28l0.18-0.47c0.05-0.12,0-0.26-0.11-0.32
            C7.24,6.86,7.09,6.89,7.01,6.98L5.98,8.16C5.92,8.24,5.9,8.35,5.94,8.44C5.99,8.54,6.08,8.6,6.18,8.6h0.13L6.02,9.27
            c-0.05,0.12,0,0.26,0.11,0.33c0.04,0.03,0.09,0.04,0.14,0.04c0.08,0,0.16-0.04,0.21-0.1L7.6,8.15C7.67,8.07,7.68,7.96,7.63,7.87z
                M6.27,9.38l0.45-1.04H6.18l1.03-1.18L6.89,7.98h0.5L6.27,9.38z'
      />
    </g>
  </svg>
);
