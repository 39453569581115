import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tab,
  Typography,
  Box,
  useTheme,
  styled,
  TabProps,
} from '@mui/material';
import { TabContext, TabList, TabListProps, TabPanel } from '@mui/lab';
import { ExpandMore, WarningAmberRounded } from '@mui/icons-material';
import { PolicyAlertModel, useCachedPolicyAlertsForLocationQuery, useNwsAlertsQuery } from '../../Services/API';
import { useAppSelector } from '../../app/hooks';
import { getSelectedLocation } from '../../features/dash/dashSlice';
import { useEffect, useState } from 'react';
import { Card } from '../Base';
import { groupBy } from '../../utils/utils';
import { formatTimeStringFromMins } from '../../features/Time/TimeHelpers';
import React from 'react';
import { NWSAlertTabContainer } from './NWS/NWSAlertTabContainer';

interface PolicyDisplayModel {
  label: string;
  id: string;
  title: string;
  message: string;
  startTime: string;
  allClearMinutes: string;
  countingDown: boolean;
  allClearTime: string;
  lastNotifiedTime?: string;
  lastMessageSent?: string;
  remainingTime?: string;
}

const ScaryIcon = () => {
  const theme = useTheme();
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        marginRight: 1,
        width: 32,
        height: 32,
        borderRadius: 1,
        backgroundColor: theme.palette.error.main,
      }}>
      {/* backgroundColor: '#CB4F4E'}}> */}
      <WarningAmberRounded
        sx={{
          color: '#fff',
        }}
      />
    </Box>
  );
};

const StyledTabList = styled((props: TabListProps) => <TabList {...props} />)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}));

const NwsTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}));

const PolicyTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}));

export function AlertsCard() {
  const selectedLocation = useAppSelector(getSelectedLocation);
  const { data: nwsAlerts } = useNwsAlertsQuery(selectedLocation?.id || '', {
    skip: selectedLocation === undefined,
    pollingInterval: 60000,
  });
  const { data: policyViolations } = useCachedPolicyAlertsForLocationQuery(selectedLocation?.id || '', {
    skip: selectedLocation === undefined,
    pollingInterval: 60000,
  });

  const [selectedAlertTab, setSelectedAlertTab] = useState('');
  const [groupedPolicyViolations, setGroupedPolicyViolations] = useState<PolicyDisplayModel[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (nwsAlerts && nwsAlerts.length > 0)
      if (policyViolations && policyViolations.length <= 0) setSelectedAlertTab(nwsAlerts[0].Identifier);
    if (policyViolations && policyViolations.length > 0) {
      let groupedViolations = groupBy(policyViolations, 'policyShortName');
      let displayModels: PolicyDisplayModel[] = [];

      var dateOptions: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      for (let key in groupedViolations) {
        let violation: PolicyAlertModel[] = groupedViolations[key];

        let displayModel: PolicyDisplayModel = {
          label: violation[0].policyDisplayName,
          id: key,
          title: `${violation[0].policyDisplayName} Policy Warning`,
          message: '',
          startTime: '',
          allClearMinutes: '',
          allClearTime: '',
          countingDown: false,
        };

        let sortedViolations = violation.sort((x, y) =>
          violation[0].isDesc ? y.thresholdValue - x.thresholdValue : x.thresholdValue - y.thresholdValue
        );
        displayModel.message = `Your ${violation[0].policyDisplayName} policy(s) of `;

        // Find the first event that started
        const firstEvent = sortedViolations.reduce((acc, cur) => {
          return acc && cur.startedTime < acc.startedTime ? cur : acc;
        });

        displayModel.startTime = new Date(firstEvent.startedTime).toLocaleTimeString(navigator.language, dateOptions);

        // Find the event with the most recent notification
        const mostRecentNotification = sortedViolations.reduce((acc, cur) => {
          // Find the event that has started most recently and has a message
          if (!acc || (cur.startedTime > acc.startedTime && cur.message))
            return cur;

          return acc;
        });

        displayModel.lastNotifiedTime = new Date(mostRecentNotification.lastAlertTime).toLocaleTimeString(
          navigator.language,
          dateOptions
        );
        displayModel.lastMessageSent = mostRecentNotification.message;

        displayModel.allClearMinutes = sortedViolations[0].allClearMinutes.toString();
        displayModel.countingDown = sortedViolations[0].allClearTime ? true : false;
        // This part is a bit confusing.
        // First off, the double new Date is so that we can call getTime() doesn't work on the normal date object in sortedViolations.
        // Second, allClearTime is a bit misleading, it's when the all clear count down time has started, so the final alert time will be
        // at allClearTime + the allclearminutes.
        // allcleartime is null if it is in violation the last time we checked.
        displayModel.allClearTime = sortedViolations[0].allClearTime
          ? new Date(
              new Date(sortedViolations[0].allClearTime).getTime() + sortedViolations[0].allClearMinutes * 60000
            ).toLocaleTimeString(navigator.language, dateOptions)
          : new Date(
              new Date(sortedViolations[0].lastUpdateTime).getTime() + sortedViolations[0].allClearMinutes * 60000
            ).toLocaleTimeString(navigator.language, dateOptions);
        displayModel.remainingTime = sortedViolations[0].allClearTime
          ? formatTimeStringFromMins(
              (new Date(
                new Date(sortedViolations[0].allClearTime).getTime() + sortedViolations[0].allClearMinutes * 60000
              ).getTime() -
                new Date().getTime()) /
                60000,
              'remaining'
            )
          : undefined;

        sortedViolations.forEach((x: PolicyAlertModel) => {
          displayModel.message += `${x.thresholdValue}, `;
        });

        displayModel.message =
          displayModel.message.substr(0, displayModel.message.length - 2) + ' ' + violation[0].unit;

        displayModel.message += ' have been triggered.';
        displayModel.message += !sortedViolations[0]?.value
          ? ` Current ${violation[0].policyDisplayName} is ${sortedViolations[0]?.value?.toFixed(
              violation[0].precision
            )} ${violation[0].unit}.`
          : '';
        displayModel.message += ' Last update at ';
        displayModel.message +=
          new Date(sortedViolations[sortedViolations.length - 1].lastUpdateTime).toLocaleTimeString(
            navigator.language,
            dateOptions
          ) + '.';

        displayModels.push(displayModel);
      }
      if (displayModels.length > 0) {
        setSelectedAlertTab(displayModels[0].id);
        setGroupedPolicyViolations(displayModels);
      }
    } else setGroupedPolicyViolations([]);
  }, [nwsAlerts, policyViolations]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedAlertTab(newValue);
  };

  return (nwsAlerts && nwsAlerts.length > 0) || (policyViolations && policyViolations.length > 0) ? (
    <Grid item xs={12}>
      <Card scary={true}>
        <Accordion style={{ background: 'transparent' }} elevation={0} expanded={isExpanded}>
          <AccordionSummary
            sx={{ background: 'transparent', '& .MuiAccordionSummary-content': { overflow: 'hidden' } }}
            expandIcon={<ExpandMore onClick={() => setIsExpanded(!isExpanded)} />}>
            <Grid container>
              <Grid item container xs={12} onClick={() => setIsExpanded(!isExpanded)} wrap='nowrap'>
                <Grid item xs={false}>
                  <ScaryIcon />
                </Grid>
                <Grid item xs={11} display='flex' flexDirection='column' justifyContent='center'>
                  <Typography
                    textAlign='left'
                    overflow='hidden'
                    variant='subtitle1'
                    whiteSpace='nowrap'
                    textOverflow='ellipsis'>
                    {`Active Warnings for ${selectedLocation?.label}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} onClick={() => setIsExpanded(true)}>
                {selectedAlertTab !== '' && (
                <TabContext value={selectedAlertTab}>
                  <StyledTabList scrollButtons='auto' onChange={handleChange} variant='scrollable'>
                    {groupedPolicyViolations?.map(displayModel => (
                      <PolicyTab key={displayModel.id} label={displayModel.label} value={displayModel.id} />
                    ))}
                    {nwsAlerts?.map(nwsAlert => (
                      <NwsTab
                        key={nwsAlert.Identifier}
                        label={nwsAlert.Event}
                        value={nwsAlert.Identifier}
                      />
                    ))}
                  </StyledTabList>
                </TabContext>
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ background: 'transparent' }}>
            {selectedAlertTab !== '' && (
            <TabContext value={selectedAlertTab}>
              {groupedPolicyViolations?.map(displayModel => (
                <TabPanel key={displayModel.id} value={displayModel.id}>
                  <Box textAlign='left'>
                    <Typography variant='h6'>{displayModel.title}</Typography>
                    <hr />
                    <Typography variant='subtitle1'>{displayModel.message}</Typography>
                    <hr />
                    <ul style={{ listStyle: 'initial' }}>
                      <li>
                        <Typography variant='body2'>
                          <b>Warning started:</b> {displayModel.startTime}
                        </Typography>
                      </li>
                      {displayModel.lastMessageSent && displayModel.lastNotifiedTime && (
                        <>
                          <li>
                            <Typography variant='body2'>
                              <b>Last Notified:</b> {displayModel.lastNotifiedTime}
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              <b>Last Notification:</b> {displayModel.lastMessageSent}
                            </Typography>
                          </li>
                        </>
                      )}
                      <li>
                        <Typography variant='body2'>
                          <b>All Clear Minutes :</b> {displayModel.allClearMinutes} (Next possible clear:{' '}
                          {displayModel.allClearTime}
                          {displayModel.remainingTime && `, ${displayModel.remainingTime}`})
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </TabPanel>
              ))}
                {nwsAlerts?.map(nwsAlert => (
                <TabPanel key={nwsAlert.Identifier} value={nwsAlert.Identifier}>
                    <NWSAlertTabContainer nwsAlert={nwsAlert} />
                </TabPanel>
              ))}
            </TabContext>
            )}
          </AccordionDetails>
        </Accordion>
      </Card>
    </Grid>
  ) : (
    <></>
  );
}
