/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useEffect, useState } from 'react';
import {
  ModelStateErrorResponse,
  PAMessageErrorModel,
  usePaQuery,
  usePaStoredMessagesQuery,
  useSendPAMessageMutation,
} from '../../Services/API';
import {
  BaseModal,
  Button,
  Checkbox,
  MultiSelect,
  PWMenuItem,
  Select,
  SelectOption,
  SubFormContainer,
  TextArea,
} from '../Base';
import Link from '../Base/Link';
import { useDispatch } from 'react-redux';
import { openToast } from '../../features/toast/toastSlice';

interface ModalProps {
  onSubmit?: React.MouseEventHandler;
  onCancel?: React.MouseEventHandler;
  open: boolean;
  handleClose: Function;
  batchPASystems: string[];
}

interface InputRowProps {
  children: JSX.Element;
  label: string;
}

function InputRow(props: InputRowProps) {
  const { children, label } = props;

  return (
    <Grid container spacing={1} style={{ marginBottom: 16 }}>
      <Grid
        container
        item
        md={3}
        sm={12}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant='subtitle1'>{label}</Typography>
      </Grid>
      <Grid container item md={9} sm={12}>
        {children}
      </Grid>
    </Grid>
  );
}

const CustomMenuItem = (option: SelectOption, selectValues: any) => {
  let isSelected = selectValues.includes(option.value);

  return (
    <PWMenuItem key={option.value} value={option.value}>
      <Checkbox checked={isSelected} onChange={() => {}} />
      {option.text || option.value}
    </PWMenuItem>
  );
};

const PLAY_COUNT_MAXIMUM = 5;

export function SendPAMessageModal(props: ModalProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { open, handleClose, batchPASystems } = props;

  const { data: paStoredMessages } = usePaStoredMessagesQuery();
  const { data: paSystems } = usePaQuery();
  const [errorState, setErrorState] = useState<PAMessageErrorModel>({});

  const [
    sendMessage,
    {
      data: sendMessageResponse,
      isSuccess: sendMessageSuccess,
      isError: sendMessageError,
      error: sendMessageErrorResponse,
    },
  ] = useSendPAMessageMutation();

  const [message, setMessage] = useState('');
  const [repeatCount, setRepeatCount] = useState('1');

  const [paStoredMessageOptions, setPaStoredMessageOptions] = useState<SelectOption[]>([]);
  const [paSystemOptions, setPaSystemOptions] = useState<SelectOption[]>([]);

  const [selectedPaSystems, setSelectedPaSystems] = useState<string[]>(batchPASystems);
  const [responseModalOpen, setResponseModalOpen] = useState<boolean>(false);
  const [playCountLowerLimit, setPlayCountLowerLimit] = useState<number>(1);

  const onSelectStoredMessage = (value: string) => {
    setMessage(value);
  };

  const handleSend = () => {
    sendMessage({ pAList: selectedPaSystems, message: message, repeatCount: repeatCount });
  };

  useEffect(() => { 
    if(selectedPaSystems && selectedPaSystems.length > 0 && paSystems){
      let max = 1;

      selectedPaSystems.map((id) =>  {
        let paSystem = paSystems.find((pa) => pa.id.toString() === id.toString());
        if(paSystem && paSystem.defaultPlayCount > playCountLowerLimit){
          max = paSystem.defaultPlayCount;
        }
      })

      setPlayCountLowerLimit(max);
      if(max > parseInt(repeatCount)){
        setRepeatCount(max.toString());
      }
    }
    if(selectedPaSystems && selectedPaSystems.length === 0){
      setPlayCountLowerLimit(1);
      setRepeatCount('1');
    }
  }, [selectedPaSystems]);

  useEffect(() => {
    if (paStoredMessages) {
      let options = paStoredMessages.map(function (v) {
        return { value: v.message, text: v.title };
      });
      setPaStoredMessageOptions(options);
    }
  }, [paStoredMessages]);

  useEffect(() => {
    if (paSystems) {
      let options = paSystems.map(function (v) {
        return { value: v.id, text: v.name + ' - ' + v.ipAddress };
      });
      setPaSystemOptions(options);
    }
  }, [paSystems]);

  useEffect(() => {
    if (open && batchPASystems) {
      setSelectedPaSystems(batchPASystems);
    }
  }, [open]);

  useEffect(() => {
    if (sendMessageSuccess && sendMessageResponse) {
      setResponseModalOpen(true);
      setSelectedPaSystems([]);
      setMessage('');
      setRepeatCount('1');
    } else if (sendMessageError) {
      var paMessageErrors = (sendMessageErrorResponse as ModelStateErrorResponse)?.data.responseException
        ?.errors as PAMessageErrorModel;
      if (paMessageErrors) {
        setErrorState({ ...paMessageErrors });
        if (paMessageErrors.ServerError)
          dispatch(openToast({ variant: 'error', header: paMessageErrors.ServerError[0] }));
        else dispatch(openToast({ variant: 'error', header: 'Failed To Send Message' }));
      } else {
        dispatch(openToast({ variant: 'error', header: 'Failed To Send Message' }));
      }
    }
  }, [sendMessageSuccess, sendMessageError, sendMessageResponse, sendMessageErrorResponse]);

  const playCountOptions = [];
  for(let i = playCountLowerLimit; i <= PLAY_COUNT_MAXIMUM; i++){
    var optionText = i > 1 ? i.toString() + ' times' : i.toString() + ' time';
    playCountOptions.push({ value: i, text: optionText });
  }

  return (
    <BaseModal
      modalState={{
        open: open,
        handleOpen: () => {},
        handleClose: () => {
          setErrorState({});
          handleClose(false);
        },
      }}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} style={{ marginBottom: 8 }}>
          <Typography variant='h5'>Send Public Address (PA) Message</Typography>
          <Typography variant='body1' sx={{ color: theme.palette.text.secondary }}>
            Play a text-to-speech message through your selected Outdoor Warning System hardware.
          </Typography>
        </Grid>

        <Divider
          style={{ height: 1, width: '100%', borderColor: theme.palette.divider, marginTop: 8, marginBottom: 16 }}
        />

        <Grid container style={{ marginBottom: 16, width: '100%' }}>
          <Grid item sx={{ marginBottom: '8px' }}>
            <Typography variant='subtitle1'>Choose hardware unit(s)</Typography>
          </Grid>

          <Grid item xs={12}>
            <MultiSelect
              isError={errorState.PAList && errorState.PAList?.length > 0}
              helperText={errorState.PAList ? errorState.PAList[0] : undefined}
              placeholder='Select hardware unit(s)'
              value={selectedPaSystems}
              options={paSystemOptions}
              onChange={e => setSelectedPaSystems(e)}
              renderMenuItem={CustomMenuItem}
              renderValue={<Typography>{selectedPaSystems.length} hardware units selected</Typography>}
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: 16 }}>
          <SubFormContainer>
            <Typography variant='subtitle1' sx={{ marginBottom: '8px' }}>
              Choose a Saved Message
            </Typography>
            <Box style={{ marginBottom: 16 }}>
              <Select
                options={paStoredMessageOptions}
                placeholder='Select Saved Message'
                onChange={onSelectStoredMessage}
              />
            </Box>
            <Typography variant='subtitle1' sx={{ marginBottom: '8px' }}>
              Or, Type a Custom Message
            </Typography>
            <Box style={{ height: 100, marginBottom: 16 }}>
              <TextArea
                isError={message.length > 350 || (errorState.message && errorState.message.length > 0)}
                value={message}
                onChange={e => {
                  setMessage(e.target.value);
                  setErrorState({ ...errorState, message: undefined });
                }}
                helperText={
                  <Typography
                    variant={errorState.message ? 'caption' : 'overline'}
                    color={
                      errorState.message && errorState.message.length > 0
                        ? theme.palette.error.main
                        : 100 > 350 - message.length
                        ? 20 > 350 - message.length
                          ? theme.palette.error.main
                          : theme.palette.warning.main
                        : undefined
                    }>
                    {errorState.message && errorState.message.length > 0
                      ? errorState.message[0]
                      : `${350 - message.length} characters left`}
                  </Typography>
                }
              />
            </Box>
            <Grid container style={{ marginBottom: 16 }} display='flex' justifyContent='space-between'>
              <Grid item xs={12} display='flex' justifyContent='flex-end'>
                <Link href={'/PAStoredMessages'}>
                  <Typography>Go To Message Library</Typography>
                </Link>
              </Grid>
            </Grid>
          </SubFormContainer>
        </Grid>
        <Grid container style={{ marginBottom: 16 }}>
          <SubFormContainer>
            <InputRow label='Play Message'>
              <Select
                value={repeatCount}
                options={playCountOptions}
                onChange={e => setRepeatCount(e)}
              />
            </InputRow>
          </SubFormContainer>
        </Grid>
        <Grid container display='flex' flexDirection='row-reverse'>
          <Button type='primary' onClick={e => handleSend()}>
            <Typography variant='body1'> Send PA </Typography>
          </Button>
          <Button type='clear' style={{ marginRight: 12 }} onClick={() => handleClose(false)}>
            <Typography variant='body1'> Cancel </Typography>
          </Button>
        </Grid>
      </Grid>
      <BaseModal
        modalState={{
          open: responseModalOpen,
          handleOpen: () => {},
          handleClose: () => {
            setResponseModalOpen(false);
          },
        }}>
        <Grid>
          <Typography variant='h5'>PA Test Result</Typography>
          {sendMessageResponse && sendMessageResponse.some(response => response.status == 1) && (
            <>
              <Typography variant='h6' sx={{ color: theme.palette.success.main }}>
                Successful Messages:{' '}
              </Typography>
              {sendMessageResponse?.map(response => {
                if (response.status === 1) return <Typography>{response.paName}</Typography>;

                return <></>;
              })}
            </>
          )}

          {sendMessageResponse && sendMessageResponse.some(response => response.status == 0) && (
            <>
              <Typography variant='h6' sx={{ color: theme.palette.error.main }}>
                Failed Messages:{' '}
              </Typography>
              {sendMessageResponse?.map(response => {
                if (response.status === 0) return <Typography>{response.paName}</Typography>;

                return <></>;
              })}
            </>
          )}
        </Grid>
        <Grid container display='flex' flexDirection='row-reverse'>
          <Button type='primary' style={{ marginRight: 12 }} onClick={() => setResponseModalOpen(false)}>
            <Typography variant='body1'> OK </Typography>
          </Button>
        </Grid>
      </BaseModal>
    </BaseModal>
  );
}
