import { memo, useEffect, useState } from 'react';
import { useSitesQuery, useUserSitesQuery } from '../../Services/API';
import { Select, SelectOption } from '../Base';

interface SiteSelectProps {
  value: string;
  customerId: number;
  onSelect: (value: string, name: string) => void;
  isError?: boolean;
  disabled?: boolean;
}

export const SiteSelect = memo(function SiteSelect(props: SiteSelectProps) {
  const { value, onSelect, customerId, isError, disabled } = props;

  const [siteOptions, setSiteOptions] = useState<SelectOption[]>([]);

  const { data: userSites, isLoading: isUserSitesLoading } = useUserSitesQuery(customerId);
  const { data: sites, isLoading: isSitesLoading } = useSitesQuery();

  useEffect(() => {
    if (userSites && !isUserSitesLoading && userSites.length > 0)
      setSiteOptions(userSites.map(site => ({ value: site.id, text: site.name })));
    else if (!isSitesLoading && sites) {
      setSiteOptions(sites.map(site => ({ value: site.id, text: site.name })));
    }
  }, [isSitesLoading, sites, isUserSitesLoading, userSites]);

  return (
    <Select
      disabled={disabled}
      isError={isError}
      value={value}
      options={siteOptions}
      onChange={(v, n) => onSelect(v, n)}
    />
  );
});
