import { LngLat } from 'maplibre-gl';

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export const getDistance = (coord1: LngLat, coord2: LngLat) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(coord2.lat - coord1.lat); // deg2rad below
  var dLon = deg2rad(coord2.lng - coord1.lng);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(coord1.lat)) * Math.cos(deg2rad(coord2.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return Math.round(d * 0.621371); // return in miles rounded.;
};
