/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import {
  useSinglewireConfigQuery,
  useSinglewireMappingsQuery,
  useUserQuery
} from '../Services/API';
import { Button } from '../Components/Base';
import { useAuth } from 'oidc-react';
import { AddRounded, ArrowRightAlt } from '@mui/icons-material';
import { Redirect } from 'wouter';
import useDocumentTitle from '../Components/Base/useDocumentTitle';
import { ConfigureModal } from '../Components/Integrations/Singlewire/ConfigureModal';
import { UserRoles } from '../Constants/Constants';
import ScenariosTable from '../Components/Integrations/Singlewire/ScenariosTable';
import { ReactComponent as SinglewireLogo } from '../Assets/singlewirelogo.svg';
import { ReactComponent as PerryWeatherLogo } from '../Assets/pwlogo.svg';

export function IntegrationsSinglewire() {
  const { userData: user } = useAuth();
  const [configureModalOpen, setConfigureModalOpen] = useState(false);

  const { data: userData, isSuccess: isUserLoaded } = useUserQuery();
  const customerId = userData?.customerId ?? 0;
  const { data: existingSinglewireConfig, isLoading: isConfigLoading, isUninitialized } = useSinglewireConfigQuery(customerId, { skip: !isUserLoaded });
  const { data: mappings, isLoading: isMappingsLoading, isSuccess: isMappingsSuccess } = useSinglewireMappingsQuery(customerId, { skip: !isUserLoaded });

  useDocumentTitle('Singlewire Integration - Perry Weather');

  if (![UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.AssistantAdmin].includes(user?.profile?.Role)) {
    return <Redirect to="/Unauthorized" />;
  }

  if (isUninitialized || isConfigLoading || isMappingsLoading) {
    return (
      <Typography variant='body1' color='textPrimary'>
        Loading...
      </Typography>
    );
  }

  if (!existingSinglewireConfig) {
    return <Redirect to="/Integrations" />;
  }

  if (isMappingsSuccess && mappings?.length === 0) {
    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}>
        <Grid
          container
          margin={1}
          alignContent='center'
          alignItems='center'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          spacing={1}>
          <Grid item container justifyContent="center" alignItems="center" marginBottom={6}>
            <PerryWeatherLogo style={{ width: 85, height: 85 }} />
            <ArrowRightAlt style={{ width: 40, height: 40, marginRight: '10px'}} />
            <SinglewireLogo style={{ width: 85, height: 85 }} />
          </Grid>

          <Grid item>
            <Typography variant='h4' noWrap>
              You don't have any scenario triggers added
            </Typography>
          </Grid>

          <Grid item maxWidth={490}>
            <Typography variant='body1'>Add a scenario trigger to continue.</Typography>
          </Grid>

          <Grid item maxWidth={510}>
            <Button
              onClick={() => setConfigureModalOpen(true)}>
              <AddRounded />
              <Typography>Add Scenario Trigger</Typography>
            </Button>
          </Grid>
        </Grid>
        <ConfigureModal
          integrationConfig={existingSinglewireConfig}
          modalState={{
            open: configureModalOpen,
            handleClose: () => setConfigureModalOpen(false),
            handleOpen: () => false,
          }}
        />
      </div>
    );
  }

  return (
    <ScenariosTable mappings={mappings!} config={existingSinglewireConfig} customerId={customerId} />
  )

  
}
export default IntegrationsSinglewire;
