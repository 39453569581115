import { styled, Link as MuiLink, Theme } from '@mui/material';
import { Link as WouterLink } from 'wouter';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface BaseLinkProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
  inactive?: boolean;
  href?: string;
}

const ActiveLink = styled(MuiLink)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
}));

const InactiveLink = styled(MuiLink)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  color: theme.palette.text.secondary,
}));

export const styledLinkClass = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      /* unvisited link */
      '&:link': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },

      /* visited link */
      '&:visited': {
        color: theme.palette.primary.main,
      },

      /* mouse over link */
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
      },
    },
  })
);

export const subtleLinkClass = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      /* unvisited link */
      '&:link': {
        color: theme.palette.text.secondary,
        textDecoration: 'underline',
      },

      /* visited link */
      '&:visited': {
        color: theme.palette.text.secondary,
        textDecoration: 'underline',
      },

      /* mouse over link */
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

export function Link(props: BaseLinkProps) {
  const { children, onClick, href, inactive = false } = props;

  return href ? (
    <WouterLink to={href}>
      {inactive ? (
        <InactiveLink underline='hover' onClick={onClick}>
          {children}
        </InactiveLink>
      ) : (
        <ActiveLink underline='hover' onClick={onClick}>
          {children}
        </ActiveLink>
      )}
    </WouterLink>
  ) : (
    <>
      {inactive ? (
        <InactiveLink underline='hover' onClick={onClick}>
          {children}
        </InactiveLink>
      ) : (
        <ActiveLink underline='hover' onClick={onClick}>
          {children}
        </ActiveLink>
      )}
    </>
  );
}

export default Link;
