import { api } from './api';

export interface OrganizationLocation {
  id: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
}

export interface OrganizationLocationSite {
  id?: string;
  organizationLocationId: string;
  siteId: string;
}

const orgLocationApi = api.injectEndpoints({
  endpoints: builder => ({
    organizationLocations: builder.query<OrganizationLocation[], void>({
      query: () => 'organizationLocation',
      transformResponse: (response: { data: OrganizationLocation[] }) => response.data,
    }),
    customerOrgLocations: builder.query<OrganizationLocation[], number>({
      query: customerId => `organizationLocation/customer/${customerId}`,
      transformResponse: (response: { data: OrganizationLocation[] }) => response.data,
    }),
    userOrgLocations: builder.query<OrganizationLocation[], string>({
      query: userId => `organizationLocation/user/${userId}`,
      transformResponse: (response: { data: OrganizationLocation[] }) => response.data,
    }),
    notiUserOrgLocations: builder.query<OrganizationLocation[], string>({
      query: userId => `organizationLocation/NotiUser/${userId}`,
      transformResponse: (response: { data: OrganizationLocation[] }) => response.data,
    }),
    organizationLocationSites: builder.query<OrganizationLocationSite[], string>({
      query: orgLocationId => `organizationLocation/Sites/${orgLocationId}`,
      transformResponse: (response: { data: OrganizationLocationSite[] }) => response.data,
    }),
    addOrganizationLocationSite: builder.mutation<OrganizationLocationSite, OrganizationLocationSite>({
      query: newSite => ({
        url: 'organizationLocation/Sites',
        method: 'POST',
        body: newSite,
      }),
      transformResponse: (response: { data: OrganizationLocationSite }) => response.data,
    }),
    deleteOrganizationLocationSite: builder.mutation<string, string>({
      query: orgLocSiteId => ({
        url: `organizationLocation/Sites/${orgLocSiteId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: { message: string }) => response.message,
    }),
  }),
});

export const {
  useOrganizationLocationsQuery,
  useCustomerOrgLocationsQuery,
  useUserOrgLocationsQuery,
  useNotiUserOrgLocationsQuery,
  useOrganizationLocationSitesQuery,
  useAddOrganizationLocationSiteMutation,
  useDeleteOrganizationLocationSiteMutation,
} = orgLocationApi;
