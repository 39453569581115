import { Source, Layer, LayerProps, Marker, MapRef } from 'react-map-gl';
import circle from '@turf/circle';
import { useAppSelector } from '../../../../app/hooks';
import { getShowAllLocations } from '../../../../features/map/mapSlice';

export interface CircleProps {
    latitude: number;
    longitude: number;
    radius: number;
    index: number;
    color: string;
    sourceId: string;
    broadcast?: boolean;
    mapRef?: React.RefObject<MapRef>;
}

export function CircleLayer(props: CircleProps) {
    const { latitude, longitude, radius, color, sourceId, broadcast = false, mapRef } = props;
    var center = [longitude, latitude];
    const showAllLocations = useAppSelector(getShowAllLocations);

    // Use Memo here?
    const layerStyle: LayerProps = {
        id: sourceId,
        type: 'line',
        paint: {
            'line-color': color,
            'line-width': 2
        }
    };

    var zoom = mapRef?.current?.getMap()?.transform?._zoom;
    var circ = circle(center, radius, { steps: 32, units: 'miles', properties: null });

    const { latitude: markerLatitude, longitude: markerLongitude } = getNorthernmostPoint(latitude, longitude, radius);

    return (
        <Source id={sourceId} type="geojson" data={circ}>
            <Layer beforeId="admin_sub" {...layerStyle} />
            {broadcast && !showAllLocations && zoom > 7 &&  (
                <Marker offsetLeft={-25} latitude={markerLatitude} longitude={markerLongitude}>
                    <div
                        style={{
                            backgroundColor: color,
                            borderRadius: 50,
                            padding: 1,
                            paddingLeft: 10,
                            paddingRight: 10
                        }}>
                        {radius}mi
                    </div>
                </Marker>
            )}
        </Source>
    );
}

export default CircleLayer;

function getNorthernmostPoint(lat: number, lon: number, radiusMiles: number): { latitude: number; longitude: number } {
    function toRadians(degrees: number) {
        return degrees * (Math.PI / 180);
    }

    function toDegrees(radians: number) {
        return radians * (180 / Math.PI);
    }
    // Earth's radius in miles
    const EARTH_RADIUS = 3959;

    // Convert latitude and longitude to radians
    const latRad = toRadians(lat);

    // Angular distance in radians = radius / earth's radius
    const angularDistance = radiusMiles / EARTH_RADIUS;

    // The northernmost point will have the same longitude as the center point
    // Calculate the new latitude
    const newLatRad = latRad + angularDistance;

    // Convert back to degrees
    const newLat = toDegrees(newLatRad);

    return {
        latitude: newLat,
        longitude: lon
    };
}
