//9201

export const ThunderstormsAndFrozenPrecipitation = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M2.67,7L1.89,8.56C1.87,8.6,1.86,8.65,1.88,8.7c0.02,0.05,0.05,0.09,0.1,0.11C2,8.83,2.03,8.83,2.06,8.83
            c0.07,0,0.14-0.04,0.17-0.11l0.78-1.55c0.02-0.05,0.03-0.1,0.01-0.15s-0.05-0.09-0.1-0.11C2.83,6.87,2.71,6.91,2.67,7z'
      />
      <path
        className='st1'
        d='M5.62,6.91C5.57,6.92,5.53,6.96,5.51,7L4.73,8.56C4.68,8.65,4.72,8.77,4.82,8.81C4.84,8.83,4.87,8.83,4.9,8.83
            c0.07,0,0.14-0.04,0.17-0.11l0.78-1.55c0.02-0.04,0.03-0.1,0.01-0.15c-0.02-0.05-0.05-0.09-0.1-0.11C5.72,6.89,5.66,6.89,5.62,6.91
            z'
      />
      <path
        className='st1'
        d='M8.41,7L7.63,8.56C7.61,8.6,7.6,8.65,7.62,8.7c0.02,0.05,0.05,0.09,0.1,0.11C7.74,8.83,7.77,8.83,7.8,8.83
            c0.07,0,0.14-0.04,0.17-0.11l0.78-1.56c0.02-0.05,0.03-0.1,0.01-0.15c-0.02-0.05-0.05-0.09-0.1-0.11C8.57,6.87,8.46,6.91,8.41,7z'
      />
      <path
        className='st1'
        d='M4.41,7.4L4.34,7.33l0.08-0.08c0.08-0.08,0.08-0.2,0-0.28c-0.08-0.08-0.2-0.08-0.27,0L4.06,7.05L3.99,6.97
            c-0.08-0.08-0.2-0.08-0.27,0s-0.08,0.2,0,0.27l0.08,0.08L3.71,7.4c-0.08,0.08-0.08,0.2,0,0.27C3.75,7.71,3.8,7.73,3.85,7.73
            c0.05,0,0.1-0.02,0.14-0.06L4.06,7.6l0.08,0.08c0.04,0.04,0.08,0.06,0.14,0.06c0.05,0,0.1-0.02,0.14-0.06
            C4.49,7.6,4.49,7.48,4.41,7.4z'
      />
      <path
        className='st1'
        d='M3.78,9.02L3.7,8.95l0.08-0.08c0.08-0.08,0.08-0.2,0-0.28c-0.08-0.08-0.2-0.08-0.27,0L3.43,8.67L3.35,8.6
            c-0.08-0.08-0.2-0.08-0.27,0C3,8.67,3,8.8,3.08,8.87l0.08,0.08L3.08,9.02C3,9.1,3,9.22,3.08,9.3c0.04,0.04,0.09,0.06,0.14,0.06
            c0.05,0,0.1-0.02,0.14-0.06l0.08-0.08L3.5,9.3c0.04,0.04,0.08,0.06,0.14,0.06c0.05,0,0.1-0.02,0.14-0.06
            C3.86,9.22,3.85,9.1,3.78,9.02z'
      />
      <path
        className='st1'
        d='M7.79,3.45c-0.05,0-0.11,0-0.16,0.01C7.4,2.56,6.6,1.94,5.67,1.94c-1.1,0-1.99,0.88-2.02,1.97
            C3.01,3.96,2.49,4.5,2.49,5.16c0,0.69,0.56,1.25,1.25,1.25h4.05c0.82,0,1.48-0.67,1.48-1.48C9.27,4.11,8.61,3.45,7.79,3.45z
                M4.04,4.07c0-0.03,0-0.07,0-0.11c0-0.9,0.73-1.64,1.64-1.64c0.81,0,1.49,0.58,1.61,1.38c0.01,0.05,0.04,0.1,0.08,0.13
            c0.05,0.03,0.1,0.04,0.15,0.03C7.62,3.84,7.7,3.83,7.79,3.83c0.61,0,1.1,0.49,1.1,1.1s-0.49,1.1-1.1,1.1l-0.02,0
            c-0.02,0-0.04,0-0.06,0H3.74c-0.48,0-0.87-0.39-0.87-0.87c0-0.48,0.39-0.87,0.87-0.87c0.03,0,0.06,0,0.1,0.01
            c0.06,0,0.11-0.01,0.15-0.05c0.04-0.04,0.06-0.1,0.06-0.15L4.04,4.07z'
      />
      <path
        className='st1'
        d='M2.2,5.24C1.64,5.18,1.22,4.71,1.22,4.15c0-0.61,0.49-1.1,1.1-1.1c0.09,0,0.17,0.01,0.27,0.03
            c0.05,0.02,0.11,0,0.15-0.03c0.05-0.03,0.08-0.08,0.08-0.13c0.13-0.8,0.81-1.38,1.61-1.38c0.24,0,0.46,0.07,0.67,0.16
            c0.17-0.04,0.34-0.06,0.53-0.06c0.01,0,0.02,0,0.03,0C5.66,1.6,5.64,1.56,5.59,1.53C5.25,1.29,4.85,1.17,4.44,1.17
            c-0.93,0-1.72,0.62-1.95,1.51C2.43,2.67,2.38,2.67,2.32,2.67c-0.82,0-1.48,0.67-1.48,1.48c0,0.76,0.57,1.4,1.33,1.47l0.02,0
            c0.02,0,0.03-0.01,0.04-0.02C2.21,5.49,2.2,5.37,2.2,5.24z'
      />
      <path
        className='st1'
        d='M7.35,7.73h-0.3L7.3,7.09c0.02-0.06,0-0.14-0.06-0.17C7.22,6.9,7.19,6.9,7.17,6.9c-0.04,0-0.08,0.02-0.11,0.05
            L6.02,8.13C5.98,8.17,5.98,8.23,6,8.28s0.07,0.08,0.13,0.08h0.33L6.08,9.22c-0.03,0.06,0,0.14,0.06,0.18
            c0.02,0.01,0.05,0.02,0.07,0.02c0.04,0,0.08-0.02,0.11-0.05l1.14-1.4c0.03-0.04,0.04-0.1,0.02-0.15C7.45,7.76,7.4,7.73,7.35,7.73z
                M6.21,9.28l0.46-1.05H6.13l1.04-1.19L6.84,7.87h0.51L6.21,9.28z'
      />
    </g>
  </svg>
);
