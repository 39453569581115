export const PWLogo = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width='24' height='40' viewBox='0 0 24 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.9907 11.5297C23.9909 9.48024 23.4194 7.46788 22.3351 5.70064C21.2509 3.9334 19.6933 2.47541 17.8234 1.47737C15.3958 0.126163 12.5324 -0.318832 9.78295 0.227849C9.46969 0.290325 9.13032 0.365296 8.85622 0.452762C6.313 1.11616 4.06825 2.56177 2.46788 4.56683C0.867514 6.57189 0.000342211 9.02512 0 11.5485V11.8234C0 12.2732 4.77982e-06 12.723 0.045689 13.1666C0.27411 15.5907 1.17474 17.8585 2.0819 20.1077C4.41024 25.7149 7.07078 31.1904 10.0505 36.5076C10.7032 37.6509 11.3166 38.7817 12.0084 40C12.1781 39.7189 12.3021 39.5252 12.4131 39.319C14.26 35.8516 16.1657 32.4092 17.9408 28.9043C20.0902 24.8032 21.8971 20.5457 23.3446 16.1717C23.8071 14.7571 24.0275 13.2803 23.9973 11.7984C23.9973 11.7047 23.9973 11.6172 23.9973 11.5235L23.9907 11.5297Z'
      fill='url(#paint0_linear)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.676 2.90161L5.3253 13.9598H10.9118L7.93583 22.5315L18.7108 10.8485H13.3918L15.676 2.90161Z'
      fill={props.color}
    />
    <defs>
      <linearGradient id='paint0_linear' x1='28.4219' y1='-3.5' x2='2.81967e-07' y2='40' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FFE37E' />
        <stop offset='1' stopColor='#22BACF' />
      </linearGradient>
    </defs>
  </svg>
);
