import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import BaseControl from '../Controls/BaseControl';
import { RadarState } from './Radar';

interface PlayButtonProps {
    radarState: RadarState;
    handleRadarStateChange: (value: RadarState) => void;
}

export function PlayButton(props: PlayButtonProps) {
  const { radarState, handleRadarStateChange } = props;

  const playButtonHandleStateChange = () => {
    if (radarState === RadarState.PLAY) {
      handleRadarStateChange(RadarState.STATIC);
    } else {
      handleRadarStateChange(RadarState.PLAY);
    }
  }

  return (
    <div>
      <BaseControl
        onClick={playButtonHandleStateChange}
        label='Play Button'
      >
        {radarState != RadarState.PLAY ? <PlayArrowIcon /> : <PauseIcon />}
      </BaseControl>
    </div>
  );
}

export default PlayButton;
