/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Button as MuiButton, Divider, Grid, styled, Typography, useMediaQuery, Skeleton } from '@mui/material';
import BaseTimeChart from '../../Base/BaseTimeChart';
import { useTheme } from '@mui/styles';
import { Datum, Serie } from '@nivo/line';
import {
    Forecast,
    useForecastDailyForLocationQuery,
    useForecastHourlyForLocationQuery,
    WeatherCode,
} from '../../../Services/API';
import { Globe, RainDropOutline, Thermometer, Wind } from '../../../Assets';
import { useRef, useState, useEffect } from 'react';
import Link from '../../Base/Link';
import { DaysOfWeek, Month } from '../../../features/Time/TimeConstant';
import { Vector } from '../../../Assets/Vector';
import WeatherIcon from './WeatherIcon';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Card } from '../../Base';
import { useAppSelector } from '../../../app/hooks';
import { getSelectedLocation } from '../../../features/dash/dashSlice';
import { SxProps } from '@mui/system';
import { AcUnit } from '@mui/icons-material';
import ForecastLineChart from './ForecastLineChart';

const weatherCodeSuffix = ['06', '11', '16', '17', '18', '24', '25', '26'];

const ScrollButtonRight = styled(MuiButton)(({ theme }) => ({
    height: '100%',
    borderRadius: 0,
    color: 'transparent',
    '&:hover': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        background: `linear-gradient(90deg, ${theme.palette.background.default}00 00%, ${theme.palette.background.default}FF 80%)`,
        color: theme.palette.text.primary,
    },
    '&.Mui-disabled': {
        color: 'transparent',
    },
}));

const ScrollButtonLeft = styled(MuiButton)(({ theme }) => ({
    height: '100%',
    borderRadius: 0,
    color: 'transparent',
    '&:hover': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        background: `linear-gradient(270deg, ${theme.palette.background.default}00 00%, ${theme.palette.background.default}FF 80%)`,
        color: theme.palette.text.primary,
    },
    '&.Mui-disabled': {
        color: 'transparent',
    },
}));
// background: "linear-gradient(196.4deg, #FFE37E -79.78%, #22BACF 134.73%), linear-gradient(207.43deg, #FFE37E -10.71%, #22BACF 131.76%)",

interface ScrollProps {
    scrollRight: boolean;
    scrollLeft: boolean;
}

interface ScrollingState {
    isScrolling: boolean;
    clientX: number;
    scrollX: number;
}

export default function ForecastBreakdown() {
    const theme = useTheme();
    const selectedLocation = useAppSelector(getSelectedLocation);
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const { data: hourlyData, isFetching: isFetchingHourlyForecast } = useForecastHourlyForLocationQuery(selectedLocation ? selectedLocation.id || '' : '', {
        skip: selectedLocation === undefined,
        pollingInterval: 15 * 60 * 1000 // 15 minutes
    });
    const { data: dailyData, isFetching: isFetchingDailyForecast } = useForecastDailyForLocationQuery(selectedLocation ? selectedLocation.id || '' : '', {
        skip: selectedLocation === undefined,
        pollingInterval: 6 * 60 * 60 * 1000 // 6 hours
    });
    const [forecastType, setForecastType] = useState('hourly');
    const [maxBound, setMaxBound] = useState(100);
    const [minBound, setMinBound] = useState(0);
    const [hasSnowInForecast, setHasSnowInForecast] = useState(false);
    const [seriesType, setSeriesType] = useState('feelLike');

    const [scrollProps, setScrollProps] = useState<ScrollProps>({ scrollLeft: false, scrollRight: false });
    const [scrollingState, setScrollingState] = useState<ScrollingState>({ isScrolling: false, clientX: 0, scrollX: 0 });

    const forecastColors: { [index: string]: string } = {
        feelLike: '#FFE37E',
        windSpeed: '#979c9b',
        precipitation: '#22BACF',
        snowAccumulation: '#8cdbda',
        wbgt: '#d47844',
        ambientTemperature: '#FFE37E',
        temperatureHigh: '#FFE37E',
        temperatureLow: '#FFE37E',
    };

    const ref = useRef<HTMLDivElement>(null);

    const value: string = '1';
    const shadowTextSxProp: SxProps = {
        textShadow: `-${value}px -${value}px 0 ${theme.palette.background.paper},  ${value}px -${value}px 0 ${theme.palette.background.paper}, -${value}px ${value}px 0 ${theme.palette.background.paper}, ${value}px ${value}px 0 ${theme.palette.background.paper};`,
    };
    // const shadowTextSxProp: SxProps  = { textShadow: `-${value}px -${value}px 0 ${theme.palette.background.paper},  ${value}px -${value}px 0 ${theme.palette.background.paper}, -${value}px ${value}px 0 ${theme.palette.background.paper}, ${value}px ${value}px 0 ${theme.palette.background.paper};` };

    const onSeriesSelect = (seriesType: string) => {
        setSeriesType(seriesType);
    };

    const weatherData = () => {
        let forecastData: Forecast[] = [];
        if (forecastType === 'hourly' && hourlyData) {
            forecastData = hourlyData;
        } else if (forecastType === 'daily' && dailyData) {
            forecastData = dailyData;
        }

        let data: JSX.Element[] = [];

        if (forecastType === 'hourly') {
            forecastData.forEach(forecast => {
                var date = new Date(forecast.observationTime ? forecast.observationTime?.toString() : forecast.forecastStartTime?.toString());
                var dayOfWeek = date.getDay();
                var hours = date.getHours();
                var hourStr = '';
                var meridianStr = '';

                if (hours === 0) {
                    hourStr = '12';
                    meridianStr = ' AM';
                } else if (hours > 12) {
                    hourStr = (hours - 12).toString();
                    meridianStr = ' PM';
                } else if (hours === 12) {
                    hourStr = '12';
                    meridianStr = ' PM';
                } else {
                    hourStr = hours.toString();
                    meridianStr = ' AM';
                }

                let weatherCode = forecast.weatherCode ? forecast.weatherCode?.value : '9999';
                let ambientTemperature = forecast.ambientTemperature ? Math.round(forecast.ambientTemperature?.value) : '--';
                let precip = forecast.precipitation ? Math.round(forecast.precipitation?.value * 100) / 100 : '--';
                let feelLike = forecast.feelsLike ? Math.round(forecast.feelsLike?.value) : '--';
                let wbgt = forecast.wbgt ? Math.round(forecast.wbgt?.value) : '--';
                let windSpeed = forecast.windSpeed ? Math.round(forecast.windSpeed?.value) : '--';
                let snowAccumulation = forecast.snowAccumulation ? Math.round(forecast.snowAccumulation!.value * 100) / 100 : '--';

                data.push(
                    <Grid
                        key={forecast.observationTime.toString()}
                        item
                        display='flex'
                        flexDirection='column'
                        height={'100%'}
                        justifyContent='space-between'
                        width={'60px'}>
                        <Grid item>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='h5' sx={shadowTextSxProp}>
                                    {DaysOfWeek[dayOfWeek]}
                                </Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='caption' sx={shadowTextSxProp}>
                                    {hourStr + meridianStr}
                                </Typography>
                            </Grid>
                            {forecast.precipitationChance && forecast.precipitationChance.value > 0 ? (
                                <Grid item display='flex' alignItems='center' justifyContent='center'>
                                    <RainDropOutline width={10} height={10} color={theme.palette.text.primary} />
                                    <Typography sx={shadowTextSxProp}>{Math.round(forecast.precipitationChance?.value)}%</Typography>
                                </Grid>
                            ) : (
                                <Grid item display='flex' height={24}></Grid>
                            )}
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginTop={1}>
                                <WeatherIcon code={weatherCode} isDaylight={forecast.dayLight} />
                            </Grid>
                        </Grid>
                        <Grid item display='flex' flexDirection='column' height={150} justifyContent='flex-end'>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                {/* <Typography sx={{ WebkitTextStroke: `1px ${theme.palette.background.paper}`}}>{Math.round(forecast.ambientTemperature!.value)}°</Typography> */}
                                <Typography sx={shadowTextSxProp}>{ambientTemperature}°</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                <Typography sx={shadowTextSxProp}>{precip}"</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                <Typography sx={shadowTextSxProp}>{feelLike}°</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                <Typography sx={shadowTextSxProp}>{wbgt}</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                <Vector transform={`rotate(${forecast.windDirection} 5 5.5)`} />
                                <Typography marginLeft={0.25}>{windSpeed}</Typography>
                            </Grid>
                            {hasSnowInForecast && (
                                <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                    <Typography sx={shadowTextSxProp}>
                                        {snowAccumulation}"
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                );
            });
        } else if (forecastType === 'daily') {
            forecastData.forEach(forecast => {
                var date = new Date(forecast.observationTime ? forecast.observationTime?.toString() : forecast.forecastStartTime?.toString());
                var month = date.getMonth() + 1;
                var day = date.getDate();
                var dayOfWeek = date.getDay();

                let weatherCode = forecast.weather_code ? forecast.weather_code?.value : '9999';
                let tempHigh = forecast.temperatureHigh ? Math.round(forecast.temperatureHigh?.value) : '--';
                let tempLow = forecast.temperatureLow ? Math.round(forecast.temperatureLow?.value) : '--';
                let precipitationValue = forecast.precipitation ? (Math.round(forecast.precipitation.value * 100) / 100) : '--';
                let feelsLike = forecast.feelLike ? Math.round(forecast.feelLike.value) : '--';
                let wbgt = forecast.wgbt ? Math.round(forecast.wgbt.value) : '--';
                let windSpeed = forecast.windSpeed ? Math.round(forecast.windSpeed.value) : '--';
                let snowAccumulation = forecast.snowAccumulation ? (Math.round(forecast.snowAccumulation!.value * 100) / 100) : '--';

                data.push(
                    <Grid
                        key={forecast.observationTime.toString()}
                        item
                        display='flex'
                        flexDirection='column'
                        height={'100%'}
                        justifyContent='space-between'
                        width={'60px'}>
                        <Grid item>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='h5' sx={shadowTextSxProp}>
                                    {DaysOfWeek[dayOfWeek]}
                                </Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='caption' sx={shadowTextSxProp}>
                                    {Month[month] + ' ' + day}
                                </Typography>
                            </Grid>
                            {forecast.precipitationChance && forecast.precipitationChance.value > 0 ? (
                                <Grid item display='flex' alignItems='center' justifyContent='center'>
                                    <RainDropOutline width={10} height={10} color={theme.palette.text.primary} />
                                    <Typography sx={shadowTextSxProp}>{Math.round(forecast.precipitationChance?.value)}%</Typography>
                                </Grid>
                            ) : (
                                <Grid item display='flex' height={24}></Grid>
                            )}
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginTop={1}>
                                <WeatherIcon code={weatherCode} isDaylight={true} />
                            </Grid>
                        </Grid>
                        <Grid item display='flex' flexDirection='column' height={'100%'} justifyContent='flex-end'>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                <Typography sx={shadowTextSxProp}>{tempHigh}°</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                <Typography sx={shadowTextSxProp}>{tempLow}°</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                <Typography sx={shadowTextSxProp}>{precipitationValue}"</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                <Typography sx={shadowTextSxProp}>{feelsLike}°</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                <Typography sx={shadowTextSxProp}>{wbgt}</Typography>
                            </Grid>
                            <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                <Vector transform={`rotate(${forecast.windDirection} 5 5.5)`} />
                                <Typography sx={shadowTextSxProp} marginLeft={0.25}>
                                    {windSpeed}
                                </Typography>
                            </Grid>
                            {hasSnowInForecast && (
                                <Grid item display='flex' alignItems='center' justifyContent='center' marginBottom={1}>
                                    <Typography sx={shadowTextSxProp}>
                                        {snowAccumulation}"
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                );
            });
        }

        return data;
    };

    const onScroll = () => {
        if (ref !== null && ref.current !== null) {
            var shouldScrollLeft = ref.current.scrollLeft !== 0;
            var shouldScrollRight = ref.current.scrollWidth - ref.current.clientWidth !== ref.current.scrollLeft;

            if (scrollProps.scrollLeft !== shouldScrollLeft || scrollProps.scrollRight !== shouldScrollRight)
                setScrollProps({ scrollLeft: shouldScrollLeft, scrollRight: shouldScrollRight });
        }
    };

    const scroll = (scrollOffset: number) => {
        if (ref !== null && ref.current !== null) {
            ref.current.scrollLeft += scrollOffset;
        }
    };

    const onMouseDown = (event: any) => {
        setScrollingState({ ...scrollingState, isScrolling: true, clientX: event.clientX });
    };

    const onMouseUp = (event: any) => {
        setScrollingState({ ...scrollingState, isScrolling: false });
    };

    const onMouseMove = (event: any) => {
        const { clientX, scrollX } = scrollingState;

        if (scrollingState.isScrolling && ref !== null && ref.current !== null) {
            let scrollLeft = scrollX + clientX - event.clientX;

            if (scrollLeft < 0) scrollLeft = 0;

            if (ref.current.scrollWidth - ref.current.clientWidth < scrollLeft)
                scrollLeft = ref.current.scrollWidth - ref.current.clientWidth;

            ref.current.scrollLeft = scrollLeft;
            setScrollingState({ ...scrollingState, scrollX: scrollLeft, clientX: event.clientX });
        }
    };

    useEffect(() => {
        if (ref !== null && ref.current !== null) {
            var shouldScrollLeft = ref.current.scrollLeft !== 0;
            var shouldScrollRight = ref.current.scrollWidth - ref.current.clientWidth !== ref.current.scrollLeft;

            if (shouldScrollLeft !== scrollProps.scrollLeft || shouldScrollRight !== scrollProps.scrollRight)
                setScrollProps({ scrollLeft: shouldScrollLeft, scrollRight: shouldScrollRight });
        }
    }, [dailyData, hourlyData]);

    useEffect(() => {
        var hasHourlySnow = false;
        var hasDailySnow = false;

        if (hourlyData) {
            hasHourlySnow = hourlyData.some((value) => value.snowAccumulation && value.snowAccumulation?.value > 0);

            if (forecastType === 'hourly' && (seriesType === 'temperatureHigh' || seriesType === 'temperatureLow'))
                setSeriesType('feelLike');
        }

        if (dailyData) {
            hasDailySnow = dailyData.some((value) => value.snowAccumulation && value.snowAccumulation?.value > 0);

            if (forecastType === 'daily' && seriesType === 'ambientTemperature')
                setSeriesType('feelLike');

            dailyData.forEach(val => {
                if (val.weather_code && weatherCodeSuffix.includes(val.weather_code.value.slice(-2)))
                    hasDailySnow = true;
            }, false);
        }

        setHasSnowInForecast(hasDailySnow || hasHourlySnow);
    }, [hourlyData, dailyData, forecastType, seriesType]);

    return (
        <Card header='Forecast Breakdown'>
            <Grid marginBottom={1} marginTop={-6} display='flex' justifyContent='flex-end'>
                <Link
                    inactive={forecastType !== 'hourly'}
                    onClick={() => {
                        setForecastType('hourly');
                    }}>
                    <Typography>Hourly</Typography>
                </Link>
                <Divider style={{ height: 16, width: 1, borderColor: theme.palette.divider, margin: 16 }} />
                <Link
                    inactive={forecastType !== 'daily'}
                    onClick={() => {
                        setForecastType('daily');
                    }}>
                    <Typography>Daily</Typography>
                </Link>
            </Grid>
            {(isFetchingHourlyForecast && forecastType === 'hourly') || (isFetchingDailyForecast && forecastType === 'daily') ? (
                <Box display='flex' justifyContent='space-evenly'>
                    <Skeleton variant='rectangular' width='100%' height={300} />
                </Box>
            ) : (hourlyData && forecastType === 'hourly') || (dailyData && forecastType === 'daily') ? (
                <Grid container height={340} position='relative'>
                    <Grid item display='flex' maxWidth='80px' width='80px' flexDirection='row' height='100%'>
                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                            {forecastType === 'hourly' ? (
                                <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                    <Button
                                        onClick={() => onSeriesSelect('ambientTemperature')}
                                        variant='text'
                                        sx={{
                                            color: seriesType === 'ambientTemperature' ? forecastColors[seriesType] : 'white',
                                            padding: 0,
                                            textTransform: 'none',
                                            '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                        }}>
                                        <Box width={20} height={20}>
                                            <Thermometer
                                                width={20}
                                                height={20}
                                                color={
                                                    seriesType === 'ambientTemperature'
                                                        ? forecastColors[seriesType]
                                                        : theme.palette.action.inactive
                                                }
                                            />
                                        </Box>
                                        <Typography
                                            variant='body1'
                                            marginLeft={1}
                                            marginRight={1}
                                            color={
                                                seriesType === 'ambientTemperature' ? forecastColors[seriesType] : theme.palette.text.secondary
                                            }>
                                            Temp
                                        </Typography>
                                    </Button>
                                </Box>
                            ) : (
                                <>
                                    <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                        <Button
                                            onClick={() => onSeriesSelect('temperatureHigh')}
                                            variant='text'
                                            sx={{
                                                color: seriesType === 'temperatureHigh' ? forecastColors[seriesType] : 'white',
                                                padding: 0,
                                                textTransform: 'none',
                                                '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                            }}>
                                            <Box width={20} height={20}>
                                                <Thermometer
                                                    width={20}
                                                    height={20}
                                                    color={
                                                        seriesType === 'temperatureHigh'
                                                            ? forecastColors[seriesType]
                                                            : theme.palette.action.inactive
                                                    }
                                                />
                                            </Box>
                                            <Typography
                                                variant='body1'
                                                marginLeft={1}
                                                marginRight={1}
                                                color={
                                                    seriesType === 'temperatureHigh' ? forecastColors[seriesType] : theme.palette.text.secondary
                                                }>
                                                High
                                            </Typography>
                                        </Button>
                                    </Box>
                                    <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                        <Button
                                            onClick={() => onSeriesSelect('temperatureLow')}
                                            variant='text'
                                            sx={{
                                                color: seriesType === 'temperatureLow' ? forecastColors[seriesType] : 'white',
                                                padding: 0,
                                                textTransform: 'none',
                                                '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                            }}>
                                            <Box width={20} height={20}>
                                                <Thermometer
                                                    width={20}
                                                    height={20}
                                                    color={
                                                        seriesType === 'temperatureLow' ? forecastColors[seriesType] : theme.palette.action.inactive
                                                    }
                                                />
                                            </Box>
                                            <Typography
                                                variant='body1'
                                                marginLeft={1}
                                                marginRight={1}
                                                color={
                                                    seriesType === 'temperatureLow' ? forecastColors[seriesType] : theme.palette.text.secondary
                                                }>
                                                Low
                                            </Typography>
                                        </Button>
                                    </Box>
                                </>
                            )}
                            <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                <Button
                                    onClick={() => onSeriesSelect('precipitation')}
                                    variant='text'
                                    sx={{
                                        color: seriesType === 'precipitation' ? forecastColors[seriesType] : 'white',
                                        padding: 0,
                                        textTransform: 'none',
                                        '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                    }}>
                                    <Box width={20} height={20}>
                                        <RainDropOutline
                                            width={20}
                                            height={20}
                                            color={
                                                seriesType === 'precipitation' ? forecastColors[seriesType] : theme.palette.action.inactive
                                            }
                                        />
                                    </Box>
                                    <Typography
                                        variant='body1'
                                        marginLeft={1}
                                        marginRight={1}
                                        color={seriesType === 'precipitation' ? forecastColors[seriesType] : theme.palette.text.secondary}>
                                        Precip
                                    </Typography>
                                </Button>
                            </Box>
                            <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                <Button
                                    onClick={() => onSeriesSelect('feelLike')}
                                    variant='text'
                                    sx={{
                                        color: seriesType === 'feelLike' ? forecastColors[seriesType] : 'white',
                                        padding: 0,
                                        textTransform: 'none',
                                        '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                    }}>
                                    <Box width={20} height={20}>
                                        <Thermometer
                                            width={20}
                                            height={20}
                                            color={seriesType === 'feelLike' ? forecastColors[seriesType] : theme.palette.action.inactive}
                                        />
                                    </Box>
                                    <Typography
                                        variant='body1'
                                        marginLeft={1}
                                        marginRight={1}
                                        color={seriesType === 'feelLike' ? forecastColors[seriesType] : theme.palette.text.secondary}>
                                        Feels
                                    </Typography>
                                </Button>
                            </Box>
                            <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                <Button
                                    onClick={() => onSeriesSelect('wbgt')}
                                    variant='text'
                                    sx={{
                                        color: seriesType === 'wbgt' ? forecastColors[seriesType] : 'white',
                                        padding: 0,
                                        textTransform: 'none',
                                        '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                    }}>
                                    <Box width={20} height={20}>
                                        <Globe
                                            width={20}
                                            height={20}
                                            color={seriesType === 'wbgt' ? forecastColors[seriesType] : theme.palette.action.inactive}
                                        />
                                    </Box>
                                    <Typography
                                        variant='body1'
                                        marginLeft={1}
                                        marginRight={1}
                                        color={seriesType === 'wbgt' ? forecastColors[seriesType] : theme.palette.text.secondary}>
                                        WBGT
                                    </Typography>
                                </Button>
                            </Box>
                            <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                <Button
                                    onClick={() => onSeriesSelect('windSpeed')}
                                    variant='text'
                                    sx={{
                                        color: seriesType === 'windSpeed' ? forecastColors[seriesType] : 'white',
                                        padding: 0,
                                        textTransform: 'none',
                                        '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                    }}>
                                    <Box width={20} height={20}>
                                        <Wind
                                            width={20}
                                            height={20}
                                            color={seriesType === 'windSpeed' ? forecastColors[seriesType] : theme.palette.action.inactive}
                                        />
                                    </Box>
                                    <Typography
                                        variant='body1'
                                        marginLeft={1}
                                        marginRight={1}
                                        color={seriesType === 'windSpeed' ? forecastColors[seriesType] : theme.palette.text.secondary}>
                                        Wind
                                    </Typography>
                                </Button>
                            </Box>
                            {hasSnowInForecast && (
                                <Box marginBottom={1} display='flex' justifyContent='flex-start' alignItems='center'>
                                    <Button
                                        onClick={() => onSeriesSelect('snowAccumulation')}
                                        variant='text'
                                        sx={{
                                            color: seriesType === 'snowAccumulation' ? forecastColors[seriesType] : 'white',
                                            padding: 0,
                                            textTransform: 'none',
                                            '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                                        }}>
                                        <Box width={20} height={20}>
                                            <AcUnit
                                                width={20}
                                                height={20}
                                                sx={{
                                                    color:
                                                        seriesType === 'snowAccumulation'
                                                            ? forecastColors[seriesType]
                                                            : theme.palette.action.inactive,
                                                }}
                                            />
                                        </Box>
                                        <Typography
                                            variant='body1'
                                            marginLeft={1}
                                            marginRight={1}
                                            color={
                                                seriesType === 'snowAccumulation' ? forecastColors[seriesType] : theme.palette.text.secondary
                                            }>
                                            Snow
                                        </Typography>
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Grid>

                    {!scrollingState.isScrolling && !isXs && (
                        <Box position='absolute' width='50px' left='78px' height='100%' zIndex={5}>
                            <ScrollButtonLeft disabled={!scrollProps.scrollLeft} onClick={() => scroll(-240)}>
                                <KeyboardArrowLeftIcon sx={{ width: 50, height: 50 }} />
                            </ScrollButtonLeft>
                        </Box>
                    )}

                    {!scrollingState.isScrolling && !isXs && (
                        <Box position='absolute' width='50px' right='16px' height='100%' zIndex={5}>
                            <ScrollButtonRight disabled={!scrollProps.scrollRight} onClick={() => scroll(240)}>
                                <KeyboardArrowRightIcon sx={{ width: 50, height: 50 }} />
                            </ScrollButtonRight>
                        </Box>
                    )}

                    <Grid
                        ref={ref}
                        className='hidden-scrollbar noselect '
                        item
                        sx={{
                            flexGrow: 1,
                            position: 'relative',
                            height: 'inherit',
                            overflowX: 'hidden',
                            overflowY: 'hidden',
                            scrollbarWidth: 'none',
                            '&::webkit-scrollbar-thumb': { display: 'none' },
                            '&:hover': { overflowX: 'scroll' },
                            '&::-webkit-scrollbar': { display: 'none !important' },
                            cursor: scrollingState.isScrolling ? 'grabbing' : 'grab',
                        }}
                        onMouseDown={onMouseDown}
                        onMouseUp={onMouseUp}
                        onMouseMove={onMouseMove}
                        onMouseLeave={onMouseUp}
                        onScroll={() => onScroll()}>
                        <ForecastLineChart
                            forecastType={forecastType}
                            seriesType={seriesType}
                            hourlyForecastData={hourlyData}
                            dailyForecastData={dailyData}
                            forecastColors={forecastColors}
                        />

                        <Grid
                            container
                            width={forecastType === 'hourly' ? '2160px' : '420px'}
                            height={'inherit'}
                            flexDirection='row'
                            position='absolute'>
                            {weatherData()}
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Typography>No Data</Typography>
            )}
        </Card>
    );
}
