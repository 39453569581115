/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import {
  Label,
  TextField,
  SubFormContainer,
  Button,
  BaseModal,
  Select,
  WarningDialog,
  Tooltip,
  styledLinkClass,
  Checkbox,
} from '../Base';
import {
  Location,
  OrganizationLocation,
  PWError,
  useCreateNotiUserMutation,
  useCreateUserMutation,
  useDeleteNotiUserMutation,
  User,
  UserErrorModel,
  ModelStateErrorResponse,
  useUpdateNotiUserMutation,
  useUpdateUserMutation,
  useUpdateUserOrganizationLocationsMutation,
  useUserLocationsQuery,
  useNotiUserOrgLocationsQuery,
  useDemoteUserMutation,
  usePromoteNotificationUserMutation,
  ServerErrorModel,
} from '../../Services/API';
import { useDispatch } from 'react-redux';
import { SiteSelect } from './SiteSelect';
import { RoleSelect } from './RoleSelect';
import { LocationSelect } from './LocationSelect';
import { openToast } from '../../features/toast/toastSlice';
import { timezones } from '../../utils/utils';

interface TeamModalState {
  open: boolean;
  currentUser: User;
  selectedUser?: User;
  handleClose: Function;
  handleOpen: Function;
}

interface TeamFormModalProps {
  modalState: TeamModalState;
}

interface InputRowProps {
  children: React.ReactNode;
  label: string;
  tooltip?: JSX.Element;
}

function InputRow(props: InputRowProps) {
  const { children, label, tooltip } = props;

  return (
    <Grid container spacing={2} style={{ marginBottom: 16 }}>
      <Grid container item md sm={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {tooltip ? (
          <Tooltip title={tooltip} placement='top'>
            <div>
              <Label text={label} labelVariant='subtitle1' />
            </div>
          </Tooltip>
        ) : (
          <Label text={label} labelVariant='subtitle1' />
        )}
      </Grid>
      <Grid container item md={7} sm={12}>
        {children}
      </Grid>
    </Grid>
  );
}

export function TeamFormModal(props: TeamFormModalProps) {
  const { modalState } = props;
  const theme = useTheme();
  const classes = styledLinkClass(theme);

  const defaultUser: User = {
    id: '',
    userName: '',
    firstName: '',
    lastName: '',
    fullName: '',
    email: null,
    roleName: '',
    roleId: '',
    roleHierarchy: 4,
    siteId: 0,
    siteName: '',
    phoneNumber: null,
    customerId: 0,
    timeZone: 'CST',
    smsNotifications: false,
    emailNotifications: false,
  };

  const user: User = modalState.selectedUser || defaultUser;

  const [errorState, setErrorState] = useState<UserErrorModel>({});

  const dispatch = useDispatch();
  const [updatedUser, setUpdatedUser] = useState<User>(defaultUser);
  const [userOrgLocations, setUserOrgLocations] = useState<OrganizationLocation[]>([]);

  const [
    updateNotiUserApi,
    {
      data: updateNotiResponse,
      isSuccess: updateNotiSuccess,
      isError: updateNotiError,
      error: updateNotiErrorResponse,
      isLoading: updateNotiIsLoading,
    },
  ] = useUpdateNotiUserMutation();
  const [
    addNotiUserApi,
    {
      data: addNotiUserResponse,
      isSuccess: isAddNotiUserSuccess,
      isError: isAddNotiUserError,
      error: addNotiUserError,
      isLoading: addNotiIsLoading,
    },
  ] = useCreateNotiUserMutation();

  const [
    updateUserApi,
    {
      data: updateResponse,
      isSuccess: updateSuccess,
      isError: updateError,
      error: updateErrorResponse,
      isLoading: updateUserIsLoading,
    },
  ] = useUpdateUserMutation();
  const [
    addUserApi,
    {
      data: addResponse,
      isSuccess: addSuccess,
      isError: addError,
      error: addErrorResponse,
      isLoading: addUserIsLoading,
    },
  ] = useCreateUserMutation();
  const [
    demoteUserApi,
    {
      data: demoteUserResponse,
      isSuccess: demoteUserSuccess,
      isError: demoteUserError,
      error: demoteUserErrorResponse,
      isLoading: demoteUserIsLoading,
    },
  ] = useDemoteUserMutation();

  const [
    updateOrgLoc,
    {
      data: updateOrganizationLocationsResponse,
      isSuccess: isUpdateOrganizationLocationSuccess,
      isError: isUpdateOrgLocError,
      error: updateOrgLocError,
    },
  ] = useUpdateUserOrganizationLocationsMutation();
  const {
    refetch: refetchUserLocations,
    data: userLocations,
    isFetching: isUserLocsFetching,
  } = useUserLocationsQuery(
    { userId: user.id, isNotiOnly: user.roleId === '-1' },
    { skip: user.id === '' || user.roleId === '-1' }
  );
  const {
    refetch: refetchNotiUserLocations,
    data: notiUserLocations,
    isFetching: isNotiUserLocationsFetching,
  } = useNotiUserOrgLocationsQuery(user.id, { skip: user.roleId !== '-1' });

  const [
    promoteNotificationUser,
    {
      data: promoteNotiUserResponse,
      isSuccess: promoteNotiUserSuccess,
      isError: promoteNotiUserError,
      error: promoteNotiUserErrorResponse,
    }] = usePromoteNotificationUserMutation();

  const handleClose = () => {
    setUpdatedUser(defaultUser);
    setErrorState({});
    modalState.handleClose();
  };

  const handleOpen = () => {
    modalState.handleOpen();
  };

  //This should be refactored into each use case.
  //handleUserEdit
  //handleUserCreate
  //handleNotificationUserEdit
  //handleNotificationUserCreate
  //handleNotificaitonUserPromote -> calls a new NotificationUserApiquery to promote noti user to regular user
  //handleUserDemote -> calls a new UserApi query to demote user to notificaiton user.
  const handleSave = () => {
    if (updatedUser.id.length > 0) {
      if (updatedUser.roleId === '-1')
        updateNotiUserApi({
          ...updatedUser,
          email: updatedUser.email === '' ? null : updatedUser.email,
          phoneNumber: updatedUser.phoneNumber === '' ? null : updatedUser.phoneNumber,
        });
      else {
        if (user.roleId === '-1') promoteNotificationUser(updatedUser);
        else {
          updateOrgLoc({
            userId: user.id,
            locations: userOrgLocations.map<Location>(x => {
              return {
                radius1: 10,
                radius2: 20,
                radius3: 30,
                organizationLocationId: x.id,
                latitude: x.latitude,
                longitude: x.longitude,
                label: x.address,
                locationName: x.name,
                allClearMinutes: 30,
                flags: 2,
                forecastLocation: false,
              };
            }),
          });
          updateUserApi({
            ...updatedUser,
            email: updatedUser.email === '' ? null : updatedUser.email,
            phoneNumber: updatedUser.phoneNumber === '' ? null : updatedUser.phoneNumber,
          });
        }
      }
    } else {
      if (updatedUser.siteId === 0)
        if (updatedUser.roleId === '-1')
          addNotiUserApi({
            ...updatedUser,
            email: updatedUser.email === '' ? null : updatedUser.email,
            phoneNumber: updatedUser.phoneNumber === '' ? null : updatedUser.phoneNumber,
            siteId: modalState.currentUser.siteId,
            siteName: modalState.currentUser.siteName,
          });
        else
          addUserApi({
            ...updatedUser,
            email: updatedUser.email === '' ? null : updatedUser.email,
            phoneNumber: updatedUser.phoneNumber === '' ? null : updatedUser.phoneNumber,
            siteId: modalState.currentUser.siteId,
            siteName: modalState.currentUser.siteName,
          });
      else {
        if (updatedUser.roleId === '-1')
          addNotiUserApi({
            ...updatedUser,
            email: updatedUser.email === '' ? null : updatedUser.email,
            phoneNumber: updatedUser.phoneNumber === '' ? null : updatedUser.phoneNumber,
          });
        else
          addUserApi({
            ...updatedUser,
            email: updatedUser.email === '' ? null : updatedUser.email,
            phoneNumber: updatedUser.phoneNumber === '' ? null : updatedUser.phoneNumber,
          });
      }
    }
  };

  useEffect(() => {
    if (promoteNotiUserErrorResponse) {
      console.log(promoteNotiUserErrorResponse)
      const errors: ServerErrorModel = (promoteNotiUserErrorResponse as ModelStateErrorResponse)?.data.responseException?.errors as ServerErrorModel;
      const message = errors.ServerError ? errors.ServerError[0] : 'Unable to promote notification user.';
      dispatch(openToast({ variant: 'error', header: message.replaceAll("UserName", "Email") }));
    }
    if (promoteNotiUserSuccess && promoteNotiUserResponse) {
      dispatch(openToast({ variant: 'success', header: 'Successfully promoted to full user' }));
      setUpdatedUser(promoteNotiUserResponse.user);
      handleClose();
    }
  }, [promoteNotiUserSuccess, promoteNotiUserError, promoteNotiUserErrorResponse, promoteNotiUserResponse])

  //add user from store if one exists, then get org locs via api.
  useEffect(() => {
    if (user && user.id !== updatedUser.id) {
      if (user.roleId !== '-1') refetchUserLocations();
      else refetchNotiUserLocations();
    }
  }, [user]);

  //Fetching locations for both types of users.
  useEffect(() => {
    let userOrgLocs: string[] = [];
    if (!isNotiUserLocationsFetching && notiUserLocations) {
      userOrgLocs = notiUserLocations.map(x => x.id);
    }

    if (user && user.id !== updatedUser.id) setUpdatedUser({ ...user, orgLocations: userOrgLocs });
    else setUpdatedUser({ ...updatedUser, orgLocations: userOrgLocs });
  }, [notiUserLocations, isNotiUserLocationsFetching]);

  useEffect(() => {
    let userOrgLocs: string[] = [];
    if (!isUserLocsFetching && userLocations) {
      userOrgLocs = userLocations
        .filter(
          x =>
            x.organizationLocationId !== undefined &&
            x.organizationLocationId !== null &&
            x.organizationLocationId.length > 0
        )
        .map(x => x.organizationLocationId!);
    }

    if (user && user.id !== updatedUser.id) setUpdatedUser({ ...user, orgLocations: userOrgLocs });
    else setUpdatedUser({ ...updatedUser, orgLocations: userOrgLocs });
  }, [userLocations, isUserLocsFetching]);

  useEffect(() => {
    if (demoteUserSuccess) {
      addNotiUserApi({ ...updatedUser, id: '' });
      setUpdatedUser({ ...updatedUser, id: '' });
    } else if (demoteUserError)
      dispatch(
        openToast({
          variant: 'error',
          header:
            (demoteUserErrorResponse as PWError)?.data.responseException.message ??
            (demoteUserErrorResponse as PWError)?.data.responseException.errors,
        })
      );
  }, [demoteUserResponse, demoteUserSuccess, demoteUserError, demoteUserErrorResponse]);

  useEffect(() => {
    if (isUpdateOrganizationLocationSuccess) {
      refetchUserLocations();
    } else if (isUpdateOrgLocError)
      dispatch(openToast({ variant: 'error', header: (updateOrgLocError as PWError)?.data.responseException.message }));
  }, [
    isUpdateOrganizationLocationSuccess,
    updateOrganizationLocationsResponse,
    isUpdateOrgLocError,
    updateOrgLocError,
  ]);

  //After successful update, show toast close modal.
  useEffect(() => {
    if (updateNotiSuccess) {
      dispatch(openToast({ variant: 'success', header: updateNotiResponse || '' }));
      handleClose();
    } else if (updateNotiError) {
      let errorMessage = 'Error updating notification user.';
      if (updateNotiErrorResponse) {
        let userError = (updateNotiErrorResponse as ModelStateErrorResponse).data?.responseException
          ?.errors as UserErrorModel;
        if (userError.ServerError) errorMessage = userError.ServerError[0];
        setErrorState({ ...userError });
      }
      dispatch(openToast({ variant: 'error', header: errorMessage }));
    }
  }, [updateNotiResponse, updateNotiSuccess, updateNotiError, updateNotiErrorResponse]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(openToast({ variant: 'success', header: updateResponse || '' }));
      handleClose();
    } else if (updateError) {
      if (updateErrorResponse) {
        let userError = (updateErrorResponse as ModelStateErrorResponse).data?.responseException
          ?.errors as UserErrorModel;
        setErrorState({ ...userError });
      }
      dispatch(openToast({ variant: 'error', header: 'Error updating user' }));
    }
  }, [updateSuccess, updateError, updateResponse, updateErrorResponse]);

  //After creating a User, show toast and close modal.
  useEffect(() => {
    if (addSuccess && addResponse && addResponse.user) {
      dispatch(openToast({ variant: 'success', header: addResponse.message }));

      updateOrgLoc({
        userId: addResponse.user.id,
        locations: userOrgLocations.map<Location>(x => {
          return {
            radius1: 10,
            radius2: 20,
            radius3: 30,
            organizationLocationId: x.id,
            latitude: x.latitude,
            longitude: x.longitude,
            label: x.address,
            locationName: x.name,
            allClearMinutes: 30,
            flags: 2,
            forecastLocation: false,
          };
        }),
      });
      handleClose();
    } else if (addError) {
      if (addErrorResponse) {
        let userError = (addErrorResponse as ModelStateErrorResponse).data?.responseException?.errors as UserErrorModel;
        setErrorState({ ...userError });

        if (userError && userError.ServerError)
          dispatch(openToast({ variant: 'error', header: userError.ServerError[0] }));
        else dispatch(openToast({ variant: 'error', header: 'Error adding user' }));
      }
    }
  }, [addSuccess, addError, addResponse, addErrorResponse]);

  useEffect(() => {
    if (isAddNotiUserSuccess && addNotiUserResponse && addNotiUserResponse.user) {
      dispatch(openToast({ variant: 'success', header: addNotiUserResponse.message }));

      //updateNotiOrgLoc({userId: addResponse.user.id, locations: userOrgLocations});
      handleClose();
    } else if (isAddNotiUserError) {
      if (addNotiUserError) {
        let userError = (addNotiUserError as ModelStateErrorResponse).data?.responseException?.errors as UserErrorModel;
        setErrorState({ ...userError });

        if (userError && userError.ServerError)
          dispatch(openToast({ variant: 'error', header: userError.ServerError[0] }));
        else dispatch(openToast({ variant: 'error', header: 'Error adding user' }));
      }
    }
  }, [addNotiUserResponse, isAddNotiUserSuccess, isAddNotiUserError, addNotiUserError]);

  return (
    <BaseModal modalState={{ open: modalState.open, handleOpen: e => handleOpen(), handleClose: handleClose }}>
      <Grid container>
        <Grid item md={12} style={{ marginBottom: 24 }}>
          {user.id ? (
            <Typography variant='h5'>
              {'Editing "' + updatedUser.firstName + ' ' + updatedUser.lastName + '"'}
            </Typography>
          ) : (
            <Typography variant='h5'>Add New User</Typography>
          )}
        </Grid>
        <Grid container>
          <Grid container>
            <SubFormContainer>
              {user.userName !== '' && user.userName !== null && user.userName !== user.email && (
                <InputRow label='User Name'>
                  <TextField
                    isError={errorState?.UserName && errorState.UserName.length > 0}
                    disabled
                    value={updatedUser.userName}
                    onChange={e => setUpdatedUser({ ...updatedUser, userName: e.target.value })}
                  />
                </InputRow>
              )}
              <InputRow label='First Name *'>
                <TextField
                  helperText={errorState?.FirstName ? errorState.FirstName[0] : undefined}
                  isError={errorState?.FirstName && errorState.FirstName.length > 0}
                  value={updatedUser.firstName}
                  onChange={e => setUpdatedUser({ ...updatedUser, firstName: e.target.value })}
                />
              </InputRow>
              <InputRow label='Last Name'>
                <TextField
                  value={updatedUser.lastName}
                  onChange={e => setUpdatedUser({ ...updatedUser, lastName: e.target.value })}
                />
              </InputRow>
              <InputRow label={`Email ${updatedUser.roleId === '-1' ? '' : '*'}`}>
                <TextField
                  inputMode='email'
                  helperText={errorState?.Email ? errorState.Email[0] : undefined}
                  isError={errorState?.Email && errorState.Email.length > 0}
                  value={updatedUser.email === null ? undefined : updatedUser.email}
                  onChange={e =>
                    setUpdatedUser({
                      ...updatedUser,
                      email: e.target.value,
                      userName: user.userName === user.email ? e.target.value : user.userName,
                    })
                  }
                />
              </InputRow>
              <InputRow label='Phone'>
                <TextField
                  helperText={errorState?.PhoneNumber ? errorState.PhoneNumber[0] : undefined}
                  isError={errorState?.PhoneNumber && errorState.PhoneNumber.length > 0}
                  value={updatedUser.phoneNumber === null ? undefined : updatedUser.phoneNumber}
                  onChange={e => setUpdatedUser({ ...updatedUser, phoneNumber: e.target.value })}
                />
              </InputRow>
              <InputRow label='Time Zone *'>
                <Select
                  isError={errorState?.TimeZone && errorState.TimeZone.length > 0}
                  value={updatedUser.timeZone}
                  options={timezones}
                  onChange={e => setUpdatedUser({ ...updatedUser, timeZone: e })}
                />
              </InputRow>
            </SubFormContainer>
          </Grid>
          <Grid container>
            <SubFormContainer
              subheader='Organization Info'
              containerDesc="Select the user's permission level, group, and preload organizational locations.">
              <InputRow label='Permission Level *'>
                <RoleSelect
                  isError={errorState?.RoleId && errorState.RoleId.length > 0}
                  userRole={modalState.currentUser.roleHierarchy}
                  value={updatedUser.roleId}
                  onSelect={r =>
                    setUpdatedUser({
                      ...updatedUser,
                      roleId: r.id,
                      roleName: r.name,
                      roleDisplayName: r.displayName,
                      roleHierarchy: r.hierarchy,
                    })
                  }
                />
              </InputRow>
              {updatedUser.roleId === '-1' && (
                <InputRow label='Notification Type'>
                  <Grid item xs={6}>
                    <Checkbox
                      checked={updatedUser.smsNotifications}
                      onChange={e => setUpdatedUser({ ...updatedUser, smsNotifications: e.target.checked })}
                    />
                    <Typography component='span' variant='body1'>
                      Text
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Checkbox
                      checked={updatedUser.emailNotifications}
                      onChange={e => setUpdatedUser({ ...updatedUser, emailNotifications: e.target.checked })}
                    />
                    <Typography component='span' variant='body1'>
                      Email
                    </Typography>
                  </Grid>
                </InputRow>
              )}
              <InputRow label='User Group *'>
                <SiteSelect
                  disabled={modalState.currentUser.roleHierarchy > 2}
                  isError={errorState?.SiteId && errorState.SiteId.length > 0}
                  value={
                    updatedUser.siteId === 0 ? modalState.currentUser.siteId.toString() : updatedUser.siteId.toString()
                  }
                  customerId={updatedUser.customerId}
                  onSelect={(v, n) => setUpdatedUser({ ...updatedUser, siteId: Number.parseInt(v), siteName: n })}
                />
              </InputRow>
              <InputRow
                tooltip={
                  <Typography>
                    Organization Locations are available to all of your users. To add or edit your Organization
                    Locations,
                    <a className={classes.link} rel='noreferrer' target='_blank' href='mailto:support@perryweather.com'>
                      let us know
                    </a>
                    and we'll help you.
                  </Typography>
                }
                label='Organization Location(s)'>
                <LocationSelect
                  placeholder='Preload user with location(s)'
                  values={updatedUser.orgLocations}
                  customerId={updatedUser.customerId === 0 ? modalState.currentUser.customerId : updatedUser.customerId}
                  onChange={v => {
                    setUpdatedUser({ ...updatedUser, orgLocations: v.map(x => x.id) });
                    setUserOrgLocations(v);
                  }}
                />
              </InputRow>
            </SubFormContainer>
          </Grid>
        </Grid>
        <Grid container marginTop='24px'>
          <Grid item md={5} xs={false} />
          <Grid item container md={7} xs={12} spacing={1}>
            <Grid item md={6} xs={6}>
              <Button type='clear' onClick={handleClose}>
                <Typography variant='body1'> Cancel </Typography>
              </Button>
            </Grid>
            <Grid item md={6} xs={6}>
              {user.roleId !== '-1' && user.roleId !== '' && updatedUser.roleId === '-1' ? (
                <WarningDialog
                  warningTitle='Warning: Demoting Full User'
                  warningText={`Are you sure you want to transition ${updatedUser.fullName} from a ${user.roleDisplayName || user.roleName
                    } to a Notification Only user? Doing so will result in the removal of all of their custom saved locations and settings.`}
                  button={<Button type='primary'>Save</Button>}
                  onConfirm={e => demoteUserApi(updatedUser)}
                />
              ) : (
                <Button
                  disabled={updateNotiIsLoading || updateUserIsLoading || addNotiIsLoading || addUserIsLoading}
                  type='primary'
                  onClick={handleSave}>
                  <Typography variant='body1' style={{ color: 'black' }}>
                    {user.id.length > 0 ? 'Save' : 'Add User'}
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  );
}
