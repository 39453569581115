import { api } from '.';

export interface Advisory {
  id: string;
  title: string;
  link: string;
  expires?: string;
}

export interface NWSAlert {
  // Don't use Id directly! This field is going away soon.
  Id?: string; // This property is for legacy support and should be removed when we remove the feature interface
  Identifier: string;
  AreaDesc: string;
  Sent: Date;
  Effective?: Date;
  Onset?: Date;
  Expires?: Date;
  Ends?: Date;
  Status: string;
  MessageType: string;
  Category: string;
  Certainty: string;
  Urgency: string;
  Event: string;
  SenderName: string;
  Headline: string;
  Description: string;
  Instruction: string;
  Responise: string;
}

const advisoryApi = api.injectEndpoints({
  endpoints: builder => ({
    advisory: builder.query<Advisory[], string>({
      query: locationId => `Advisory/${locationId}`,
      transformResponse: (response: { data: Advisory[] }) => response.data,
    }),
    nwsAlerts: builder.query<NWSAlert[], string>({
      query: locationId => `Advisory/Detail/${locationId}`,
      transformResponse: (response: { data: NWSAlert[] }) => response.data,
    }),
  }),
});

export const { useAdvisoryQuery, useNwsAlertsQuery } = advisoryApi;
