import { Layer, LayerProps, Source } from 'react-map-gl';
import { useTropicalForecastQuery } from '../../../../Services/API';
import * as turf from '@turf/helpers';

export function TropicalForecastLayer() {
  const { data } = useTropicalForecastQuery(null, {
    pollingInterval: 1 * 60000,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  let polygonCoordinates: number[][][][] = [[]];
  let lineCoordinates: number[][][] = [];
  var pointCollection: turf.Feature<turf.Point>[] = [];
  if (data) {
    var milesPerMeterSecond = 2.236936;
    data.data.map(item => {
      polygonCoordinates[0].push(item.conePolygon);
      const linePoints = item.points.map(point => {

        const windSpeedMphUnits = 'mph';

        const windSpeedMph = (point.wind.maxSustained * milesPerMeterSecond).toFixed(2);
        const gustSpeedMph = (point.wind.gust * milesPerMeterSecond).toFixed(2);
        const movementSpeedMph = (item.movement.speed.value * milesPerMeterSecond).toFixed(2);

        pointCollection.push(
          turf.point(point.coordinates, {
            name: item.name,
            time: new Date(point.time).toLocaleTimeString('en-US', { weekday: 'long' }),
            category: item.type,
            pressure: `${item.pressure.value}  ${item.pressure.units}`,
            windSpeed: `${windSpeedMph} ${windSpeedMphUnits}`,
            gust: `${gustSpeedMph} ${windSpeedMphUnits}`,
            movement: `${movementSpeedMph} ${windSpeedMphUnits}`,
          })
        );
        return point.coordinates;
      });
      lineCoordinates.push(linePoints);
    });
  }

  const layerStyle: LayerProps = {
    id: 'tropical-forecast',
    type: 'fill',
    minzoom: 0,
    maxzoom: 14,
    layout: {},
    source: 'tropical-forecast',
    paint: {
      'fill-color': '#000',
      'fill-opacity': 0.3,
    },
  };

  const outlineLayer: LayerProps = {
    id: 'outline-layer',
    type: 'line',
    minzoom: 0,
    maxzoom: 14,
    layout: { 'line-join': 'round', 'line-cap': 'round' },
    source: 'tropical-forecast',

    paint: {
      'line-color': '#000',
      'line-opacity': 1,
      'line-width': 2,
    },
  };

  const innerLineLayer: LayerProps = {
    id: 'inner-line-layer',
    type: 'line',
    minzoom: 3,
    maxzoom: 14,
    layout: { 'line-join': 'round', 'line-cap': 'round' },
    source: 'tropical-forecast-line',

    paint: {
      'line-color': '#000',
      'line-opacity': 1,
      'line-width': 6,
    },
  };

  const tropicalForecastPointLayer: LayerProps = {
    id: 'tropical-forecast-point-layer',
    type: 'circle',
    minzoom: 3,
    maxzoom: 14,
    layout: {},
    source: 'tropical-forecast-points',

    paint: {
      'circle-color': '#fff',
      'circle-radius': 10,
    },
  };

  const tropicalForecastTextLayer: LayerProps = {
    id: 'tropical-forecast-text-layer',
    type: 'symbol',
    minzoom: 3,
    maxzoom: 14,
    layout: {
      'text-field': ['get', 'time'],
      'text-size': 16,
      'text-offset': [0, 1.5],
      'text-padding': 0,
      'text-allow-overlap': false,
    },
    source: 'tropical-forecast-points',

    paint: {
      'text-color': '#000',
      'text-halo-color': '#FFF',
      'text-halo-width': 2,
      'text-halo-blur': 0.5,
    },
  };

  return data ? (
    <>
      <Source
        id='tropical-forecast'
        type='geojson'
        data={{
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'MultiPolygon',
                coordinates: polygonCoordinates,
              },
            },
          ],
        }}>
        <Layer beforeId='admin_sub' {...layerStyle} />
        <Layer beforeId='admin_sub' {...outlineLayer} />
      </Source>

      <Source
        id='tropical-forecast-line'
        type='geojson'
        data={{
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'MultiLineString',
                coordinates: lineCoordinates,
              },
            },
          ],
        }}>
        <Layer beforeId='admin_sub' {...innerLineLayer} />
      </Source>

      {/* <Source id="tropical-forecast-points" type="geojosn" data={tropicalForecastPointSource.toString()}>
            <Layer beforeId="admin_sub" {...tropicalForecastPointLayer} ></Layer>
        </Source> */}

      <Source
        id='tropical-forecast-points'
        type='geojson'
        data={{
          type: 'FeatureCollection',
          features: [...pointCollection],
        }}>
        <Layer beforeId='admin_sub' {...tropicalForecastPointLayer} />
        <Layer beforeId='admin_sub' {...tropicalForecastTextLayer} />
      </Source>
    </>
  ) : (
    <></>
  );
}
export default TropicalForecastLayer;
