/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, useTheme } from '@mui/material';
import { useState, memo, useMemo } from 'react';
import {
  SinglewireMapping, 
  useCustomerOrgLocationsQuery, 
  useDeleteSinglewireMappingMutation, 
  useSinglewireScenariosQuery,
  useSinglewireSitesQuery,
  useSitesQuery,
} from '../../../Services/API';
import { Button, DeletionDialog } from '../../Base';
import { Add, ArrowRightAlt } from '@mui/icons-material';
import { ConfigureModal } from '../../Integrations/Singlewire/ConfigureModal';
import PermissionGate from '../../Shared/PermissionGate';
import { UserRoles } from '../../../Constants/Constants';
import { GridColDef } from '@mui/x-data-grid';
import Table from '../../Base/BaseTable';
import { normalize } from '../../../utils/utils';
import { useDispatch } from 'react-redux';
import { openToast } from '../../../features/toast/toastSlice';
import { IntegrationConfig } from '../../../Services/API';

interface MappingsTableProps {
  mappings: Record<string, SinglewireMapping>;
}

type IntegrationsSinglewireProps = {
  config: IntegrationConfig;
  mappings: SinglewireMapping[];
  customerId: number;
}

export function ScenariosTable({ config, mappings, customerId }: IntegrationsSinglewireProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [configureModalOpen, setConfigureModalOpen] = useState(false);

  const { data: orgLocations } = useCustomerOrgLocationsQuery(customerId);
  const { data: sites } = useSitesQuery();
  const { data: singlewireSites } = useSinglewireSitesQuery(customerId);
  const { data: singlewireScenarios } = useSinglewireScenariosQuery(customerId);
  const [deleteSinglewireMapping] = useDeleteSinglewireMappingMutation();

  // Normalize data for easier access by rows in the table
  const mappingsById = useMemo(() => normalize(mappings), [mappings]);
  const orgLocationsById = useMemo(() => normalize(orgLocations), [orgLocations]);
  const sitesById = useMemo(() => normalize(sites), [sites]);
  const singlewireSitesById = useMemo(() => normalize(singlewireSites), [singlewireSites]);
  const singlewireScenariosById = useMemo(() => normalize(singlewireScenarios), [singlewireScenarios]);

  const onDeleteScenarioMapping = async (id: string) => {
    const result = await deleteSinglewireMapping(id);
    if (result.hasOwnProperty('error')) {
      console.error('Error deleting scenario trigger', result.error);
      dispatch(openToast({
        variant: 'error',
        header: 'Error deleting Scenario Trigger',
        message: 'An error occurred while deleting the Scenario Trigger. Please try again.',
        autoHideDuration: 6000,
      }));
      return;
    }
    dispatch(openToast({
      variant: 'success',
      header: 'Scenario Trigger Deleted',
    }));
  };

  const MappingsTable = memo(({ mappings }: MappingsTableProps) => {
    type CellProps = { scenario: SinglewireMapping };

    const RenderPolicyGroup = ({ scenario }: CellProps) => (
      <Grid container spacing={1}>
        <Grid item>
          <Typography overflow="hidden" textOverflow="ellipsis">
            {sitesById[scenario.policyGroupId]?.name}
          </Typography>
        </Grid>
      </Grid>
    );
    const RenderLocation = ({ scenario }: CellProps) => (
      <Grid container spacing={1}>
        <Grid item>
          <Typography overflow="hidden" textOverflow="ellipsis">
            {orgLocationsById[scenario.organizationLocationId]?.name}
          </Typography>
        </Grid>
      </Grid>
    );
    const RenderSinglewireScenario = ({ scenario }: CellProps) => (
      <Grid container spacing={1}>
        <Grid item>
          <Typography overflow="hidden" textOverflow="ellipsis">
            {singlewireScenariosById[scenario.singlewireScenarioId]?.name}
          </Typography>
        </Grid>
      </Grid>
    );
    const RenderSinglewireSite = ({ scenario }: CellProps) => (
      <Grid container spacing={1}>
        <Grid item>
          <Typography overflow="hidden" textOverflow="ellipsis">
            {singlewireSitesById[scenario.singlewireSiteId]?.name}
          </Typography>
        </Grid>
      </Grid>
    );
    const RenderActions = ({ scenario }: CellProps) => (
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <DeletionDialog
          item="Scenario Trigger"
          onRemove={() => onDeleteScenarioMapping(scenario.id!)}
          identifier="this Scenario Trigger"
          iconColor={theme.palette.error.main}
          />
      </Grid>
    );
    const columns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'Id',
        sortable: true,
        flex: 0.1,
        hide: true,
      },
      {
        field: 'policyGroupId',
        headerName: 'Policy Group',
        sortable: false,
        flex: 0.2,
        hide: false,
        renderCell: ({ id }) => <RenderPolicyGroup scenario={mappings[id]} />,
      },
      {
        field: 'organizationLocationId',
        headerName: 'At Location',
        sortable: false,
        flex: 0.2,
        hide: false,
        renderCell: ({ id }) => <RenderLocation scenario={mappings[id]} />,
      },
      {
        field: 'arrow',
        headerName: '',
        flex: 0.1,
        hide: false,
        renderCell: () => (
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <ArrowRightAlt sx={{ width: '40px', height: '40px' }} />
          </Grid>
        )
      },
      {
        field: 'singlewireScenarioId',
        headerName: 'Triggers Scenario',
        sortable: false,
        flex: 0.2,
        hide: false,
        renderCell: ({ id }) => <RenderSinglewireScenario scenario={mappings[id]} />,
      },
      {
        field: 'singlewireSiteId',
        headerName: 'At Singlewire Site',
        sortable: false,
        flex: 0.2,
        hide: false,
        renderCell: ({ id }) => <RenderSinglewireSite scenario={mappings[id]} />,
      },
      {
        field: 'delete',
        headerName: '',
        flex: 0.07,
        hide: false,
        renderCell: ({ id }) => <RenderActions scenario={mappings[id]} />,
      }
    ];
  
    return (
      <Table
        cols={columns}
        rows={Object.values(mappings)}
        rowHeight={54}
        rowSpace={8} />
    )
  });

  return (
    <Grid container style={{ padding: '0 8px' }}>
      <Grid item container style={{ marginBottom: 16 }}>
        <Grid item xs={12} md={8} lg={6} xl={5}>
          <Typography variant='h3' textAlign="left">
            Singlewire Scenario Triggers
          </Typography>
          <Typography variant='body1' color="textSecondary" textAlign="left">
            When a Policy Group is triggered at a Location, the corresponding Scenario will be triggered at a Site in the Singlewire platform
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={1}
          marginBottom={2}
          justifyContent="end"
          >
          <Grid xs={8} item container justifyContent="end">
            <PermissionGate allowedRoles={[UserRoles.SuperAdmin, UserRoles.Admin]}>
              <Button
                onClick={() => setConfigureModalOpen(true)}>
                <Add />
                <Typography>Add Scenario Trigger</Typography>
              </Button>
            </PermissionGate>
          </Grid>
        </Grid>
      </Grid>
      <MappingsTable mappings={mappingsById} />
      <ConfigureModal
        integrationConfig={config}
        modalState={{
          open: configureModalOpen,
          handleClose: () => setConfigureModalOpen(false),
          handleOpen: () => false,
        }}
      />
    </Grid>
  );
}
export default ScenariosTable;
