import * as React from 'react';

export const BatteryIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.83333 0V1.22222H4.27778V0H1.83333ZM9.16667 0V1.22222H11.6111V0H9.16667ZM0.611111 2.44444C0.449039 2.44446 0.29361 2.50885 0.179008 2.62345C0.0644061 2.73805 1.62047e-05 2.89348 0 3.05556V10.3889C1.62047e-05 10.551 0.0644061 10.7064 0.179008 10.821C0.29361 10.9356 0.449039 11 0.611111 11H12.8333C12.9954 11 13.1508 10.9356 13.2654 10.821C13.38 10.7064 13.4444 10.551 13.4444 10.3889V3.05556C13.4444 2.89348 13.38 2.73805 13.2654 2.62345C13.1508 2.50885 12.9954 2.44446 12.8333 2.44444H0.611111ZM1.22222 3.66667H12.2222V9.77778H1.22222V3.66667ZM8.55556 4.88889V6.11111H7.33333V7.33333H8.55556V8.55556H9.77778V7.33333H11V6.11111H9.77778V4.88889H8.55556ZM2.44444 6.11111V7.33333H5.5V6.11111H2.44444Z'
      fill={props.color}
    />
  </svg>
);
