import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { API_URL } from '../../Services/API';
import { getToken } from '../../Services/Auth/authSlice';
import { BaseModal, Button } from '../Base';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DateRangePicker from '@mui/lab/DateRangePicker';
import { DateRange as MUIDateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import moment, { Moment } from 'moment';
import { openToast } from '../../features/toast/toastSlice';

interface ModalProps {
  onSubmit?: React.MouseEventHandler;
  onCancel?: React.MouseEventHandler;
  open: boolean;
  handleClose: Function;
  weatherStationId?: string;
  weatherStationName?: string;
  type: string;
}

enum ReportInterval {
  Daily = 'Daily',
  Hourly = 'Hourly',
  ConditionSnaps = 'ConditionSnaps',
  DailyReportMaxDays = 180,
  HourlyReportMaxDays = 31,
  ConditionSnapsMaxDays = 7,
}

export function ExportDataModal(props: ModalProps) {
  const token: string | null = useAppSelector(getToken);

  const { open, handleClose, weatherStationId, weatherStationName, type } = props;

  const [dateRange, setDateRange] = useState<MUIDateRange<Moment>>([null, null]);
  const [reportInterval, setReportInterval] = useState<string>(type == 'historical_data' ? ReportInterval.ConditionSnaps : ReportInterval.Daily);
  const [reportMaxDays, setReportMaxDays] = useState<number>(type == 'historical_data' ? ReportInterval.ConditionSnapsMaxDays : ReportInterval.DailyReportMaxDays);
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [invalidRange, setInvalidRange] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleDateRangeChange = (nextDateRange: MUIDateRange<Moment>) => {
    if (dateRange[0] != nextDateRange[0] && dateRange[1] != null) {
      nextDateRange[1] = null;
    }
    if (nextDateRange[0] && nextDateRange[1] && Math.abs(getRangeDiff(nextDateRange)) > reportMaxDays) {
      dispatch(
        openToast({ variant: 'error', header: `${reportInterval} report cannot span more than ${reportMaxDays} days.` })
      );
      setInvalidRange(true);
    }
    if (nextDateRange[0] && nextDateRange[1] && Math.abs(getRangeDiff(nextDateRange)) <= reportMaxDays) {
      setInvalidRange(false);
    }

    setDateRange(nextDateRange);
  };

  const handleIntervalChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setReportInterval(value);
    setReportMaxDays(
      value === ReportInterval.ConditionSnaps ? ReportInterval.ConditionSnapsMaxDays : value === ReportInterval.Daily ? ReportInterval.DailyReportMaxDays : ReportInterval.HourlyReportMaxDays
    );
    setDateRange([null, null]);
  };

  const handleRangeAccept = (dateRange: MUIDateRange<Moment>) => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      if (dateRange[1] > dateRange[0] && getRangeDiff(dateRange) <= reportMaxDays) {
        setDateRange(dateRange);
      }
    }
  };

  const handleExport = () => {
    let startDate = dateRange[0]!.toISOString();
    let endDate = dateRange[1]!.toISOString();
    setRequestPending(true);
    if (reportInterval != ReportInterval.Daily && reportInterval != ReportInterval.Hourly && reportInterval != ReportInterval.ConditionSnaps) {
      dispatch(openToast({ variant: 'error', header: 'Invalid report interval. You must select Daily or Hourly.' }));
      setRequestPending(false);
      return;
    }

    if (type == 'historical_data') {
      fetch(
        `${API_URL}/Observations/Historical/Export/${reportInterval}/${weatherStationId}?startDate=${startDate}&endDate=${endDate}`,
        {
          method: 'GET',
          headers: {
            Accept: 'text/csv',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          var link = document.createElement('a');
          link.href = url;
          link.download = `${weatherStationName}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setRequestPending(false);
        })
        .catch(error => {
          dispatch(openToast({ variant: 'error', header: 'Error requesting data.' }));
          setRequestPending(false);
          handleClose();
        });

    } else {
      fetch(
        `${API_URL}/Observations/Historical/Export/ConditionSnapshots/${weatherStationId}?startDate=${startDate}&endDate=${endDate}`,
        {
          method: 'GET',
          headers: {
            Accept: 'text/csv',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
          var link = document.createElement('a');
          link.href = url;
          link.download = `${weatherStationName}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setRequestPending(false);
        })
        .catch(error => {
          dispatch(openToast({ variant: 'error', header: 'Error requesting data.' }));
          setRequestPending(false);
          handleClose();
        });
    }
  };

  const defaultMonth = moment().subtract(1, 'month');

  const getRangeDiff = (dateRange: MUIDateRange<Moment>) => {
    if (!dateRange[1] || !dateRange[0]) return 0;
    return dateRange[1].diff(dateRange[0], 'days');
  };

  useEffect(() => {
    if (open) {
      setDateRange([null, null]);

      if (type == 'historical_data') {
        setReportInterval(ReportInterval.Daily);
        setReportMaxDays(ReportInterval.DailyReportMaxDays);

      } else {
        setReportInterval(ReportInterval.ConditionSnaps);
        setReportMaxDays(ReportInterval.ConditionSnapsMaxDays);
      }
    }
  }, [open]);

  return (
    <BaseModal
      modalState={{
        open: open,
        handleOpen: () => {
        },
        handleClose: () => {
          handleClose(false);
        },
      }}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} style={{ marginBottom: 8 }} display={'flex'} justifyContent={'space-between'}>
          <Typography variant='h5'>Export Data</Typography>

          {type == 'historical_data' ? (
            <ToggleButtonGroup exclusive value={reportInterval} disabled={requestPending} onChange={handleIntervalChange}>
              <ToggleButton sx={{ color: 'primary.main' }} value={ReportInterval.Daily} disabled={reportInterval == ReportInterval.Daily}>
                {ReportInterval.Daily}
              </ToggleButton>
              <ToggleButton sx={{ color: 'primary.main' }} value={ReportInterval.Hourly} disabled={reportInterval == ReportInterval.Hourly}>
                {ReportInterval.Hourly}
              </ToggleButton>
            </ToggleButtonGroup>
          ) : (<></>)}
        </Grid>
        <Divider style={{ width: '100%', marginBottom: 16 }} />

        <Grid item container xs={12} display='flex' justifyContent='center' marginTop={2} marginBottom={4}>
          {requestPending ? (
            <CircularProgress />
          ) : (
            <DateRangePicker
              label='Start Date'
              disableFuture={true}
              inputFormat='MM/DD/yyyy'
              value={dateRange}
              defaultCalendarMonth={defaultMonth}
              onChange={dateRange => handleDateRangeChange(dateRange)}
              onAccept={dateRange => handleRangeAccept(dateRange)}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} sx={{ maxWidth: 150 }} />
                  <Box height='100%' marginLeft={1} marginRight={1}>
                    <Typography height='100%' variant='h3'>
                      -
                    </Typography>
                  </Box>
                  <TextField {...endProps} sx={{ maxWidth: 150 }} />
                </>
              )}
            />
          )}
        </Grid>
        <Grid container display='flex' justifyContent='flex-end' spacing={1}>

          <Grid item>
            <Button type='clear' onClick={e => handleClose()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={invalidRange || requestPending || dateRange[0] == null || dateRange[1] == null}
              onClick={e => handleExport()}>
              Export {type == 'historical_data' ? reportInterval : ''}
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </BaseModal>
  );
}
