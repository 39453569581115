/* eslint-disable react-hooks/exhaustive-deps */
import './Background.css';
import { useTheme } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { getSelectedLocation, getSevereLocations } from '../../features/dash/dashSlice';
import { useState, useEffect } from 'react';
import { useRoute } from 'wouter';

export default function Background() {
  const selectedLocation = useAppSelector(getSelectedLocation);
  const severeLocations = useAppSelector(getSevereLocations);

  const [scaryMode, setScaryMode] = useState(false);
  const [isDashboard] = useRoute('/');
  let theme = useTheme();

  useEffect(() => {
    if (selectedLocation && selectedLocation.id && severeLocations) {
      let threatsForLoc = severeLocations[selectedLocation.id];

      if (isDashboard && threatsForLoc && threatsForLoc.length > 0) {
        setScaryMode(true);
      } else {
        setScaryMode(false);
      }
    }
  }, [isDashboard, severeLocations, selectedLocation]);

  if (theme.palette.mode === 'dark') {
    return (
      <div className='Background-Dark'>
        <div className='Background-Dark-Group'>
          <div className='Background-Dark-Gradient-1'></div>
          <div className='Background-Dark-Gradient-2'></div>
          <div className='Background-Dark-Circles-Group'>
            <div className='Background-Dark-Circles-2'></div>
            <div className='Background-Dark-Circles-1'></div>
          </div>
        </div>
        <div className='Background-Dark-Gradient'></div>
        {scaryMode && <div className='Background-Dark-Scary-Gradient'></div>}
      </div>
    );
  } else {
    return (
      <div className='Background-Light'>
        <div className='Background-Light-Gradient'></div>
        {scaryMode && <div className='Background-Light-Scary-Gradient'></div>}

        <div className='Background-Light-Group'>
          <div className='Background-Light-Gradient-1'></div>
          <div className='Background-Light-Gradient-2'></div>
          <div className='Background-Light-Circles-Group'>
            <div className='Background-Light-Circles-2'></div>
            <div className='Background-Light-Circles-1'></div>
          </div>
        </div>
      </div>
    );
  }
}
