export const AirQuality = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ? props.width : '18'}
    height={props.height ? props.height : '20'}
    viewBox='0 0 18 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4 0C2.90694 0 2 0.906937 2 2V4.44531C1.35943 4.18995 0.699107 4 0 4V6C0.605793 6 1.28909 6.29608 2 6.63867V8.44531C1.35943 8.18995 0.699107 8 0 8V10C0.605793 10 1.28909 10.2961 2 10.6387V12.4453C1.35943 12.19 0.699107 12 0 12V14C0.605793 14 1.28909 14.2961 2 14.6387V18C2 19.0931 2.90694 20 4 20H14C15.0931 20 16 19.0931 16 18V15.5586C16.6407 15.8128 17.3013 16 18 16V14C17.236 14 16.3673 13.5655 15.4473 13.1055C14.3603 12.5635 13.236 12 12 12C10.764 12 9.63973 12.5635 8.55273 13.1055C7.63273 13.5655 6.764 14 6 14V16C7.236 16 8.36027 15.4365 9.44727 14.8945C10.3673 14.4345 11.236 14 12 14C12.6058 14 13.2891 14.2961 14 14.6387V18H4V2H14V4.44141C13.3593 4.18706 12.6987 4 12 4C10.764 4 9.63973 4.56347 8.55273 5.10547C7.63273 5.56547 6.764 6 6 6V8C7.236 8 8.36027 7.43653 9.44727 6.89453C10.3673 6.43453 11.236 6 12 6C12.764 6 13.6327 6.43453 14.5527 6.89453C15.6397 7.43753 16.764 8 18 8V6C17.3942 6 16.7109 5.70392 16 5.36133V2C16 0.906937 15.0931 0 14 0H4ZM12 8C10.764 8 9.63973 8.56347 8.55273 9.10547C7.63273 9.56547 6.764 10 6 10V12C7.236 12 8.36027 11.4365 9.44727 10.8945C10.3673 10.4345 11.236 10 12 10C12.764 10 13.6327 10.4345 14.5527 10.8945C15.6397 11.4375 16.764 12 18 12V10C17.236 10 16.3673 9.56547 15.4473 9.10547C14.3603 8.56347 13.236 8 12 8Z'
      fill={props.color}
    />
  </svg>
);
