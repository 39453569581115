//2012-Night

export const ChanceOfDrizzleNight = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st0'
        d='M2.55,5.5c-0.54-0.07-1.03-0.33-1.4-0.73c0.04,0,0.07,0,0.11,0c1.51,0,2.73-1.23,2.73-2.73
                c0-0.34-0.06-0.68-0.19-0.99c0.5,0.21,0.9,0.59,1.15,1.07c0.02,0.04,0.06,0.05,0.1,0.03c0.06-0.03,0.14-0.06,0.22-0.09
                c0.02-0.01,0.04-0.02,0.04-0.04c0.01-0.02,0.01-0.04,0-0.06c-0.36-0.72-1.02-1.24-1.8-1.43C3.38,0.5,3.27,0.48,3.16,0.47
                c-0.03,0-0.06,0.01-0.07,0.04c-0.02,0.02-0.02,0.06,0,0.08c0.06,0.08,0.13,0.17,0.18,0.27C3.49,1.22,3.6,1.63,3.6,2.04
                c0,1.29-1.05,2.34-2.34,2.34c-0.15,0-0.3-0.01-0.45-0.04C0.71,4.31,0.6,4.28,0.5,4.24c-0.03-0.01-0.06,0-0.08,0.02
                C0.4,4.29,0.39,4.32,0.4,4.35c0.05,0.11,0.11,0.21,0.17,0.31c0.45,0.7,1.19,1.15,2.02,1.24H2.6c0.02,0,0.04-0.01,0.06-0.03
                C2.68,5.85,2.68,5.82,2.67,5.8C2.65,5.73,2.63,5.65,2.62,5.56C2.61,5.53,2.59,5.5,2.55,5.5z'
      />
      <path
        className='st0'
        d='M8.11,3.63c-0.05,0-0.11,0-0.16,0.01c-0.23-0.9-1.03-1.52-1.96-1.52c-1.1,0-2,0.88-2.03,1.98
                C3.3,4.15,2.78,4.69,2.78,5.36c0,0.69,0.56,1.26,1.26,1.26h4.08c0.82,0,1.49-0.67,1.49-1.49C9.6,4.3,8.94,3.63,8.11,3.63z
                M4.34,4.15c0-0.91,0.74-1.65,1.65-1.65c0.82,0,1.5,0.58,1.62,1.39c0.01,0.05,0.04,0.1,0.08,0.13c0.04,0.03,0.1,0.04,0.15,0.03
                c0.1-0.02,0.19-0.03,0.27-0.03c0.61,0,1.11,0.5,1.11,1.11c0,0.61-0.5,1.11-1.11,1.11H8.09c-0.02,0-0.04,0-0.06,0H4.04
                c-0.48,0-0.87-0.39-0.87-0.88c0-0.48,0.39-0.87,0.87-0.87c0.03,0,0.07,0,0.1,0.01c0.05,0,0.11-0.02,0.15-0.05
                C4.33,4.4,4.35,4.34,4.35,4.28C4.34,4.24,4.34,4.2,4.34,4.15z'
      />
      <path
        className='st0'
        d='M4.16,6.98L3.78,7.7C3.74,7.79,3.78,7.9,3.87,7.95C3.9,7.96,3.92,7.97,3.95,7.97c0.07,0,0.14-0.04,0.17-0.1
                l0.37-0.71c0.02-0.05,0.03-0.1,0.01-0.15C4.49,6.96,4.45,6.92,4.41,6.9C4.32,6.86,4.21,6.89,4.16,6.98z'
      />
      <path
        className='st0'
        d='M6.1,6.98L5.72,7.7C5.68,7.79,5.72,7.9,5.81,7.95c0.03,0.01,0.05,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
                l0.37-0.71c0.02-0.05,0.03-0.1,0.01-0.15C6.43,6.96,6.39,6.92,6.35,6.9C6.26,6.86,6.15,6.89,6.1,6.98z'
      />
      <path
        className='st0'
        d='M4.75,8.15L4.38,8.87C4.33,8.96,4.37,9.07,4.47,9.12c0.03,0.01,0.05,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
                l0.37-0.71c0.02-0.05,0.03-0.1,0.01-0.15C5.08,8.13,5.05,8.09,5,8.07C4.91,8.03,4.8,8.06,4.75,8.15z'
      />
      <path
        className='st0'
        d='M6.69,8.15L6.32,8.87C6.27,8.96,6.31,9.07,6.41,9.12c0.03,0.01,0.05,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
                l0.37-0.71c0.02-0.05,0.03-0.1,0.01-0.15C7.02,8.13,6.99,8.09,6.94,8.07C6.85,8.03,6.74,8.06,6.69,8.15z'
      />
      <path
        className='st0'
        d='M8.04,6.98L7.66,7.7C7.62,7.79,7.66,7.9,7.75,7.95c0.03,0.01,0.05,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
                l0.37-0.71C8.4,7.11,8.4,7.05,8.38,7.01C8.37,6.96,8.33,6.92,8.29,6.9C8.2,6.86,8.09,6.89,8.04,6.98z'
      />
    </g>
  </svg>
);
