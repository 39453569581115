import { Theme } from '@mui/material/styles';
import { LightTheme } from './LightTheme';
import { DarkTheme } from './DarkTheme';

const ThemeMap: { [key: string]: Theme } = {
  DarkTheme,
  LightTheme,
};

export default function getTheme(theme: string): Theme {
  return ThemeMap[theme];
}
