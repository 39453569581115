//9003-Night

export const PartlyCloudyNight = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st4'
        d='M2.55,6.96c-0.54-0.07-1.03-0.33-1.4-0.73c0.04,0,0.07,0,0.11,0C2.77,6.23,3.99,5,3.99,3.5
                c0-0.34-0.06-0.68-0.19-0.99c0.5,0.21,0.9,0.59,1.15,1.07c0.02,0.04,0.06,0.05,0.1,0.03c0.06-0.03,0.14-0.06,0.22-0.09
                C5.29,3.52,5.3,3.5,5.31,3.48c0.01-0.02,0.01-0.04,0-0.06C4.95,2.7,4.29,2.18,3.51,1.99C3.38,1.96,3.27,1.94,3.16,1.93
                c-0.03,0-0.06,0.01-0.07,0.04C3.07,1.99,3.07,2.03,3.09,2.05c0.06,0.08,0.13,0.17,0.18,0.27C3.49,2.68,3.6,3.08,3.6,3.5
                c0,1.29-1.05,2.34-2.34,2.34c-0.15,0-0.3-0.01-0.45-0.04C0.71,5.77,0.6,5.74,0.5,5.7c-0.03-0.01-0.06,0-0.08,0.02
                C0.39,5.75,0.39,5.78,0.4,5.81c0.05,0.11,0.11,0.21,0.17,0.31c0.45,0.7,1.19,1.15,2.02,1.24c0,0,0.01,0,0.01,0
                c0.02,0,0.04-0.01,0.06-0.03c0.02-0.02,0.02-0.05,0.01-0.07C2.65,7.19,2.63,7.1,2.62,7.02C2.61,6.99,2.59,6.96,2.55,6.96z'
      />
      <path
        className='st4'
        d='M7.95,5.1C7.72,4.2,6.92,3.58,5.99,3.58c-1.1,0-2,0.89-2.03,1.98C3.3,5.6,2.78,6.15,2.78,6.82
                c0,0.69,0.56,1.26,1.26,1.26h4.08c0.82,0,1.49-0.67,1.49-1.49C9.6,5.71,8.84,5,7.95,5.1z M4.34,5.61c0-0.91,0.74-1.65,1.65-1.65
                c0.81,0,1.5,0.58,1.62,1.39c0.01,0.05,0.04,0.1,0.08,0.13c0.04,0.03,0.1,0.04,0.15,0.03c0.72-0.18,1.38,0.38,1.38,1.08
                c0,0.61-0.5,1.11-1.11,1.11c0,0-0.01,0-0.01,0c-0.02,0-0.04,0-0.07,0h-4c-0.48,0-0.87-0.39-0.87-0.88s0.39-0.87,0.87-0.87
                c0.03,0,0.07,0,0.1,0c0.05,0.01,0.11-0.01,0.15-0.05c0.04-0.04,0.06-0.1,0.06-0.15C4.34,5.7,4.34,5.65,4.34,5.61z'
      />
    </g>
  </svg>
);
