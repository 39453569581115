import MasterMap from '../../../../Assets/masterMap.svg';
import { PWColors } from '../../../../Theme/PWColors';

export function MasterMapControl({ incrementState, isActive }: any) {

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: '100%', marginBottom: 10, backgroundColor: isActive ? PWColors.dark.primary[500] : '#fff' }} className='mapboxgl-ctrl mapboxgl-ctrl-group'>
                <button
                    className={`mapboxgl-ctrl-icon`}
                    type='button'
                    onClick={incrementState}>
                    <img src={MasterMap} alt='Master Map' style={{ width: '80%', height: '80%' }} />
                </button>
            </div>
        </div>
    );
}

export default MasterMapControl;
