import { Location } from '../../../../../Services/API';
import { getBBox } from '../../Util/BBox';
import { Viewport } from '../../Util/Viewport';
import { Map } from 'maplibre-gl';
import { RadarImageBundle } from './RadarUtil';
import { radarTypes } from '../../../../../features/map/mapSlice';

export class BaronUtil {
    static internationalRadarParams = { type: 'Radar', product: 'C09-0x0395-0', isFuture: false, frame: 0 };
    static pastRadarParams = { type: 'Radar', product: '', isFuture: false, frame: 19 };
    static futureRadarParams = { type: 'Radar', product: '', isFuture: true, frame: 0 };
    static extendedRadarParams = { type: 'Radar', product: 'nam-maxreflectivity-dbz-all', isFuture: true, frame: 0 };
    static currentRoadParams = { type: 'Radar/road-conditions', product: 'road-condition-current-hires', isFuture: false, frame: 0 };
    static futureRoadParams = { type: 'Radar/road-conditions', product: 'road-condition', isFuture: true, frame: 0 };

    static getRadarParams = (radarType: radarTypes, location: Location | undefined) => {
        const isInternational =
            location && (location?.latitude > 49 || location?.latitude < 24.5 || location?.longitude < -126 || location?.longitude > -67);

        if (isInternational) return BaronUtil.internationalRadarParams;
        if (radarType === 'extended') return BaronUtil.extendedRadarParams;
        if (radarType === 'future') return BaronUtil.futureRadarParams;
        if (radarType === 'current-road-conditions') return BaronUtil.currentRoadParams;
        if (radarType === 'future-road-conditions') return BaronUtil.futureRoadParams;

        // Default to past
        return BaronUtil.pastRadarParams;
    };

    /**
     * Returns a resized height and width, to be < 3000 pixels.
     * Baron requests for WMS tiles maxes out at 3000 pixels.
     * @param width - pixel width
     * @param height - pixel height
     */
    static adjustHeightAndWidth(width: number, height: number) {
        const aspectRatio = width / height;
        if (height > 3000) {
            height = 3000;
            width = Math.floor(height * aspectRatio);
        }
        if (width > 3000) {
            width = 3000;
            height = Math.floor(width / aspectRatio);
        }

        return { width: width, height: height };
    }

    static getPaths(viewport: Viewport, mapRef: Map, urls: string[] | undefined) {
        if (!urls) return [];

        const reversedUrls = urls.slice().reverse();
        const bbox = getBBox(viewport, mapRef);
        const a = BaronUtil.project(bbox.top, bbox.left);
        const b = BaronUtil.project(bbox.bottom, bbox.right);

        const { width, height } = this.adjustHeightAndWidth(mapRef.getCanvas().width, mapRef.getCanvas().height);

        const bboxR = `&BBOX=${a.x},${b.y},${b.x},${a.y}&height=${height}&width=${width}`;
        const paths = reversedUrls.map(url => url + bboxR);
        return paths;
    }

    static getPath(viewport: Viewport, mapRef: Map, urls: string[], index: number, radarType: radarTypes) {
        if (!urls) return '';
        const reversedUrls = urls.slice().reverse();

        const bbox = getBBox(viewport, mapRef);
        const a = BaronUtil.project(bbox.top, bbox.left);
        const b = BaronUtil.project(bbox.bottom, bbox.right);

        const { width, height } = this.adjustHeightAndWidth(mapRef.getCanvas().width, mapRef.getCanvas().height);

        const bboxR = `&BBOX=${a.x},${b.y},${b.x},${a.y}&height=${height}&width=${width}`;
        if (index < reversedUrls.length && reversedUrls[index] !== undefined) {
            return reversedUrls[index] + bboxR;
        }
        return reversedUrls[0] + bboxR;
    }

    static project(latitude: number, longitude: number) {
        const MAX_LAT = 85.0511287798;
        var d = Math.PI / 180.0;
        var lat = Math.max(Math.min(MAX_LAT, latitude), -MAX_LAT);
        var x = longitude * d;
        var y = lat * d;

        y = Math.log(Math.tan(Math.PI / 4 + y / 2));
        const obj = {
            x: x * 6378137,
            y: y * 6378137
        };
        return obj;
    }

    static getCurrentFrame(urls: string[], progress: number) {
        let numberOfSteps = 100 / urls.length;
        let frame = Math.floor(progress / numberOfSteps);
        return Math.min(frame, urls.length - 1);
    }

    static getCurrentBundle(urls: RadarImageBundle[], progress: number) {
        let numberOfSteps = 100 / urls.length;
        let frame = Math.floor(progress / numberOfSteps);
        return Math.min(frame, urls.length - 1);
    }

    static parseDateFromUrl = (url: string, radarType: radarTypes) => {
        if (radarType === 'past') return BaronUtil.parsePastDateFromUrl(url);
        if (radarType === 'current-road-conditions') return BaronUtil.parsePWDateFromUrl(url);
        if (radarType === 'future-road-conditions') return BaronUtil.parsePWDateFromUrl(url);
        if (radarType === 'future') return BaronUtil.parseFutureDateFromUrl(url);
        if (radarType === 'extended') return BaronUtil.parseExtendedDateFromUrl(url);
        return '';
    };

    static parsePastDateFromUrl = (url: string) => {
        const urlParams = new URLSearchParams(url);
        const date = urlParams.get('LAYERS');
        if (date === null) return '';
        const dateObj = new Date(date);

        const formatedTime = new Intl.DateTimeFormat('default', { hour12: true, hour: 'numeric', minute: 'numeric' }).format(dateObj);
        return formatedTime;
    };

    static parseFutureDateFromUrl = (url: string) => {
        const urlParams = new URLSearchParams(url);
        const date = urlParams.get('TIME');
        if (date === null) return '';
        const dateObj = new Date(date);

        const formatedTime = new Intl.DateTimeFormat('default', {
            month: '2-digit',
            day: '2-digit',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric'
        }).format(dateObj);
        return formatedTime;
    };

    static parseExtendedDateFromUrl = (url: string) => {
        const urlParams = new URLSearchParams(url);
        const date = urlParams.get('TIME');
        if (date === null) return '';
        const dateObj = new Date(date);

        const formatedTime = new Intl.DateTimeFormat('default', {
            month: '2-digit',
            day: '2-digit',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric'
        }).format(dateObj);
        return formatedTime;
    };

    static parseDateFromTmsUrl = (url: string, radarType: radarTypes) => {
        if (url.indexOf('pw_time') > -1) {
            return BaronUtil.parsePWDateFromUrl(url);
        }
        if (url.indexOf('valid_time') > -1) {
            return BaronUtil.parseFutureDateFromTmsUrl(url);
        }

        return BaronUtil.parsePastDateFromTmsUrl(url);
    };

    static parsePastDateFromTmsUrl = (url: string) => {
        const splitUrl = url.split('+');

        const dateElement = splitUrl.find(element => element.includes('/{z}/{x}/{y}'));
        if (dateElement === undefined) return '';

        const date = dateElement.split('/{z}/{x}/{y}');
        if (date[0] === undefined) return '';
        const dateObj = new Date(date[0]);

        const formatedTime = new Intl.DateTimeFormat('default', { hour12: true, hour: 'numeric', minute: 'numeric' }).format(dateObj);
        return formatedTime;
    };

    static parseFutureDateFromTmsUrl = (url: string) => {
        let split = url.split('valid_time=');
        if (split[1]) {
            let dateString = split[1].split('&')[0];
            const canParse = Date.parse(dateString);
            if (canParse) {
                const dateObj = new Date(dateString);
                const formatedTime = new Intl.DateTimeFormat('default', {
                    month: '2-digit',
                    day: '2-digit',
                    hour12: true,
                    hour: 'numeric',
                    minute: 'numeric'
                }).format(dateObj);
                return formatedTime;
            }
        }
        return '';
    };

    static parsePWDateFromUrl = (url: string) => {
        let split = url.split('pw_time=');
        if (split[1]) {
            let dateString = split[1].split('&')[0];
            const canParse = Date.parse(dateString);
            if (canParse) {
                const dateObj = new Date(dateString);
                const formatedTime = new Intl.DateTimeFormat('default', {
                    month: '2-digit',
                    day: '2-digit',
                    hour12: true,
                    hour: 'numeric',
                    minute: 'numeric'
                }).format(dateObj);
                return formatedTime;
            }
        }
        return '';
    };
}
