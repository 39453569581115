import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type baseLayerType = 'satellite' | 'dark' | 'light';
export type overlayTypes =
  | 'radar'
  | 'lightning'
  | 'nws-alerts'
  | 'storm-vectors'
  | 'surface-analysis'
  | 'tropical-models'
  | 'tropical-forecast'
  | 'road-conditions' ;

export type radarTypes = 'past' | 'future' | 'extended' | 'current-road-conditions' | 'future-road-conditions';
export type speedTypes = 'slow' | 'normal' | 'fast';

export type mapActionTypes = 'observation' | 'measure';

export interface MapState {
  baseLayerStyle: baseLayerType;
  overlays: overlayTypes[];
  status: 'idle' | 'loading' | 'failed';
  futureToggled: boolean;
  radarStyle: radarTypes;
  radarPlaying: boolean;
  opacity: number;
  speed: speedTypes;
  actionType?: mapActionTypes;
  progress: number;
  currentTime: RadarTimeDisplay;
  showAllLocations: boolean;
  // currentTime: string;
}

export interface RadarTimeDisplay {
  time: string;
  displayDate: boolean;
}

const initialState: MapState = {
  baseLayerStyle: 'light',
  overlays: ['radar', 'lightning'],
  status: 'idle',
  futureToggled: false,
  radarStyle: 'past',
  radarPlaying: false,
  opacity: 1.0,
  speed: 'normal',
  progress: 0,
  currentTime: { time: '', displayDate: false },
  showAllLocations: false,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    selectBaseLayer: (state, actions: PayloadAction<baseLayerType>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.baseLayerStyle = actions.payload;
    },
    selectOverlays: (state, actions: PayloadAction<overlayTypes>) => {
      if (state.overlays.includes(actions.payload)) {
        state.overlays = state.overlays.filter(layer => layer !== actions.payload);
      } else {
        state.overlays = [...state.overlays, actions.payload];
      }
    },
    setOverlays: (state, actions: PayloadAction<overlayTypes[]>) => {
      // Remove radar if road conditions are being toggled on, and update the radar type as well
      if (!state.overlays.includes('road-conditions') && actions.payload.includes('road-conditions')) {
        state.overlays = actions.payload.filter(layer => layer !== 'radar');
        state.radarStyle = 'future-road-conditions';
      // Same with the above, but for when radar is being toggled on
      } else if (!state.overlays.includes('radar') && actions.payload.includes('radar')) {
        state.overlays = actions.payload.filter(layer => layer !== 'road-conditions'); // Remove road conditions if radar is selected
        state.radarStyle = 'past';
      } else {
        state.overlays = actions.payload as overlayTypes[];
      }
    },
    setRadarStyle: (state, actions: PayloadAction<radarTypes>) => {
      state.radarStyle = actions.payload;
    },
    setOpacity: (state, actions: PayloadAction<number>) => {
      state.opacity = actions.payload;
    },
    setSpeed: (state, actions: PayloadAction<speedTypes>) => {
      state.speed = actions.payload;
    },
    setRadarPlaying: (state, actions: PayloadAction<boolean>) => {
      state.radarPlaying = actions.payload;
    },
    setActionType: (state, actions: PayloadAction<mapActionTypes | undefined>) => {
      state.actionType = actions.payload;
    },
    setProgress: (state, actions: PayloadAction<number>) => {
      state.progress = actions.payload;
    },
    setCurrentTime: (state, actions: PayloadAction<RadarTimeDisplay>) => {
      state.currentTime = actions.payload;
    },
    setShowAllLocations: (state, actions: PayloadAction<boolean>) => {
      state.showAllLocations = actions.payload;
    },
  },
});

export const {
  selectBaseLayer,
  selectOverlays,
  setOverlays,
  setRadarPlaying,
  setOpacity,
  setSpeed,
  setActionType,
  setProgress,
  setCurrentTime,
  setRadarStyle,
  setShowAllLocations
} = mapSlice.actions;

export const getRadarStyle = (state: RootState) => state.map.radarStyle;
export const getOpacity = (state: RootState) => state.map.opacity;
export const getCurrentTime = (state: RootState) => state.map.currentTime;
export const getSpeedText = (state: RootState) => state.map.speed;
export const getAnimSpeed = (state: RootState) => {
  switch (state.map.speed) {
    case 'fast':
      return 500;
    case 'normal':
      return 750;
    case 'slow':
      return 1000;
  }
};
export const getProgress = (state: RootState) => state.map.progress;
export const getBaseLayer = (state: RootState) => state.map.baseLayerStyle;
export const getOverlays = (state: RootState) => state.map.overlays;
export const getRadarPlaying = (state: RootState) => state.map.radarPlaying;
export const getActionType = (state: RootState) => state.map.actionType;
export const getIsLayerRadar = createSelector(getOverlays, (overlays) => overlays.includes('radar'));
export const getIsLayerRoadConditions = createSelector(getOverlays, (overlays) => overlays.includes('road-conditions'));
export const getShowAllLocations = (state: RootState) => state.map.showAllLocations;

export default mapSlice.reducer;
