import { memo } from 'react';
import { Bread, BreadVariant } from './Bread';

interface BannerProps {
  variant: BreadVariant;
  header?: string;
  icon?: JSX.Element;
  message?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Banner = memo(function Banner(props: BannerProps) {
  return (
    <div style={{ marginBottom: 4, cursor: props.onClick ? 'pointer' : 'default' }} onClick={props.onClick}>
      <Bread {...props} />
    </div>
  );
});
