import * as React from 'react';

export const Checkmark = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 13}
    height={props.height ?? 11}
    viewBox='0 0 13 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.5286 0.862305L4 8.39095L1.13802 5.52897L0.195312 6.47168L4 10.2764L12.4714 1.80501L11.5286 0.862305Z'
      fill={props.color ?? 'currentColor'}
    />
  </svg>
);
