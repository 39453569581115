/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Typography, Theme, Box, useMediaQuery } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties, useTheme } from '@mui/styles';
import { VideoCamOff } from '../../Assets/VideoCamOff';
import { StatusComponent } from './StatusComponent';
import { getSevereLocations, SevereThreat } from '../../features/dash/dashSlice';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import Tooltip from './Tooltip';
import { GetThreatIcon } from '../../features/ThreatHelper';
import { Expand } from '../../Assets/Expand';
import WxImageModal from '../WeatherStation/WxImageModal';

interface WxImageProps {
  selectable?: boolean;
  expandable?: boolean;
  showStatus?: boolean;
  weatherStationId?: string;
  locationId?: string;
  style?: CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      boxSizing: 'border-box',
      boxShadow: '20px 25px 40px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      borderRadius: 8,
      border: '1px solid',
      borderColor: theme.palette.border.main,
      backgroundColor: theme.palette.background.paper,
    },
    image: {
      borderRadius: 8,
      width: '100%',
      opacity: 0.3,
      objectFit: 'cover',
    },
    imageDefault: {
      borderRadius: 8,
      width: '100%',
      height: '100%',
      opacity: 1,
      objectFit: 'cover',
    },
    imageSelected: {
      width: '100%',
      borderRadius: 8,
      border: '2px solid',
      borderColor: theme.palette.action.selected,
      objectFit: 'cover',
    },
    imagePlaceholder: {
      width: '100%',
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    selected: {
      border: '2px solid',
      borderColor: theme.palette.action.selected,
    },
  })
);

export function WxImage(props: WxImageProps) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { locationId, weatherStationId, style, expandable = false, showStatus = false, selectable = false } = props;
  const [threats, setThreats] = useState<SevereThreat[]>([]);
  const [delayStatus, setDelayStatus] = useState(false);
  const [expandModalOpen, setExpandModalOpen] = useState(false);

  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);

  let date = new Date();

  const severeLocations = useAppSelector(getSevereLocations);

  const GetStatusComponent = () => {
    let count = 0;
    let subCount = 0;

    const threatIcons = threats.map(threat => {
      count++;
      return count <= 3 ? (
        <Tooltip
          key={threat.shortName}
          title={
            threat.longName.length > 1 ? (
              <Box padding='0 16px'>
                <ul style={{ listStyle: 'initial' }}>
                  {threat.longName.map(longname => (
                    <li key={++subCount}>
                      <Typography variant='body2'>{longname}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            ) : (
              <Typography variant='body2'>{threat.longName}</Typography>
            )
          }>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            {GetThreatIcon(threat.shortName, '#fff', 20, 20)}{' '}
          </div>
        </Tooltip>
      ) : null;
    });

    let additionalThreatsDisplay: string[] = [];

    if (threats.length > 3)
      additionalThreatsDisplay = [...threats.slice(3).map(x => x.longName.join(', '))].join(', ').split(', ');

    return (
      <StatusComponent status='danger' style={{ position: 'absolute', top: 8, right: 8 }}>
        {threatIcons}
        {threats.length > 3 && (
          <Tooltip
            title={
              <Box padding='0 16px'>
                {additionalThreatsDisplay.length > 1 ? (
                  <ul style={{ listStyle: 'initial' }}>
                    {additionalThreatsDisplay.map(threat => (
                      <li key={threat}>
                        <Typography variant='body2'>{threat}</Typography>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography variant='body2'>{additionalThreatsDisplay[0]}</Typography>
                )}
              </Box>
            }>
            <Box height={20}>
              <Typography variant='h5' lineHeight={0.75}>
                ...
              </Typography>
            </Box>
          </Tooltip>
        )}
      </StatusComponent>
    );
  };

  useEffect(() => {
    if (locationId && severeLocations) {
      let threatsForLoc = severeLocations[locationId];

      if (threatsForLoc && threatsForLoc.length > 0) {
        setDelayStatus(true);
        setThreats(threatsForLoc);
      } else {
        setDelayStatus(false);
        setThreats([]);
      }
    }
  }, [severeLocations, locationId]);

  useEffect(() => {
    if (weatherStationId) {
      setImgSrc(`https://functionstestin9947.blob.core.windows.net/images/${weatherStationId}.jpg?${date}`);
    }
  }, [weatherStationId]);

  const display = (
    <div className={classes.imageContainer} style={style}>
      {threats.length > 0 && GetStatusComponent()}

      {imgSrc ? (
        !selectable ? (
          <img
            draggable='false'
            className={classes.imageDefault}
            src={imgSrc}
            alt={''}
            onError={e => {
              setImgSrc(undefined);
            }}
          />
        ) : (
          <img
            draggable='false'
            className={classes.image}
            src={imgSrc}
            alt={''}
            onError={e => {
              setImgSrc(undefined);
            }}
          />
        )
      ) : (
        <div className={[classes.imagePlaceholder, ''].join(' ')}>
          <VideoCamOff width={36} height={32} color={theme.palette.text.disabled} />
        </div>
      )}
    </div>
  );

  if (showStatus) {
    return (
      <Tooltip
        title={!delayStatus ? <Typography>{'No policy warnings'}</Typography> : ''}
        arrow
        placement='top'
        enterDelay={300}
        leaveDelay={200}>
        {display}
      </Tooltip>
    );
  } else {
    return (
      <div className={classes.imageContainer} style={style}>
        {display}
        {!isSm && expandable && imgSrc && (
          <IconButton sx={{ position: 'absolute', bottom: 5, right: 5 }} onClick={() => setExpandModalOpen(true)}>
            <Expand color={theme.palette.primary.main} />
          </IconButton>
        )}
        {!isSm && expandable && imgSrc && (
          <WxImageModal
            src={imgSrc}
            modalState={{ open: expandModalOpen, handleClose: () => setExpandModalOpen(false), handleOpen: () => {} }}
          />
        )}
      </div>
    );
  }
}
