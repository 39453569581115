import { animated, useSpring } from 'react-spring';
import { useTheme } from '@mui/material/styles';

interface ProgressHalfCircleProps {
  value: number | undefined;
  max: number;
  min: number;
  color?: string;
  width?: number;
}


/**  ProgressCircle is an animated component that displays a half circle progress bar similar to the WBGT gauge but with a variable width.
 *  @param max - The maximum value of the gauge.
 *  @param min - The minimum value of the gauge.
 *  @param value - The current value and progress of the gauge between the min and max.
 *  @param color - The color of the gauge.
 *  @param width - The width of the gauge. This determines how large the gauge is on the screen.
 */

export function ProgressCircle(props: ProgressHalfCircleProps) {
    const { value, min, max, width = 33 } = props;
    const theme = useTheme();
  
    const scale = width / 33;
    const height = 17 * scale;
    const dotWidth = 45 * scale;
    const dotHeight = 28 * scale;
    const dotRadius = 2 * scale;
    const transformOriginX = 22.5 * scale;
    const transformOriginY = 28 * scale;
    const arcTransformOriginX = 16.5 * scale;
    const arcTransformOriginY = 17.5 * scale;
  
    const currentValue = value ? Math.min(Math.max(value, min), max) : 0;
    const progressPercentage = (currentValue - min) / (max - min);
    const percentageInDeg = Math.round(progressPercentage * 170) + 5;
    const percentageReverseInDeg = -180 + Math.round(progressPercentage * 180);
  
    const animatedStyle = useSpring({
      from: {
        transform: 'rotate(5deg)',
        transformOrigin: `${transformOriginX}px ${transformOriginY}px`,
      },
      to: {
        transform: `rotate(${percentageInDeg}deg)`,
        transformOrigin: `${transformOriginX}px ${transformOriginY}px`,
      },
      config: { duration: 1000 },
    });
  
    const animatedStyle2 = useSpring({
      from: {
        transform: 'rotate(-180deg)',
        transformOrigin: `${arcTransformOriginX}px ${arcTransformOriginY}px`,
      },
      to: {
        transform: `rotate(${percentageReverseInDeg}deg)`,
        transformOrigin: `${arcTransformOriginX}px ${arcTransformOriginY}px`,
      },
      config: { duration: 1000 },
    });
  
    return (
      <div style={{ height: height + 3, justifyContent: 'center', display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ position: 'absolute', display: 'flex' }}>
          <svg width={width} height={height} viewBox={`0 0 33 15`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M32 16C32 7.71573 25.0604 1 16.5 1C7.93959 1 1 7.71573 1 16"
              stroke={theme.palette.action.disabled}
              strokeWidth="2"
            />
          </svg>
        </div>
        <div style={{ overflow: 'hidden', position: 'absolute', display: 'flex' }}>
          <animated.div style={{ 
            width: width,
            height: height,
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'center',
            ...animatedStyle2 
          }}>
            <svg width={width} height={height} viewBox="0 0 33 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M32 16C32 7.71573 25.0604 1 16.5 1C7.93959 1 1 7.71573 1 16"
                stroke={props.color || theme.palette.success.main}
                strokeWidth="2"
              />
            </svg>
          </animated.div>
        </div>
    </div>
  );
}

export default ProgressCircle;