import * as React from 'react';

export const WifiIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.99984 0.333008C5.2385 0.333008 2.73822 1.45206 0.928223 3.26139L2.2238 4.55697C3.75913 3.17963 5.77984 2.33301 7.99984 2.33301C10.2198 2.33301 12.2412 3.17897 13.7759 4.55697L15.0701 3.2627C13.2608 1.4527 10.7612 0.333008 7.99984 0.333008ZM7.99984 3.66634C6.14717 3.66634 4.45787 4.36171 3.1652 5.49837L4.57926 6.91244C5.5066 6.13577 6.69917 5.66634 7.99984 5.66634C9.3005 5.66634 10.4937 6.1351 11.4204 6.91244L12.8332 5.49967C11.5412 4.36234 9.8525 3.66634 7.99984 3.66634ZM7.99984 6.99967C7.0665 6.99967 6.20982 7.32432 5.52848 7.86165L7.99984 10.333L10.4712 7.86165C9.78986 7.32432 8.93317 6.99967 7.99984 6.99967Z'
      fill={props.color}
    />
  </svg>
);
