import { animated, useSpring } from 'react-spring';
import createStyles from '@mui/styles/createStyles';
import { useTheme, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface ProgressHalfCircleProps {
  value: number;
  max: number;
  min: number;
  color?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    animatedArc: {
      width: '33',
      height: '17',
      alignItems: 'flex-end',
      display: 'flex',
      justifyContent: 'center'
    },
    animatedDot: {
      width: '45',
      height: '28',
      alignItems: 'flex-end',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export function ProgressHalfCircleComponent(props: ProgressHalfCircleProps) {
  const { value, min, max } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  var currentValue = value;
  if (value <= min) {
    currentValue = min;
  } else if (value >= max) {
    currentValue = max;
  }

  var progressPercentage = (currentValue - min) / (max - min);
  var percentageInDeg = Math.round(progressPercentage * 170) + 5;
  var percentageReverseInDeg = -180 + Math.round(progressPercentage * 180);

  const animatedStyle = useSpring({
    from: {
      transform: 'rotate(5deg)',
      transformOrigin: '22.5px 28px',
    },
    to: {
      transform: `rotate(${percentageInDeg}deg)`,
      transformOrigin: '22.5px 28px',
    },
    config: { duration: 1000 },
  });

  const animatedStyle2 = useSpring({
    from: {
      transform: 'rotate(-180deg)',
      transformOrigin: '16.5px 17.5px',
    },
    to: {
      transform: `rotate(${percentageReverseInDeg}deg)`,
      transformOrigin: '16.5px 17.5px',
    },
    config: { duration: 1000 },
  });

  return (
    <div style={{ height: 20, justifyContent: 'center', display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ position: 'absolute', display: 'flex' }}>
        <svg width='33' height='17' viewBox='0 0 33 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M32 16C32 7.71573 25.0604 1 16.5 1C7.93959 1 1 7.71573 1 16'
            stroke={theme.palette.action.disabled}
            strokeWidth='2'
          />
        </svg>
      </div>
      <div style={{ overflow: 'hidden', position: 'absolute', display: 'flex' }}>
        <animated.div className={classes.animatedArc} style={animatedStyle2}>
          <svg width='33' height='17' viewBox='0 0 33 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M32 16C32 7.71573 25.0604 1 16.5 1C7.93959 1 1 7.71573 1 16'
              stroke={props.color ? props.color : theme.palette.success.main}
              strokeWidth='2'
            />
          </svg>
        </animated.div>
      </div>

      <div style={{ overflow: 'hidden', position: 'absolute', display: 'flex' }}>
        <animated.div className={classes.animatedDot} style={animatedStyle}>
          <svg
            width='45'
            height='28'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ overflow: 'visible' }}>
            <circle
              cx='-0.5'
              cy='16'
              r='2'
              fill={props.color ? props.color : theme.palette.success.main}
            />
          </svg>
        </animated.div>
      </div>
    </div>
  );
}

export default ProgressHalfCircleComponent;
