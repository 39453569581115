import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
import { RootState } from '../../app/store';
// import type { User } from 'oidc-react';

type AuthState = {
  // user: User | null
  token: string | null;
  needsLogin: Boolean;
};
const initialState: AuthState = {
  token: null,
  needsLogin: false,
};

const slice = createSlice({
  name: 'auth',
  // initialState: { token: null } as AuthState,
  initialState: initialState,
  reducers: {
    setCredentials: (
      state,
      // { payload: { user, token } }: PayloadAction<{ user: User; token: string }>
      { payload: { token } }: PayloadAction<{ token: string }>
    ) => {
      // state.user = user
      state.token = token;
    },
    setNeedsHardLogin: (state, actions: PayloadAction<Boolean>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.needsLogin = actions.payload;
    },
  },
});

export const { setCredentials, setNeedsHardLogin } = slice.actions;

export const getNeedsLogin = (state: RootState) => state.auth.needsLogin;
export const getToken = (state: RootState) => state.auth.token;

export default slice.reducer;

// export const selectCurrentUser = (state: RootState) => state.auth.user
