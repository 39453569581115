import { api } from '.';

export interface Widget {
  isInDelay: boolean;
  locationName: string;
  clearTime: string;
}

interface WidgetRequest {
  customerName: string;
  sirenName: string;
}

const publicApi = api.injectEndpoints({
  endpoints: builder => ({
    legacySirenWidget: builder.query<Widget, WidgetRequest>({
      query: request => {
        console.log(request);
        return `public/${request.customerName}/${request.sirenName}/Widget`;
      },
      transformResponse: (response: { data: Widget }) => response.data,
    }),
  }),
});

export const { useLegacySirenWidgetQuery } = publicApi;
