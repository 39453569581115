import { Layer, LayerProps, Source } from 'react-map-gl';
import { useSurfaceAnalysisQuery } from '../../../../Services/API';

export function SurfaceAnalysisLayer() {
  const { data } = useSurfaceAnalysisQuery(null, {
    pollingInterval: 1 * 60000,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const layerStyle: LayerProps = {
    id: 'surface-analysis-layer',
    type: 'raster',
    minzoom: 0,
    maxzoom: 14,
    paint: {},
  };

  return data ? (
    <Source id='surface-analysis' type='raster' tileSize={256} tiles={[data]} scheme='tms'>
      <Layer {...layerStyle} />
    </Source>
  ) : (
    <></>
  );
}
export default SurfaceAnalysisLayer;
