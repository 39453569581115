import { PWColors } from '../../../../Theme/PWColors';
import React, { useEffect } from 'react';
import { radarTypes } from '../../../../features/map/mapSlice';
import { Box, styled, ToggleButton, ToggleButtonGroup, Typography, Zoom } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { Location } from '../../../../Services/API';

const StyledToggleButton = styled(ToggleButton)(() => ({
  width: '100%',
  border: '1px',
  borderRadius: '8px',
  padding: 12,
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'left',
  '&:hover': {
    backgroundColor: '#E9E9E9',
  },
}));

const selectionLabels: { [key in radarTypes]: string } = {
  past: 'past',
  future: 'future',
  extended: 'extended',
  "current-road-conditions": 'Road Conditions',
  "future-road-conditions": 'Road Conditions'
}

interface RadarTypeSelectProps {
  onClick?: React.MouseEventHandler;
  show: boolean;
  handleRadarTypeChange: (newRadarType: radarTypes) => void;
  location?: Location;
  radarType: radarTypes;
  variant: 'radar' | 'road-conditions';
}

export function RadarTypeSelect(props: RadarTypeSelectProps) {
  const { onClick, show, location, handleRadarTypeChange, radarType, variant } = props;

  const isInternational = location && (location?.latitude > 49 || location?.latitude < 24.5 || location?.longitude < -126 || location?.longitude > -67);

  useEffect(() => {
    if (isInternational) handleRadarTypeChange(variant === 'radar' ? 'past' : 'current-road-conditions');
  }, [location]);

  useEffect(() => {
    handleRadarChange(null, variant === 'radar' ? 'past' : 'future-road-conditions');
  }, [variant])

  const handleRadarChange = (event: React.MouseEvent<HTMLElement> | null, newRadarStyle: radarTypes) => {
    if (newRadarStyle !== null) handleRadarTypeChange(newRadarStyle);
  };

  return (
    <div style={{ position: 'absolute', left: 55, bottom: 50 }}>
      <div onClick={onClick}>
        <Box
          style={{ backgroundColor: '#fff' }}
          className='radarSelectBox'
          minWidth={75}
          border={'1px solid'}
          borderRadius={2}
          zIndex={1}>
          <Typography margin={0.5}>{selectionLabels[radarType]}</Typography>
        </Box>
        <Box>
          <Zoom in={show}>
            <div className='overlayMenu left'>
              {variant === 'radar' && (
                <ToggleButtonGroup
                  color='secondary'
                  exclusive
                  orientation='vertical'
                  value={radarType as string}
                  onChange={handleRadarChange}>
                  <StyledToggleButton value='past'>
                    <Typography variant='body2' color={PWColors.dark.base['900']}>
                      Past
                    </Typography>
                    <KeyboardArrowLeft color='secondary' />
                  </StyledToggleButton>
                  {!isInternational && (
                    <StyledToggleButton value='future'>
                      <Typography variant='body2' color={PWColors.dark.base['900']}>
                        Future
                      </Typography>
                      <KeyboardArrowRight color='secondary' />
                    </StyledToggleButton>
                  )}
                  {!isInternational && (
                    <StyledToggleButton value='extended'>
                      <Typography variant='body2' color={PWColors.dark.base['900']}>
                        Extended
                      </Typography>
                      <KeyboardDoubleArrowRight color='secondary' />
                    </StyledToggleButton>
                  )}
                </ToggleButtonGroup>
              )}
              {variant === 'road-conditions' && (
                <ToggleButtonGroup
                color='secondary'
                exclusive
                orientation='vertical'
                value={radarType as string}
                onChange={handleRadarChange}>
                <StyledToggleButton value='future-road-conditions'>
                  <Typography variant='body2' color={PWColors.dark.base['900']}>
                    Road Conditions
                  </Typography>
                  <KeyboardArrowRight color='secondary' />
                </StyledToggleButton>
              </ToggleButtonGroup>
              )}
            </div>
          </Zoom>
        </Box>
      </div>
    </div>
  );
}

export default RadarTypeSelect;
