import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { RadialBar } from "@nivo/radial-bar";
import React from "react"
import { getDurationString, RadialBarData } from "../../Pages/LiveCameraLogs"
import {PWColors} from "../../Theme/PWColors"


interface TotalUsageRadialBarProps {
    data: RadialBarData[],
    maxValue: number

}

const TotalUsageRadialBar = ({data, maxValue}: TotalUsageRadialBarProps) => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    
    const colors = [
        PWColors[mode].primary[800],
        PWColors[mode].primary[700],
        PWColors[mode].primary[600],
        PWColors[mode].primary[500],
        PWColors[mode].primary[400],
        PWColors[mode].primary[300],
        PWColors[mode].primary[200],
        PWColors[mode].primary[100],
    ]

    return (
        <RadialBar
            data={data}
            valueFormat=">-.2f"
            height={300}
            width={300}
            maxValue={maxValue}
            colors={colors}
            cornerRadius={2}
            enableRadialGrid={false}
            labelsTextColor={"#fff"}
            enableCircularGrid={false}
            circularAxisOuter={{tickComponent: () => null}}
            theme={{
                fontFamily: 'proxima-nova',
                fontSize: 12,
                textColor: theme.palette.text.primary,
                axis: {
                    domain: {
                    line: {
                        stroke: 'none',
                    },
                    },
                    ticks: {
                    line: {
                        stroke: 'none',
                    },
                    },
                },
                grid: {
                    line: {
                    stroke: theme.palette.divider,
                    },
                },
            }}
            tooltip={(props) => {
                const { bar } = props;
                return (
                    <div
                    style={{
                        background: theme.palette.background.paper,
                        width: 185,
                        borderRadius: 8,
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 8,
                        paddingRight: 8,
                        border: '1px solid',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderColor: theme.palette.border.main,
                    }}>
                        <Typography variant="body1" color="textPrimary" >{bar.data.x} </Typography>
                        <Typography variant="body1" color="textPrimary" >{getDurationString(bar.data.y * 60)}</Typography>
                    </div>
                )
            }}     
        />
    )
}

export default TotalUsageRadialBar

