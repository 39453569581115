//9100-Day

export const WindyDay = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st0'
        d='M3.04,6.26c0.02-0.04,0.05-0.08,0.08-0.13c0-0.02,0.01-0.04,0.01-0.05c-0.01-0.05-0.04-0.1-0.09-0.12
            C2.6,5.68,2.34,5.2,2.34,4.68c0-0.84,0.68-1.52,1.52-1.52c0.53,0,0.96,0.22,1.24,0.64c0.02,0.02,0.04,0.03,0.07,0.04
            c0.08-0.05,0.18-0.09,0.28-0.13c0.01-0.05,0-0.09-0.03-0.13c-0.35-0.52-0.9-0.81-1.55-0.81c-1.05,0-1.9,0.85-1.9,1.9
            c0,0.65,0.33,1.25,0.87,1.6C2.87,6.3,2.9,6.31,2.94,6.31C2.98,6.31,3.01,6.28,3.04,6.26z'
      />
      <path
        className='st0'
        d='M3.85,2.37c0.1,0,0.19-0.08,0.19-0.19v-0.9c0-0.1-0.08-0.19-0.19-0.19c-0.1,0-0.19,0.08-0.19,0.19v0.9
            C3.66,2.28,3.74,2.37,3.85,2.37z'
      />
      <path
        className='st0'
        d='M2.39,2.65c0.04,0.05,0.1,0.09,0.16,0.09c0.03,0,0.07-0.01,0.1-0.03c0.04-0.03,0.07-0.07,0.08-0.12
            s0-0.1-0.02-0.14L2.24,1.68C2.21,1.64,2.17,1.61,2.12,1.6c-0.05-0.01-0.1,0-0.14,0.02C1.89,1.68,1.86,1.79,1.92,1.88L2.39,2.65z'
      />
      <path
        className='st0'
        d='M1.59,3.65c0.03,0.01,0.06,0.02,0.09,0.02c0.07,0,0.13-0.04,0.16-0.1c0.05-0.09,0.02-0.2-0.08-0.25l-0.8-0.43
            c-0.08-0.05-0.2-0.01-0.25,0.08C0.67,3.06,0.7,3.17,0.79,3.22L1.59,3.65z'
      />
      <path
        className='st0'
        d='M1.57,4.64c0-0.1-0.08-0.19-0.19-0.19h-0.9c-0.1,0-0.19,0.08-0.19,0.19c0,0.1,0.08,0.19,0.19,0.19h0.9
            C1.49,4.82,1.57,4.74,1.57,4.64z'
      />
      <path
        className='st0'
        d='M1.74,5.64C1.69,5.62,1.64,5.63,1.6,5.66L0.8,6.09C0.76,6.11,0.73,6.15,0.71,6.2C0.7,6.25,0.7,6.3,0.73,6.34
            c0.03,0.06,0.1,0.1,0.16,0.1c0.03,0,0.07-0.01,0.09-0.02l0.79-0.43c0.04-0.02,0.08-0.06,0.09-0.11s0.01-0.1-0.02-0.14
            C1.83,5.69,1.79,5.65,1.74,5.64z'
      />
      <path
        className='st0'
        d='M6.01,3.65c0.03,0,0.05-0.01,0.09-0.03l0.79-0.43c0.04-0.02,0.08-0.06,0.09-0.11s0.01-0.1-0.02-0.14
            C6.91,2.84,6.8,2.81,6.71,2.86L5.92,3.29C5.87,3.32,5.84,3.36,5.83,3.41c-0.01,0.05-0.01,0.1,0.02,0.14
            C5.87,3.61,5.94,3.65,6.01,3.65z'
      />
      <path
        className='st0'
        d='M2.38,6.63L1.9,7.39C1.88,7.43,1.87,7.48,1.88,7.53s0.04,0.09,0.08,0.12c0.03,0.02,0.06,0.03,0.1,0.03
            c0.06,0,0.13-0.03,0.16-0.09L2.7,6.83c0.03-0.04,0.04-0.09,0.02-0.14c-0.01-0.05-0.04-0.1-0.08-0.12C2.55,6.51,2.44,6.54,2.38,6.63
            z'
      />
      <path
        className='st0'
        d='M5.15,2.74c0.06,0,0.13-0.03,0.16-0.09l0.48-0.76c0.02-0.05,0.03-0.1,0.02-0.14C5.8,1.7,5.77,1.65,5.73,1.63
            C5.69,1.6,5.64,1.59,5.59,1.6C5.54,1.61,5.5,1.64,5.47,1.69L4.99,2.45C4.94,2.54,4.96,2.66,5.05,2.71
            C5.08,2.73,5.12,2.74,5.15,2.74z'
      />
      <path
        className='st0'
        d='M7.14,6.84C6.98,6.74,6.79,6.7,6.63,6.73c-0.12,0.02-0.18,0.1-0.16,0.21C6.48,7,6.5,7.04,6.54,7.07
            C6.59,7.1,6.64,7.11,6.7,7.09c0.04-0.01,0.09,0,0.16,0.02C7.01,7.16,7.12,7.33,7.1,7.48C7.07,7.67,6.94,7.8,6.76,7.8
            c-0.27,0-0.7,0-1.06,0H4.21c-0.01,0-0.01,0-0.02,0c0,0,0,0-0.01,0c-0.04,0-0.09,0.01-0.14,0.05c-0.06,0.06-0.08,0.13-0.05,0.2
            C4.04,8.18,4.16,8.18,4.2,8.18h0.67c0.26,0,0.53,0,0.79,0c0.38,0,0.76,0,1.08,0c0.22,0,0.43-0.1,0.56-0.26
            C7.44,7.75,7.49,7.52,7.45,7.3C7.42,7.12,7.3,6.95,7.14,6.84z'
      />
      <path
        className='st0'
        d='M5.46,7.12c-0.2,0-0.4,0-0.6,0H4.41c-0.07,0-0.12,0.02-0.16,0.06C4.21,7.22,4.2,7.26,4.2,7.31
            c0,0.09,0.06,0.18,0.21,0.18c0.13,0,0.25,0,0.38,0h0.36c0.06,0,0.11,0,0.17,0c0.06,0,0.11,0,0.17,0c0.21,0,0.41-0.09,0.54-0.25
            c0.13-0.16,0.19-0.37,0.16-0.57C6.14,6.34,5.9,6.11,5.57,6.05C5.3,6,4.97,6.15,4.83,6.41C4.77,6.52,4.74,6.63,4.74,6.75
            c0,0.11,0.07,0.19,0.18,0.2c0.05,0,0.17-0.01,0.19-0.18c0-0.01,0-0.03,0.01-0.04l0-0.02c0.02-0.11,0.09-0.21,0.2-0.26
            C5.42,6.39,5.54,6.4,5.64,6.45C5.78,6.54,5.85,6.7,5.81,6.86C5.77,7.01,5.63,7.12,5.46,7.12z'
      />
      <path
        className='st0'
        d='M6.02,8.5H3.51c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18h2.51C6.1,8.85,6.17,8.92,6.17,9
            C6.17,9.13,6,9.2,5.91,9.11c-0.07-0.07-0.18-0.07-0.25,0c-0.07,0.07-0.07,0.18,0,0.25C5.76,9.45,5.89,9.5,6.02,9.5
            c0.28,0,0.5-0.22,0.5-0.5C6.52,8.73,6.29,8.5,6.02,8.5z'
      />
      <path
        className='st0'
        d='M7.64,8.13c-0.02,0.03,0,0.07,0.03,0.07h0.59c0.8,0,1.45-0.65,1.45-1.45c0.01-0.8-0.65-1.45-1.45-1.45
            c-0.06,0-0.11,0-0.16,0.01c-0.22-0.87-1-1.48-1.91-1.48c-1.07,0-1.95,0.86-1.97,1.93C3.58,5.79,3.07,6.32,3.07,6.97
            c0,0.38,0.19,0.71,0.47,0.94c0.02,0.02,0.06,0,0.06-0.02c0.02-0.09,0.05-0.18,0.11-0.25c0.01-0.02,0.01-0.04,0-0.05
            C3.55,7.42,3.44,7.21,3.44,6.96c0-0.47,0.38-0.85,0.85-0.85c0.03,0,0.06,0,0.1,0.01c0.06,0,0.11-0.02,0.15-0.05
            C4.58,6.03,4.6,5.98,4.59,5.92V5.82c0-0.89,0.73-1.64,1.62-1.63C7,4.2,7.65,4.77,7.78,5.54c0,0.05,0.03,0.1,0.07,0.13
            C7.89,5.7,7.95,5.71,8,5.7c0.09-0.02,0.18-0.03,0.26-0.03c0.59,0,1.07,0.48,1.07,1.08c0,0.59-0.49,1.06-1.08,1.06H8.24
            c-0.02,0-0.04,0-0.05,0H7.82c-0.02,0-0.03,0.01-0.04,0.03C7.76,7.9,7.72,8.02,7.64,8.13z'
      />
    </g>
  </svg>
);
