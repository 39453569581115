//0002-Night

export const HazeNight = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <path
      fill='currentColor'
      d='M2.44,5.81h0.53c-0.14-0.1-0.27-0.22-0.39-0.35C2.64,5.47,2.7,5.47,2.76,5.47c1.46,0,2.66-1.19,2.66-2.66
            c0-0.34-0.07-0.67-0.19-0.99c0.84,0.36,1.4,1.2,1.4,2.12c0,0.77-0.38,1.45-0.97,1.87h0.54c0.48-0.48,0.77-1.14,0.77-1.87
            c0-1.21-0.83-2.28-2.01-2.57C4.85,1.34,4.74,1.32,4.63,1.3c-0.02,0-0.04,0.01-0.05,0.03C4.57,1.34,4.57,1.37,4.58,1.39
            c0.07,0.09,0.12,0.17,0.18,0.27c0.2,0.35,0.31,0.75,0.31,1.15c0,1.27-1.04,2.31-2.31,2.31c-0.16,0-0.32-0.01-0.48-0.05
            c-0.09-0.01-0.2-0.04-0.31-0.09c-0.02-0.01-0.04,0-0.06,0.01C1.89,5.01,1.89,5.04,1.9,5.05C1.95,5.16,2,5.26,2.07,5.36
            C2.17,5.53,2.3,5.67,2.44,5.81z'
    />
    <g fill='currentColor'>
      <path
        className='st0'
        d='M7.39,5.81l1.41,0c0.12,0,0.22-0.1,0.22-0.22c0-0.12-0.1-0.22-0.22-0.22l-1.41,0c-0.06,0-0.11,0.02-0.16,0.07
                c-0.04,0.04-0.06,0.1-0.06,0.15C7.17,5.71,7.26,5.81,7.39,5.81z'
      />
      <path
        className='st0'
        d='M4.35,7.33c0-0.12-0.1-0.22-0.22-0.22l-3.72,0c-0.06,0-0.11,0.02-0.16,0.07c-0.04,0.04-0.06,0.1-0.06,0.15
                c0,0.12,0.1,0.22,0.22,0.22l3.72,0C4.25,7.55,4.35,7.45,4.35,7.33z'
      />
      <path
        className='st0'
        d='M5,7.55l1.15,0c0.12,0,0.22-0.1,0.22-0.22c0-0.12-0.1-0.22-0.22-0.22L5,7.11c-0.06,0-0.11,0.02-0.16,0.07
                C4.8,7.22,4.78,7.27,4.78,7.33C4.78,7.45,4.88,7.55,5,7.55z'
      />
      <path
        className='st0'
        d='M8.08,7.11l-1.15,0c-0.06,0-0.12,0.02-0.16,0.07c-0.04,0.04-0.06,0.1-0.06,0.15c0,0.12,0.1,0.22,0.22,0.22
                l1.15,0c0.12,0,0.22-0.1,0.22-0.22S8.2,7.11,8.08,7.11z'
      />
      <path
        className='st0'
        d='M4.93,6.46c0-0.12-0.1-0.22-0.22-0.22l-3.72,0c-0.06,0-0.11,0.02-0.16,0.07c-0.04,0.04-0.06,0.1-0.06,0.15
                c0,0.12,0.1,0.22,0.22,0.22l3.72,0C4.83,6.68,4.93,6.58,4.93,6.46z'
      />
      <path
        className='st0'
        d='M9.18,6.24l-3.72,0c-0.06,0-0.11,0.02-0.16,0.07c-0.04,0.04-0.06,0.1-0.06,0.15c0,0.12,0.1,0.22,0.22,0.22
                l3.72,0c0.12,0,0.22-0.1,0.22-0.22C9.4,6.34,9.3,6.24,9.18,6.24z'
      />
      <path
        className='st0'
        d='M7.18,7.98l-3.72,0c-0.06,0-0.11,0.02-0.16,0.07C3.26,8.09,3.24,8.14,3.24,8.2c0,0.12,0.1,0.22,0.22,0.22
                l3.72,0c0.12,0,0.22-0.1,0.22-0.22C7.4,8.08,7.3,7.98,7.18,7.98z'
      />
    </g>
  </svg>
);
