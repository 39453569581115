import React from 'react';
import { UserNotification } from '../../Services/API';
import { CorrectUTC, formatPhonenumber } from '../../utils/utils';
import Table from '../Base/BaseTable';

class UserNotiRowDisplay {
  id: string;
  message: string;
  sent: string;
  service: string;
  destination: string;

  constructor(def: UserNotification) {
    this.id = def.id;
    this.message = def.message;
    this.sent = CorrectUTC(def.sentUtc).toLocaleString() + ' ' + def.timeZone;
    this.service = def.service.split(' ')[0] === 'Twilio' ? 'Text' : 'Push';
    this.destination = !isNaN(parseInt(def.destination)) ? formatPhonenumber(def.destination) : def.destination;
  }
}

interface NotificationTableProps {
  rows: UserNotification[];
  onSelectionModelChange?: any;
  hideMessage?: boolean;
  hideSent?: boolean;
  hideService?: boolean;
  hideDestination?: boolean;
  loading?: boolean;
}

export function NotificationTable(props: NotificationTableProps) {
  const { rows, hideMessage, hideSent, hideService, hideDestination, loading } = props;

  const columns = [
    { field: 'sent', headerName: 'Sent', sortable: true, flex: 0.2, hide: hideSent },
    { field: 'service', headerName: 'Type', sortable: true, flex: 0.1, hide: hideService },
    { field: 'destination', headerName: 'Destination', sortable: true, flex: 0.2, hide: hideDestination },
    { field: 'message', headerName: 'Message', sortable: true, flex: 0.4, hide: hideMessage },
  ];

  let rowDis = rows.map(def => new UserNotiRowDisplay(def));

  return <Table loading={loading} cols={columns} rows={rowDis} rowHeight={54} rowSpace={8} rowsPerPageOptions={[10]} />;
}
