import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

export interface ChangePasswordModel {
  userId: string;
  currentPassword: string;
  newPassword: string;
  confirmedNewPassword: string;
}

export interface ChangePasswordModelError {
  data?: {
    IdentityError?: string[];
    UserId?: string[];
    NewPassword?: string[];
    CurrentPassword?: string[];
    ConfirmedNewPassword?: string[];
  };
}

interface ActivateEmailResponse {
  isSuccess: boolean;
  message: string;
}

export var LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

export const identApi = createApi({
  reducerPath: 'identApi',
  baseQuery: fetchBaseQuery({ baseUrl: LOGIN_URL }) as BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,

  endpoints: builder => ({
    changePassword: builder.mutation<string, ChangePasswordModel>({
      query: changePwModel => ({
        url: 'account/change',
        method: 'POST',
        body: changePwModel,
      }),
      transformResponse: (response: { data: string }) => response.data,
    }),
    sendActivationEmail: builder.mutation<ActivateEmailResponse, string>({
      query: userId => ({
        url: `account/sendactivateemail?id=${userId}`,
      }),
    }),
  }),
});

export const { useChangePasswordMutation, useSendActivationEmailMutation } = identApi;
