//9100-Night

export const WindyNight = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st0'
        d='M2.71,6.2C2.2,6.13,1.75,5.89,1.4,5.52c0.04,0,0.07,0,0.1,0c1.41,0,2.55-1.15,2.55-2.55
            c0-0.32-0.06-0.64-0.18-0.93c0.47,0.2,0.84,0.55,1.08,1c0.02,0.04,0.06,0.05,0.09,0.03C5.1,3.04,5.18,3.01,5.25,2.98
            c0.02,0,0.03-0.02,0.04-0.04c0.01-0.02,0.01-0.04,0-0.06C4.95,2.21,4.34,1.73,3.61,1.55C3.49,1.52,3.38,1.5,3.28,1.49
            c-0.03,0-0.06,0.01-0.07,0.04c-0.02,0.02-0.02,0.06,0,0.07c0.06,0.07,0.12,0.16,0.17,0.25c0.21,0.34,0.31,0.71,0.31,1.1
            c0,1.21-0.98,2.19-2.19,2.19c-0.14,0-0.28-0.01-0.42-0.04c-0.09-0.03-0.2-0.06-0.29-0.09c-0.03-0.01-0.06,0-0.07,0.02
            C0.69,5.07,0.69,5.09,0.7,5.12c0.05,0.1,0.1,0.2,0.16,0.29c0.42,0.65,1.11,1.08,1.89,1.16h0.01c0.02,0,0.04-0.01,0.06-0.03
            C2.83,6.53,2.83,6.5,2.82,6.48C2.8,6.41,2.78,6.33,2.77,6.25C2.77,6.23,2.75,6.2,2.71,6.2z'
      />
      <path
        className='st0'
        d='M6.84,5.94c-0.15-0.1-0.33-0.14-0.49-0.1c-0.11,0.02-0.17,0.1-0.15,0.2C6.2,6.09,6.23,6.13,6.27,6.16
            c0.04,0.03,0.1,0.04,0.15,0.03c0.04-0.01,0.09,0,0.15,0.02C6.71,6.25,6.82,6.41,6.8,6.56C6.78,6.74,6.64,6.87,6.48,6.87
            c-0.26,0-0.67,0-1.02,0H4.02c-0.01,0-0.01,0-0.02,0c0,0,0,0-0.01,0c-0.04,0-0.09,0.01-0.13,0.05C3.8,6.97,3.78,7.04,3.81,7.11
            c0.05,0.12,0.16,0.12,0.2,0.12h0.64c0.25,0,0.51,0,0.76,0c0.37,0,0.73,0,1.04,0c0.21,0,0.41-0.09,0.54-0.25
            C7.13,6.82,7.18,6.6,7.14,6.39C7.11,6.21,7,6.05,6.84,5.94z'
      />
      <path
        className='st0'
        d='M5.23,6.21c-0.19,0-0.39,0-0.58,0H4.21c-0.06,0-0.12,0.02-0.16,0.06C4.02,6.31,4.01,6.35,4.01,6.4
            c0,0.08,0.06,0.17,0.2,0.17c0.12,0,0.24,0,0.36,0h0.34c0.05,0,0.11,0,0.16,0s0.11,0,0.16,0c0.2,0,0.39-0.09,0.52-0.24
            c0.13-0.15,0.19-0.35,0.16-0.55C5.88,5.47,5.65,5.24,5.33,5.18c-0.26-0.04-0.57,0.1-0.71,0.35c-0.06,0.1-0.09,0.21-0.09,0.32
            c0,0.11,0.07,0.19,0.17,0.19c0.05,0,0.16-0.01,0.18-0.17c0-0.01,0-0.03,0.01-0.04l0-0.02C4.91,5.7,4.98,5.61,5.08,5.56
            C5.18,5.51,5.3,5.52,5.4,5.57C5.54,5.65,5.6,5.81,5.56,5.96C5.53,6.11,5.39,6.21,5.23,6.21z'
      />
      <path
        className='st0'
        d='M5.76,7.54H3.35c-0.09,0-0.17,0.08-0.17,0.17s0.08,0.17,0.17,0.17h2.42c0.08,0,0.14,0.06,0.14,0.14
            c0,0.12-0.16,0.19-0.25,0.1c-0.07-0.07-0.17-0.07-0.24,0c-0.07,0.07-0.07,0.17,0,0.24c0.09,0.09,0.21,0.14,0.34,0.14
            c0.27,0,0.48-0.22,0.48-0.48C6.24,7.76,6.03,7.54,5.76,7.54z'
      />
      <path
        className='st0'
        d='M7.32,7.18c-0.02,0.03,0,0.06,0.03,0.06h0.56c0.77,0,1.4-0.63,1.4-1.4c0.01-0.77-0.63-1.4-1.4-1.4
            c-0.06,0-0.11,0-0.15,0.01C7.55,3.62,6.8,3.04,5.92,3.04c-1.03,0-1.88,0.83-1.9,1.86c-0.61,0.04-1.1,0.55-1.1,1.18
            c0,0.37,0.18,0.69,0.46,0.9c0.02,0.02,0.06,0,0.06-0.02c0.02-0.09,0.05-0.17,0.11-0.24c0.01-0.02,0.01-0.04,0-0.05
            C3.39,6.51,3.28,6.3,3.28,6.06c0-0.45,0.37-0.82,0.82-0.82c0.03,0,0.06,0,0.1,0.01c0.06,0,0.11-0.02,0.14-0.05
            C4.38,5.17,4.4,5.12,4.39,5.06v-0.1c0-0.86,0.7-1.57,1.56-1.56c0.75,0.01,1.38,0.56,1.5,1.3c0,0.05,0.03,0.1,0.07,0.13
            c0.04,0.03,0.1,0.04,0.14,0.03c0.09-0.02,0.17-0.03,0.25-0.03c0.57,0,1.03,0.46,1.03,1.04c0,0.57-0.47,1.02-1.04,1.02H7.89
            c-0.02,0-0.04,0-0.05,0H7.49c-0.02,0-0.03,0.01-0.04,0.03C7.44,6.96,7.4,7.08,7.32,7.18z'
      />
    </g>
  </svg>
);
