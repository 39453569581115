import { Slider, SliderProps, styled } from '@mui/material';
import { _useMapControl as useMapControl } from 'react-map-gl';
import {
  RadarTimeDisplay,
} from '../../../../features/map/mapSlice';
import { RadarState } from './Radar';

interface TimeLineProps extends SliderProps {
    handleSetProgress: (value: number) => void;
    labelText: string;
    radarState : any;
    handleRadarStateChange: (value: RadarState) => void;
    progress: number;
}

export function Timeline(props: TimeLineProps) {
  const { handleSetProgress, handleRadarStateChange, radarState, progress, labelText } = props;

  const handleTimeLineScrub = (nextProgress: number) => {
    handleSetProgress(nextProgress);
    if(radarState !== RadarState.SCRUB)
      handleRadarStateChange(RadarState.SCRUB);
  }

  const { containerRef } = useMapControl({
    onDragStart: (evt: any) => {
    },
    onDoubleClick: (evt: any) => {
      evt.stopPropagation();
    },
    onClick: (evt: any) => {
      evt.stopPropagation();
    },
  });



  return (
    <div style={{height: '100%', flexGrow: 10}} >
      <div
        style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center' }}
        ref={containerRef}>
        <StyledSlider
          aria-label='Radar-Progress'
          value={progress}
          valueLabelFormat={labelText}
          valueLabelDisplay={radarState === RadarState.PLAY ? 'on' : 'auto'}
          onChange={(evt: any) => {
            handleTimeLineScrub(evt ? evt.target.value : 0);
          }}
        />
      </div>
    </div>
  );
}

export default Timeline;


//Need to find a way that makes sense for radar time display
function formatAMPM(timeDisplay: RadarTimeDisplay) {
    // function formatAMPM(dateString: string) {
    var date = new Date(timeDisplay.time);
    if (date instanceof Date) {
      var hours = date.getHours();
      var minutes: string | number = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;

      if (timeDisplay.displayDate) {
        var monthDate = `${date.getMonth() + 1}/${date.getDate()}`;
        let strTime = `${monthDate} ${hours}:${minutes} ${ampm}`;
        return strTime;
      } else {
        let strTime = `${hours}:${minutes} ${ampm}`;
        return strTime;
      }
    }
  }

  const StyledSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.success.light,
    '& .MuiSlider-track': {
      height: 10,
      borderRadius: 8,
      background: 'linear-gradient(219.96deg, #FFE37E -38.99%, #22BACF 112%)',
    },
    '& .MuiSlider-rail': {
      height: 8,
      borderRadius: 8,
      background: '#ffffff',
    },
    '& .MuiSlider-thumb': {
      color: '#fff',
    },
    '& .MuiSlider-valueLabelLabel': {
      backgroundColor: '#fff',
      color: '#000',
      fontSize: 16,
      // margin: 8
    },
    '& .MuiSlider-valueLabel': {
      backgroundColor: '#fff',
      borderRadius: '10px',
      padding: 8,
    },
  }));
