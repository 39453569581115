import { Redirect } from 'wouter';
import { useDispatch } from 'react-redux';
import { useAuth } from 'oidc-react';

import { setCredentials, setNeedsHardLogin } from '../Services/Auth/authSlice';

export function Callback() {
  ///These credentials are only being set when setCredentials is called
  /// probably need to be called somewhere else.
  const dispatch = useDispatch();
  const { userData } = useAuth();
  let redirectUrl = window.sessionStorage.getItem('auth:redirect');
  // dispatch(setCredentials({user: userData!, token: userData!.access_token}))
  // if (userData!.profile) { // Super admin limiting, would prob be better to have a claim
  if (userData!.profile) {
    // Super admin limiting, would prob be better to have a claim
    dispatch(setCredentials({ token: userData!.access_token }));
    dispatch(setNeedsHardLogin(false));
    return (
      <>
        <Redirect to={redirectUrl ? redirectUrl : '/'} />
      </>
    );
  } else {
    return (
      <>
        <Redirect to='/Unauthorized' />
      </>
    );
  }

  //This stuff has some work to do
  // let redirect: string = sessionStorage.getItem ("auth:redirect") ? sessionStorage.getItem("auth:redirect") as string : "/";
}

export default Callback;
