import { useState, useRef, useEffect } from 'react';
import ReactMapGL, { Marker, MapRef, MapEvent } from 'react-map-gl';
import { CircleLayer } from '../../Dashboard/Map/Layers/CircleLayer';
import LocationMarker from '../../../Assets/locationMarker.svg';
import { Location } from '../../../Services/API';
import { useTheme } from '@mui/material';
import { CallbackEvent } from 'react-map-gl/src/components/draggable-control';
import { useAppSelector } from '../../../app/hooks';
import { getEditedLocation } from '../../../features/dash/dashSlice';
import '../../Dashboard/Map/map.css';

interface MapProps {
  geometry?: { latitude: number; longitude: number };
  radii?: { radius1: number; radius2: number; radius3: number, radius4: number };
  onClick?: (geometry: { lat: number; lng: number }) => void;
  disableInput?: boolean;
}

const mapTilerKey = process.env.REACT_APP_MAP_TILER_KEY;

export default function InputMap(props: MapProps) {
  const theme = useTheme();
  const { onClick, disableInput = false } = props;
  const editLocation: Location = useAppSelector(getEditedLocation);

  const [viewport, setViewport] = useState({
    latitude: editLocation.latitude,
    longitude: editLocation.longitude,
    zoom: 15,
  });

  useEffect(() => {
    setViewport(v => ({ latitude: editLocation!.latitude, longitude: editLocation!.longitude, zoom: v.zoom }));
  }, [editLocation]);

  const _onViewportChange = (newView: { latitude: number; longitude: number; zoom: number }) => {
    if (disableInput) {
      setViewport({ ...viewport, zoom: newView.zoom });
    } else {
      setViewport(newView);
    }
  };

  const handleClickEvent = (e: MapEvent | CallbackEvent) => {
    if (!disableInput) {
      setViewport({ ...viewport, latitude: e.lngLat[1], longitude: e.lngLat[0] });
      if (onClick) onClick({ lat: e.lngLat[1], lng: e.lngLat[0] });
    }
  };

  const mapRef = useRef<MapRef>(null);
  return (
    <ReactMapGL
      {...viewport}
      className='pw-input-map'
      onViewportChange={_onViewportChange}
      onClick={handleClickEvent}
      attributionControl={false}
      ref={mapRef}
      width='100%'
      height='100%' // <--- Has problems in fullscreen, using 100vh causes a much too large map.
      mapStyle={`https://api.maptiler.com/maps/basic/style.json?key=${mapTilerKey}`}
      // mapStyle={light ? "https://api.maptiler.com/maps/basic/style.json?key=" : "https://api.maptiler.com/maps/7a330e3e-5974-4112-9b7f-15f3a2416735/style.json?key="}
    >
      <Marker
        offsetLeft={-19}
        offsetTop={-33}
        draggable={!disableInput}
        onDragEnd={handleClickEvent}
        latitude={editLocation.latitude}
        longitude={editLocation.longitude}>
        <img
          height={40}
          width={40}
          src={LocationMarker}
          onDragStart={event => event.preventDefault()}
          alt='Location marker'
        />
      </Marker>

      <CircleLayer
        index={1}
        latitude={editLocation.latitude}
        longitude={editLocation.longitude}
        radius={editLocation.radius1}
        color={theme.palette.error.light}
        sourceId={'radii-1'}
      />
      {editLocation.radius2 > 0 ? (
        <CircleLayer
          index={2}
          latitude={editLocation.latitude}
          longitude={editLocation.longitude}
          radius={editLocation.radius2}
          color={theme.palette.warning.main}
          sourceId={'radii-2'}
        />
      ) : null}
      {editLocation.radius3 > 0 ? (
        <CircleLayer
          index={3}
          latitude={editLocation.latitude}
          longitude={editLocation.longitude}
          radius={editLocation.radius3}
          color={theme.palette.success.main}
          sourceId={'radii-3'}
        />
      ) : null}
      {editLocation.radius4 && editLocation.radius4 > 0 ? (
        <CircleLayer
          index={4}
          latitude={editLocation.latitude}
          longitude={editLocation.longitude}
          radius={editLocation.radius4}
          color={"#2461FF"}
          sourceId={'radii-4'}
        />
      ) : null}
    </ReactMapGL>
  );
}
