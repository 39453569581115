/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Grid, GridSize } from '@mui/material';
import { Checkbox, Slider, Label } from '../../Base';
import { useTheme } from '@mui/styles';
import { Policy, useSitePoliciesQuery } from '../../../Services/API';
import { Lock } from '@mui/icons-material';
import { Box } from '@mui/system';

export interface Radii {
  radius1: number;
  radius2: number;
  radius3: number;
  radius4: number;
}

export interface DisabledPolicyInput {
  radius1: boolean;
  radius2: boolean;
  radius3: boolean;
  radius4: boolean;
}

interface RadiusSlider {
  value: number;
  softMin: number;
  softMax: number;
  disabled: boolean;
}

interface RadiiSliderData {
  radius1: RadiusSlider;
  radius2: RadiusSlider;
  radius3: RadiusSlider;
  radius4: RadiusSlider;
}

interface SliderInputRowProps {
  label: string;
  labelSize?: boolean | GridSize;
  checkbox?: boolean;
  checkboxValue?: boolean;
  disabled?: boolean;
  onChecked?: React.ChangeEventHandler<HTMLInputElement>;
  children: React.ReactNode;
}

interface RadiiSliderProps  { 
  fourthLZSupport: boolean;
  radii: Radii; 
  onChange?: (radii: Radii) => void 
}

const DEFAULT_RADIUS = 10;
const MAX_RADIUS = 50;

function SliderInputRow(props: SliderInputRowProps) {
  const { label, labelSize, checkbox, checkboxValue, onChecked, children, disabled = false } = props;
  const theme = useTheme();

  return (
    <Grid container style={{ marginBottom: 16 }}>
      <Grid container item md={labelSize} sm={12}>
        <Grid item sm={2} md={3} display='flex' justifyContent='center'>
          {disabled ? (
            <Box justifySelf='center' flexDirection='column' display='flex' justifyContent='center'>
              <Lock htmlColor={theme.palette.text.secondary} />
            </Box>
          ) : (
            checkbox && <Checkbox disabled={disabled} checked={checkboxValue} onChange={onChecked} />
          )}
        </Grid>
        <Grid item xs style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Label labelColor={disabled ? 'textSecondary' : undefined} text={label} labelVariant='body1' />
        </Grid>
      </Grid>
      <Grid container item md sm={12}>
        {children}
      </Grid>
    </Grid>
  );
}

export function RadiiSliders(props: RadiiSliderProps) {
  const theme = useTheme();
  const { fourthLZSupport, radii, onChange } = props;
  const { data: userPolicies } = useSitePoliciesQuery();

  const displayFourthLZ = () => {

    if (fourthLZSupport || radii.radius4 !== 0) {
      return true;
    }
    else{
      return false;
    }
  };

  const [disabledInputs, setDisabledInputs] = useState({ radius1: false, radius2: false, radius3: false, radius4: false});

  const [radiiData, setRadiiData] = useState<RadiiSliderData>({
    radius1: { value: DEFAULT_RADIUS, softMin: 2, softMax: MAX_RADIUS - 3, disabled: false },
    radius2: { value: 0, softMin: 4, softMax: MAX_RADIUS - 2, disabled: true },
    radius3: { value: 0, softMin: 6, softMax: MAX_RADIUS - 1, disabled: true },
    radius4: { value: 0, softMin: 8, softMax: MAX_RADIUS, disabled: true },
  });

  const [radiiDisplay, setRadiiDisplay] = useState({
    radius1: { display: `0 - ${radiiData.radius1.value}`, unit: 'mi' },
    radius2: { display: `${radiiData.radius1.value} - ${radiiData.radius2.value}`, unit: 'mi' },
    radius3: { display: `${radiiData.radius2.value} - ${radiiData.radius3.value}`, unit: 'mi' },
    radius4: { display: `${radiiData.radius3.value} - ${radiiData.radius4.value}`, unit: 'mi' },
  });

  const setRadius1Policy = (data: RadiiSliderData, polRad: number, disabledInputsByPolicy: DisabledPolicyInput ) => {
    data.radius1.value = polRad;
    disabledInputsByPolicy.radius1 = true;
          
    if (data.radius4.value === 0 && data.radius1.value > MAX_RADIUS - 3) {
      data.radius4.disabled = true;
      disabledInputsByPolicy.radius4 = true;
      if(data.radius3.value === 0 && data.radius1.value > MAX_RADIUS - 2) {
        data.radius3.disabled = true;
        disabledInputsByPolicy.radius3 = true;
        if(data.radius2.value === 0 && data.radius1.value > MAX_RADIUS - 1) {
          data.radius2.disabled = true;
          disabledInputsByPolicy.radius2 = true;
        }
      }
    }
         
    data.radius2.softMin = data.radius1.value + 1;
    data.radius3.softMin = data.radius2.softMin + 1;
    data.radius4.softMin = data.radius3.softMin + 1;
  }

  const setRadius2Policy = (data: RadiiSliderData, polRad: number, disabledInputsByPolicy: DisabledPolicyInput ) => {
    data.radius2.value = polRad;
    disabledInputsByPolicy.radius2 = true;
   
    if(data.radius4.value === 0 && data.radius2.value > MAX_RADIUS - 2) {
      data.radius4.disabled = true;
      disabledInputsByPolicy.radius4 = true;
      if(data.radius3.value === 0 && data.radius2.value > MAX_RADIUS - 1) {
        data.radius3.disabled = true;
        disabledInputsByPolicy.radius3 = true;
      }
    }
    data.radius3.softMin = data.radius2.value + 1;
    data.radius4.softMin = data.radius3.softMin + 1;
  }

  const setRadius3Policy = (data: RadiiSliderData, polRad: number, disabledInputsByPolicy: DisabledPolicyInput ) => {
    data.radius3.value = polRad;
    disabledInputsByPolicy.radius3 = true;
   
    if(data.radius4.value === 0 && data.radius3.value > MAX_RADIUS - 1) {
      data.radius4.disabled = true;        
      disabledInputsByPolicy.radius4 = true;
    }
    data.radius4.softMin = data.radius3.value + 1;
  }

  const setRadius4Policy = (data: RadiiSliderData, polRad: number, disabledInputsByPolicy: DisabledPolicyInput ) => {
    data.radius4.value = polRad;
    disabledInputsByPolicy.radius4 = true;
  }

  const applyUserPolicies = (data: RadiiSliderData) => {

    let disabledInputsByPolicy = { radius1: false, radius2: false, radius3: false, radius4: false };

    if (userPolicies) {
      let lrPolicies: Policy[] = userPolicies.filter(x => x.policyTypeShortName?.substr(0, 2) === 'LR');

      if (lrPolicies && lrPolicies.length > 0) {
        if (lrPolicies.some(x => x.policyTypeShortName === 'LR1')) {
          let polRad1 = lrPolicies.find(x => x.policyTypeShortName === 'LR1')!.threshold;
          setRadius1Policy(data, polRad1, disabledInputsByPolicy);
        }         
        if (lrPolicies.some(x => x.policyTypeShortName === 'LR2')) {
          let polRad2 = lrPolicies.find(x => x.policyTypeShortName === 'LR2')!.threshold;
          setRadius2Policy(data, polRad2, disabledInputsByPolicy);     
        }
        if (lrPolicies.some(x => x.policyTypeShortName === 'LR3')) {
          let polRad3 = lrPolicies.find(x => x.policyTypeShortName === 'LR3')!.threshold;
          setRadius3Policy(data, polRad3, disabledInputsByPolicy);    
        }
        if (lrPolicies.some(x => x.policyTypeShortName === 'LR4')) {
          let polRad4 = lrPolicies.find(x => x.policyTypeShortName === 'LR4')!.threshold;
          setRadius4Policy(data, polRad4, disabledInputsByPolicy); 
        }
      }
    }
    return disabledInputsByPolicy;
  }

  useEffect(() => {
    if (radii) {
      let radius2Disabled = radiiData.radius2.disabled && radii.radius2 === 0;
      let radius3Disabled = radiiData.radius3.disabled && radii.radius3 === 0;
      let radius4Disabled = radiiData.radius4.disabled && radii.radius4 === 0;

      let radius1SoftMax = radius2Disabled ? MAX_RADIUS : radius3Disabled ? MAX_RADIUS - 1 :  radius4Disabled ? MAX_RADIUS - 2 : MAX_RADIUS - 3;
      let radius2SoftMax = radius3Disabled ? MAX_RADIUS : radius4Disabled ? MAX_RADIUS - 1 : MAX_RADIUS - 2;
      let radius3SoftMax = radius4Disabled ? MAX_RADIUS : MAX_RADIUS - 1;

      let radius2SoftMin = 2;
      let radius3SoftMin = 3;
      let radius4SoftMin = 4;


      radiiData.radius1.value = radii.radius1
      radiiData.radius1.softMax = radius1SoftMax;
      
      radiiData.radius2.value = radii.radius2
      radiiData.radius2.softMax = radius2SoftMax;
      radiiData.radius2.softMin = radius2SoftMin;
      radiiData.radius2.disabled = radii.radius2 === 0;

      radiiData.radius3.value = radii.radius3
      radiiData.radius3.softMax = radius3SoftMax;
      radiiData.radius3.softMin = radius3SoftMin;
      radiiData.radius3.disabled = radii.radius3 === 0;

      radiiData.radius4.value = radii.radius4
      radiiData.radius4.softMax = MAX_RADIUS;
      radiiData.radius4.softMin = radius4SoftMin;
      radiiData.radius4.disabled = radii.radius4 === 0;

      //Apply user policies here
      
      setRadiiData(radiiData);
    }
  }, []);

  useEffect(() => {  
    let disabledInputsByPolicy = applyUserPolicies(radiiData);
    setDisabledInputs(disabledInputsByPolicy);
    setRadiiData(radiiData);   

  }, [userPolicies]);

  useEffect(() => {
    if (radiiData.radius2.disabled)
      setRadiiDisplay({
        radius1: { display: `0 - ${radiiData.radius1.value}`, unit: 'mi' },
        radius2: { display: ``, unit: '' },
        radius3: { display: ``, unit: '' },
        radius4: { display: ``, unit: '' },
      });
    else if (radiiData.radius3.disabled)
      setRadiiDisplay({
        radius1: { display: `0 - ${radiiData.radius1.value}`, unit: 'mi' },
        radius2: { display: `${radiiData.radius1.value} - ${radiiData.radius2.value}`, unit: 'mi' },
        radius3: { display: ``, unit: '' },
        radius4: { display: ``, unit: '' },
      });
    else if (radiiData.radius4.disabled)
      setRadiiDisplay({
        radius1: { display: `0 - ${radiiData.radius1.value}`, unit: 'mi' },
        radius2: { display: `${radiiData.radius1.value} - ${radiiData.radius2.value}`, unit: 'mi' },
        radius3: { display: `${radiiData.radius2.value} - ${radiiData.radius3.value}`, unit: 'mi' },
        radius4: { display: ``, unit: '' },
      });
    else
      setRadiiDisplay({
        radius1: { display: `0 - ${radiiData.radius1.value}`, unit: 'mi' },
        radius2: { display: `${radiiData.radius1.value} - ${radiiData.radius2.value}`, unit: 'mi' },
        radius3: { display: `${radiiData.radius2.value} - ${radiiData.radius3.value}`, unit: 'mi' },
        radius4: { display: `${radiiData.radius3.value} - ${radiiData.radius4.value}`, unit: 'mi' },
    }); 
  }
  , [radiiData]);

  const handleRadiiCheckedBoxChange = (radii: 'radius2' | 'radius3' | 'radius4', unchecked: boolean) => {

    let radius1 = radiiData.radius1;
    let radius2 = radiiData.radius2;
    let radius3 = radiiData.radius3;
    let radius4 = radiiData.radius4;
    if (unchecked === undefined) {
      return;
    }
    switch (radii) {
      case 'radius2':   
        radius2.disabled = unchecked;
        if (unchecked) {
          radius1.softMax = MAX_RADIUS;
          radius2.value = 0;
          radius3.disabled = unchecked;
          radius3.value = 0;
          radius4.disabled = unchecked;
          radius4.value = 0;

        } else {
          if(radius1.value > radius1.softMax){
            radius1.value = radius1.softMax;
          }
          radius1.softMax = MAX_RADIUS - 1;
          if(radius1.value > radius1.softMax){
            radius1.value = radius1.softMax;
          }
          radius2.value = radius1.value + 1;         
        }
        break;
      case 'radius3':
        radius3.disabled = unchecked;

        if (unchecked) {
          radius1.softMax = MAX_RADIUS - 1;
          radius2.softMax = MAX_RADIUS;
          radius3.value = 0;
          radius4.value = 0;
          radius4.disabled = unchecked;
        } else {
          radius1.softMax = MAX_RADIUS - 2;
          radius2.softMax = MAX_RADIUS - 1;
          if(radius1.value > radius1.softMax){
            radius1.value = radius1.softMax;
          }
          if(radius2.value > radius2.softMax){
            radius2.value = radius2.softMax;
          }
          else if(radius2.value < radius1.value){
            radius2.value = radius1.value + 1;
          }

          radius3.value = radius2.value + 1;     
          radius2.disabled = unchecked;
        }
          
        break;
      case 'radius4':
        radius4.disabled = unchecked;
        if (unchecked) {
          radius1.softMax = MAX_RADIUS - 2;
          radius2.softMax = MAX_RADIUS - 1;
          radius3.softMax = MAX_RADIUS;
          radius4.value = 0;
          } else {
          radius1.softMax = MAX_RADIUS - 3;
          radius2.softMax = MAX_RADIUS - 2;
          radius3.softMax = MAX_RADIUS - 1;
          if(radius1.value > radius1.softMax){
            radius1.value = radius1.softMax;
          }
          if(radius2.value > radius2.softMax){
            radius2.value = radius2.softMax;
          }
          else if(radius2.value < radius1.value){
            radius2.value = radius1.value + 1;
          }
          if(radius3.value > radius3.softMax){
            radius3.value = radius3.softMax;
          }
          else if(radius3.value < radius2.value){
            radius3.value = radius2.value + 1;
          }
          radius4.value = radius3.value + 1;  
          radius2.disabled = unchecked;   
          radius3.disabled = unchecked;    
        }
        break;
      }
    let disabledInputsByPolicy = applyUserPolicies(radiiData);
    setDisabledInputs(disabledInputsByPolicy);
    if (onChange !== undefined)
      onChange({ radius1: radius1.value, radius2: radius2.value, radius3: radius3.value, radius4: radius4.value});
    setRadiiData({ radius1: { ...radius1 }, radius2: { ...radius2 }, radius3: { ...radius3 }, radius4: { ...radius4 } });

  }

  const handleRadiiChange = (radii: 'radius1' | 'radius2' | 'radius3' | 'radius4', size?: number) => {
    let radius1 = radiiData.radius1;
    let radius2 = radiiData.radius2;
    let radius3 = radiiData.radius3;
    let radius4 = radiiData.radius4;

    switch (radii) {
      case 'radius1':
        if (size) {
          radius1.value = size;
          if (!radius2.disabled && !disabledInputs.radius2) if (size >= radius2.value - 1) radius2.value = size + 1;
          if (!radius3.disabled && !disabledInputs.radius3) if (size >= radius3.value - 2) radius3.value = size + 2;
          if (!radius4.disabled && !disabledInputs.radius4) if (size >= radius4.value - 3) radius4.value = size + 3;
        }
        break;
      case 'radius2':
        if (size) {
          radius2.value = size;

          if (size <= radius1.value + 1 && !disabledInputs.radius1) radius1.value = size - 1;
          if (!radius3.disabled && !disabledInputs.radius3) if (size >= radius3.value - 1) radius3.value = size + 1;
          if (!radius4.disabled && !disabledInputs.radius4) if (size >= radius4.value - 2) radius4.value = size + 2;
        }
       
        break;
      case 'radius3':
        if (size) {
          radius3.value = size;

          if (size <= radius1.value + 2 && !disabledInputs.radius1) radius1.value = size - 2;
          if (!radius2.disabled && !disabledInputs.radius2) if (size <= radius2.value + 1) radius2.value = size - 1;
          if (!radius4.disabled && !disabledInputs.radius4) if (size >= radius4.value - 1) radius4.value = size + 1;
        }
        break;
      case 'radius4':
          if (size) {
            radius4.value = size;
            
            if (size <= radius1.value + 3 && !disabledInputs.radius1) radius1.value = size - 3;
            if (!radius2.disabled && !disabledInputs.radius2) if (size <= radius2.value + 2) radius2.value = size - 2;
            if (!radius3.disabled && !disabledInputs.radius3) if (size <= radius3.value + 1) radius3.value = size - 1;
          }     
    }

    if (onChange !== undefined){
      onChange({ radius1: radius1.value, radius2: radius2.value, radius3: radius3.value, radius4: radius4.value});
    }

    setRadiiData({ radius1: { ...radius1 }, radius2: { ...radius2 }, radius3: { ...radius3 }, radius4: { ...radius4 } });
  };

  return (
    <div>
      <Grid container>
        <Grid item xs>
          <SliderInputRow label='Zone 1' labelSize={4} disabled={disabledInputs.radius1}>
            <Slider
              disabled={radiiData.radius1.disabled || disabledInputs.radius1}
              backgroundColor='#EE7064'
              displayValueSize={4}
              max={MAX_RADIUS}
              softMin={radiiData.radius1.softMin}
              softMax={radiiData.radius1.softMax}
              value={radiiData.radius1.value}
              customDisplay={radiiDisplay.radius1.display}
              unit={radiiDisplay.radius1.unit}
              onChange={e => handleRadiiChange('radius1', e as number)}
            />
          </SliderInputRow>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <SliderInputRow
            disabled={disabledInputs.radius2}
            label='Zone 2'
            labelSize={4}
            checkbox
            checkboxValue={!radiiData.radius2.disabled}
            onChecked={e => {
              handleRadiiCheckedBoxChange('radius2', !e.target.checked);
            }}>
            <Slider
              displayValueSize={4}
              disabled={radiiData.radius2.disabled || disabledInputs.radius2}
              backgroundColor={theme.palette.warning.main}
              min={0}
              softMin={radiiData.radius2.softMin}
              softMax={radiiData.radius2.softMax}
              max={MAX_RADIUS}
              value={radiiData.radius2.value}
              customDisplay={radiiDisplay.radius2.display}
              unit={radiiDisplay.radius2.unit}
              onChange={e => handleRadiiChange('radius2', e as number)}
            />
          </SliderInputRow>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <SliderInputRow
            disabled={disabledInputs.radius3}
            label='Zone 3'
            labelSize={4}
            checkbox
            checkboxValue={!radiiData.radius3.disabled}
            onChecked={e => {
              handleRadiiCheckedBoxChange('radius3', !e.target.checked);
            }}>
            <Slider
              disabled={radiiData.radius3.disabled || disabledInputs.radius3}
              displayValueSize={4}
              backgroundColor={theme.palette.success.main}
              min={0}
              softMin={radiiData.radius3.softMin}
              softMax={radiiData.radius3.softMax}
              max={MAX_RADIUS}
              value={radiiData.radius3.value}
              customDisplay={radiiDisplay.radius3.display}
              unit={radiiDisplay.radius3.unit}
              onChange={e => handleRadiiChange('radius3', e as number)}
            />
          </SliderInputRow>
        </Grid>
      </Grid>
      { displayFourthLZ() &&
      <Grid container>
        <Grid item xs>
          <SliderInputRow
            disabled={disabledInputs.radius4}
            label='Zone 4'
            labelSize={4}
            checkbox
            checkboxValue={!radiiData.radius4.disabled}
            onChecked={e => {
              handleRadiiCheckedBoxChange('radius4', !e.target.checked);
            }}>
            <Slider
              disabled={radiiData.radius4.disabled || disabledInputs.radius4}
              displayValueSize={4}
              backgroundColor='#2461FF'
              min={0}
              softMin={radiiData.radius4.softMin}
              max={MAX_RADIUS}
              value={radiiData.radius4.value}
              customDisplay={radiiDisplay.radius4.display}
              unit={radiiDisplay.radius4.unit}
              onChange={e => handleRadiiChange('radius4', e as number)}
            />
          </SliderInputRow>
        </Grid>
      </Grid>
      }
    </div>
  );
}
