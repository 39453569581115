/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import {
  Observation,
  Policy,
  PolicyAlertModel,
  Threat,
  useCachedPolicyAlertsForLocationQuery,
  useLightningStatusQuery,
  useObservationsForLocationQuery,
  useSitePoliciesQuery,
  useThreatsQuery,
} from '../../../Services/API/';
import { useAppSelector } from '../../../app/hooks';

import TimeParser, { GetShortTime } from '../../../features/Time/TimeHelpers';
import { ThreatBanner } from './ThreatBanner';
import { PolicyTypeName, ThreatTypes } from '../../../Constants/Constants';
import { Checkmark } from '../../../Assets/Checkmark';
import { Card, Tooltip } from '../../Base';
import { getSelectedLocation } from '../../../features/dash/dashSlice';
import {
  GetThreatColor,
  GetThreatIcon,
  GetThreatLevelText,
  GetPolicyText,
  GetFirstPolicy,
  isSevere,
} from '../../../features/ThreatHelper';
import { groupBy } from '../../../utils/utils';
import React from 'react';

const threatTypes: ThreatTypes[] = ['AQI', 'CHILL', 'HEAT', 'WBGT', 'PRECIP', 'WIND', 'GUST'];

const getConditionValue = (threatType: string, observation: Observation) => {
  switch (threatType) {
    case 'WBGT':
      return observation.data?.wgbt?.value;
    case 'CHILL':
      return observation.data?.feelLike?.value;
    case 'HEAT':
      return observation.data?.feelLike?.value;
    case 'WIND':
      return observation.data?.windSpeed?.value;
    case 'PRECIP':
      return observation.data?.precipitation?.value;
    case 'AQI':
      return observation.airQuality?.pM2_5['1-Hour'];
    case 'GUST':
      return observation.data?.windGust?.value;
    default:
      return undefined;
  }
};

const CreateText = (warnings: string[]) => {
  let count: number = 0;
  return warnings.map((warning, key) => {
    count++;
    // return (<Typography key={`policywarning-${key}-${warning}`} component='span' > {warning}{count < warnings.length ? ',' : ''}</Typography>)
    return (
      <Tooltip
        key={count}
        title={<Typography>For more information, see the Active Warnings section.</Typography>}
        arrow
        placement='top'
        enterDelay={300}
        leaveDelay={200}>
        <Typography
          component='span'
          variant='subtitle1'
          sx={{ textDecorationLine: 'underline', textDecorationStyle: 'dotted' }}>
          {' '}
          {warning}
          {count < warnings.length ? ',' : ''}
        </Typography>
      </Tooltip>
    );
  });
};

var warnings: string[] = [];
export function ThreatsForecast() {
  const theme = useTheme();
  const selectedLocation = useAppSelector(getSelectedLocation);

  const { data: policiesData } = useSitePoliciesQuery();
  const { data: policyViolations } = useCachedPolicyAlertsForLocationQuery(selectedLocation?.id || '', {
    skip: selectedLocation?.id === undefined,
  });
  const { data: observationData } = useObservationsForLocationQuery(selectedLocation?.id || '', {
    skip: selectedLocation?.id === undefined,
  });
  const { data: threatList } = useThreatsQuery(selectedLocation?.id || '', {
    skip: selectedLocation === undefined,
    pollingInterval: 180000,
  });
  const { data: lightningDelay } = useLightningStatusQuery(selectedLocation ? selectedLocation.id || '' : '', {
    skip: selectedLocation === undefined,
  });

  let groupedViolations: any = groupBy(policyViolations, 'policyShortName');
  let policyViolationKeys = groupedViolations ? Object.keys(groupedViolations) : undefined;

  const threatComponents = (threats: Threat[], observation?: Observation, policies?: Policy[]) => {
    var returnComponents: JSX.Element[] = [];
    warnings = [];

    var shouldAddPolicyWarning = false;

    if (lightningDelay) {
      var delay1 = TimeParser(lightningDelay.item1);
      var delay2 = TimeParser(lightningDelay.item2);
      var delay3 = TimeParser(lightningDelay.item3);

      var shouldAddLightning = false;

      if (delay1[0] > 0 || delay1[1] > 0) {
        shouldAddLightning = true;
      } else if (delay2[0] > 0 || delay2[1] > 0) {
        shouldAddLightning = true;
      } else if (delay3[0] > 0 || delay3[1] > 0) {
        shouldAddLightning = true;
      } else {
        shouldAddLightning = false;
      }

      if (shouldAddLightning) {
        shouldAddPolicyWarning = true;
        warnings.push('Lightning');
      }
    }

    threatTypes.forEach(threatType => {
      /// This if statement is janky, but basically if there is a policyViolation for it we want to skip this.
      // if ((policyViolationKeys == null) || (policyViolationKeys && policyViolationKeys.length == 0) || (policyViolationKeys && policyViolationKeys.length > 0 && !policyViolationKeys.includes(threatType))) {
      if (policyViolationKeys && policyViolationKeys.length > 0 && policyViolationKeys.includes(threatType)) {
        let policy = policies ? GetFirstPolicy(policies, threatType) : undefined;
        if (policy) {
          shouldAddPolicyWarning = true;
          warnings.push(policy.policyTypeName);
        }
      } else {
        let policy = policies ? GetFirstPolicy(policies, threatType) : undefined;
        let value =
          policy && policy.policyTypeShortName && observation
            ? getConditionValue(policy.policyTypeShortName, observation)
            : undefined;

        var threat = threats.find(x => x.threatType === threatType);

        if (policy && value !== undefined && isSevere(value, policy)) {
          let exceedingText = policy.isDesc ? 'falling below' : 'exceeding';

          returnComponents.push(
            <Grid item xs={6} display='flex' flex={'1 1 auto'} key={threatType}>
              <ThreatBanner variant={'error'}>
                <Grid item>
                  {GetThreatIcon(threatType as PolicyTypeName, theme.palette.error.main, 40, 40)}
                  <Typography color={theme.palette.text.primary}>
                    {GetPolicyText(threatType as PolicyTypeName)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h5'>{GetThreatLevelText(2)}</Typography>
                </Grid>
                <Grid item>
                  <Typography textAlign='center' color={theme.palette.text.primary}>
                    {exceedingText}
                    <Typography component='span' textAlign='center' variant='subtitle1'>
                      {' '}
                      {policy.threshold} {policy.unit}{' '}
                    </Typography>
                  </Typography>
                </Grid>
              </ThreatBanner>
            </Grid>
          );
        } else if (threat && threat.threatLevel > 0) {
          let threatColor = GetThreatColor(threat.threatLevel);
          let exceedingText = threat.isDesc ? 'falling below' : 'exceeding';

          var timeStr: string[] = [];
          if (threat.threatTimes) {
            threat.threatTimes.forEach(interval => {
              var startTimeStr = GetShortTime(interval.startTime);
              var endTimeStr = GetShortTime(interval.endTime);
              timeStr.push(startTimeStr + ' - ' + endTimeStr);
            });
          } else if (threat.threatType === 'PRECIP') {
            timeStr.push('Next 24hr');
          }

          returnComponents.push(
            <Grid item xs={6} display='flex' flex={'1 1 auto'} key={threatType}>
              <ThreatBanner variant={threat.threatLevel === 2 ? 'error' : 'warn'}>
                <Grid item>
                  {GetThreatIcon(threat.threatType as PolicyTypeName, threatColor!, 40, 40)}
                  <Typography color={theme.palette.text.primary}>
                    {GetPolicyText(threat.threatType as PolicyTypeName)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h5'>{GetThreatLevelText(threat.threatLevel)}</Typography>
                </Grid>
                <Grid item>
                  <Typography textAlign='center' color={theme.palette.text.primary}>
                    {exceedingText}
                    <Typography component='span' textAlign='center' variant='subtitle1'>
                      {' '}
                      {threat.threshold} {threat.unit}{' '}
                    </Typography>
                  </Typography>
                  <Typography textAlign='center' color={theme.palette.text.primary}>
                    {timeStr.join(', ')}
                  </Typography>
                </Grid>
              </ThreatBanner>
            </Grid>
          );
        }
      }
    });

    // if(returnComponents.length === 0)
    if (returnComponents.length === 0 && !shouldAddPolicyWarning) {
      let threatColor = GetThreatColor(0);
      return (
        <Grid item key={'NONE'} display='flex' flexDirection='column' alignItems='center'>
          {GetThreatIcon('NONE', threatColor!, 40, 40)}
          <Typography variant='h5' color={theme.palette.success.main}>
            {'Looking Good Today'}
          </Typography>
        </Grid>
      );
    } else {
      return returnComponents;
    }
  };

  const PolicyWarnings = (warnings: string[]) => {
    return (
      <Grid item xs={12} display='flex' alignItems='center' key={`warning${warnings.length}`}>
        {GetThreatIcon('NWS', theme.palette.error.main, 20, 20)}
        <Typography marginLeft={1} textAlign='left'>
          Policy Warnings in effect: {CreateText(warnings)}{' '}
        </Typography>
      </Grid>
    );
  };

  const NoThreatInfo = (threats: Threat[], observation?: Observation, policies?: Policy[]) => {
    var elements: JSX.Element[] = [];
    var noThreats = threats.filter(threat => threat.threatLevel === 0);

    if (noThreats.length > 0) {
      let count = 0;

      var noThreatText = () =>
        noThreats.map(threat => {
          var policy = policies ? GetFirstPolicy(policies, threat.threatType) : undefined;
          var value =
            policy && policy.policyTypeShortName && observation
              ? getConditionValue(policy.policyTypeShortName, observation)
              : undefined;

          if (policy && value !== undefined && isSevere(value, policy)) {
            return null;
          }

          var threatName = GetPolicyText(threat.threatType as PolicyTypeName);
          var listOfThresholds: string[] = [];
          var filteredPolicies = policies
            ?.filter(policy => policy.policyTypeShortName === threat.threatType)
            .sort(function (a, b) {
              return a.threshold - b.threshold;
            });

          if (filteredPolicies) {
            filteredPolicies.forEach(policy => {
              listOfThresholds.push(policy.threshold + ' ' + threat.unit);
            });
          }

          count++;
          return (
            <Tooltip
              key={count}
              title={<Typography>Your policy is set to {listOfThresholds.join(', ')}.</Typography>}
              arrow
              placement='top'
              enterDelay={300}
              leaveDelay={200}>
              <Typography
                component='span'
                variant='subtitle1'
                sx={{ textDecorationLine: 'underline', textDecorationStyle: 'dotted' }}>
                {' '}
                {threatName}
                {count < noThreats.length ? ',' : ''}
              </Typography>
            </Tooltip>
          );
        });

      elements.push(
        <Grid item xs={12} display='flex' alignItems='center' key={`nothreat${count}`}>
          <Checkmark width={20} height={20} color={theme.palette.success.main} />
          <Typography marginLeft={1} textAlign='left'>
            Low Risk Today: {noThreatText()}{' '}
          </Typography>
        </Grid>
      );
    }

    return elements;
  };

  const isScary = (observation?: Observation, policies?: Policy[]) => {
    if (policies !== undefined && policies.length > 0 && threatList) {
      var threat = threatList.find(value => {
        return value.threatLevel > 1;
      });
      if (threat) {
        return true;
      } else {
        threatTypes.forEach(threatType => {
          var policy = policies ? GetFirstPolicy(policies, threatType) : undefined;
          var value =
            policy && policy.policyTypeShortName && observation
              ? getConditionValue(policy.policyTypeShortName, observation)
              : undefined;

          if (policy && value !== undefined && isSevere(value, policy)) {
            return true;
          }
        });
      }
    }
    return false;
  };

  return (
    <Card header="Today's Policy Risks" scary={isScary(observationData, policiesData)}>
      {policiesData && policiesData.length === 0 ? (
        <Typography>No Policies</Typography>
      ) : threatList && threatList.length > 0 ? (
        <Grid container display='flex' alignItems='flex-start' alignContent='flex-start'>
          <Grid item container display='flex' justifyContent='space-around' spacing={2}>
            {threatComponents(threatList, observationData, policiesData)}
          </Grid>
          {warnings.length > 0 ? (
            <Grid item container display='flex' justifyContent='space-around' marginTop={2} spacing={2}>
              {PolicyWarnings(warnings)}
            </Grid>
          ) : (
            <></>
          )}
          <Grid item container display='flex' alignItems='flex-start' marginTop={2} spacing={2}>
            {NoThreatInfo(threatList, observationData, policiesData)}
          </Grid>
        </Grid>
      ) : (
        <Typography>No Data</Typography>
      )}
    </Card>
  );
}

export default ThreatsForecast;
