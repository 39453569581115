/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEvent, useEffect, useState } from 'react';
import { IconButton, useMediaQuery } from '@mui/material';
import { LocationCard } from './LocationCard';
import { Location } from '../../../../Services/API';
import { getSelectedLocation } from '../../../../features/dash/dashSlice';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useAppSelector } from '../../../../app/hooks';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { useTheme } from '@mui/styles';

interface ScrollingComponentProps {
  Locations?: Location[];
  onLocationSelect?: (location: Location) => void;
}

interface ScrollingComponentProps {
  Locations?: Location[];
}

const elemPrefix = 'locations-';
const getId = (index: string) => `${elemPrefix}${index}`;

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

export default function ScrollingComponent(props: ScrollingComponentProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { Locations, onLocationSelect } = props;
  const apiRef = React.useRef({} as scrollVisibilityApiType);
  const selectedLocation = useAppSelector(getSelectedLocation);

  const [locationsIn, setLocationsIn] = useState<boolean>(true);

  const scrollToPos = ({ getItemById, scrollToItem }: scrollVisibilityApiType) => {
    const location = getItemById(getId(selectedLocation?.id!));
    scrollToItem(location, 'auto', 'start');
  };

  useEffect(() => {
    // the ref doesn't seem to be valid yet, or the selectedLocation doesnt seem to be updated into the tree yet...
    if (selectedLocation) {
      apiRef.current.scrollToItem(apiRef.current.getItemById(getId(selectedLocation?.id!)), 'auto', 'start');
    }
  }, [selectedLocation, Locations]);

  // useEffect(() => { /// If I do this potentially a lot of calls to it.
  // 	if (selectedLocation) {
  // 		apiRef.current.scrollToItem(apiRef.current.getItemById(getId(selectedLocation?.id!)), "auto", "start");
  // 	}
  // })

  const handleLocSelect = (loc: Location) => {
    setLocationsIn(false);
    setTimeout(() => setLocationsIn(true), 500);

    if (onLocationSelect) onLocationSelect(loc);
  };

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);

    return (
      <IconButton
        sx={{
          position: 'absolute',
          left: 0,
          width: 100,
          height: '100%',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          zIndex: 10,
          backgroundColor: 'transparent',
          color: 'transparent',
          '&:hover': {
            background: `linear-gradient(270deg, ${theme.palette.background.default}00 00%, ${theme.palette.background.default}FF 80%)`,
            color: theme.palette.text.primary,
          },
          '&.Mui-disabled': {
            color: 'transparent',
          },
        }}
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}>
        <KeyboardArrowLeftIcon sx={{ width: 75, height: 75 }} />
      </IconButton>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
      <IconButton
        sx={{
          position: 'absolute',
          right: 0,
          width: 100,
          height: '100%',
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          backgroundColor: 'transparent',
          color: 'transparent',
          zIndex: 10,
          '&:hover': {
            background: `linear-gradient(90deg, ${theme.palette.background.default}00 00%, ${theme.palette.background.default}FF 80%)`,
            color: theme.palette.text.primary,
          },
          '&.Mui-disabled': {
            color: 'transparent',
          },
        }}
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}>
        <KeyboardArrowRightIcon sx={{ width: 75, height: 75 }} />
      </IconButton>
    );
  }

  const locationComponents = () => {
    if (Locations) {
      let delay = 0;
      let result: JSX.Element[] = [];
      Locations.filter(x => x.id !== selectedLocation?.id).forEach((item, key) => {
        result.push(
          <LocationCard
            onSelect={handleLocSelect}
            key={key}
            location={item}
            itemId={getId(item!.id!)}
            style={{ transitionDelay: locationsIn ? delay.toString() + 'ms' : '0ms' }}
            show={locationsIn}
          />
        );
        delay += 200;
      });

      return result;
    } else return <div key={0}></div>;
  };

  return (
    <div style={{ position: 'relative' }}>
      <ScrollMenu
        RightArrow={!isMobile && RightArrow}
        LeftArrow={!isMobile && LeftArrow}
        apiRef={apiRef}
        onInit={scrollToPos}>
        {locationComponents()}
      </ScrollMenu>
    </div>
  );
}
