import { api } from './api';

export interface AirQualityVM {
  id: string;
  name: string;
  customerId: number;
  customerName: string;
  weatherStationId: string;
  weatherStationName: string;
  lastActivity?: Date;
  pM2_5: PM2_5;
}

export interface AirQuality {
  pM2_5: PM2_5;
}

export interface PM2_5 {
  now?: number;
  '10-Minutes'?: number;
  '30-Minutes'?: number;
  '1-Hour'?: number;
  '1-Day'?: number;
  '1-Week'?: number;
}

export type PollutantData = {
  pollutantName: string;
  pollutantType: number;
  rawData: RawData;
  aqiData: AQIData;
  source: number;
  distanceInMiles: number;
  utcTimeStamp: string;
};

export type RawData = {
  oneHourAvgMeasurement?: number;
  oneHourMaxMeasurement?: number;
  eightHoursAvgMeasurement?: number;
  eightHoursMaxMeasurement?: number;
  twelveHoursAvgMeasurement?: number;
  twelveHoursMaxMeasurement?: number;
  twentyFourHoursAvgMeasurement?: number;
  twentyFourHoursMaxMeasurement?: number;
  airQualitySensorType: number;
  sensorInformation: SensorInformation;
};

export type SensorInformation = {
  id: string;
  provider: string;
  latitude: number;
  longitude: number;
};

export type AQIData = {
  nowCastIndex?: AQIIndex;
  oneHourIndex?: AQIIndex;
  eightHourIndex?: AQIIndex;
  twentyFourHourIndex?: AQIIndex;
};

export type AQIIndex = {
  value?: number;
  concernLevelType: number;
  color: string;
  shortDescription: string;
  description: string;
};

export type AirQualityCategory = {
  id: number;
  color: string;
  concernLevel: number;
  minIndexValue: number;
  maxIndexValue: number;
  description: string;
}

const airQualityApi = api.injectEndpoints({
  endpoints: builder => ({
    airQualitySensors: builder.query<AirQualityVM[], void>({
      query: id => ({ url: 'AirQuality' }),
      transformResponse: (response: { data: AirQualityVM[] }) => response.data,
    }),
    getAirQualityForLocation: builder.query<PollutantData[], string>({
      query: locationId => `AirQuality/Location/${locationId}`,
      transformResponse: (response: { data: PollutantData[] }) => response.data,
      providesTags: (_result, _error, arg) => [{ type: 'AirQuality', id: arg }]
    }),
    getAirQualityCategories: builder.query<AirQualityCategory[], void>({
      query: () => 'AirQuality/Categories',
      transformResponse: (response: { data: AirQualityCategory[] }) => response.data,
    }),
  }),
});

export const { useAirQualitySensorsQuery, useGetAirQualityForLocationQuery, useGetAirQualityCategoriesQuery } = airQualityApi;
