import { Layer, LayerProps, Source } from 'react-map-gl';
import { useTropicalModelsQuery } from '../../../../Services/API';

export function TropicalModelLayer() {
  const { data } = useTropicalModelsQuery(null, {
    pollingInterval: 1 * 60000,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const layerStyle: LayerProps = {
    id: 'tropical-models',
    type: 'raster',
    minzoom: 0,
    maxzoom: 14,
    paint: {},
  };

  return data ? (
    <Source id='tropical-models' type='raster' tileSize={256} tiles={[data]} scheme='tms'>
      <Layer beforeId='admin_sub' {...layerStyle} />
    </Source>
  ) : (
    <></>
  );
}
export default TropicalModelLayer;
