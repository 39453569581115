import { Box, Divider, Typography } from '@mui/material';
import { PWColors } from '../../Theme/PWColors';

interface SubFormContainerProps {
  subheader?: string;
  containerDesc?: string;
  disableDivider?: boolean;
  children: React.ReactNode;
}

export function SubFormContainer(props: SubFormContainerProps) {
  const { subheader, containerDesc, children, disableDivider = false } = props;

  return (
    <Box width='100%' height='100%'>
      {!disableDivider && <Divider style={{ marginBottom: 16 }} />}
      {subheader && <Typography variant='subtitle1'>{subheader}</Typography>}
      {containerDesc && (
        <Typography variant='body2' style={{ marginBottom: 24, color: PWColors.dark.base[100] }}>
          {containerDesc}
        </Typography>
      )}
      {children}
    </Box>
  );
}
