import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import counterReducer from '../features/counter/counterSlice';
import menuReducer from '../features/menu/menuSlice';
import authReducer from '../Services/Auth/authSlice';
import toastReducer from '../features/toast/toastSlice';
import { api, identApi } from '../Services/API';
import dashReducer from '../features/dash/dashSlice';
import mapReducer from '../features/map/mapSlice';
import { singlewireApi } from '../Services/API';

export const store = configureStore({
  reducer: {
    dash: dashReducer,
    counter: counterReducer,
    menu: menuReducer,
    map: mapReducer,
    toast: toastReducer,
    [api.reducerPath]: api.reducer,
    [identApi.reducerPath]: identApi.reducer,
    [singlewireApi.reducerPath]: singlewireApi.reducer,
    auth: authReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(singlewireApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
