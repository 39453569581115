import { Box, Fade, Typography } from '@mui/material';
import { LngLat } from 'maplibre-gl';
import { useAppSelector } from '../../../../app/hooks';
import { getSelectedLocation } from '../../../../features/dash/dashSlice';
import { getDistance } from '../../../../features/map/MapHelpers';

interface StrikeViewProps {
  clickedInfo: any;
}

export function TropicalForecastView(props: StrikeViewProps) {
  const { clickedInfo } = props;
  console.log(clickedInfo);
  return (
    <Fade in={true}>
      <Box
        style={{
          borderRadius: 8,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          margin: 8,
          padding: 8,
          background: 'rgba(0,0,0,0.8)',
          color: '#fff',
          textAlign: 'left',
          minWidth: 140,
          fontSize: 10,
          zIndex: 9,
          pointerEvents: 'none',
          left: clickedInfo ? clickedInfo.x - 100 : 0,
          top: clickedInfo ? clickedInfo.y - 160 : 0,
        }}>
        <Typography variant='caption'> Name: {clickedInfo.feature.properties.name}</Typography>
        <Typography variant='caption'> Issue time: {clickedInfo.feature.properties.time}</Typography>
        <Typography variant='caption'> Category: {clickedInfo.feature.properties.category}</Typography>
        <Typography variant='caption'> Pressure: {clickedInfo.feature.properties.pressure}</Typography>
        <Typography variant='caption'> Wind Speed: {clickedInfo.feature.properties.windSpeed}</Typography>
        <Typography variant='caption'> Gust: {clickedInfo.feature.properties.gust}</Typography>
        <Typography variant='caption'> Movement: {clickedInfo.feature.properties.movement}</Typography>
      </Box>
    </Fade>
  );
}

export default TropicalForecastView;
