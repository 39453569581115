import * as React from 'react';

export const Marker = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width} height={props.height} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.9997 35.8965C19.9628 35.8528 19.9234 35.8059 19.8817 35.7561C19.5652 35.3783 19.1124 34.8284 18.5686 34.1423C17.4807 32.7694 16.0311 30.8537 14.5824 28.6807C13.1327 26.5061 11.6901 24.0831 10.6116 21.6949C9.52938 19.2986 8.83301 16.9789 8.83301 14.9997C8.83301 8.83248 13.8325 3.83301 19.9997 3.83301C26.1669 3.83301 31.1663 8.83248 31.1663 14.9997C31.1663 16.9789 30.47 19.2986 29.3877 21.6949C28.3092 24.0831 26.8667 26.5061 25.417 28.6807C23.9683 30.8537 22.5186 32.7694 21.4307 34.1423C20.887 34.8284 20.4341 35.3783 20.1177 35.7561C20.0759 35.8059 20.0366 35.8528 19.9997 35.8965ZM15.333 14.9997C15.333 17.5775 17.4219 19.6663 19.9997 19.6663C22.5775 19.6663 24.6663 17.5775 24.6663 14.9997C24.6663 12.4219 22.5775 10.333 19.9997 10.333C17.4219 10.333 15.333 12.4219 15.333 14.9997Z'
      fill={props.color}
      stroke='#121A24'
    />
  </svg>
);
