import { Source, Layer, LayerProps } from 'react-map-gl';
import { RadarParams, useRadarQuery } from '../../../../Services/API';
import { RasterPaint, Map } from 'maplibre-gl';
import { useState, useEffect } from 'react';
import { getOpacity, getOverlays } from '../../../../features/map/mapSlice';
import { useAppSelector } from '../../../../app/hooks';
import { Viewport } from '../Util';

interface AlertLayerProps {
  hasLocation?: boolean;
  viewport: Viewport;
  mapRef: Map;
}

export function NWSAlertLayer(props: AlertLayerProps) {
  const opacity = useAppSelector(getOpacity);
  const chosenLayers = useAppSelector(getOverlays);

  const [layerParams] = useState<RadarParams>({ type: 'Alert', isFuture: false, frame: 0 });

  const { data } = useRadarQuery(layerParams, {
    pollingInterval: 1 * 60000,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const { mapRef, hasLocation } = props;

  useEffect(() => {
    let source: any = mapRef.getSource('alert');
    if (data && source) {
      source.tiles = [data!];
      source.url = data!;

      let mapAny: any = mapRef as any;
      mapAny.getSource('alert').tiles = [data!];
      mapAny.style.sourceCaches['alert'].clearTiles();

      // // Load the new tiles for the current viewport (map.transform -> viewport)
      mapAny.style.sourceCaches['alert'].update(mapAny.transform);
      mapAny.triggerRepaint();
    }
  }, [data, mapRef]);

  const paints: RasterPaint = {
    'raster-opacity': opacity,
  };

  const layerStyle: LayerProps = {
    id: 'alert',
    type: 'raster',
    minzoom: 0,
    maxzoom: 14,
    paint: paints,
  };

  const url: string = data
    ? data
    : 'https://st3.depositphotos.com/10839834/32287/v/950/depositphotos_322878930-stock-illustration-transparent-checkered-chess-background.jpg';

  const staticTmsSource = mapRef.getSource('static-tms-radar');
  const staticImageSource = mapRef.getSource('static-image-radar');
  const playImageSource = mapRef.getSource('play-image-radar');
  const scrubImageSource = mapRef.getSource('scrub-image-radar');
  const hasLightningZonesLayer = mapRef.getLayer('radii-1');

  const hasRadarLayer = staticTmsSource || staticImageSource || playImageSource || scrubImageSource;

  return (
    <>
      <Source id='alert' type='raster' tileSize={256} tiles={[url]} scheme='tms'>
        {staticTmsSource && <Layer beforeId='static-tms-radar' {...layerStyle} />}
        {staticImageSource && <Layer beforeId='static-image-radar' {...layerStyle} />}
        {playImageSource && <Layer beforeId='play-image-radar' {...layerStyle} />}
        {scrubImageSource && <Layer beforeId='scrub-image-radar' {...layerStyle} />}

        {!hasRadarLayer && (
          <Layer beforeId={hasLightningZonesLayer ? 'radii-1' : 'admin_sub'} {...layerStyle} />
        )}
      </Source>
    </>
  );
}

export default NWSAlertLayer;
