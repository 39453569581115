import { api } from '.';

export interface AlertTime {
  id: string;
  day: number;
  startTime: string;
  endTime: string;
  enabled: boolean;
  allDay: boolean;
  start: string;
  end: string;
}

const alertTimesApi = api.injectEndpoints({
  endpoints: builder => ({
    alertTimes: builder.query<AlertTime[], void>({
      query: () => 'Settings/AlertTimes',
      transformResponse: (response: { data: AlertTime[] }) => response.data,
      providesTags: ['NotificationSettings'],
    }),
    updateAlertTimes: builder.mutation<string, AlertTime[]>({
      query: alertTimes => ({
        url: 'Settings/AlertTimes',
        method: 'POST',
        body: alertTimes,
      }),
      transformResponse: (response: { data: string }) => response.data,
      invalidatesTags: ['NotificationSettings'],
    }),
  }),
});

export const { useAlertTimesQuery, useUpdateAlertTimesMutation } = alertTimesApi;
