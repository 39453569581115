import { Paper, PaperProps, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { CSSProperties, memo } from 'react';

export type BreadVariant = 'error' | 'warn' | 'info' | 'success';

interface StyledPaperProps extends PaperProps {
  breadvariant: BreadVariant;
}

interface BannerProps {
  variant: BreadVariant;
  children?: JSX.Element | JSX.Element[];
  sx?: CSSProperties;
}

const StyledPaper = styled(Paper, {
  shouldForwardProp: prop => prop !== 'success',
})<StyledPaperProps>(({ breadvariant, theme }) => ({
  padding: '8px 16px 8px 16px',
  width: '100%',
  borderRadius: 6,
  borderWidth: 1,
  borderStyle: 'solid',
  ...(breadvariant === 'error' && {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.background.error,
  }),
  ...(breadvariant === 'warn' && {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    backgroundColor: theme.palette.background.warning,
  }),
  ...(breadvariant === 'info' && {
    color: theme.palette.info.light,
    borderColor: theme.palette.info.light,
    backgroundColor: theme.palette.info.dark,
  }),
  ...(breadvariant === 'success' && {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    backgroundColor: theme.palette.background.success,
  }),
}));

export const ThreatBanner = memo(function ThreatBanner(props: BannerProps) {
  const { variant, children, sx } = props;
  const theme = useTheme();

  return (
    <StyledPaper breadvariant={variant} theme={theme} sx={sx}>
      <Grid container display='flex' flexDirection='column' height='100%'>
        {children}
      </Grid>
    </StyledPaper>
  );
});
