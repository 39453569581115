import * as React from 'react';

export const CellularNetworkIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? '14'}
    height={props.height ?? '14'}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.28643 0.953125C-0.311624 3.55118 -0.311624 7.78215 2.28643 10.3802L3.22914 9.4375C1.14053 7.34889 1.14053 3.98444 3.22914 1.89583L2.28643 0.953125ZM11.7135 0.953125L10.7708 1.89583C12.8594 3.98444 12.8594 7.34889 10.7708 9.4375L11.7135 10.3802C14.3116 7.78215 14.3116 3.55118 11.7135 0.953125ZM4.17185 2.83854C2.6152 4.39519 2.6152 6.93814 4.17185 8.49479L5.11456 7.55208C4.06721 6.50473 4.06721 4.8286 5.11456 3.78125L4.17185 2.83854ZM9.8281 2.83854L8.88539 3.78125C9.93274 4.8286 9.93274 6.50473 8.88539 7.55208L9.8281 8.49479C11.3847 6.93814 11.3847 4.39519 9.8281 2.83854ZM6.99997 4.33333C6.64635 4.33333 6.30721 4.47381 6.05717 4.72386C5.80712 4.97391 5.66664 5.31305 5.66664 5.66667C5.66683 5.90054 5.72852 6.13024 5.84554 6.33274C5.96255 6.53523 6.13077 6.70338 6.33331 6.82031V13.6667H7.66664V6.81901C7.86899 6.70219 8.03708 6.53424 8.15409 6.332C8.27109 6.12975 8.33289 5.90032 8.33331 5.66667C8.33331 5.31305 8.19283 4.97391 7.94278 4.72386C7.69274 4.47381 7.3536 4.33333 6.99997 4.33333Z'
      fill={props.color}
    />
  </svg>
);
