//9004-Night

export const MostlyCloudyNight = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M6.36,2.15C6.26,2.43,6.21,2.71,6.21,3c0.02,1.26,1.07,2.27,2.32,2.25c0.05,0,0.1,0,0.16-0.01
            C8.5,5.46,8.26,5.62,8,5.74c0.14,0.04,0.27,0.08,0.39,0.14C8.68,5.7,8.94,5.46,9.12,5.15c0.05-0.08,0.1-0.17,0.14-0.26
            c0.01-0.01,0.01-0.04-0.01-0.05S9.22,4.81,9.21,4.82C9.11,4.86,9.02,4.89,8.94,4.9C8.8,4.93,8.66,4.95,8.53,4.95
            C7.44,4.97,6.53,4.09,6.51,3C6.51,2.66,6.59,2.31,6.76,2.01c0.05-0.08,0.1-0.16,0.15-0.23c0.01-0.01,0.01-0.04,0.01-0.05
            C6.91,1.71,6.89,1.7,6.87,1.7c-0.1,0.01-0.19,0.03-0.3,0.06C5.73,1.99,5.14,2.68,4.97,3.51c0.09,0.03,0.19,0.06,0.28,0.1
            C5.36,2.97,5.76,2.42,6.36,2.15z'
      />
      <path
        className='st1'
        d='M1.98,7.73c-0.5-0.1-0.86-0.53-0.86-1.05c0-0.6,0.49-1.08,1.08-1.08c0.08,0,0.17,0.01,0.26,0.03
            c0.06,0.01,0.11,0,0.15-0.03c0.05-0.03,0.08-0.08,0.08-0.13c0.12-0.79,0.79-1.36,1.59-1.36c0.19,0,0.38,0.04,0.56,0.11
            c0.19-0.06,0.39-0.09,0.6-0.1C5.43,4.12,5.43,4.11,5.42,4.1C5.09,3.86,4.69,3.74,4.28,3.74c-0.92,0-1.7,0.61-1.93,1.49
            c-0.87-0.1-1.62,0.59-1.62,1.45c0,0.75,0.56,1.37,1.31,1.45C2.01,8.01,1.98,7.87,1.98,7.73z'
      />
      <path
        className='st1'
        d='M7.58,5.99c-0.05,0-0.11,0-0.16,0.01C7.2,5.12,6.42,4.51,5.5,4.51c-1.08,0-1.96,0.87-1.99,1.94
            C2.87,6.49,2.36,7.03,2.36,7.68c0,0.68,0.55,1.23,1.23,1.23h3.99c0.81,0,1.46-0.66,1.46-1.46C9.05,6.64,8.39,5.99,7.58,5.99z
                M3.59,8.53c-0.47,0-0.85-0.38-0.85-0.85c0-0.47,0.38-0.85,0.85-0.85c0.03,0,0.06,0,0.09,0.01c0.06,0,0.11-0.01,0.15-0.05
            C3.88,6.74,3.9,6.68,3.9,6.63c0-0.04-0.01-0.09-0.01-0.13c0-0.89,0.72-1.61,1.61-1.61c0.8,0,1.47,0.57,1.59,1.36
            C7.1,6.3,7.13,6.35,7.17,6.37c0.05,0.03,0.1,0.04,0.15,0.03C7.41,6.38,7.5,6.37,7.58,6.37c0.6,0,1.08,0.49,1.08,1.08
            c0,0.6-0.49,1.08-1.08,1.08c0,0-0.01,0-0.01,0c-0.02,0-0.04,0-0.06,0H3.59z'
      />
    </g>
  </svg>
);
