//0021

export const Thunderstorm = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <g fill='currentColor'>
        <path
          className='st4'
          d='M3.04,6.07l-0.75,1.5c-0.05,0.09-0.01,0.2,0.08,0.25C2.4,7.83,2.43,7.84,2.45,7.84c0.07,0,0.14-0.04,0.17-0.1
                    l0.75-1.5C3.4,6.19,3.4,6.14,3.38,6.09C3.37,6.04,3.33,6,3.29,5.98C3.2,5.94,3.09,5.97,3.04,6.07z'
        />
        <path
          className='st4'
          d='M4.4,5.97C4.35,5.99,4.32,6.02,4.3,6.07l-0.75,1.5C3.5,7.66,3.53,7.77,3.63,7.82
                    c0.03,0.01,0.05,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1l0.75-1.5c0.02-0.04,0.03-0.1,0.01-0.14C4.62,6.04,4.59,6,4.55,5.98
                    C4.5,5.96,4.45,5.95,4.4,5.97z'
        />
        <path
          className='st4'
          d='M8.06,6.07l-0.75,1.5C7.26,7.66,7.3,7.77,7.39,7.82c0.03,0.01,0.05,0.02,0.08,0.02c0.07,0,0.14-0.04,0.17-0.1
                    l0.75-1.5C8.42,6.19,8.42,6.14,8.4,6.09S8.35,6,8.31,5.98C8.22,5.94,8.11,5.97,8.06,6.07z'
        />
        <path
          className='st4'
          d='M7.64,2.63c-0.05,0-0.1,0-0.16,0.01C7.27,1.77,6.5,1.17,5.6,1.17c-1.06,0-1.93,0.85-1.95,1.91
                    C3.01,3.12,2.51,3.65,2.51,4.29c0,0.67,0.54,1.21,1.21,1.21h3.92c0.79,0,1.44-0.64,1.44-1.44C9.08,3.27,8.43,2.63,7.64,2.63z
                    M3.73,5.12c-0.46,0-0.84-0.38-0.84-0.84c0-0.46,0.38-0.84,0.84-0.84c0.03,0,0.06,0,0.09,0.01c0.06,0,0.11-0.01,0.15-0.05
                    c0.04-0.04,0.06-0.1,0.06-0.15c0-0.04-0.01-0.08-0.01-0.13c0-0.87,0.71-1.58,1.58-1.58c0.78,0,1.44,0.56,1.56,1.33
                    c0.01,0.05,0.04,0.1,0.08,0.13c0.04,0.03,0.1,0.04,0.15,0.03C7.47,3.01,7.56,3,7.64,3C8.23,3,8.7,3.48,8.7,4.06
                    c0,0.59-0.48,1.06-1.06,1.06c0,0-0.01,0-0.01,0c-0.02,0-0.04,0-0.06,0H3.73z'
        />
        <path
          className='st4'
          d='M6.73,7H6.27l0.38-0.98c0.04-0.1,0-0.21-0.09-0.26C6.53,5.73,6.49,5.72,6.45,5.72
                    c-0.06,0-0.12,0.03-0.16,0.07L4.7,7.61C4.64,7.67,4.63,7.76,4.66,7.84s0.11,0.13,0.2,0.13h0.5L4.79,9.28
                    c-0.04,0.1-0.01,0.22,0.09,0.27c0.03,0.02,0.07,0.03,0.11,0.03c0.06,0,0.13-0.03,0.17-0.08L6.9,7.35C6.95,7.29,6.96,7.2,6.93,7.12
                    C6.89,7.05,6.81,7,6.73,7z M4.99,9.36l0.7-1.61H4.86l1.59-1.82l-0.5,1.28h0.78L4.99,9.36z'
        />
      </g>
      <path
        className='st4'
        d='M2.14,4.33C1.66,4.23,1.3,3.82,1.3,3.31c0-0.59,0.48-1.06,1.06-1.06c0.08,0,0.17,0.01,0.26,0.03
                c0.05,0.01,0.11,0,0.15-0.03c0.04-0.03,0.08-0.08,0.08-0.13C2.97,1.35,3.62,0.79,4.4,0.79c0.19,0,0.37,0.04,0.55,0.11
                c0.19-0.06,0.39-0.09,0.59-0.1C5.53,0.79,5.53,0.78,5.52,0.77C5.19,0.54,4.81,0.42,4.4,0.42c-0.9,0-1.67,0.6-1.89,1.46
                C1.66,1.79,0.92,2.47,0.92,3.31c0,0.73,0.55,1.34,1.29,1.42C2.17,4.61,2.15,4.47,2.14,4.33z'
      />
    </g>
  </svg>
);
