import { memo } from 'react';
import { Snackbar, SnackbarCloseReason } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Bread, BreadVariant } from './Bread';
import { Check, ErrorOutline, InfoOutlined, WarningAmber } from '@mui/icons-material';

export interface ToastProps {
  variant: BreadVariant;
  header: string;
  message?: string;
  show: boolean;
  autoHideDuration?: number;
  onClose?: (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void;
}

export const Toast = memo(function Snack(props: ToastProps) {
  const theme = useTheme();
  const { variant, header, message, show, onClose, autoHideDuration = 3000 } = props;

  const iconElem = () => {
    switch (variant) {
      case 'success':
        return <Check />;
      case 'error':
        return <ErrorOutline />;
      case 'warn':
        return <WarningAmber />;
      case 'info':
        return <InfoOutlined />;
      default:
        return undefined;
    }
  };

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      transitionDuration={500}
      onClose={onClose}
      open={show}
      style={{
        position: 'fixed',
        top: '80px',
        right: '2%',
        bottom: 'auto',
        left: 'auto',
        minWidth: '10%',
        [theme.breakpoints.up('sm')]: {
          maxWidth: '20%',
        },
      }}>
      <div>
        <Bread variant={variant} header={header} icon={iconElem()} message={message} closeable />
      </div>
    </Snackbar>
  );
});
