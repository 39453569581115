import { CSSProperties } from 'react';
import { _useMapControl as useMapControl } from 'react-map-gl';
import { Box, Grow, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LightningStrike } from '../../../../Assets/LightningStrike';
import { PWColors } from '../../../../Theme/PWColors';
import BaseControl from './BaseControl';

export interface LegendControlProps {
    onClick?: React.MouseEventHandler;
    show: boolean;
}

export function LegendControl(props: LegendControlProps) {
    const { onClick, show } = props;

    const stopPropagation = (evt: any) => evt.stopPropagation();

    const { containerRef } = useMapControl({
        onDragStart: stopPropagation,
        onDoubleClick: stopPropagation,
        onClick: stopPropagation,
    });

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    // essentially need position absolute if being used standalone, i.e, not inside the group.
    const style: CSSProperties = {
        right: 10,
        top: 10,
        position: 'absolute',
        backgroundColor: '#fff',
        borderRadius: 100,
    };

    var controlClass: string = `mapbroxgl-ctrl mapboxgl-ctrl-group`;

    return !isMd ? (
        <div style={{ ...style, borderRadius: 100 }} className={controlClass} ref={containerRef}>
            <Box
                alignItems='center'
                justifyContent='space-around'
                display='flex'
                flexDirection='row'
                paddingLeft={2}
                paddingRight={2}
                width={300}
                height={40}>
                <LightningStrike color={PWColors.light.danger[500]} />
                <Typography color={PWColors.light.base[900]}>0-5 min</Typography>
                <LightningStrike color={PWColors.light.warning[500]} />
                <Typography color={PWColors.light.base[900]}>5-15 min</Typography>
                <LightningStrike color={PWColors.light.success[500]} />
                <Typography color={PWColors.light.base[900]}>15-30 min</Typography>
            </Box>
        </div>
    ) : (
        <div style={{ zIndex: 1, marginBottom: '10px' }}>
            <Grow in={show}>
                <div
                    style={{ ...style, borderRadius: 8, zIndex: 1000, marginRight: 40 }}
                    className={controlClass}
                    ref={containerRef}
                    onClick={onClick}>
                    <Box
                        alignItems='left'
                        justifyContent='space-around'
                        display='flex'
                        flexDirection='column'
                        paddingLeft={1}
                        paddingRight={1}
                        width={120}
                        height={140}>
                        <Box width='100%' display='flex' justifyContent='space-around' flexDirection='row'>
                            <LightningStrike color={PWColors.light.danger[500]} />
                            <Typography color={PWColors.light.base[900]}>0-5 min</Typography>
                        </Box>
                        <Box width='100%' display='flex' justifyContent='space-around' flexDirection='row'>
                            <LightningStrike color={PWColors.light.warning[500]} />
                            <Typography color={PWColors.light.base[900]}>5-15 min</Typography>
                        </Box>
                        <Box width='100%' display='flex' justifyContent='space-around' flexDirection='row'>
                            <LightningStrike color={PWColors.light.success[500]} />
                            <Typography color={PWColors.light.base[900]}>15-30 min</Typography>
                        </Box>
                    </Box>
                </div>
            </Grow>
            <BaseControl label='Legend' onClick={onClick}>
                <LightningStrike />
            </BaseControl>
        </div>
    );
}

export default LegendControl;
