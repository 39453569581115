import { Grid, Typography } from '@mui/material';
import {  useEffect, useRef, useState } from 'react';
import { BaseModal, Button, Card } from '../Base';
import { LiveCamera, useLazyConsumedMinutesForCustomerQuery } from '../../Services/API';
import ReactHlsPlayer from '@gumlet/react-hls-player/dist';
import { useAppSelector } from '../../app/hooks';
import { getToken } from '../../Services/Auth/authSlice';
import { useTheme } from '@mui/styles';



export interface LiveCameraProps {
    camera: LiveCamera | undefined
    setActiveCamera: (camera: LiveCamera | undefined) => void
    setDisconnectMessage: (message: string) => void
}

export default function LiveCameraCard({camera, setActiveCamera, setDisconnectMessage}: LiveCameraProps) {
    const socketRef = useRef<WebSocket>();
    const [streamPath, setStreamPath] = useState<string>();
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const [closingMessage, setClosingMessage] = useState<string>('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const token = useAppSelector(getToken);

    //after viewing for 3 minutes, ask user to continue viewing
    //user must click continue within 30 seconds or stream will close

    const modalTimer = useRef<NodeJS.Timeout>();
    const modalTimerMilliseconds = 180000;
    const startModalTimer = () => {
        if(streamPath){
            modalTimer.current = setTimeout(()=> {
                setDialogOpen(true);
            }, modalTimerMilliseconds);
            
        }
    }

    useEffect(()=> {
        if(modalTimer.current)
            clearTimeout(modalTimer.current as NodeJS.Timeout);
        if(streamPath)
            startModalTimer();
        return () => {
            if(modalTimer.current)
                clearTimeout(modalTimer.current as NodeJS.Timeout);
        }
    },[streamPath])

    const dialogTimer = useRef<NodeJS.Timeout>();
    const dialogTimerMilliseconds = 30000;
    useEffect(()=> {
        if(dialogOpen){
             dialogTimer.current = setTimeout(()=> {
                setDialogOpen(false);
                setStreamPath(undefined);
                setActiveCamera(undefined);
                setDisconnectMessage('You have been disconnected due to inactivity.')
            }, dialogTimerMilliseconds);
        }
        return () => {
            if(dialogTimer.current)
                clearTimeout(dialogTimer.current as NodeJS.Timeout);
        }
    }, [dialogOpen])

    const cancelDialogTimeout = () => {
        if(dialogTimer.current)
            clearTimeout(dialogTimer.current as NodeJS.Timeout);
            clearTimeout(modalTimer.current as NodeJS.Timeout);
            setDialogOpen(false);
            startModalTimer();
    }
   


    useEffect(() => {
        if(socketRef.current == null && camera && token){
            socketRef.current = new WebSocket(`wss://${process.env.REACT_APP_LIVESTREAM_URL}/Stream/ws/${camera.id}`, ['access_token', token]);
            // socketRef.current = new WebSocket(`ws://localhost:2000/Stream/ws/${camera.id}`, ['access_token', token]);
            // socketRef.current = new WebSocket(`ws://localhost:5164/Stream/ws/${camera.id}`, ['access_token', token]);
            socketRef.current.onmessage = async (event) => {
                if(streamPath == undefined && (event.data as string).includes('https://')){
                    setStreamPath(event.data)
                }
                else{
                    setLoadingMessage(event.data);
                }
                if(socketRef.current)
                    socketRef.current?.send('pong');
            }
            socketRef.current.onopen = () => {
                console.log('socket opened')
                if(socketRef.current)
                    socketRef.current.send('pong');
            }
            socketRef.current.onclose = (event) => {
                console.log('server closed socket: ', event)
                if(event.reason)
                    setClosingMessage(event.reason);
                else
                    setClosingMessage('Unable to connect to camera.')
                setStreamPath(undefined);
            }
            socketRef.current.onerror = (event) => {
                console.log('error: ', event)
            }
        }
        return () => {
            if(socketRef.current)
            socketRef.current.close();

            console.log('closing socket')
        }
    }, [])

  
    const playerRef = useRef<HTMLVideoElement>(null);
    


    return (
        <Grid xs={12} item >

            <Card 
            header={camera ? camera.name : 'Select Camera'} 
            >
            {!streamPath ?
                <Grid container style={{width: "100%", aspectRatio: "2", borderRadius: "8px"}} justifyContent={"center"} alignItems={"center"}>
                    {closingMessage ? 
                        <Typography variant={"h6"}>{closingMessage}</Typography>
                        :
                        <Grid container flexDirection={"column"}>
                        <Typography variant={"h6"}>Connecting to camera...</Typography>
                        <Typography variant={"h6"}>{loadingMessage}</Typography>
                        <Typography variant={"h6"}>This might take up to 30 seconds to complete.</Typography>
                        </Grid>
                    }
                </Grid>
            :
               <ReactHlsPlayer
                    src={streamPath}
                    playerRef={playerRef}
                    autoPlay={false}
                    controls={true}
                    width="100%"
                    height="auto"
                    onError={(e) => console.log(e)}
                    preload={"none"}
                />
            }
            </Card>
            <TimeoutModal 
                dialogOpen={dialogOpen} 
                setDialogOpen={setDialogOpen}
                cancelDialogTimeout={cancelDialogTimeout}
            />
        </Grid>
    );
}

interface TimeoutModalProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    cancelDialogTimeout: () => void;
}

const TimeoutModal = ({dialogOpen, setDialogOpen, cancelDialogTimeout}: TimeoutModalProps) => {
    const [timer, setTimer] = useState(30);
    const theme = useTheme();
    useEffect(()=> {
        if(dialogOpen){
            const interval = setInterval(()=> {
                setTimer(timer => timer - 1);
            }, 1000);
            return () => {
                clearInterval(interval);
            }
        }
    }, [dialogOpen])

    return (
        <BaseModal modalState={{open: dialogOpen, handleOpen: ()=> setDialogOpen(true) , handleClose: ()=> setDialogOpen(false)}}>
            <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
                <Typography variant={"h6"}>You have been viewing this camera for 3 minutes.</Typography>
                <Typography variant={"h6"}>Would you like to continue?</Typography>
                <Typography variant={"h6"}>You will be disconnected in&nbsp;
                <span style={{color: theme.palette.error.main}}>
                    {timer} 
                </span>
                  &nbsp;seconds.</Typography>
                <Grid container justifyContent={"center"} alignItems={"center"} direction={"row"} marginTop={2}>
                    <Grid item>
                        <Button  onClick={()=> cancelDialogTimeout()}>Continue</Button>
                    </Grid>
                </Grid>
            </Grid>
        </BaseModal>
    )
}

