//0017

export const Snow = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st1'
        d='M7.79,3.45c-0.05,0-0.11,0-0.16,0.01C7.4,2.56,6.6,1.94,5.67,1.94c-1.1,0-1.99,0.88-2.02,1.97
                C3.01,3.96,2.49,4.5,2.49,5.16c0,0.69,0.56,1.25,1.25,1.25h4.05c0.82,0,1.48-0.67,1.48-1.48C9.27,4.11,8.61,3.45,7.79,3.45z
                M4.04,4.07c0-0.03,0-0.07,0-0.11c0-0.9,0.73-1.64,1.64-1.64c0.81,0,1.49,0.58,1.61,1.38c0.01,0.05,0.04,0.1,0.08,0.13
                c0.05,0.03,0.1,0.04,0.15,0.03C7.62,3.84,7.7,3.83,7.79,3.83c0.61,0,1.1,0.49,1.1,1.1s-0.49,1.1-1.1,1.1l-0.02,0
                c-0.02,0-0.04,0-0.06,0H3.74c-0.48,0-0.87-0.39-0.87-0.87c0-0.48,0.39-0.87,0.87-0.87c0.03,0,0.06,0,0.1,0.01
                c0.06,0,0.11-0.01,0.15-0.05c0.04-0.04,0.06-0.1,0.06-0.15L4.04,4.07z'
      />
      <path
        className='st1'
        d='M2.2,5.24C1.64,5.18,1.22,4.71,1.22,4.15c0-0.61,0.49-1.1,1.1-1.1c0.09,0,0.17,0.01,0.27,0.03
                c0.05,0.02,0.11,0,0.15-0.03c0.05-0.03,0.08-0.08,0.08-0.13c0.13-0.8,0.81-1.38,1.61-1.38c0.24,0,0.46,0.07,0.67,0.16
                c0.17-0.04,0.34-0.06,0.53-0.06c0.01,0,0.02,0,0.03,0C5.66,1.6,5.64,1.56,5.59,1.53C5.25,1.29,4.85,1.17,4.44,1.17
                c-0.93,0-1.72,0.62-1.95,1.51C2.43,2.67,2.38,2.67,2.32,2.67c-0.82,0-1.48,0.67-1.48,1.48c0,0.76,0.57,1.4,1.33,1.47l0.02,0
                c0.02,0,0.03-0.01,0.04-0.02C2.21,5.49,2.2,5.37,2.2,5.24z'
      />
      <path
        className='st1'
        d='M5.72,8.27L5.64,8.19l0.08-0.08c0.08-0.08,0.08-0.2,0-0.27c-0.07-0.07-0.2-0.07-0.27,0L5.37,7.92L5.29,7.84
                c-0.08-0.08-0.2-0.08-0.27,0s-0.08,0.2,0,0.27l0.08,0.08L5.02,8.27c-0.08,0.08-0.08,0.2,0,0.27C5.06,8.58,5.11,8.6,5.15,8.6
                c0.05,0,0.1-0.02,0.14-0.06l0.08-0.08l0.08,0.08C5.48,8.58,5.53,8.6,5.58,8.6c0.05,0,0.1-0.02,0.14-0.06
                C5.8,8.47,5.8,8.34,5.72,8.27z'
      />
      <g>
        <g fill='currentColor'>
          <path
            className='st1'
            d='M3.97,7.4L3.89,7.33l0.08-0.08c0.08-0.08,0.08-0.2,0-0.28c-0.08-0.08-0.2-0.08-0.27,0L3.61,7.05L3.54,6.97
                        c-0.08-0.08-0.2-0.08-0.27,0s-0.08,0.2,0,0.27l0.08,0.08L3.26,7.4c-0.08,0.08-0.08,0.2,0,0.27C3.3,7.71,3.35,7.73,3.4,7.73
                        c0.05,0,0.1-0.02,0.14-0.06L3.61,7.6l0.08,0.08c0.04,0.04,0.08,0.06,0.14,0.06c0.05,0,0.1-0.02,0.14-0.06
                        C4.04,7.6,4.04,7.48,3.97,7.4z'
          />
          <path
            className='st1'
            d='M3.33,9.02L3.25,8.95l0.08-0.08c0.08-0.08,0.08-0.2,0-0.28c-0.08-0.08-0.2-0.08-0.27,0L2.98,8.67L2.9,8.6
                        c-0.08-0.08-0.2-0.08-0.27,0c-0.08,0.08-0.08,0.2,0,0.27L2.7,8.95L2.63,9.02c-0.08,0.08-0.08,0.2,0,0.27
                        c0.04,0.04,0.09,0.06,0.14,0.06c0.05,0,0.1-0.02,0.14-0.06l0.08-0.08L3.05,9.3c0.04,0.04,0.08,0.06,0.14,0.06
                        c0.05,0,0.1-0.02,0.14-0.06C3.41,9.22,3.41,9.1,3.33,9.02z'
          />
        </g>
        <g fill='currentColor'>
          <path
            className='st1'
            d='M8.11,7.4L8.03,7.32l0.08-0.08c0.08-0.08,0.08-0.2,0-0.27c-0.07-0.07-0.2-0.07-0.27,0L7.76,7.05L7.68,6.97
                        c-0.08-0.08-0.2-0.08-0.27,0s-0.08,0.2,0,0.27l0.08,0.08L7.41,7.4c-0.08,0.08-0.08,0.2,0,0.27C7.45,7.71,7.5,7.73,7.54,7.73
                        c0.05,0,0.1-0.02,0.14-0.06L7.76,7.6l0.08,0.08c0.04,0.04,0.09,0.06,0.14,0.06c0.05,0,0.1-0.02,0.14-0.06
                        C8.19,7.6,8.19,7.48,8.11,7.4z'
          />
          <path
            className='st1'
            d='M7.48,9.03L7.4,8.95l0.08-0.08c0.08-0.08,0.08-0.2,0-0.27c-0.07-0.07-0.2-0.07-0.27,0L7.12,8.67L7.05,8.6
                        c-0.08-0.08-0.2-0.08-0.27,0c-0.08,0.08-0.08,0.2,0,0.27l0.08,0.08L6.77,9.02C6.7,9.1,6.7,9.22,6.77,9.3
                        c0.04,0.04,0.09,0.06,0.13,0.06c0.05,0,0.1-0.02,0.14-0.06l0.08-0.08L7.2,9.3c0.04,0.04,0.09,0.06,0.14,0.06
                        c0.05,0,0.1-0.02,0.14-0.06C7.55,9.22,7.55,9.1,7.48,9.03z'
          />
        </g>
      </g>
    </g>
  </svg>
);
