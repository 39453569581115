import { Box, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { ConvertToUtc, formatTimeStringFromMins, getTimeDifferenceInMins } from '../../../../features/Time/TimeHelpers';
import { Observation } from '../../../../Services/API';
import { Link } from '../../../Base';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Popup } from 'react-map-gl';

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StyledDivLink = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: 300,
}));

interface ObservationViewProps {
  clickedInfo: any;
  observation?: Observation;
  metarObservation?: Observation;
}

const renderStationData = (observation: Observation) => {
  var stationName =
    observation?.type !== undefined
      ? observation?.type >= 0
        ? observation.weatherStation?.name
        : observation.metarStation?.name
      : '';

  var conditionsStr = observation?.data?.weather_code ? observation.data.weather_code.text : '--';
  var obsTimeStr = observation?.data?.observationTime
    ? 'Updated ' +
      formatTimeStringFromMins(Math.round(getTimeDifferenceInMins(ConvertToUtc(observation.data.observationTime))))
    : '';

  var tempStr = observation?.data?.ambientTemperature
    ? Math.round(observation.data.ambientTemperature.value) + '°' + observation.data.ambientTemperature.unit
    : '--';
  var windSpeedStr = observation?.data?.windSpeed
    ? observation.data.windDirection +
      ' ' +
      Math.round(observation.data.windSpeed.value) +
      ' ' +
      observation.data.windSpeed.unit
    : '--';
  var windGustStr = observation?.data?.windGust
    ? observation.data.windDirection +
      ' ' +
      Math.round(observation.data.windGust.value) +
      ' ' +
      observation.data.windGust.unit
    : '--';

  var feelStr = observation?.data?.feelLike
    ? observation.data.feelLike.value.toFixed(1) + '°' + observation.data.feelLike.unit
    : '--';
  var wbgtStr = observation?.data?.wgbt
    ? observation.data.wgbt.value.toFixed(1) + '°' + observation.data.wgbt.unit
    : '--';
  var precipTodayStr = observation?.data?.precipitation
    ? observation.data.precipitation.value.toFixed(2) + ' ' + observation.data.precipitation.unit
    : '--';

  return (
    <StyledBox>
      <Typography variant='subtitle1'>Station Name:</Typography>
      <Typography variant='subtitle1'>{stationName}</Typography>
      <Typography variant='body1'>{obsTimeStr}</Typography>
      <hr style={{ backgroundColor: '#fff', width: '100%' }} />
      <StyledDiv>
        <Typography variant='caption'>Conditions:</Typography>
        <Typography variant='caption'>{conditionsStr}</Typography>
      </StyledDiv>
      <StyledDiv>
        <Typography variant='caption'>Temperature:</Typography>
        <Typography variant='caption'>{tempStr}</Typography>
      </StyledDiv>
      <StyledDiv>
        <Typography variant='caption'>Wind Speed:</Typography>
        <Typography variant='caption'>{windSpeedStr}</Typography>
      </StyledDiv>
      <StyledDiv>
        <Typography variant='caption'>Wind Gust:</Typography>
        <Typography variant='caption'>{windGustStr}</Typography>
      </StyledDiv>
      <StyledDiv>
        <Typography variant='caption'>Feels:</Typography>
        <Typography variant='caption'>{feelStr}</Typography>
      </StyledDiv>
      <StyledDiv>
        <Typography variant='caption'>WBGT:</Typography>
        <Typography variant='caption'>{wbgtStr}</Typography>
      </StyledDiv>
      <StyledDiv>
        <Typography variant='caption'>Precipitation Today:</Typography>
        <Typography variant='caption'>{precipTodayStr}</Typography>
      </StyledDiv>
    </StyledBox>
  );
};

export function ObservationView(props: ObservationViewProps) {
  const { clickedInfo, observation, metarObservation } = props;

  const [preferred, setPreferred] = useState(true);

  var isMetar = observation?.type === -1 || !metarObservation;

  return (
    <Popup longitude={clickedInfo.lngLat.lng} latitude={clickedInfo.lngLat.lat} anchor='bottom'>
      {!isMetar && (
        <StyledDivLink>
          <Link onClick={() => setPreferred(!preferred)}>
            <Typography>{preferred ? 'METAR' : 'Preferred'}</Typography>
            <ArrowForwardIcon sx={{ width: 20, height: 16 }} />
          </Link>
        </StyledDivLink>
      )}
      {preferred
        ? observation && renderStationData(observation)
        : metarObservation && renderStationData(metarObservation)}
    </Popup>
  );
}

export default ObservationView;
