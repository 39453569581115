//9005

export const Cloudy = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <g fill='currentColor'>
        <path
          className='st4'
          d='M7.64,4.67c-0.05,0-0.1,0-0.16,0.01C7.27,3.81,6.5,3.21,5.6,3.21c-1.06,0-1.93,0.85-1.95,1.91
                C3.01,5.16,2.51,5.69,2.51,6.33c0,0.67,0.54,1.21,1.21,1.21h3.92c0.79,0,1.44-0.64,1.44-1.44C9.08,5.31,8.43,4.67,7.64,4.67z
                    M3.73,7.17c-0.46,0-0.84-0.38-0.84-0.84c0-0.46,0.38-0.84,0.84-0.84c0.03,0,0.06,0,0.09,0.01c0.06,0,0.11-0.01,0.15-0.05
                C4.01,5.4,4.03,5.35,4.02,5.3c0-0.04-0.01-0.08-0.01-0.13c0-0.87,0.71-1.58,1.58-1.58c0.78,0,1.44,0.56,1.56,1.33
                c0.01,0.05,0.04,0.1,0.08,0.13c0.04,0.03,0.1,0.04,0.15,0.03c0.09-0.02,0.18-0.03,0.26-0.03c0.59,0,1.06,0.48,1.06,1.06
                c0,0.59-0.48,1.06-1.06,1.06c0,0-0.01,0-0.01,0c-0.02,0-0.04,0-0.06,0H3.73z'
        />
      </g>
      <path
        className='st4'
        d='M2.14,6.38C1.66,6.27,1.3,5.86,1.3,5.35c0-0.59,0.48-1.06,1.06-1.06c0.08,0,0.17,0.01,0.26,0.03
            c0.05,0.01,0.11,0,0.15-0.03c0.04-0.03,0.08-0.08,0.08-0.13C2.97,3.4,3.62,2.84,4.4,2.84c0.19,0,0.37,0.04,0.55,0.11
            c0.19-0.06,0.39-0.09,0.59-0.1C5.53,2.84,5.53,2.82,5.52,2.81C5.19,2.58,4.81,2.46,4.4,2.46c-0.9,0-1.67,0.6-1.89,1.46
            C1.66,3.83,0.92,4.51,0.92,5.35c0,0.73,0.55,1.34,1.29,1.42C2.17,6.65,2.15,6.52,2.14,6.38z'
      />
    </g>
  </svg>
);
