import { useTheme } from '@mui/material';
import React from 'react';
import { _useMapControl as useMapControl } from 'react-map-gl';

interface BaseControlProps {
  children: React.ReactElement;
  label: string;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function BaseControl(props: BaseControlProps) {
  const { children, onClick, label, selected } = props;
  const theme = useTheme();
  // essentially need position absolute if being used standalone, i.e, not inside the group.
  // const style = useMemo(() => ({position: 'absolute', ...props.style}), [props.style]);
  // const [show, setShow] = useState(false);
  const { containerRef } = useMapControl({
    onDragStart: (evt: any) => {},
    onDoubleClick: (evt: any) => {
      evt.stopPropagation();
    },
    onClick: (evt: any) => {
      evt.stopPropagation();
    },
  });

  const type = 'test';
  return (
    <div
      className='mapboxgl-ctrl mapboxgl-ctrl-group'
      style={{ backgroundColor: selected ? theme.palette.primary.main : 'white' }}
      ref={containerRef}>
      <button
        key={type}
        className={`mapboxgl-ctrl-icon mapboxgl-ctrl-${type}`}
        type='button'
        title={label}
        onClick={onClick}>
        {children}
      </button>
    </div>
  );
}
