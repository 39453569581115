import { Box, Grid, GridProps, Typography, useTheme } from '@mui/material';
import { Tooltip } from '../Base';
import { Checkmark } from '../../Assets/Checkmark';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

interface HardwareStatusProps {
  tooltipText: NonNullable<React.ReactNode>;
  status: 'Healthy' | 'Unhealthy';
  statusText?: string | JSX.Element;
  gridProps?: GridProps;
}

export function HardwareStatus(props: HardwareStatusProps) {
  const { status, statusText = status, tooltipText, gridProps } = props;
  const theme = useTheme();

  if (status === 'Healthy') {
    return (
      <Tooltip title={tooltipText} arrow placement='bottom' enterDelay={300} leaveDelay={200}>
        <Grid {...gridProps}>
          <Box>
            <Checkmark color={theme.palette.success.main} />
          </Box>
          <Typography color={theme.palette.success.main}>{statusText}</Typography>
        </Grid>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={tooltipText} arrow placement='bottom' enterDelay={300} leaveDelay={200}>
        <Grid {...gridProps}>
          <Box>
            <WarningAmberRoundedIcon sx={{ color: theme.palette.error.main }} />
          </Box>
          <Typography color={theme.palette.error.main}>{statusText}</Typography>
        </Grid>
      </Tooltip>
    );
  }
}

export default HardwareStatus;
