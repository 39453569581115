//2020-Night

export const ChanceOfFreezingRainNight = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 10 10'>
    <g fill='currentColor'>
      <path
        className='st0'
        d='M2.55,5.92c-0.54-0.07-1.03-0.33-1.4-0.73c0.04,0,0.07,0,0.11,0c1.51,0,2.73-1.23,2.73-2.73
                c0-0.34-0.06-0.68-0.19-0.99c0.5,0.21,0.9,0.59,1.15,1.07c0.02,0.04,0.06,0.05,0.1,0.03c0.06-0.03,0.14-0.06,0.22-0.09
                c0.02-0.01,0.04-0.02,0.04-0.04c0.01-0.02,0.01-0.04,0-0.06c-0.36-0.72-1.02-1.24-1.8-1.43C3.38,0.92,3.27,0.9,3.16,0.89
                c-0.03,0-0.06,0.01-0.07,0.04c-0.02,0.02-0.02,0.06,0,0.08c0.06,0.08,0.13,0.17,0.18,0.27C3.49,1.64,3.6,2.05,3.6,2.46
                c0,1.29-1.05,2.34-2.34,2.34c-0.15,0-0.3-0.01-0.45-0.04C0.71,4.73,0.6,4.7,0.5,4.66c-0.03-0.01-0.06,0-0.08,0.02
                C0.4,4.71,0.39,4.74,0.4,4.77c0.05,0.11,0.11,0.21,0.17,0.31c0.45,0.7,1.19,1.15,2.02,1.24H2.6c0.02,0,0.04-0.01,0.06-0.03
                c0.02-0.02,0.02-0.05,0.01-0.07C2.65,6.15,2.63,6.07,2.62,5.98C2.61,5.95,2.59,5.92,2.55,5.92z'
      />
      <path
        className='st0'
        d='M8.12,4.05c-0.05,0-0.11,0-0.16,0.01C7.73,3.16,6.93,2.54,6,2.54c-1.1,0-2,0.88-2.03,1.98
                C3.31,4.57,2.79,5.11,2.79,5.78c0,0.69,0.56,1.26,1.26,1.26h4.08c0.82,0,1.49-0.67,1.49-1.49C9.61,4.72,8.95,4.05,8.12,4.05z
                M4.35,4.57c0-0.91,0.74-1.65,1.65-1.65c0.82,0,1.5,0.58,1.62,1.39c0.01,0.05,0.04,0.1,0.08,0.13s0.1,0.04,0.15,0.03
                c0.1-0.02,0.19-0.03,0.27-0.03c0.61,0,1.11,0.5,1.11,1.11s-0.5,1.11-1.11,1.11H8.1c-0.02,0-0.04,0-0.06,0H4.05
                c-0.48,0-0.87-0.39-0.87-0.88c0-0.48,0.39-0.87,0.87-0.87c0.03,0,0.07,0,0.1,0.01c0.05,0,0.11-0.02,0.15-0.05
                C4.34,4.82,4.36,4.76,4.36,4.7C4.35,4.66,4.35,4.62,4.35,4.57z'
      />
      <path
        className='st0'
        d='M2.98,7.55L2.56,8.38c-0.04,0.09,0,0.21,0.09,0.26c0.03,0.01,0.06,0.01,0.09,0.01c0.07,0,0.14-0.04,0.17-0.1
                l0.42-0.83c0.05-0.09,0.01-0.21-0.08-0.26C3.15,7.42,3.03,7.46,2.98,7.55z'
      />
      <path
        className='st0'
        d='M8.9,7.55L8.48,8.38C8.44,8.47,8.47,8.59,8.57,8.64C8.6,8.65,8.63,8.65,8.65,8.65c0.07,0,0.14-0.04,0.17-0.1
                l0.42-0.83c0.05-0.09,0.01-0.21-0.08-0.26C9.06,7.42,8.95,7.46,8.9,7.55z'
      />
      <path
        className='st0'
        d='M6.05,7.46C6,7.47,5.96,7.51,5.94,7.55L5.53,8.38C5.51,8.43,5.5,8.48,5.52,8.53s0.05,0.09,0.1,0.11
                c0.01,0,0.04,0.01,0.07,0.01c0.07,0,0.14-0.04,0.17-0.11l0.41-0.83c0.02-0.05,0.03-0.1,0.01-0.15c-0.02-0.05-0.05-0.09-0.1-0.11
                C6.15,7.44,6.1,7.44,6.05,7.46z'
      />
      <g fill='currentColor'>
        <path
          className='st0'
          d='M4.74,8.23L4.66,8.15l0.08-0.08c0.08-0.08,0.08-0.2,0-0.28c-0.08-0.08-0.2-0.08-0.27,0L4.39,7.88L4.31,7.8
                    c-0.08-0.08-0.2-0.08-0.27,0s-0.08,0.2,0,0.27l0.08,0.08L4.04,8.23c-0.08,0.08-0.08,0.2,0,0.27c0.04,0.04,0.09,0.06,0.14,0.06
                    c0.05,0,0.1-0.02,0.14-0.06l0.08-0.08L4.46,8.5C4.5,8.54,4.55,8.56,4.6,8.56c0.05,0,0.1-0.02,0.14-0.06
                    C4.82,8.43,4.81,8.3,4.74,8.23z'
        />
        <path
          className='st0'
          d='M7.76,8.23L7.68,8.15l0.08-0.08c0.08-0.08,0.08-0.2,0-0.27c-0.07-0.07-0.2-0.07-0.27,0L7.41,7.88L7.33,7.8
                    c-0.08-0.08-0.2-0.08-0.27,0s-0.08,0.2,0,0.27l0.08,0.08L7.06,8.23c-0.08,0.08-0.08,0.2,0,0.27C7.1,8.54,7.15,8.56,7.19,8.56
                    c0.05,0,0.1-0.02,0.14-0.06l0.08-0.08L7.48,8.5c0.04,0.04,0.09,0.06,0.14,0.06c0.05,0,0.1-0.02,0.14-0.06
                    C7.84,8.43,7.84,8.3,7.76,8.23z'
        />
      </g>
    </g>
  </svg>
);
