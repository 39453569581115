import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';
import { UserErrorModel, PAMessageErrorModel, SiteErrorModel, PolicyErrorsModel, LocationErrorModel } from '.';
import { CommunicationErrorModel } from './communicationsApi';
import { baseQueryWithReauth, PWError } from './sharedApiUtils';

export interface WeatherModel {
  value: number;
  unit: string;
}
export interface WeatherCode {
  value: string;
  text: string;
}

export interface ServerErrorModel {
  ServerError?: string[];
}

export interface ModelStateErrorResponse {
  data: {
    responseException?: {
      errors?:
      | PAMessageErrorModel
      | UserErrorModel
      | SiteErrorModel
      | PolicyErrorsModel
      | LocationErrorModel
      | CommunicationErrorModel
      | ServerErrorModel;
    };
  };
}

export const API_URL = process.env.REACT_APP_API_URL!;

export const api = createApi({
  baseQuery: baseQueryWithReauth(API_URL) as BaseQueryFn<string | FetchArgs, unknown, PWError | ModelStateErrorResponse, {}>,
  tagTypes: [
    'Policies',
    'Sites',
    'CommunicationHistory',
    'Users',
    'NotiUsers',
    'Hardware',
    'Locations',
    'NotificationSettings',
    'Widget',
    'WeatherStation',
    'Webhooks',
    'Integrations.Singlewire',
    'AirQuality',
  ],
  refetchOnMountOrArgChange: 60,
  endpoints: () => ({}),
});
