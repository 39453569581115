export const LightningStrike2 = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width='16' height='22' viewBox='0 0 16 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9672 0.759766L0.959961 12.2969H6.90081L3.73606 21.2398L15.1944 9.05084H9.5381L11.9672 0.759766Z'
      fill={props.color}
    />
  </svg>
);
